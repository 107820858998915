import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Badge, Button, List, Modal, Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { getTitle, getIcon } from '../../common/NotificationUtil';
import { LoanReject } from '../../../redux/actions/dashboard/loanReject.action';
import { UserTypes } from '../../common/userTypes';
import { useSelector } from 'react-redux';

const PropertyNotificationModal = ({ list = [], clearNotifications, isTaxAccountant = false, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [spin, setSpin] = useState(false);

  const admin = JSON.parse(localStorage.getItem('admin'));

  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);

  const clientVirtualEmail = clientDetail?.data?.result?.virtual_email;

  const propertyAddress = list?.find((item) => item.type === 'check_investment_rent_mail');

  const NOLoan = (id) => {
    setSpin(true);
    dispatch(LoanReject(id)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
      }
      setSpin(false);
      clearNotifications(true, id);
    });
  };

  const renderActionItem = (item) => {
    if (item.type === 'check_property_loan' && admin.user_type === UserTypes.USER) {
      return !item.loan_id ? (
        <>
          <Button size="middle" className="mr-2" onClick={() => navigate(`/property/edit/${item.property_id}`, { state: { fromSameSite: true } })}>
            Update
          </Button>
          <Button size="middle" onClick={() => NOLoan(item.property_id)}>
            Loan Not Applicable
          </Button>
        </>
      ) : (
        <Button size="middle" onClick={() => navigate(`/bank`)}>
          Connect
        </Button>
      );
    } else if (item.type === 'check_deprecation_mail') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {/* {item.property_id ? <Button onClick={() => RemindDepreciation(item.property_id)}>Remind me later</Button> : ''} */}
        </div>
      );
    } else if (item.type === 'check_settlement_mail') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {/* {item.property_id ? <Button onClick={() => RemindSettlement(item.property_id)}>Remind me later</Button> : ''} */}
        </div>
      );
    } else if (item.type === 'check_investment_rent_mail') {
      return (
        <Button size="middle" onClick={() => setIsOpenEmail(true)}>
          Email Now
        </Button>
      );
    } else {
      return '';
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    notification.success({
      message: 'Success',
      description: 'Text copied.',
    });
  };

  const emailText = () => {
    const encodedEmailContent = encodeURIComponent(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    // Create the mailto link
    const mailtoLink = `mailto:${admin?.virtual_email}?subject=Rent Summary&body=${encodedEmailContent}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <>
      <Modal
        onCancel={() => clearNotifications()}
        title="Property Notification"
        footer={null}
        centered
        style={{ zIndex: '1005', borderRadius: '1em' }}
        className="property-notification-modal"
        open={list.length > 0}
      >
        <Spin spinning={spin || loading}>
          <List
            itemLayout="horizontal"
            dataSource={list || []}
            renderItem={(item, index) => (
              <List.Item key={index} style={item?.complete ? { background: '#f6f6f6' } : {}}>
                <List.Item.Meta
                  className={'meta'}
                  avatar={getIcon(item?.type)}
                  title={
                    <>
                      {!item?.complete ? (
                        <div className={'title'}>
                          {getTitle(item?.type)}
                          {!item?.complete && <div className="extra">{renderActionItem(item)}</div>}
                        </div>
                      ) : (
                        <Badge.Ribbon text="Completed" color="#56aaaa">
                          <div className={'title'}>
                            {getTitle(item?.type)}
                            {!item?.complete && <div className="extra">{renderActionItem(item)}</div>}
                          </div>
                        </Badge.Ribbon>
                      )}
                    </>
                  }
                  description={item?.message}
                />
              </List.Item>
            )}
          />
        </Spin>
      </Modal>

      {/* EMAILNOW MODAL */}
      <Modal onCancel={() => setIsOpenEmail(false)} footer={null} centered width={550} style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpenEmail}>
        <div ref={ref}>
          Dear Property Manager, <br />
          <br />
          How are you? I hope you are doing well.
          <br />
          <br />
          Going forward, please add my below email to send my monthly rental summary for all my properties "{isTaxAccountant ? clientVirtualEmail : admin?.virtual_email}"
          <br />
          <br />
          Property Details as below : <br />
          {propertyAddress?.property_address}
          <br />
          <br />
          Please confirm by email once this has been action. <br />
          <br />
          Have a nice day <br />
          Regards
        </div>

        <div className="p-2 text-end">
          <Button className="copy_btn3" onClick={() => copyText()}>
            Copy
          </Button>
          <Button className="copy_btn1" onClick={() => emailText()}>
            Send Mail
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PropertyNotificationModal;
