import { createSlice } from "@reduxjs/toolkit";

const initialState = { BankData: [], error: false, isLoading: true };

export const getBankDetailsSlice = createSlice({
    name: "GetDashboardBankData",
    initialState,
    reducers: {
        getBankDetailsRequest: (state, action) => {
            state.BankData = [];
            state.isLoading = true;
        },
        getBankDetailsSuccess: (state, action) => {
            state.BankData = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getBankDetailsFailure: (state, action) => {
            state.BankData = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getBankDetailsRequest, getBankDetailsSuccess, getBankDetailsFailure } =
    getBankDetailsSlice.actions;

export default getBankDetailsSlice.reducer;
