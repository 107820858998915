import { postRequest } from '../../../utils/apiUtils';
import { verifyUserSuccess } from '../../reducers/slices/auth/verifyUser.slice';

export const sendOTPMobileVerification = (data) => {
  return async () => {
    const { result, error } = await postRequest(`auth/verification/send-otp`, data);

    if (!error) {
      return result;
    }
    return error;
  };
};

export const verifyOTPMobileVerification = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`auth/verification/verify-otp`, data);

    if (!error) {
      if (result?.success === true) {
        await dispatch(verifyUserSuccess(result));
      }
      return result;
    }
    return result;
  };
};
