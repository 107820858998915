import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const OtpVerificationSlice = createSlice({
    name: "ForgotPassword",
    initialState,
    reducers: {
        OtpVerificationRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        OtpVerificationSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        OtpVerificationFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { OtpVerificationRequest, OtpVerificationSuccess, OtpVerificationFailure } = OtpVerificationSlice.actions;

export default OtpVerificationSlice.reducer;
