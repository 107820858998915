import { getRequest } from '../../../utils/apiUtils';
import { getclientDetailByIdByIdFailure, getclientDetailByIdByIdRequest, getclientDetailByIdByIdSuccess } from '../../reducers/slices/client-detail/getclientdetailById.slice';

export const GetclientDetailById = (id, type) => {
  return async (dispatch) => {
    await dispatch(getclientDetailByIdByIdRequest());
    const { result, error } = await getRequest(`${type === 1 ? 'tax-accountant' : 'channel-partner'}/client/${id}`);
    if (!error) {
      return await dispatch(getclientDetailByIdByIdSuccess(result));
    }
    return await dispatch(getclientDetailByIdByIdFailure(result));
  };
};
