import { Button, Col, Collapse, Drawer, Flex, notification, Popover, Row, Table, theme } from 'antd';
import React, { useState } from 'react';
import EditRecordExpenses from '../../../record_expenses/edit';
import { getTransactionTypeLabel } from '../../../../../utils/ConstLabel';
import dayjs from 'dayjs';
import { CategoryType, TrensectionType } from '../../../../common/userTypes';
import CustomIcon from '../../../../sidebar/custIcon';
import editIcon from '../../../../../assets/images/edit.svg';
import { GetReportTransactionData } from '../../../../../redux/actions/reports/getReport.action';
import { useDispatch } from 'react-redux';
import { CheckCircleOutlined, CloseCircleOutlined, EyeFilled, InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import DT from '../../../../common/DT';
import RecordExpenses from '../../../record_expenses';
import CategoryList from './CategoryList';
// import LoanChart from './LoanChart';
import { amountFormat, getYearList } from '../../../../../utils';
import './index.less';
import { getRentInputAmount } from '../../../../../redux/actions/AuditTrail/getAuditTrailList.action';
import { GetReciptdocumentationdData } from '../../../../../redux/actions/client-detail/clients-tabs/documentRecipt.action';

export default function AuditOutput({ auditYear, getAuditList, auditData }) {
  const { token } = theme.useToken();
  const dispatch = useDispatch();

  const [openTransaction, setOpenTransaction] = useState(false);
  const [openEOFYTransaction, setOpenEOFYTransaction] = useState(false);
  const [openTransactionEdit, setOpenTransactionEdit] = useState(false);
  const [openTransactionAdd, setOpenTransactionAdd] = useState(false);
  const [transactionEditValue, setTransactionEditValue] = useState(null);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [transactionEOFYData, setTransactionEOFYData] = useState([]);
  const [tranParamValues, setTranParamValues] = useState({});

  const currentClient = localStorage.getItem('currentClient');

  const onCloseTransaction = () => {
    setOpenTransaction(false);
  };

  const onCloseEOFYTransaction = () => {
    setOpenEOFYTransaction(false);
  };

  const onCloseTransactionEdit = () => {
    setOpenTransactionEdit(false);
  };
  const onCloseTransactionAdd = () => {
    setOpenTransactionAdd(false);
  };

  const onClickCategoryValue = (propertyId = null, categoryId, year = null, transactionType = null, loanId = null, interestId = null, isTotalLoan = null, fromAudit = false) => {
    setTransactionLoading(true);
    const parm = {};
    if (propertyId) {
      parm.propertyId = propertyId;
    }
    parm.categoryId = categoryId;
    if (year) {
      parm.year = year;
    }

    if (transactionType) {
      parm.transactionType = transactionType;
    }
    if (loanId) {
      parm.loanId = loanId;
    }

    if (interestId) {
      parm.interestId = interestId;
    }

    if (isTotalLoan) {
      parm.isTotalLoan = isTotalLoan;
    }

    if (fromAudit) {
      parm.auditData = true;
    }

    setTranParamValues({ ...parm });

    dispatch(GetReportTransactionData(parm, currentClient)).then((res) => {
      if (res.payload.success === true) {
        setTransactionData(res.payload.result);
      }

      setTransactionLoading(false);
    });
    setOpenTransaction(true);
  };

  const onClickCategoryInputValue = (propertyId = null, year = null) => {
    setTransactionLoading(true);

    dispatch(getRentInputAmount(propertyId, year, currentClient)).then((res) => {
      if (res.success === true) {
        setTransactionEOFYData(res.result);
      }

      setTransactionLoading(false);
    });
    setOpenEOFYTransaction(true);
  };

  const getBankDocument = (id, type) => {
    setTransactionLoading(true);
    dispatch(GetReciptdocumentationdData(id, currentClient, type)).then((res) => {
      if (res?.payload?.success) {
        setTransactionLoading(false);
        window.open(res?.payload?.result?.url, '_blank');
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setTransactionLoading(false);
      }
    });
  };

  const panelStyle = {
    marginBottom: 24,
    background: 'rgb(229 229 229)',
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const getItems = (panelStyle) => [
    {
      key: '1',
      label: (
        <div className="d-flex flex-row gap-2 align-items-center">
          <h6 className="m-0 underline-text">Rent Reconciliation</h6>
          <Popover
            placement="right"
            overlayStyle={{ maxWidth: '600px' }}
            title={
              <div className="fs-6">
                <p className="tooltipChildCss">Easily check for differences between your End of Year Statement and the Monthly Rent statements from your property manager.</p>
                <ul className="tooltipChildCss">
                  <li>If rent summaries are emailed to your unique ID, simply review the variance.</li>
                  <li>Otherwise, forward the End of Year Rent summary to your unique email or manually add the annual rent amount.</li>
                </ul>
              </div>
            }
          >
            <InfoCircleOutlined className="cursor-pointer" />
          </Popover>
        </div>
      ),
      children: (
        <div className="audit-table-parent">
          <Table pagination={false} bordered={false} size="small" className="m-0 p-0" dataSource={auditData?.property_rent_data} columns={rent_income_column} />
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '2',
      label: (
        <div className="d-flex flex-row gap-2 align-items-center">
          <h6 className="m-0 underline-text">Interest on Loan Reconciliation</h6>
          <Popover
            overlayStyle={{ maxWidth: '600px' }}
            placement="right"
            title={
              <div className="fs-6">
                <p className="tooltipChildCss">Match your direct transaction feed's interest with the year-end interest summary from your banks.</p>
                <ul className="tooltipChildCss">
                  <li>Input the interest amounts for each loan account and review any differences.</li>
                  <li>This ensures all interest costs for your property investments are accurately captured.</li>
                </ul>
              </div>
            }
          >
            <InfoCircleOutlined className="cursor-pointer" />
          </Popover>
        </div>
      ),
      children: (
        <div className="audit-table-parent">
          <Table
            pagination={false}
            size="small"
            rowKey={(record) => record.id}
            dataSource={[...auditData?.property_loan_data, ...auditData?.property_extra_loan_data]}
            columns={bank_data_column}
            expandable={{
              expandedRowRender: (record) => <Table pagination={false} size="small" dataSource={record?.property_array} columns={bank_data_property_column} />,
              rowExpandable: (record) => record?.property_array?.length > 0,
            }}
          />
        </div>
      ),
      style: panelStyle,
    },
    // {
    //   key: '3',
    //   label: <h6 className="underline-text">Loan Balance Charts</h6>,
    //   children: (
    //     <div className="audit-table-parent">
    //       <LoanChart auditDataChart={auditDataChart} />
    //     </div>
    //   ),
    //   style: panelStyle,
    // },
    {
      key: '4',
      label: (
        <div className="d-flex flex-row gap-2 align-items-center">
          <h6 className="m-0 underline-text">Other Expenses Reconciliation</h6>
          <Popover
            placement="right"
            overlayStyle={{ maxWidth: '600px' }}
            title={
              <div className="fs-6">
                <p className="tooltipChildCss">Ensure all property-related expenses (e.g., land tax, council rates, repairs) are accounted for.</p>
                <ul className="tooltipChildCss">
                  <li>We compare last year’s expenses with this year’s to help you identify any missed items.</li>
                  <li>Expenses with nil or lower amounts than last year are highlighted in RED for easy focus.</li>
                </ul>
              </div>
            }
          >
            <InfoCircleOutlined className="cursor-pointer" />
          </Popover>
        </div>
      ),
      children: (
        <div className="audit-table-parent">
          <CategoryList category_list={auditData.category_tbl_data || []} auditYear={auditYear} onClickCategoryValue={onClickCategoryValue} />
        </div>
      ),
      style: panelStyle,
    },
  ];
  const columns = [
    {
      title: <span>Transaction Source</span>,
      dataIndex: 'transaction_type',
      key: 'expense_type',
      render: (_text, record) => {
        return getTransactionTypeLabel(Number(record?.transaction_type));
      },
    },
    {
      title: <span>Date</span>,
      dataIndex: 'transaction_date',
      key: 'expense_date',
      render: (text) => {
        const formattedDate = dayjs(text).format('DD-MM-YYYY');
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: <span>Price</span>,
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record) => {
        return (
          <span
            className="text-decoration-underline cursor-pointer"
            onClick={(e) => {
              if (
                [
                  TrensectionType.RENT_TYPE_TRANSACTION,
                  TrensectionType.MANUAL_TYPE_TRANSACTION,
                  TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
                  TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
                  TrensectionType.BASIQ_TYPE_TRANSACTION,
                ].includes(Number(record.transaction_type))
              ) {
                e.preventDefault();
                setOpenTransactionEdit(true);
                setTransactionEditValue({ id: record.id, state: { TransectionType: Number(record.transaction_type), PropertyId: record?.property_id, LoanId: record?.loan_id } });
              }
            }}
          >
            {`$${amountFormat(text || 0)}`}
          </span>
        );
      },
    },
    {
      title: <span>Edit/View</span>,
      dataIndex: 'action',
      key: 'action',
      render: (_text, record) => {
        if (
          ![
            TrensectionType.RENT_TYPE_TRANSACTION,
            TrensectionType.MANUAL_TYPE_TRANSACTION,
            TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
            TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
            TrensectionType.BASIQ_TYPE_TRANSACTION,
          ].includes(Number(record.transaction_type))
        ) {
          return '-';
        }
        return (
          <Row>
            <Col>
              <Button
                className="mr-2"
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTransactionEdit(true);
                  setTransactionEditValue({ id: record.id, state: { TransectionType: Number(record.transaction_type), PropertyId: record?.property_id, LoanId: record?.loan_id } });
                }}
                icon={record.locked ? <EyeFilled /> : <CustomIcon icon={editIcon} />}
                size="small"
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const columnsEOFY = [
    {
      title: <span>Financial Year</span>,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_text) => {
        return <span>{getYearList()?.find((item) => item?.value === auditYear)?.label}</span>;
      },
    },
    {
      title: <span>Amount</span>,
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record) => {
        return (
          <span
            className="cursor-pointer text-decoration-underline"
            onClick={(e) => {
              e.preventDefault();
              getBankDocument(record.id, 3);
            }}
          >
            {amountFormat(text || 0)}
          </span>
        );
      },
    },
    {
      title: <span>View</span>,
      dataIndex: 'action',
      key: 'action',
      render: (_text, record) => {
        return (
          <Row>
            <Col>
              <Button
                className="mr-2"
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  getBankDocument(record.id, 3);
                }}
                icon={<EyeFilled />}
                size="small"
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const rent_income_column = [
    {
      title: <span>Property</span>,
      dataIndex: 'label',
      key: 'label',
      render: (_text, record) => {
        const property_name = record.street_number ? record.street_number + (record.street_name ? '  ' + record.street_name : '') : '';
        return Number(record.sold_property) === 1 ? `${property_name} (Sold)` : property_name;
      },
    },
    {
      title: <span>EOFY Summary</span>,
      dataIndex: 'total_user_input_amount',
      key: 'total_user_input_amount',
      align: 'right',
      render: (text, record) => {
        if (record.fy_rent_count === 0) {
          return amountFormat(text || 0);
        }
        return (
          <span className="cursor-pointer" onClick={() => onClickCategoryInputValue(record.property_id, auditYear)}>
            {amountFormat(text || 0)}
          </span>
        );
      },
    },
    {
      title: <span>Monthly Rental Summary</span>,
      dataIndex: 'tpa_amount',
      key: 'tpa_amount',
      align: 'right',
      render: (text, record) => {
        return (
          <Button type="text" className="cursor-pointer" onClick={() => onClickCategoryValue(record.property_id, CategoryType.RENT_CATEGORY, auditYear, null, null, null, null, true)}>
            {amountFormat(text || 0)}
          </Button>
        );
      },
    },
    {
      title: <span>Variance</span>,
      dataIndex: 'variance',
      key: 'variance',
      align: 'right',
      render: (_text, record) => {
        return amountFormat(Number(record.total_user_input_amount - record.tpa_amount) || 0);
      },
      onCell: (record) => {
        const variance = record.total_user_input_amount - record.tpa_amount;
        if (variance !== 0) {
          return {
            style: { color: 'red' },
          };
        }
      },
    },
    {
      title: ' ',
      dataIndex: 'status',
      key: 'status',
      width: '5',
      render: (_text, record) => {
        return record.total_user_input_amount - record.tpa_amount === 0 ? <CheckCircleOutlined className="audit-success-icon" /> : <CloseCircleOutlined className="audit-error-icon" />;
      },
    },
  ];

  const bank_data_column = [
    {
      title: <span>Loan</span>,
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => {
        return record.loan_id || record?.bank_account_number !== null ? `${record.bank_bsb_number}-${record.bank_account_number} (${record.bank_name})` : 'Directly Added Loans';
      },
    },
    {
      title: <span>Input Interest</span>,
      dataIndex: 'user_input_amount',
      key: 'user_input_amount',
      align: 'right',
      render: (text, record) => {
        return amountFormat(text || record.amount || 0);
      },
    },
    {
      title: <span>The Property Accountant</span>,
      children: [
        {
          title: <span>Interest Capitalized</span>,
          dataIndex: 'tpa_capi_amount',
          key: 'interest_capitalized', // Add a unique key prop
          align: 'right',
          render: (text, record) => {
            if (record.amount) {
              return 0;
            }
            return (
              <Button
                className="cursor-pointer"
                type="text"
                onClick={() =>
                  onClickCategoryValue(
                    null,
                    CategoryType.INTEREST_CAPITALIZED_CATEGORY_ID,
                    auditYear,
                    record.loan_id
                      ? [TrensectionType.BASIQ_TYPE_TRANSACTION]
                      : [TrensectionType.RENT_TYPE_TRANSACTION, TrensectionType.SETTLEMENT_TYPE_TRANSACTION, TrensectionType.MANUAL_TYPE_TRANSACTION],
                    record.loan_id,
                    record?.interest_rate_id,
                    true,
                  )
                }
              >
                {amountFormat(text || 0)}
              </Button>
            );
          },
        },
        {
          title: <span>Interest On Loan</span>,
          dataIndex: 'tpa_loan_amount',
          key: 'interest_on_loan', // Add a unique key prop
          align: 'right',
          render: (text, record) => {
            if (record.amount) {
              return 0;
            }
            return (
              <Button
                type="text"
                className="cursor-pointer"
                onClick={() =>
                  onClickCategoryValue(
                    null,
                    CategoryType.INTEREST_ON_LOAN_CATEGORY_ID,
                    auditYear,
                    record.loan_id
                      ? [TrensectionType.BASIQ_TYPE_TRANSACTION]
                      : [TrensectionType.RENT_TYPE_TRANSACTION, TrensectionType.SETTLEMENT_TYPE_TRANSACTION, TrensectionType.MANUAL_TYPE_TRANSACTION],
                    record.loan_id,
                    record?.interest_rate_id,
                    true,
                  )
                }
              >
                {amountFormat(text || 0)}
              </Button>
            );
          },
        },
      ],
    },
    {
      title: <span>Variance</span>,
      dataIndex: 'variance',
      key: 'variance',
      align: 'right',
      render: (_text, record) => {
        if (record.amount) {
          return amountFormat(Number(record.amount || 0));
        }
        return amountFormat(Number(Number(record.user_input_amount || record.amount || 0) - Number(Number(record.tpa_capi_amount) + Number(record.tpa_loan_amount)) || 0));
      },
      onCell: (record) => {
        const variance = Number(record.user_input_amount || record.amount || 0) - Number(Number(record.tpa_capi_amount) + Number(record.tpa_loan_amount));
        if (variance !== 0 || record.amount) {
          return {
            style: { color: 'red' },
          };
        }
      },
    },
    {
      title: ' ',
      dataIndex: 'status',
      key: 'status',
      width: '5',
      render: (_text, record) => {
        return Number(record.user_input_amount || record.amount || 0) - Number(Number(record.tpa_capi_amount) + Number(record.tpa_loan_amount)) === 0 ? (
          <CheckCircleOutlined className="audit-success-icon" />
        ) : (
          <CloseCircleOutlined className="audit-error-icon" />
        );
      },
    },
  ];
  const bank_data_property_column = [
    {
      title: <span>Property</span>,
      dataIndex: 'property_address',
      key: 'property_address',
    },
    {
      title: <span>Period</span>,
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_text, record) => {
        return record.combine_bank_number ? `${dayjs(record.start_date).format('DD/MM/YYYY')} - ${record.end_date != null ? dayjs(record.end_date).format('DD/MM/YYYY') : 'Present'}` : '-';
      },
    },
    {
      title: <span>Interest Allocation</span>,
      dataIndex: 'interest_allocation',
      key: 'interest_allocation',
      render: (text, record) => {
        return record.combine_bank_number ? `${text || 0}%` : '-';
      },
    },
    {
      title: <span>Interest Capitalized</span>,
      dataIndex: 'interest_capi_sum',
      key: 'interest_capi_sum',
      align: 'right',
      render: (text, record) => {
        return (
          <Button
            type="text"
            className="cursor-pointer"
            onClick={() =>
              onClickCategoryValue(
                record.property_id,
                CategoryType.INTEREST_CAPITALIZED_CATEGORY_ID,
                auditYear,
                record.combine_bank_number
                  ? [TrensectionType.BASIQ_TYPE_TRANSACTION]
                  : [TrensectionType.RENT_TYPE_TRANSACTION, TrensectionType.SETTLEMENT_TYPE_TRANSACTION, TrensectionType.MANUAL_TYPE_TRANSACTION],
                record.loan_id,
                record?.interest_rate_id,
              )
            }
          >
            {amountFormat(text || 0)}
          </Button>
        );
      },
    },
    {
      title: <span>Interest On Loan</span>,
      dataIndex: 'interest_loan_sum',
      key: 'interest_loan_sum',
      align: 'right',
      render: (text, record) => {
        return (
          <Button
            type="text"
            className="cursor-pointer"
            onClick={() =>
              onClickCategoryValue(
                record.property_id,
                CategoryType.INTEREST_ON_LOAN_CATEGORY_ID,
                auditYear,
                record.combine_bank_number
                  ? [TrensectionType.BASIQ_TYPE_TRANSACTION]
                  : [TrensectionType.RENT_TYPE_TRANSACTION, TrensectionType.SETTLEMENT_TYPE_TRANSACTION, TrensectionType.MANUAL_TYPE_TRANSACTION],
                record.loan_id,
                record?.interest_rate_id,
              )
            }
          >
            {amountFormat(text || 0)}
          </Button>
        );
      },
    },
  ];

  const onUpdateTransaction = () => {
    onCloseTransactionEdit();
    onCloseTransactionAdd();
    // Using spread operator to pass multiple arguments to function
    onClickCategoryValue(
      tranParamValues.propertyId,
      tranParamValues.categoryId,
      tranParamValues?.year,
      tranParamValues?.transactionType,
      tranParamValues?.loanId,
      tranParamValues?.interestId,
      tranParamValues?.isTotalLoan,
      tranParamValues?.auditData,
    );
    getAuditList();
  };

  return (
    <>
      <Collapse
        bordered={false}
        className="collapse-parent"
        defaultActiveKey={['1', '2', '4']}
        ghost
        items={getItems(panelStyle)}
        expandIconPosition="start"
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
      />
      <Drawer title="End Of Year Rental Statement" destroyOnClose size="large" onClose={onCloseEOFYTransaction} open={openEOFYTransaction}>
        <DT paddingclassName="mt-2 property_list table_shadow" columns={columnsEOFY} data={transactionEOFYData} rowCount={10} loading={transactionLoading} />
      </Drawer>
      <Drawer title="Transaction List" destroyOnClose size="large" onClose={onCloseTransaction} open={openTransaction}>
        <Flex justify="end" className="mb-3">
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTransactionAdd(true);
            }}
            className="p-0 property_btn1 btn btn-primary px-3 py-2 property_btn1_responsive"
          >
            + Add Expense
          </button>
        </Flex>
        <DT paddingclassName="mt-2 property_list table_shadow" columns={columns} data={transactionData} rowCount={10} loading={transactionLoading} />

        <Drawer title="Edit Transaction" width={1120} destroyOnClose onClose={onCloseTransactionEdit} open={openTransactionEdit}>
          <EditRecordExpenses tid={transactionEditValue?.id} tstate={transactionEditValue?.state} onUpdateTransaction={onUpdateTransaction} />
        </Drawer>
        <Drawer title="Add Transaction" width={1120} destroyOnClose onClose={onCloseTransactionAdd} open={openTransactionAdd}>
          <RecordExpenses onUpdateTransaction={onUpdateTransaction} tstate={'add'} />
        </Drawer>
      </Drawer>
    </>
  );
}
