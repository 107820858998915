import { getRequest } from '../../../utils/apiUtils';
import {
  getPropertyListRequest,
  getPropertyListSuccess,
  getPropertyListFailure,
  getPropertyByIdRequest,
  getPropertyByIdSuccess,
  getPropertyByIdFailure,
} from '../../reducers/slices/property/getPropertyList.slice';

export const GetPropertyList = (id) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `user/properties`;
    } else {
      tempURl = `user/properties?userId=${id}`;
    }
    await dispatch(getPropertyListRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPropertyListSuccess(result));
    }
    return await dispatch(getPropertyListFailure(result));
  };
};

export const GetPropertyById = (id) => {
  return async (dispatch) => {
    let tempURl = `user/property-notification/${id}`;
    await dispatch(getPropertyByIdRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPropertyByIdSuccess(result));
    }
    return await dispatch(getPropertyByIdFailure(result));
  };
};
