import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getMortgageBrokerDashboardDataSlice = createSlice({
  name: 'GetMortgageBrokerDashboardData',
  initialState,
  reducers: {
    getMortgageBrokerDashboardDataRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getMortgageBrokerDashboardDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getMortgageBrokerDashboardDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getMortgageBrokerDashboardDataSuccess, getMortgageBrokerDashboardDataFailure, getMortgageBrokerDashboardDataRequest } = getMortgageBrokerDashboardDataSlice.actions;

export default getMortgageBrokerDashboardDataSlice.reducer;
