import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteDocument, updateDocument, uploadDocument } from '../../../actions/DocumentUpload/DocumentUploadApi';

const initialState = {
  error: false,
  isLoading: false,
  data: {},
};

export const uploadDocumentAsync = createAsyncThunk('UploadDocument/upload', async (document) => {
  const response = await uploadDocument(document);
  return response;
});

export const updateDocumentAsync = createAsyncThunk('UploadDocument/update', async ({ id, info }) => {
  const response = await updateDocument(id, info);
  return response;
});

export const deleteDocumentAsync = createAsyncThunk('UploadDocument/delete', async (id) => {
  const response = await deleteDocument(id);
  return response;
});

export const UploadDocumentSlice = createSlice({
  name: 'Upload Document',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadDocumentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadDocumentAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = true;
        state.isLoading = false;
      })
      .addCase(updateDocumentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(updateDocumentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateDocumentAsync.fulfilled, (state, action) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(uploadDocumentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteDocumentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDocumentAsync.fulfilled, (state, action) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(deleteDocumentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUploadDocumentLoading = (state) => state.uploadDocument.isLoading;

export const selectUploadDocumentData = (state) => state.uploadDocument.data;

export default UploadDocumentSlice.reducer;
