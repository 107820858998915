import { Button } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppFavicon, AppIcon } from "../../../assets/Icons";
import { UserTypes } from "../../common/userTypes";

const PageNotFound = () => {
  const navigate = useNavigate();
  let admin = localStorage.getItem("admin");
  let admin1 = JSON.parse(admin);

  return (
    <>
      <div className="error_part center  p-3">
        <div className="error_logo">
          {admin1?.user_type === UserTypes.USER ? (
            <Link to="/" className="logo-icon me-3">
              <AppFavicon height="30" className="small" />
              <span className="big">
                <AppIcon height="30" className="logo-light-mode" />
                {/* <AppIcon height="30" className="logo-dark-mode" /> */}
              </span>
            </Link>
          ) : (
            <Link to="/clients" className="logo-icon me-3">
              <AppFavicon height="30" className="small" />
              <span className="big">
                <AppIcon height="30" className="logo-light-mode" />
              </span>
            </Link>
          )}
        </div>
        <div className="error_content">
          <div className="error_text_field">
            <h2 className="error_text">Page Not Found 🕵🏻‍♀️</h2>
            <p className="extra_text">
              Oops! 😖 The requested URL was not found on this server.
            </p>
          </div>
          <div className="error_text_field btn-center">
            <Button
              className="error_btn  m-3 "
              size="large"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
