import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getTexDashboardDataSlice = createSlice({
    name: "GetTexDashboardData",
    initialState,
    reducers: {
        getTexDashboardDataRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getTexDashboardDataSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getTexDashboardDataFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getTexDashboardDataSuccess, getTexDashboardDataFailure, getTexDashboardDataRequest } =
    getTexDashboardDataSlice.actions;

export default getTexDashboardDataSlice.reducer;
