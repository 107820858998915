import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, notification } from 'antd';
import logosvg from '../../../assets/images/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/antd.css';
import SignUpHOC from './SignUpHOC';
import { sendOTPMobileVerification, verifyOTPMobileVerification } from '../../../redux/actions/login/mobileVerify.action';
import CustomInput from '../../common';
import PhoneInput from 'react-phone-input-2';

const maskPhoneNumber = (phoneNumber) => {
  const start = phoneNumber.substring(0, phoneNumber.length - 8);
  const end = phoneNumber.substring(phoneNumber.length - 4);

  const middle = 'x'.repeat(4);

  return `${start}${middle}${end}`;
};

const MobileVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setverifyLoading] = useState(false);

  const [mobileNumber, setMobileNumber] = useState(''); // Default mobile number
  const [isEditingMobile, setIsEditingMobile] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otpData, setOtpData] = useState({});
  const user_data = useSelector((state) => state.verifyUserReducer);
  const [timer, setTimer] = useState('00');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (user_data.isLoading === false && user_data.data?.result) {
      setMobileNumber(user_data.data?.result?.phone_number);
    }
  }, [user_data]);

  // Handler to send OTP
  const handleSendOTP = (newMobileNumber = null) => {
    const mobile = newMobileNumber || mobileNumber;
    // Call your API to send OTP here
    setLoading(true);
    const param = {
      mobile: mobile,
      type: 'verifyphone',
    };
    dispatch(sendOTPMobileVerification(param)).then((data) => {
      if (data && data?.success === true) {
        notification.success({
          message: 'Success',
          description: data?.message,
        });
        setOtpData(data?.result);
        if (newMobileNumber) {
          setMobileNumber(newMobileNumber);
          setIsEditingMobile(false);
          setShowOTPInput(true);
        } else {
          setShowOTPInput(true);
        }
        setTimer(30);
      } else {
        setErrorMessage(data?.message || 'Something went wrong!');
      }
      setLoading(false);
    });
  };

  // Handler to verify OTP
  const handleVerifyOTP = (values) => {
    // Call your API to verify OTP here verifyOTPMobileVerification
    setverifyLoading(true);
    const param = {
      otp: values.otp,
      id: otpData.otpId,
      type: 'verifyphone',
    };
    dispatch(verifyOTPMobileVerification(param)).then((data) => {
      if (data && data?.success === true) {
        notification.success({
          message: 'Success',
          description: data?.message,
        });
        localStorage.setItem('admin', JSON.stringify(data?.result));
        navigate('/2fa-setup');
      } else {
        // notification.error({
        //   message: 'Failure',
        //   description: data?.message || 'Something went wrong!',
        // });
        setErrorMessage(data?.message || 'Something went wrong!');
      }
      setverifyLoading(false);
    });
  };

  // Handler to resend OTP
  const handleResendOTP = () => {
    // Call your API to resend OTP here
    handleSendOTP();
  };

  // Handler to start editing mobile number
  const handleEditMobile = () => {
    setIsEditingMobile(true);
    setShowOTPInput(false); // Hide OTP input when editing mobile number
  };

  // Handler to cancel editing mobile number
  const handleCancelEdit = () => {
    if (Object.keys(otpData).length > 0) {
      setShowOTPInput(true);
    }
    setIsEditingMobile(false);
  };

  // Handler to edit mobile number and send OTP
  const handleEditAndVerifyOTP = (values) => {
    // Call your API to update mobile number and send OTP here
    const newMobileNumber = values.mobile_number.startsWith('+') ? values.mobile_number : `+${values.mobile_number}`;

    handleSendOTP(newMobileNumber);
  };

  useEffect(() => {
    const intervalTime = setInterval(() => {
      const remaining = timer - 1;
      if (remaining !== -1) {
        if (remaining < 10) {
          setTimer(`0${remaining}`);
        } else {
          setTimer(remaining);
        }
      }
    }, 1000);
    return () => {
      if (intervalTime) {
        clearInterval(intervalTime);
      }
    };
  }, [timer]);

  return (
    <SignUpHOC>
      <div className="login_wrapper tandc_wrapper">
        <div className="card shadow border-0">
          <div className="card-body p-0">
            <div className="modal-body p-4 ">
              <div className="text-center tandc_logo mb-4">
                <img src={logosvg} className="img-fluid w-50" alt="" />
              </div>

              <div className="row">
                <h5>Verify Mobile Number</h5>
                <p>Please enter the 6-digit OTP code you received on your mobile number to verify your account. </p>
              </div>

              {errorMessage && (
                <div className="pb-2">
                  <Alert message={errorMessage} type="error" bordered closable onClose={() => setErrorMessage('')} />
                </div>
              )}

              <div className="verify-mobile-number mt-2">
                {!isEditingMobile ? (
                  <div className="row d-flex align-items-center">
                    <div className="d-flex justify-content-between">
                      <p>
                        <strong>Mobile Number:</strong> {mobileNumber ? maskPhoneNumber(mobileNumber) : '-'}
                      </p>
                      <Button onClick={handleEditMobile}>Edit</Button>
                    </div>
                    {!showOTPInput && (
                      <div className="row">
                        <div className="text-center">
                          <Button type="primary" onClick={() => handleSendOTP()} loading={loading}>
                            Send OTP
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <Form layout="vertical" onFinish={handleEditAndVerifyOTP}>
                    <div className="row d-flex align-items-center">
                      <div className="col">
                        <Form.Item
                          name="mobile_number"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input correct phone number!',
                            },
                            {
                              validator: (_, value) => {
                                if (value) {
                                  // Remove '+' if it exists at the beginning
                                  const normalizedValue = value.startsWith('+') ? value.substring(1) : value;

                                  if (normalizedValue.length > 2) {
                                    const countryCode = normalizedValue.substring(0, 2);
                                    const mobileNumber = normalizedValue.substring(2);

                                    const mobileNumberRegex = countryCode === '61' ? /^4\d{8}$/ : /^\d{8,10}$/;

                                    if (!mobileNumberRegex.test(mobileNumber)) {
                                      return Promise.reject('Please enter a valid phone number');
                                    }
                                  } else {
                                    return Promise.reject('Please enter a valid phone number');
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                          className="phone_input"
                          initialValue={mobileNumber}
                        >
                          <PhoneInput country="au" onlyCountries={['au', 'nz', 'sg', 'in']} placeholder="Enter Phone Number" inputStyle={{ width: '320px !important' }} enableSearch />
                        </Form.Item>
                      </div>
                      <div className="row">
                        <div className="text-center">
                          <Button onClick={handleCancelEdit}>Cancel</Button>
                          <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
                            Edit & Send OTP
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}

                {showOTPInput && (
                  <Form layout="vertical" onFinish={handleVerifyOTP}>
                    <div className="row d-flex align-items-center gap-4">
                      <div className="col">
                        <Form.Item
                          name="otp"
                          rules={[
                            { required: true, message: 'Please enter OTP!' },
                            { min: 6, max: 6, message: 'Please enter a valid OTP of 6 digits' },
                          ]}
                        >
                          <CustomInput className="form-control p-2" placeholder="Enter OTP" inputtype="number" maxLength={6} />
                        </Form.Item>
                      </div>
                      <div className="row">
                        <div className="text-center">
                          {timer === '00' ? (
                            <Button onClick={handleResendOTP} loading={loading} disabled={verifyLoading}>
                              Resend OTP
                            </Button>
                          ) : (
                            <span className="text-muted">{`Resend OTP in 00 : ${timer}`}</span>
                          )}
                          <Button type="primary" htmlType="submit" className="ml-2" loading={verifyLoading} disabled={loading}>
                            Verify OTP
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SignUpHOC>
  );
};

export default MobileVerify;
