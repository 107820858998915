import { postRequest } from '../../../utils/apiUtils';
import { addPropertySuccess, addPropertyFailure, addPropertyRequest } from '../../reducers/slices/property/addProperty.slice';

export const AddProperty = (data) => {
  return async (dispatch) => {
    // let tempURl = ``;
    // if (!id) {
    //     tempURl = `addproperty`
    // } else {
    //     tempURl = `addproperty?user_id=${id}`
    // }
    await dispatch(addPropertyRequest());
    const { result, error } = await postRequest(`user/property`, data);

    if (!error) {
      return await dispatch(addPropertySuccess(result));
    }
    return await dispatch(addPropertyFailure(result));
  };
};
