import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getPlanListSlice = createSlice({
  name: 'GetPlanList',
  initialState,
  reducers: {
    getPlanListRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getPlanListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getPlanListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getPlanListRequest, getPlanListSuccess, getPlanListFailure } = getPlanListSlice.actions;

export default getPlanListSlice.reducer;
