import { getRequest } from '../../../utils/apiUtils';
import { getDashboardDataSuccess, getDashboardDataFailure, getDashboardDataRequest } from '../../reducers/slices/dashboard/getDashboardData.slice';
import { getPropertyNotificationDataSuccess, getPropertyNotificationDataFailure, getPropertyNotificationDataRequest } from '../../reducers/slices/dashboard/getPropertyNotificationData.slice';

export const GetDashboardData = () => {
  return async (dispatch) => {
    await dispatch(getDashboardDataRequest());
    const { result, error } = await getRequest(`user/dashboard`);

    if (!error) {
      return await dispatch(getDashboardDataSuccess(result));
    }
    return await dispatch(getDashboardDataFailure(result));
  };
};

export const GetPropertyNotificationData = (currentClient = null) => {
  return async (dispatch) => {
    await dispatch(getPropertyNotificationDataRequest());
    const { result, error } = await getRequest(`user/property-notification${currentClient ? `?userId=${currentClient}` : ''}`);

    if (!error) {
      return await dispatch(getPropertyNotificationDataSuccess(result));
    }
    return await dispatch(getPropertyNotificationDataFailure(result));
  };
};
