import React from 'react';
import dayjs from 'dayjs';
import '../../../assets/less/index.less';

import DT from '../../common/DT';
import { Button, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { BankAccountTypes } from '../../common/userTypes';

export default function BankList({ list, loading, BankConnect, diaconnectOpen }) {
  const columns = [
    {
      title: 'Bank',
      dataIndex: 'bank_name',
      key: 'bank_name',
    },
    {
      title: 'Account Number',
      dataIndex: 'mask_account_number',
      key: 'mask_account_number',
    },
    {
      title: 'Type',
      dataIndex: 'ba_type',
      key: 'ba_type',
      render: (text) => (
        <span>
          {
            Object.entries(BankAccountTypes)
              .find(([key, val]) => val === text)?.[0]
              .split(' ')[0]
          }
        </span>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'loan_balance',
      key: 'loan_balance',
      render: (text) => {
        return `$${text || 0}`;
      },
    },
    {
      title: 'Interest Rate',
      dataIndex: 'interest_rate',
      key: 'interest_rate',
      render: (text) => {
        return <span>{text || 0}%</span>;
      },
    },
    {
      title: 'Interest Type',
      dataIndex: 'interest_type',
      key: 'interest_type',
      render: (text) => {
        return text ? <span style={{ textTransform: 'capitalize' }}>{text?.toLowerCase()}</span> : '-';
      },
    },
    {
      title: 'Repayment Type',
      dataIndex: 'loan_replacement_type',
      key: 'loan_replacement_type',
      render: (text) => {
        return text ? <span style={{ textTransform: 'capitalize' }}>{text?.replace(/_/g, ' ').toLowerCase()}</span> : '-';
      },
    },
    {
      title: 'Repayment Frequency',
      dataIndex: 'loan_application_frequency',
      key: 'loan_application_frequency',
      render: (text) => {
        return text || '-';
      },
    },
    {
      title: 'Repayment',
      dataIndex: 'min_instalment_amount',
      key: 'min_instalment_amount',
      render: (text) => {
        return text && text !== '0' ? `$${text}` : '-';
      },
    },
    {
      title: 'Available Redraw',
      dataIndex: 'available_funds',
      key: 'available_funds',
      render: (text) => {
        return `$${text || 0}`;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'loan_start_date',
      key: 'loan_start_date',
      render: (text) => {
        return text ? dayjs(text).format('DD/MM/YYYY') : '-';
      },
    },
    {
      title: 'End Date',
      dataIndex: 'loan_end_date',
      key: 'loan_end_date',
      render: (text) => {
        return text ? dayjs(text).format('DD/MM/YYYY') : '-';
      },
    },
    {
      title: 'Fees',
      dataIndex: 'loan_fees',
      key: 'loan_fees',
      align: 'center',
      render: (text) => {
        const content = (
          <div>
            {text &&
              JSON.parse(text).length > 0 &&
              JSON.parse(text).map((item) => (
                <p>
                  {item.name}: <b>${item.amount || 0}</b>
                </p>
              ))}
          </div>
        );
        return text ? (
          <Popover placement="bottom" content={content}>
            <InfoCircleOutlined className="icon" />
          </Popover>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Connect / Disconnect',
      dataIndex: 'bc_id',
      key: 'bc_id',
      render: (text, record) => {
        return text ? (
          <Button className="DisConnect_btn" onClick={() => diaconnectOpen(record, text)}>
            Disconnect
          </Button>
        ) : (
          <Button className=" Connect_btn me-2" onClick={() => BankConnect(record.b_id, record.ba_type)}>
            Connect
          </Button>
        );
      },
    },
  ];

  return <DT paddingclassName="mt-4 property_list" columns={columns} rowKey={'id'} data={list} rowCount={10} loading={loading} />;
}

BankList.defaultProps = {
  list: [],
  loading: false,
  BankConnect: () => {},
  diaconnectOpen: () => {},
};
