// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse-parent .ant-table-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.collapse-parent .audit-table-parent {
  padding: 20px;
  background: white;
}
.collapse-parent .audit-table-parent table {
  border-radius: 8px !important;
}
.collapse-parent .ant-collapse-header {
  background: white;
}
.collapse-parent .underline-text {
  text-decoration: underline;
}
.collapse-parent .ant-collapse-item {
  border: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
  border-radius: 8px;
  box-sizing: border-box !important;
}
.collapse-parent .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 8px;
}
.collapse-parent .ant-collapse-item:last-child .ant-collapse-item-active .ant-collapse-header {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.collapse-parent .ant-collapse-header {
  border-radius: 8px;
}
.collapse-parent .ant-collapse-item-active .ant-collapse-header {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.collapse-parent .audit-table-parent {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/details/clientTab/AuditTrail/index.less"],"names":[],"mappings":"AAAA;EAEI,qDAAA;AAAJ;AAFA;EAMI,aAAA;EACA,iBAAA;AADJ;AANA;EAWI,6BAAA;AAFJ;AATA;EAeI,iBAAA;AAHJ;AAZA;EAmBI,0BAAA;AAJJ;AAfA;EAuBI,oCAAA;EACA,2CAAA;EACA,kBAAA;EACA,iCAAA;AALJ;AArBA;EA8BI,kBAAA;AANJ;AAxBA;EAkCI,8BAAA;EACA,+BAAA;AAPJ;AA5BA;EAuCI,kBAAA;AARJ;AA/BA;EA2CI,yCAAA;EACA,0CAAA;AATJ;AAnCA;EAgDI,8BAAA;EACA,+BAAA;AAVJ","sourcesContent":[".collapse-parent {\n  .ant-table-container {\n    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n  }\n\n  .audit-table-parent {\n    padding: 20px;\n    background: white;\n  }\n\n  .audit-table-parent table{\n    border-radius: 8px !important;\n  }\n\n  .ant-collapse-header {\n    background: white;\n  }\n\n  .underline-text {\n    text-decoration: underline;\n  }\n\n  .ant-collapse-item {\n    border: 1px solid #e5e5e5 !important;\n    border-bottom: 1px solid #e5e5e5 !important;\n    border-radius: 8px;\n    box-sizing: border-box !important;\n  }\n\n  .ant-collapse-item:last-child .ant-collapse-header {\n    border-radius: 8px;\n  }\n\n  .ant-collapse-item:last-child .ant-collapse-item-active .ant-collapse-header {\n    border-bottom-left-radius: 0px;\n    border-bottom-right-radius: 0px;\n  }\n\n  .ant-collapse-header {\n    border-radius: 8px;\n  }\n\n  .ant-collapse-item-active .ant-collapse-header {\n    border-bottom-left-radius: 0px !important;\n    border-bottom-right-radius: 0px !important;\n  }\n\n  .audit-table-parent {\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
