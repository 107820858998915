import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const addRecordExpenseSlice = createSlice({
    name: "AddRecordExpense",
    initialState,
    reducers: {
        addRecordExpenseRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        addRecordExpenseSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        addRecordExpenseFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { addRecordExpenseRequest, addRecordExpenseSuccess, addRecordExpenseFailure } =
    addRecordExpenseSlice.actions;

export default addRecordExpenseSlice.reducer;
