import { UserTypes } from '../components/common/userTypes';

export default function setAuthToken(type = null) {
  const accesToken = localStorage.getItem('token');
  const admin = JSON.parse(localStorage.getItem('admin'));
  if (accesToken) {
    return {
      Accept: 'application/json',
      'Content-Type': type ? type : 'application/json',
      authorization: `Bearer ${accesToken}`,
      user: admin.user_type === UserTypes.USER ? 1 : admin.user_type === UserTypes.CHANNEL_PARTNER ? 3 : 2,
      Platform: 'web',
    };
  } else {
    return {
      'Content-Type': 'application/json',
    };
  }
}
