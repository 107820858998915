import { postRequest } from '../../../utils/apiUtils';
import { getReportDataSuccess, getReportDataFailure, getReportDataRequest } from '../../reducers/slices/reports/getReport.slice';
import { getReportTransactionDataSuccess, getReportTransactionDataFailure, getReportTransactionDataRequest } from '../../reducers/slices/reports/getReportTransaction.slice';

export const GetReportTransactionData = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/report/transactions`;
  } else {
    tempURl = `user/report/transactions?userId=${id}`;
  }
  return async (dispatch) => {
    await dispatch(getReportTransactionDataRequest());
    const { result, error } = await postRequest(tempURl, data);

    if (!error) {
      return await dispatch(getReportTransactionDataSuccess(result));
    }
    return await dispatch(getReportTransactionDataFailure(result));
  };
};

export const GetReportEmailData = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/email/report`;
  } else {
    tempURl = `user/email/report?userId=${id}`;
  }
  return async () => {
    const { result } = await postRequest(tempURl, data);

    return result;
  };
};

export const GetReportExcelData = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/download/report`;
  } else {
    tempURl = `user/download/report?userId=${id}`;
  }
  return async () => {
    const { result } = await postRequest(tempURl, data);

    return result;
  };
};

export const ChangeLoanReportData = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/report/loan-summary/property-security`;
  } else {
    tempURl = `user/report/loan-summary/property-security?userId=${id}`;
  }
  return async () => {
    const { result } = await postRequest(tempURl, data);

    return result;
  };
};

export const ChangeFixedInterestExpiry = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/report/loan-summary/interest-expiry`;
  } else {
    tempURl = `user/report/loan-summary/interest-expiry?userId=${id}`;
  }
  return async () => {
    const { result } = await postRequest(tempURl, data);

    return result;
  };
};

export const GetViewReportData = (data, id) => {
  // let tempURl = `user/view/report`;

  let tempURl = ``;
  if (!id) {
    tempURl = `user/view/report`;
  } else {
    tempURl = `user/view/report?userId=${id}`;
  }

  return async (dispatch) => {
    await dispatch(getReportDataRequest());
    const { result, error } = await postRequest(tempURl, data);

    if (!error) {
      return await dispatch(getReportDataSuccess(result));
    }
    return await dispatch(getReportDataFailure(result));
  };
};
