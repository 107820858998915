import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { addMortgageBroker, deleteMortgageBroker, fetchMortgageBroker } from '../../../actions/UserFetchMortgageBroker/UserFetchMortgageBrokerAction';

const initialState = {
  error: false,
  isLoading: false,
  data: {},
};

export const fetchMortgageBrokerAsync = createAsyncThunk('user/fetchMortgageBroker', async () => {
  const response = await fetchMortgageBroker();
  return response;
});

export const addMortgageBrokerAsync = createAsyncThunk('user/addMortgageBroker', async (info) => {
  const response = await addMortgageBroker(info);
  return response;
});

export const deleteMortgageBrokerAsync = createAsyncThunk('user/deleteMortgageBroker', async () => {
  const response = await deleteMortgageBroker();
  return response;
});

export const UserMortgageBrokerSlice = createSlice({
  name: 'User Mortgage Broker',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMortgageBrokerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMortgageBrokerAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchMortgageBrokerAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(addMortgageBrokerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMortgageBrokerAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(addMortgageBrokerAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteMortgageBrokerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteMortgageBrokerAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(deleteMortgageBrokerAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectMortgageBrokerLoading = (state) => state.userMortgageBroker.isLoading;
export const selectMortgageBrokerData = (state) => state.userMortgageBroker.data;

export default UserMortgageBrokerSlice.reducer;
