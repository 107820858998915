import { fetchMFAPreference, updateMFAPreference } from 'aws-amplify/auth';
import { putRequest } from '../../../utils/apiUtils';

export const fetchUserMFAPreference = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await fetchMFAPreference();
      resolve({ result, error: null });
    } catch (error) {
      reject({ data: null, error });
    }
  });
};

export const setMFAPreference = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await updateMFAPreference({ [type]: 'PREFERRED' });
      resolve({ result, error: null });
    } catch (error) {
      reject({ data: null, error });
    }
  });
};

export const disableUserMFAPreference = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await updateMFAPreference({ [type]: 'DISABLED' });
      resolve({ result, error: null });
    } catch (error) {
      reject({ data: null, error });
    }
  });
};

export const mfaUpdateForSystem = (mfaInfo) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`auth/mfa-setting`, mfaInfo);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
