import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getConnectedBankListSlice = createSlice({
  name: 'GetConnectedBankList',
  initialState,
  reducers: {
    getConnectedBankListRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    getConnectedBankListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getConnectedBankListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getConnectedBankListRequest, getConnectedBankListSuccess, getConnectedBankListFailure } = getConnectedBankListSlice.actions;

export default getConnectedBankListSlice.reducer;
