import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Divider, Spin, Switch } from 'antd';
import TwoFactorAppAuthenticationModal from './TwoFactorAppAuthenticationModal';
import TwoFactorSMSAuthenticationModal from './TwoFactorSMSAuthenticationModal';
import { useDispatch } from 'react-redux';
import { fetchUserMFAPreferenceAsync, selectUserMFAPreference, selectUserPreferenceLoading } from '../../../redux/reducers/slices/UserMFA/UserMFASlice';
import DisableMFA from './DisableMFA';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const TwoFactorAuthentication = () => {
  const [appOpen, setAppOpen] = useState(false);
  const [smsOpen, setSmsOpen] = useState(false);

  const [smsChecked, setSmsChecked] = useState(false);
  const [appChecked, setAppChecked] = useState(false);

  const [disableOpen, setDisableOpen] = useState(false);
  const [disableType, setDisableType] = useState('');

  const [displayOneMFA, setDisplayOneMFA] = useState(false);

  const oneMFAMessage = <small>Only one MFA method can be enabled at a time. Please disable the current MFA method to enable another.</small>;

  const loading = useSelector(selectUserPreferenceLoading);
  const userPreference = useSelector(selectUserMFAPreference);

  const preferredMfa = userPreference?.result?.preferred;

  const dispatch = useDispatch();

  const userMFAPreference = useCallback(async () => {
    if (preferredMfa === 'SMS') {
      setSmsChecked(true);
    } else {
      setSmsChecked(false);
    }

    if (preferredMfa === 'TOTP') {
      setAppChecked(true);
    } else {
      setAppChecked(false);
    }
  }, [preferredMfa]);

  useEffect(() => {
    dispatch(fetchUserMFAPreferenceAsync());
  }, [dispatch]);

  useEffect(() => {
    userMFAPreference();
  }, [userMFAPreference]);

  const handlSmsChange = (open) => {
    if (open) {
      if (!appChecked) {
        setSmsOpen(true);
      } else {
        setDisplayOneMFA(true);
      }
    } else {
      setDisableOpen(true);
      setDisableType('sms');
    }
  };

  const handleAppChange = async (open) => {
    if (open) {
      if (!smsChecked) {
        setAppOpen(true);
      } else {
        setDisplayOneMFA(true);
      }
    } else {
      setDisableOpen(true);
      setDisableType('totp');
    }
  };

  return (
    <Card bordered={false} className="shadow-none profile-content-card " title="Two Factor Authentication">
      <Spin spinning={loading}>
        {displayOneMFA && (
          <span className="text-danger mt-4">
            <ExclamationCircleOutlined className="text-danger me-1" />
            {oneMFAMessage}
          </span>
        )}

        <div className="d-flex align-items-center gap-2 mt-3 mb-3">
          <div>
            <Switch id="smsCheck2FA" checked={smsChecked} onChange={handlSmsChange} />
          </div>
          <label htmlFor="smsCheck2FA">
            <div className="d-flex flex-column">
              <span className="twoFAHeaderSpan">Text Message (SMS)</span>
              <span className="text-muted twoFATextSpan">Receive a one-time passcode via SMS each time you log in.</span>
            </div>
          </label>
        </div>

        <Divider />

        <div className="d-flex align-items-center gap-2">
          <div>
            <Switch id="appCheck2FA" checked={appChecked} onChange={handleAppChange} />
          </div>
          <label htmlFor="appCheck2FA">
            <div className="d-flex flex-column">
              <span className="twoFAHeaderSpan">Authenticator App (TOTP)</span>
              <span className="text-muted twoFATextSpan">Use an app to receive a temporary one-time passcode each time you log in.</span>
            </div>
          </label>
        </div>

        <TwoFactorSMSAuthenticationModal setSmsChecked={setSmsChecked} open={smsOpen} setOpen={setSmsOpen} />

        <TwoFactorAppAuthenticationModal redirect={false} open={appOpen} setOpen={setAppOpen} />

        <DisableMFA open={disableOpen} setOpen={setDisableOpen} type={disableType} setType={setDisableType} displayOneMFA={displayOneMFA} setDisplayOneMFA={setDisplayOneMFA} />
      </Spin>
    </Card>
  );
};

export default TwoFactorAuthentication;
