import { getRequest } from '../../../utils/apiUtils';
import { getRentalSummaryByIdRequest, getRentalSummaryByIdSuccess, getRentalSummaryByIdFailure } from '../../reducers/slices/rental_summary/getRentalSummaryById.slice';

export const GetRentalSummaryById = (id, type, userId) => {
  let tempURl = ``;
  if (!userId) {
    tempURl = `user/transaction/details/${id}?type=${type}`;
  } else {
    tempURl = `user/transaction/details/${id}?type=${type}&userId=${userId}`;
  }
  return async (dispatch) => {
    await dispatch(getRentalSummaryByIdRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getRentalSummaryByIdSuccess(result));
    }
    return await dispatch(getRentalSummaryByIdFailure(result));
  };
};
