import { postRequest } from "../../../utils/apiUtils";
import { postBankFailure, postBankRequest, postBankSuccess } from "../../reducers/slices/dashboard/postBankConnect.slice";

export const PostBankConnect = (data) => {
    return async (dispatch) => {
        await dispatch(postBankRequest());
        const { result, error } = await postRequest(`user/bank-connect`, data);

        if (!error) {
            return await dispatch(postBankSuccess(result));
        }
        return await dispatch(postBankFailure(result));
    };
};
