import { getRequest } from '../../../utils/apiUtils';
import { LoanRejectFailure, LoanRejectRequest, LoanRejectSuccess } from '../../reducers/slices/dashboard/loanReject.slice';

export const LoanReject = (id, id1) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/loan/reject/${id}`;
  } else {
    tempURl = `user/loan/reject/${id}?userId=${id1}`;
  }
  return async (dispatch) => {
    await dispatch(LoanRejectRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(LoanRejectSuccess(result));
    }
    return await dispatch(LoanRejectFailure(result));
  };
};
