import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserTypes } from '../components/common/userTypes';

const TexProtected = ({ role, children }) => {
  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;
  const token = localStorage.getItem('token');
  const admin = localStorage.getItem('admin');
  const { user_type = null, ...user_info } = admin ? JSON.parse(admin) : {};

  // Redirect if not authenticated or missing user type
  if (!token || !user_type) {
    window.open(landing_url, '_self');
    return null; // Prevent further rendering until redirect
  }

  // Redirect to phone verification if phone is not verified
  if (user_info?.phone_verified === false) {
    return <Navigate to="/mobile-verification" />;
  }

  // Redirect to complete profile if user type is not USER and company_name is null
  if (UserTypes.USER !== user_type && ![UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type) && user_info?.company_name === null) {
    return <Navigate to="/complete-profile" />;
  }

  // Redirect to home page if role does not include current user type
  if (role && !role.includes(user_type)) {
    return <Navigate to="/" />;
  }

  // Render children for specific user types
  if ([UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type)) {
    return children;
  }

  // Redirect to home page if user type is USER
  if (user_type === UserTypes.USER) {
    return <Navigate to="/" />;
  }

  // Redirect to landing page as fallback
  window.open(landing_url, '_self');
  return null; // Prevent further rendering until redirect
};

export default TexProtected;
