import { deleteRequest } from '../../../utils/apiUtils';
import { manageConsentFailure, manageConsentRequest, manageConsentSuccess } from '../../reducers/slices/Header/manageConsent.slice';

export const ManageConsent = () => {
  return async (dispatch) => {
    await dispatch(manageConsentRequest());
    const { result, error } = await deleteRequest(`/user/bank-consent`);

    if (!error) {
      return await dispatch(manageConsentSuccess(result));
    }
    return await dispatch(manageConsentFailure(error));
  };
};
