import { postRequest } from '../../../utils/apiUtils';
import { DeleteUserFailure, DeleteUserRequest, DeleteUserSuccess } from '../../reducers/slices/Header/Deleteuser.slice';

export const DeleteUser = (type, params) => {
  return async (dispatch) => {
    await dispatch(DeleteUserRequest());
    const { result, error } = await postRequest(`${type}/account`, params);
    if (!error) {
      return await dispatch(DeleteUserSuccess(result));
    }
    return await dispatch(DeleteUserFailure(error));
  };
};
