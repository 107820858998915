import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getDashboardDataSlice = createSlice({
    name: "GetDashboardData",
    initialState,
    reducers: {
        getDashboardDataRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getDashboardDataSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getDashboardDataFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getDashboardDataSuccess, getDashboardDataFailure, getDashboardDataRequest } =
    getDashboardDataSlice.actions;

export default getDashboardDataSlice.reducer;
