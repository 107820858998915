import { Card, Collapse, Space } from 'antd';
import React from 'react';
import faqlist from './faqlist.json';
import './index.less';
import '../../../../assets/css/antd.css';
import '../../../../assets/css/style.css';

export default function Faq() {
  return (
    <Card className="pricing_faq" title="FAQ">
      <Space direction="vertical">
        {faqlist.map((item, index) => (
          <Collapse
            key={index}
            expandIconPosition="end"
            collapsible="header"
            defaultActiveKey={['0']}
            items={[
              {
                key: index,
                label: item.question,
                children: <p dangerouslySetInnerHTML={{ __html: item.answer }} />,
              },
            ]}
          />
        ))}
      </Space>
    </Card>
  );
}
