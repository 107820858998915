import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

export const GoBackDefault = ({ fromSameSite, fallbackPath }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    !fromSameSite ? navigate(fallbackPath) : navigate(-1);
  };

  return <Button className="btn_goBack" onClick={() => handleButtonClick()} icon={<LeftOutlined />} />;
};

export const GoBack = ({ redirectValue, fromSameSite, fallbackPath }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    !fromSameSite ? navigate(fallbackPath) : navigate(-1, redirectValue);
  };

  return <Button className="btn_goBack" onClick={() => handleButtonClick()} icon={<LeftOutlined />} />;
};

export const GoBackWithClick = ({ handleOnClick = () => {}, fromSameSite, fallbackPath }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    !fromSameSite ? (handleOnClick ? handleOnClick() : navigate(fallbackPath)) : navigate(-1);
  };

  return <Button className="btn_goBack" onClick={() => handleButtonClick()} icon={<LeftOutlined />} />;
};
