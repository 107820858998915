import { Button, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { disableUserMFAPreferenceAsync, fetchUserMFAPreferenceAsync, mfaUpdateForSystemAsync } from '../../../redux/reducers/slices/UserMFA/UserMFASlice';
import { useDispatch } from 'react-redux';
import { MFAType } from '../../common/userTypes';

const DisableMFA = ({ open = false, setOpen = () => {}, type = '', setType, displayOneMFA = false, setDisplayOneMFA = () => {} }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    // Add your code here
    setType('');
    setOpen(false);
  };

  const disableMFA = async () => {
    try {
      setLoading(true);

      const mfaType = type === 'sms' ? MFAType.SMS_MFA : type === 'totp' ? MFAType.TOTP_MFA : null;

      await dispatch(disableUserMFAPreferenceAsync(type));
      await dispatch(mfaUpdateForSystemAsync({ mfa_status: false, mfa_type: mfaType }));
      setOpen(false);

      await dispatch(fetchUserMFAPreferenceAsync());

      if (displayOneMFA) {
        setDisplayOneMFA(false);
      }

      notification.success({
        message: 'Success',
        description: '2FA disabled. Your account is secure, but consider re-enabling 2FA for added protection.',
      });

      setLoading(false);
      setType('');
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong',
      });
    }
  };

  return (
    <Modal onCancel={handleCancel} footer={null} style={{ zIndex: '1005', borderRadius: '1em' }} destroyOnClose open={open}>
      <div className="d-flex flex-column gap-3">
        <h5>Disable Two-Factor Authentication from your account?</h5>
        <p className="installAuthenticatorText">This will disable Two-Factor Authentication from your account. Are you sure you want to continue?</p>
        <div className="d-flex justify-content-end gap-4 align-items-center">
          <button type="button" className="twoFAButton border-0 bg-transparent shadow-none" onClick={handleCancel}>
            Cancel
          </button>
          <Button loading={loading} iconPosition="end" type="primary" className="twoFAButton" onClick={disableMFA}>
            Yes, Disable
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisableMFA;
