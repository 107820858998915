import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const changePasswordSlice = createSlice({
    name: "Getlogins",
    initialState,
    reducers: {
        changePasswordRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        changePasswordSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        changePasswordFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { changePasswordSuccess, changePasswordFailure, changePasswordRequest } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
