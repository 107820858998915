import { UserTypes } from '../../../../components/common/userTypes';
import { getRequest } from '../../../../utils/apiUtils';
import { getUserdataListByIdFailure, getUserdataListByIdRequest, getUserdataListByIdSuccess } from '../../../reducers/slices/texDashboard/subusers/getUserdataById.slice';

export const GetUserDataListById = (id) => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(getUserdataListByIdRequest());
    const { result, error } = await getRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'sub-channel-partner' : 'sub-tax-accountant'}/${id}`);

    if (!error) {
      return await dispatch(getUserdataListByIdSuccess(result));
    }
    return await dispatch(getUserdataListByIdFailure(result));
  };
};
