import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const postTaxUserSlice = createSlice({
    name: "TaxUserForm",
    initialState,
    reducers: {
        postTaxUserRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        postTaxUserSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        postTaxUserFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { postTaxUserRequest, postTaxUserSuccess, postTaxUserFailure } = postTaxUserSlice.actions;

export default postTaxUserSlice.reducer;
