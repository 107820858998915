import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const addUserDataSlice = createSlice({
    name: "AddUserData",
    initialState,
    reducers: {
        addUserDataRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        addUserDataSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        addUserDataFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { addUserDataRequest, addUserDataSuccess, addUserDataFailure } = addUserDataSlice.actions;

export default addUserDataSlice.reducer;
