import { postRequest } from "../../../utils/apiUtils";
import { manageRevertFailure, manageRevertRequest, manageRevertSuccess } from "../../reducers/slices/Header/ManageRevert.slice";

export const ManageRevert = () => {
    return async (dispatch) => {
        await dispatch(manageRevertRequest());
        const { result, error } = await postRequest(`manage/consent`);

        if (!error) {
            return await dispatch(manageRevertSuccess(result));
        }
        return await dispatch(manageRevertFailure(result));
    };
};
