// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_wrapper .Route_showmore {
  border-bottom: 1px solid #4f9496;
  color: #52a5a7;
  font-size: 13px;
  cursor: pointer;
}
.dashboard_wrapper .ant-card-body {
  padding: 0;
}
.dashboard_wrapper .ant-table-thead {
  border-start-end-radius: 0px !important;
  border-start-start-radius: 0px !important;
}
.dashboard_wrapper .fw5-bold {
  font-weight: 700 !important;
  font-size: 13px;
}
.dashboard_wrapper .fw5-normal {
  font-size: 12px;
  color: #707880;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/tax-Dashboard/index.less"],"names":[],"mappings":"AAAA;EAEI,gCAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;AAAJ;AALA;EAQI,UAAA;AAAJ;AARA;EAYI,uCAAA;EACA,yCAAA;AADJ;AAZA;EAgBI,2BAAA;EACA,eAAA;AADJ;AAhBA;EAoBI,eAAA;EACA,cAAA;AADJ","sourcesContent":[".dashboard_wrapper {\n  .Route_showmore {\n    border-bottom: 1px solid #4f9496;\n    color: #52a5a7;\n    font-size: 13px;\n    cursor: pointer;\n  }\n  .ant-card-body {\n    padding: 0;\n  }\n\n  .ant-table-thead {\n    border-start-end-radius: 0px !important;\n    border-start-start-radius: 0px !important;\n  }\n  .fw5-bold {\n    font-weight: 700 !important;\n    font-size: 13px;\n  }\n  .fw5-normal {\n    font-size: 12px;\n    color: #707880;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
