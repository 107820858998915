import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getClientdetailSlice = createSlice({
  name: 'GetclientdetailById',
  initialState,
  reducers: {
    getclientDetailByIdByIdRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getclientDetailByIdByIdSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getclientDetailByIdByIdFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getclientDetailByIdByIdRequest, getclientDetailByIdByIdSuccess, getclientDetailByIdByIdFailure } = getClientdetailSlice.actions;

export default getClientdetailSlice.reducer;
