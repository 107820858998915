import { postRequest } from '../../../utils/apiUtils';
import { getTransactionRequest, getTransactionSuccess, getTransactionFailure } from '../../reducers/slices/transactions/getTransactions.slice';

export const GetTransactionByUserId = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/transactions/list`;
  } else {
    tempURl = `user/transactions/list?userId=${id}`;
  }
  return async (dispatch) => {
    await dispatch(getTransactionRequest());
    const { result, error } = await postRequest(tempURl, data);

    if (!error) {
      return await dispatch(getTransactionSuccess(result));
    }
    return await dispatch(getTransactionFailure(result));
  };
};
