import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const postBankConnectSlice = createSlice({
    name: "postDashboardData",
    initialState,
    reducers: {
        postBankRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        postBankSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        postBankFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { postBankRequest, postBankSuccess, postBankFailure } =
    postBankConnectSlice.actions;

export default postBankConnectSlice.reducer;
