import { getRequest } from '../../../../utils/apiUtils';
import { UserTypes } from '../../../../components/common/userTypes';
import { getTaxUserListFailure, getTaxUserListRequest, getTaxUserListSuccess } from '../../../reducers/slices/texDashboard/UserOnbording/getUseronbording.slice';

export const GetTaxUserList = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(getTaxUserListRequest());
    const { result, error } = await getRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'channel-partner/onboard/users' : 'tax-accountant/onboard-users'}`);

    if (!error) {
      return await dispatch(getTaxUserListSuccess(result));
    }
    return await dispatch(getTaxUserListFailure(result));
  };
};
