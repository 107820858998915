import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/apiUtils';

export const getFinancialPlannerData = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`tax-accountant/financial-planner`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const postFinancialPlannerData = (info) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`tax-accountant/financial-planner`, info);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const updateFinancialPlannerData = (info) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`tax-accountant/financial-planner`, info);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteFinancialPlannerData = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`tax-accountant/financial-planner/` + id);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
