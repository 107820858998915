import { getRequest } from '../../../utils/apiUtils';
import { getTransactionByIdRequest, getTransactionByIdSuccess, getTransactionByIdFailure } from '../../reducers/slices/transactions/getTransactionsById.slice';

export const GetTransactionById = (id, type, userId = null, PropertyId = null, LoanId = null) => {
  let tempURl = ``;
  if (!userId) {
    tempURl = `user/transaction/details/${id}?type=${type}`;
  } else {
    tempURl = `user/transaction/details/${id}?type=${type}&userId=${userId}`;
  }
  if (PropertyId) {
    tempURl = tempURl + `&propertyId=${PropertyId}`;
  }
  if (LoanId) {
    tempURl = tempURl + `&loanId=${LoanId}`;
  }
  return async (dispatch) => {
    await dispatch(getTransactionByIdRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getTransactionByIdSuccess(result));
    }
    return await dispatch(getTransactionByIdFailure(result));
  };
};
