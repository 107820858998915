import React, { useEffect, useState } from 'react';
import '../../../assets/less/index.less';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Descriptions, Divider, Form, Modal, notification, Popconfirm, Row, Select, Spin, Upload } from 'antd';
import imgBrowse from '../../../assets/browse.svg';

import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCategoryList } from '../../../redux/actions/category/getCategoryList.action';
import { GetPropertyListWithOutLoan } from '../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { EditRecordExpense } from '../../../redux/actions/record_expense/editRecordExpense.action';
import { STSToken } from '../../../redux/actions/record_expense/uploadFile.action';
import { GetTransactionById } from '../../../redux/actions/transactions/getTransactionsById.action';
import Input from '../../common/index';
import { UserTypes, PropertyType, TrensectionType } from '../../common/userTypes';
import { DeleteRecordExpense } from '../../../redux/actions/record_expense/deleteRecordExpense.action';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import CustomIcon from '../../sidebar/custIcon';
import deleteIcon from '../../../assets/images/delete.svg';
import { GoBackDefault } from '../../common/GoBack';
import { getPropertyTypeLabel } from '../../../utils/ConstLabel';
import { getLockYearsList } from '../../../redux/actions/AuditTrail/getAuditTrailList.action';
import { formatCurrency, getErrorMessageForLockedYear, getErrorNoteForLockedYear, getFinancialYearRanges } from '../../../utils';

const { Option } = Select;
const text = 'Are you sure you want to discard?';
const fank_feed_cat = [27, 49, 16, 34, 40, 43];

const regex = /https?:\/\/[^\s]*\.pdf/g;
const isPdfUrl = (url) => {
  return url?.match(regex) || url.includes('application/pdf');
};

export default function ManualTransaction({ tid = null, fromSameSite, onUpdateTransaction, TransectionType, PropertyId = null, LoanId = null, isTaxAccountant }) {
  const [modal, contextHolder] = Modal.useModal();
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);

  const currentClient = localStorage.getItem('currentClient');

  const navigate = useNavigate();
  let { id } = useParams();
  id = tid || id || null;
  const [productForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [propertyData, setPropertyData] = useState([]);
  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [costCategorydata, setCostCategorydata] = useState([]);
  const [singleTransData, setSingleTransData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewUrl, setPreviewUrl] = useState('');
  const [checkPropertyType, setCheckPropertyType] = useState(0);
  const [financialYears, setFinancialYears] = useState([]);
  const [lockErrorMessage, setLockErrorMessage] = useState('');
  const dispatch = useDispatch();
  const [lockDisabled, setLockDisabled] = useState(false);
  const [disabled, setDisabled] = useState([TrensectionType.BASIQ_TYPE_TRANSACTION].includes(TransectionType));

  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);
  const categoryDta = useSelector((state) => state.getCategoryListReducer);
  const singleTransDta = useSelector((state) => state.getTransactionByIdReducer);

  const navigateFunction = () => {
    if (localStorage.getItem('transactionFilters')) {
      localStorage.removeItem('transactionFilters');
    }
    admin1?.user_type === UserTypes.TAXACCOUTANT ? navigate('/clients/client/subscription/checkout') : navigate('/subscription/checkout');
  };

  useEffect(() => {
    dispatch(GetPropertyListWithOutLoan(currentClient));
    dispatch(GetCategoryList());
    dispatch(GetTransactionById(id, TransectionType, currentClient, PropertyId, LoanId));
    getLockYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (propertyDta.isLoading && categoryDta.isLoading && singleTransDta.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    const PROPERTYTYPE = propertyDta?.data?.result?.find((x) => x.id === Number(singleTransDta?.data?.result?.property_id));

    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((elm, i) => {
        return {
          value: elm.id,
          label: elm.street_number ? elm.street_number + (elm.street_name ? ' ' + elm.street_name : '') : '',
          flag: elm.property_type === PropertyType.COMMERCIAL_PROPERTY ? 1 : 0,
          key: i,
          property_type: elm.property_type,
        };
      });
      setPropertyData(dt);
    }
    if (categoryDta && categoryDta.data.result && categoryDta.error === false) {
      let dt = categoryDta.data.result
        .filter((item) => ![45, 46].includes(item.id))
        .map((elm) => {
          return {
            value: elm.id,
            label: elm.category_name,
            income: !!(elm.expense_type === 'Revenue' && elm.transaction_type === 'In'),
            expense: !!(elm.expense_type === 'Revenue' && elm.transaction_type === 'Out'),
            cost: elm.expense_type === 'Capital',
          };
        });
      let Incom = dt.filter((item) => ![false].includes(item.income));
      setIncomeCategorydata(Incom);

      if (TransectionType !== TrensectionType.BASIQ_TYPE_TRANSACTION) {
        let Expense = dt.filter((item) => ![false].includes(item.expense));
        setExpenseCategorydata(Expense);
        let Cost = dt.filter((item) => ![false].includes(item.cost));
        setCostCategorydata(Cost);
      } else {
        let Expense = dt.filter((item) => ![false].includes(item.expense) && fank_feed_cat.includes(item.value));
        setExpenseCategorydata(Expense);
        let Cost = dt.filter((item) => ![false].includes(item.cost) && fank_feed_cat.includes(item.value));
        setCostCategorydata(Cost);
      }
    }

    if (singleTransDta && singleTransDta.data.result && Number(singleTransDta.data?.result?.id) === Number(id) && singleTransDta.error === false) {
      let dt = {
        ...singleTransDta.data.result,
        property_id: Number(singleTransDta.data.result.property_id),
        amount: Number(Number(singleTransDta.data.result.amount) + Number(singleTransDta.data.result.gst_amount)).toFixed(2),
        transaction_date: dayjs(singleTransDta.data.result.transaction_date),
        property_type: getPropertyTypeLabel(PROPERTYTYPE?.property_type),
      };
      setSingleTransData(dt);
      setCheckPropertyType(propertyData?.find((item) => item.value === Number(dt.property_id))?.flag);

      setPreviewUrl(dt.receipt_path);
      if (dt.receipt_path === null || dt.receipt_path === '') {
        onRemove();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDta, categoryDta, singleTransDta]);

  useEffect(() => {
    if (financialYears.length > 0 && singleTransData && singleTransData.id) {
      const ranges = getFinancialYearRanges(financialYears);
      const date = dayjs(singleTransData.transaction_date);
      let lock = false;
      for (let range of ranges) {
        if (range.locked && date && date.isBetween(range.start, range.end, 'day', '[]')) {
          lock = true;
          const errorMessage = getErrorNoteForLockedYear(date, range.locked_user_type, range.locked_by, admin1?.id);
          setLockErrorMessage(errorMessage);
          break;
        }
      }
      if (lock) {
        setLockDisabled(true);
        setDisabled(true);
      } else {
        setLockDisabled(false);
      }
    }
  }, [singleTransData, financialYears]);

  const getLockYears = () => {
    dispatch(getLockYearsList(currentClient)).then((data) => {
      if (data.payload.success) {
        setFinancialYears(data.payload.result || []);
      }
    });
  };

  const validateDate = (date) => {
    const ranges = getFinancialYearRanges(financialYears);
    for (let range of ranges) {
      if (range.locked && date && date.isBetween(range.start, range.end, 'day', '[]')) {
        return getErrorMessageForLockedYear(date, range.locked_user_type, range.locked_by, admin1?.id);
      }
    }
    return null;
  };

  const onRemove = () => {
    try {
      setFileList([]);
    } catch (error) {
      console.error(error);
    }
  };
  const DeleteFile = () => {
    onRemove();
    setPreviewUrl('');
  };

  const changeProperty = (e, data) => {
    setCheckPropertyType(data.flag);
    productForm.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.property_type),
    });
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0 && !['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(newFileList[0]?.type)) {
      notification.error({
        message: 'Failure',
        description: 'You can upload file formats .jpg, .jpeg, .png, .pdf only!',
      });
      onRemove();
      return false;
    }
    setFileList(newFileList);
  };

  const saveExp = (dd) => {
    setLoading(true);
    dispatch(EditRecordExpense(dd, id, TransectionType, currentClient)).then((data) => {
      if (data.payload.success === true) {
        notification.success({
          message: 'Success',
          description: data.payload.message,
        });
        setSingleTransData([]);
        productForm.resetFields();
        setLoading(false);
        if (!tid) {
          admin1?.user_type === UserTypes.USER ? navigate(-1) : navigate(-1);
        } else {
          onUpdateTransaction();
        }
      } else if (data?.payload?.success === false && data?.payload?.result?.subscription === false) {
        if (admin1?.user_type === UserTypes.USER || admin1?.user_type === UserTypes.TAXACCOUTANT) {
          modal.confirm({
            title: data?.payload?.message,
            icon: <ExclamationCircleOutlined />,
            okText: 'Subscribe',
            cancelText: 'Cancel',
            onOk: () => {
              navigateFunction();
            },
          });
        } else {
          modal.confirm({
            title: 'Kindly ask your client to subscribe for this property.',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Cancel',
          });
        }
      } else {
        notification.error({
          message: 'Failure',
          description: data.payload.message || 'Something went wrong!',
        });
      }

      setLoading(false);
    });
  };

  const handleFinish = (values) => {
    setLoading(true);
    values = {
      categoryId: values.category_id,
      propertyId: values.property_id,
      transactionDate: dayjs(values.transaction_date).format('YYYY-MM-DD'),
      receiptPath: values.receiptPath,
      description: values.description ? values.description : '',
      amount: values.amount,
      gstAmount: values.gst_amount ? Number(values.gst_amount).toFixed(2) : 0,
    };
    let sendData = values;
    if (LoanId) {
      sendData.loanId = LoanId;
    }
    if (fileList && fileList.length > 0) {
      const timestamp = new Date().valueOf();
      const fileName = fileList[0]?.name.replace(/[^a-zA-Z0-9.]/g, '');
      const key = `${admin1?.id}/transactionsReceipt/${timestamp}${fileName}`;

      dispatch(STSToken()).then(async (res) => {
        if (res.payload.success) {
          const awsConfig = {
            region: res?.payload?.result?.token.Region,
            credentials: {
              accessKeyId: res?.payload?.result?.token.AccessKeyId,
              secretAccessKey: res?.payload?.result?.token.SecretAccessKey,
              sessionToken: res?.payload?.result?.token.SessionToken,
            },
          };
          const client = new S3Client(awsConfig);
          const input = {
            Body: fileList[0]?.originFileObj,
            Bucket: res?.payload?.result?.token?.BucketName,
            Key: key,
            ContentType: fileList[0]?.type,
          };
          const command = new PutObjectCommand(input);
          const response = await client.send(command);
          if (response?.$metadata?.httpStatusCode === 200) {
            sendData.receiptPath = `https://${res?.payload?.result?.token?.BucketName}.s3.amazonaws.com/${key}`;
            setLoading(false);
            saveExp(sendData);
          } else {
            setLoading(false);
            notification.error({
              message: 'Failure',
              description: 'Something went wrong in file!',
            });
          }
        }
      });
    } else if (previewUrl === '') {
      sendData.receiptPath = '';
      saveExp(sendData);
    } else {
      delete sendData.receiptPath;
      saveExp(sendData);
    }
  };

  useEffect(() => {
    productForm.resetFields();
  }, [singleTransData, productForm]);

  const discardRecord = (id) => {
    setLoading(true);
    dispatch(DeleteRecordExpense(id, TransectionType, currentClient)).then((response) => {
      if (response?.payload?.success) {
        notification.success({
          message: 'Success',
          description: response.payload.message || 'Record Expense deleted!',
        });
        if (!tid) {
          admin1?.user_type === UserTypes.USER ? navigate(-1) : navigate(-1);
        } else {
          onUpdateTransaction();
        }
        setLoading(false);
      } else if (response?.payload?.success === false && response?.payload?.result?.subscription === false) {
        if (admin1?.user_type === UserTypes.USER || admin1?.user_type === UserTypes.TAXACCOUTANT) {
          modal.confirm({
            title: response?.payload?.message,
            icon: <ExclamationCircleOutlined />,
            okText: 'Subscribe',
            cancelText: 'Cancel',
            onOk: () => {
              navigateFunction();
            },
          });
        } else {
          modal.confirm({
            title: 'Kindly ask your client to subscribe for this property.',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Cancel',
          });
        }
      } else {
        notification.error({
          message: 'Failure',
          description: response?.payload?.message || 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };
  const beforeUpload = (file, fileList) => {
    if (!['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(file?.type)) {
      return false;
    }
    // Access file content here and do something with it
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);

    // Prevent upload
    return false;
  };

  const loanItems = [
    {
      key: '1',
      label: 'Interest Allocated',
      children: `${singleTransData?.interest_allocation || 0}%`,
    },
    {
      key: '2',
      label: 'Actual Interest Amount',
      children: formatCurrency(singleTransData?.base_amount || 0),
    },
    {
      key: '3',
      label: 'Interest Amount for this property',
      children: formatCurrency(singleTransData?.amount || 0),
    },
  ];

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          {!tid && (
            <Row className="align-items-center">
              <GoBackDefault fromSameSite={fromSameSite} fallbackPath={isTaxAccountant ? '/clients/client/transaction-details' : '/transactions'} />
              <h5 className="m-0">Record Expense</h5>
            </Row>
          )}
          <div className="record_wrapper">
            <Form name="addProductForm" form={productForm} layout="vertical" autoComplete="off" onFinish={handleFinish} initialValues={singleTransData} wrapperCol={{ span: 24 }}>
              <Spin spinning={loading} size="large">
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="record_upload" style={{ width: '100%' }}>
                      <div className="card d-flex justify-content-center align-items-center p-3" style={{ width: '100%' }}>
                        <div className="record_file" style={{ width: '100%' }}>
                          {previewUrl && singleTransDta.data?.result && !lockDisabled ? (
                            <Button type="primary" className="del_btn" icon={<CustomIcon icon={deleteIcon} />} size="small" onClick={DeleteFile} />
                          ) : (
                            ''
                          )}

                          {previewUrl && singleTransDta.data?.result && (
                            <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                              <div className="responsive-wrapper responsive-wrapper-wxh-572x612 text-center">
                                {!isPdfUrl(previewUrl) ? <img src={previewUrl} className="Image_set h-100 img-fluid" alt="Preview Url" /> : <iframe title="nothing" src={previewUrl}></iframe>}
                              </div>
                            </div>
                          )}

                          {!previewUrl && singleTransDta.data?.result && LoanId && (
                            <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                              <div className="responsive-wrapper responsive-wrapper-wxh-572x612">
                                <div className="p-2">
                                  <h6 className="fw-bold h6">Loan Info</h6>
                                </div>
                                <Divider dashed className="m-0 mb-2" />
                                <div className="p-2">
                                  <img src={singleTransData?.bank_logo} className="img-fluid" alt="" style={{ height: '28px', width: '28px', borderRadius: '4px' }} />
                                  <span className="ml-2">{singleTransData?.account_number || '-'} </span>
                                </div>
                                <Descriptions
                                  layout="horizontal"
                                  className="p-2"
                                  bordered
                                  items={loanItems}
                                  column={{
                                    xs: 1,
                                    sm: 1,
                                    md: 1,
                                    lg: 1,
                                    xl: 1,
                                    xxl: 1,
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          {!previewUrl && singleTransDta.data?.result && !LoanId && (
                            <div className="file-input" style={{ width: '100%' }}>
                              <Form.Item name="receiptPath ImportUser" style={{ width: '100%', height: '100%' }}>
                                <Upload.Dragger
                                  onRemove={onRemove}
                                  maxCount={1}
                                  title=""
                                  onChange={handleChange}
                                  beforeUpload={beforeUpload}
                                  fileList={fileList}
                                  style={{ width: '100%', height: '100%' }}
                                  className="file-upload-custome"
                                  disabled={disabled}
                                  accept=".jpg, .jpeg, .png, image/jpeg, image/png, .pdf"
                                >
                                  <p className="ant-upload-drag-icon">
                                    <img src={imgBrowse} alt="browse" />
                                  </p>
                                  <p className="ant-upload-text">Click or drag file to this area to upload (.jpg, .jpeg, .png, .pdf )</p>
                                </Upload.Dragger>
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="row">
                            <div className="col-xl-6 col-md-6 col-12 date_picker">
                              <div className="mb-3 mb-lg-0">
                                <Form.Item
                                  label="Expense Date"
                                  name="transaction_date"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input Expense Date!',
                                    },
                                    () => ({
                                      validator(_, value) {
                                        const errorMessage = validateDate(value);
                                        if (!value || !errorMessage) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(errorMessage));
                                      },
                                    }),
                                  ]}
                                >
                                  <DatePicker format="DD-MM-YYYY" className="rmInput form-control p-2" disabled={disabled} />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12">
                              <Form.Item
                                label="Select Property"
                                name="property_id"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select Property!',
                                  },
                                ]}
                              >
                                <Select
                                  style={{ width: '100%', textAlign: 'left' }}
                                  placeholder="Select Property"
                                  showSearch
                                  options={propertyData}
                                  onSelect={(e, data) => changeProperty(e, data)}
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                  disabled={disabled}
                                >
                                  <Option value="" key={crypto.randomUUID()}>
                                    - - - Select Property - - -
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12 mt-4">
                              <Form.Item
                                label="Select Category"
                                name="category_id"
                                rules={[
                                  {
                                    required: true,
                                    type: 'number',
                                    message: 'Please select Category!',
                                  },
                                ]}
                              >
                                <Select
                                  style={{ width: '100%', textAlign: 'left' }}
                                  placeholder="Select Category"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                  options={
                                    TransectionType !== TrensectionType.BASIQ_TYPE_TRANSACTION
                                      ? [
                                          {
                                            label: 'Income',
                                            options: incomeCategorydata,
                                          },
                                          {
                                            label: 'Expense',
                                            options: expenseCategorydata,
                                          },
                                          {
                                            label: 'Cost',
                                            options: costCategorydata,
                                          },
                                        ]
                                      : [
                                          {
                                            label: 'Expense',
                                            options: expenseCategorydata,
                                          },
                                          {
                                            label: 'Cost',
                                            options: costCategorydata,
                                          },
                                        ]
                                  }
                                  disabled={lockDisabled}
                                >
                                  <Option value="" key={crypto.randomUUID()}>
                                    - - - Select Category - - -
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12 mt-4">
                              <Form.Item
                                label="Amount inclusive GST"
                                name="amount"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input Amount!',
                                  },
                                ]}
                              >
                                <Input className="rmInput form-control p-2" inputtype="number" allowdecimal="true" placeholder="Amount" style={disabled && { color: 'black' }} disabled={disabled} />
                              </Form.Item>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12 mt-4">
                              <Form.Item label="Property Type" name="property_type">
                                <Input className="form-control p-2" style={{ color: 'black' }} disabled={true} placeholder="property Type" type="text" />
                              </Form.Item>
                            </div>
                            {checkPropertyType > 0 && (
                              <div className="col-xl-6 col-md-6 col-12 mt-4">
                                <Form.Item
                                  label="GST"
                                  name="gst_amount"
                                  rules={[
                                    {
                                      // required: checkPropertyType === 0 ? false : true,
                                      message: 'Please enter GST amount!',
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(rule, value) {
                                        if (!value || Number(getFieldValue('amount')) >= Number(value)) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject('The GST amount cannot be greater than the total amount.');
                                      },
                                    }),
                                  ]}
                                >
                                  <Input className="form-control p-2" placeholder="GST Amount" inputtype="number" allowdecimal="true" maxLength={10} disabled={disabled} />
                                </Form.Item>
                              </div>
                            )}
                            <div className="col-12 mt-4">
                              <div className="mb-3">
                                <Form.Item label="Description(Optional)" name="description">
                                  <TextArea className="rmInput form-control p-2" placeholder="Description" rows={3} disabled={disabled} />
                                </Form.Item>
                              </div>
                            </div>
                            {/* <div className="mt-4 d-flex">
                                  <div className="col-xl-6 col-md-6 col-12">
                                    <div className="text-end">
                                      <Popconfirm placement="left" className="" title={text} onConfirm={() => discardRecord(id)} okText="Yes" cancelText="No">
                                        <div className=" login_button">
                                          <button htmlType="submit">Delete</button>
                                        </div>
                                      </Popconfirm>
                                    </div>
                                  </div>

                                  <div className="col-xl-6 col-md-6 col-12">
                                    <div className="text-end login_button">
                                      <button htmlType="submit">Update</button>
                                    </div>
                                  </div>
                                </div> */}
                            <div className="col-12 mt-4 d-flex justify-content-end">
                              {![TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION, TrensectionType.BASIQ_TYPE_TRANSACTION].includes(TransectionType) && !lockDisabled && (
                                <div className="justify-content-start text-start me-3">
                                  <Popconfirm placement="left" className="" title={text} onConfirm={() => discardRecord(id)} okText="Yes" cancelText="No">
                                    <button type="button" className="btn_submit">
                                      Delete
                                    </button>
                                  </Popconfirm>
                                </div>
                              )}
                              {TransectionType !== TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION && !lockDisabled && (
                                <div className="justify-content-end text-end">
                                  <button type="submit" className="btn_submit btn-primary px-5">
                                    Update
                                  </button>
                                </div>
                              )}
                            </div>
                            {lockDisabled && (
                              <div className="col-12">
                                <Alert
                                  message={
                                    <>
                                      <b>Note: </b>
                                      {lockErrorMessage}{' '}
                                    </>
                                  }
                                  type="warning"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Spin>
            </Form>
          </div>

          {/* Error message of subscription */}
          {contextHolder}
        </div>
      </div>
    </main>
  );
}
