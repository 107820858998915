import React, { useState } from 'react';
import { Button, Form, List, Radio } from 'antd';
import logosvg from '../../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/antd.css';
import SignUpHOC from './SignUpHOC';
import { DoubleRightOutlined } from '@ant-design/icons';
import { UserTypes } from '../../common/userTypes';
import TwoFactorAppAuthenticationModal from '../UserProfile/TwoFactorAppAuthenticationModal';
import TwoFactorSMSModal from './TwoFactorSMSModal';

const TwoFactor = () => {
  const [appOpen, setAppOpen] = useState(false);
  const [appSmsOpen, setAppSmsOpen] = useState(false);
  const navigate = useNavigate();
  const user_info = JSON.parse(localStorage.getItem('admin'));

  const handleSkip = () => {
    if (user_info?.user_type === UserTypes.USER) {
      navigate('/referral', { replace: true });
    } else {
      if (![UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(user_info.user_type) && user_info?.company_name === null) {
        navigate('/complete-profile', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    }
  };

  return (
    <SignUpHOC>
      <div className="login_wrapper tandc_wrapper">
        <div className="card shadow border-0">
          <div className="card-body p-0">
            <div className="modal-body p-4 ">
              <div className="text-center tandc_logo mb-4">
                <img src={logosvg} className="img-fluid w-50" alt="" />
              </div>

              <div className="row">
                <h5>Two Factor Authentication</h5>
                <p>Enable Two Factor Authentication to protect your account </p>
              </div>

              <div className="verify-mobile-number mt-2">
                <Form layout="vertical" onFinish={null}>
                  <div className="row d-flex align-items-center gap-4">
                    <div className="col">
                      <Form.Item
                        name="2fa_type"
                        rules={[
                          {
                            required: true,
                            message: 'Please select 2FA type!',
                          },
                        ]}
                      >
                        <List
                          itemLayout="horizontal"
                          dataSource={[{}, {}]}
                          renderItem={(item, index) => (
                            <List.Item className="cursor-pointer" onClick={() => (index === 1 ? setAppOpen(true) : setAppSmsOpen(true))}>
                              {index === 0 && (
                                <List.Item.Meta
                                  avatar={<Radio value="sms" checked={false} onChange={() => setAppSmsOpen(true)} />}
                                  title={'Text Message (SMS)'}
                                  description="Receive a one-time passcode via SMS each time you log in."
                                />
                              )}
                              {index === 1 && (
                                <List.Item.Meta
                                  avatar={<Radio value="totp" checked={false} onChange={() => setAppOpen(true)} />}
                                  title={'Authenticator App (TOTP)'}
                                  description="Use an app to receive a temporary one-time passcode each time you log in."
                                />
                              )}
                            </List.Item>
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <div className="text-end">
                        <Button className="ml-2 text-muted" onClick={() => handleSkip()} icon={<DoubleRightOutlined />} iconPosition="right">
                          Skip
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TwoFactorAppAuthenticationModal redirect={true} open={appOpen} setOpen={setAppOpen} />
      <TwoFactorSMSModal user_info={user_info} onSuccess={handleSkip} open={appSmsOpen} setOpen={setAppSmsOpen} />
    </SignUpHOC>
  );
};

export default TwoFactor;
