import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const editPropertySlice = createSlice({
    name: "EditProperty",
    initialState,
    reducers: {
        editPropertyRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        editPropertySuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        editPropertyFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { editPropertyRequest, editPropertySuccess, editPropertyFailure } = editPropertySlice.actions;

export default editPropertySlice.reducer;
