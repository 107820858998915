import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getBankConnectSlice = createSlice({
    name: "GetDashboardData",
    initialState,
    reducers: {
        getBankRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getBankSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getBankFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getBankRequest, getBankSuccess, getBankFailure } =
    getBankConnectSlice.actions;

export default getBankConnectSlice.reducer;
