import { UserTypes } from '../../../../components/common/userTypes';
import { postRequest } from '../../../../utils/apiUtils';
import { addUserDataFailure, addUserDataRequest, addUserDataSuccess } from '../../../reducers/slices/texDashboard/subusers/postuserData.slice';

export const AddUserData = (data, id) => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  let tempURl = ``;
  if (!id) {
    tempURl = `${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'sub-channel-partner' : 'sub-tax-accountant'}/create/undefined`;
  } else {
    tempURl = `${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'sub-channel-partner' : 'sub-tax-accountant'}/create/${id}`;
  }
  return async (dispatch) => {
    await dispatch(addUserDataRequest());
    const { result, error } = await postRequest(tempURl, data);

    if (!error) {
      return await dispatch(addUserDataSuccess(result));
    }
    return await dispatch(addUserDataFailure(result));
  };
};
