import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getTransactionByIdSlice = createSlice({
    name: "GetTransactions",
    initialState,
    reducers: {
        getTransactionByIdRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getTransactionByIdSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getTransactionByIdFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getTransactionByIdRequest, getTransactionByIdSuccess, getTransactionByIdFailure } =
    getTransactionByIdSlice.actions;

export default getTransactionByIdSlice.reducer;
