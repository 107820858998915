import { putRequest } from '../../../utils/apiUtils';
import { editPropertySuccess, editPropertyFailure, editPropertyRequest } from '../../reducers/slices/property/editProperty.slice';

export const EditProperty = (data, id, id1) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/property/${id}`
  } else {
    tempURl = `user/property/${id}/?userId=${id1}`
  }
  return async (dispatch) => {
    await dispatch(editPropertyRequest());
    const { result, error } = await putRequest(tempURl, data);

    if (!error) {
      return await dispatch(editPropertySuccess(result));
    }
    return await dispatch(editPropertyFailure(result));
  };
};
