import { Button, Result } from 'antd';
import React from 'react';
import { getIcon } from './NotificationUtil';
import { useNavigate } from 'react-router-dom';

export default function NoProperty({ isTaxAccountant }) {
  const navigate = useNavigate();
  return (
    <Result
      status="error"
      style={{ paddingTop: '102px' }}
      icon={getIcon('add_property_new')}
      title="Add New Property"
      subTitle="Please update your property profile by clicking on the new property button."
      extra={[
        <Button
          onClick={() => {
            isTaxAccountant ? navigate('/clients/client/property/add', { state: { fromSameSite: true } }) : navigate('/property/add', { state: { fromSameSite: true } });
          }}
          className="property_btn"
          key={crypto.randomUUID()}
        >
          + Add Property
        </Button>,
      ]}
    />
  );
}
