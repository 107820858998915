// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_wrapper .activity-parent-div {
  position: relative;
  height: 550px !important;
}
.card_wrapper .activity-center {
  position: absolute;
  top: 35%;
  left: 50%;
  scale: 1.25;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/UserActivity/index.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,wBAAA;AAAJ;AAHA;EAOI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;AADJ","sourcesContent":[".card_wrapper {\n  .activity-parent-div {\n    position: relative;\n    height: 550px !important;\n  }\n\n  .activity-center {\n    position: absolute;\n    top: 35%;\n    left: 50%;\n    scale: 1.25;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
