import { getRequest } from '../../../utils/apiUtils';
import { getPlanListRequest, getPlanListSuccess, getPlanListFailure } from '../../reducers/slices/subscription/getPlanList.slice';

export const GetPlanList = (id) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `user/subscription/plan`;
    } else {
      tempURl = `user/subscription/plan?userId=${id}`;
    }
    await dispatch(getPlanListRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPlanListSuccess(result));
    }
    return await dispatch(getPlanListFailure(result));
  };
};
