import { getRequest } from '../../../utils/apiUtils';

export const getMortgageBrokerDashboardData = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`tax-accountant/dashboard/statistics`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getMortgageBrokerDashboardListData = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`tax-accountant/dashboard/table`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
