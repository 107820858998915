import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getAuditTrailDataSlice = createSlice({
  name: 'getAuditTrailData',
  initialState,
  reducers: {
    getAuditTrailDataRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    getAuditTrailDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getAuditTrailDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getAuditTrailDataRequest, getAuditTrailDataSuccess, getAuditTrailDataFailure } = getAuditTrailDataSlice.actions;

export default getAuditTrailDataSlice.reducer;
