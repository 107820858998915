import React from 'react';
import { Modal } from 'antd';

import AddMortgageBrokerDiv from './AddMortgageBrokerDiv';

const ModalAddMortgageBroker = ({ setIsOpen1 = () => {}, isOpen1 = false }) => {
  return (
    <Modal destroyOnClose onCancel={() => setIsOpen1(false)} footer={null} centered style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpen1}>
      <AddMortgageBrokerDiv setIsOpenMortgageBroker={setIsOpen1} />
    </Modal>
  );
};

export default ModalAddMortgageBroker;
