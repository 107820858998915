import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getPropertyListSlice = createSlice({
  name: 'GetPropertyList',
  initialState,
  reducers: {
    getPropertyListRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getPropertyListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getPropertyListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
    getPropertyByIdRequest: (state, action) => {
      state.isLoading = true;
    },
    getPropertyByIdSuccess: (state, action) => {
      state.data = { ...action.payload, result: [...new Map([...state.data?.result, action.payload?.result].map((item) => [item['id'], item])).values()] };
      state.error = false;
      state.isLoading = false;
    },
    getPropertyByIdFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getPropertyListRequest, getPropertyListSuccess, getPropertyListFailure, getPropertyByIdRequest, getPropertyByIdSuccess, getPropertyByIdFailure } = getPropertyListSlice.actions;

export default getPropertyListSlice.reducer;
