import { putRequest } from '../../../utils/apiUtils';
import { getApproveUserFailure, getApproveUserRequest, getApproveUserSuccess } from '../../reducers/slices/dashboard/getApprove.slice';

export const getApproveUser = (id, type) => {
  return async (dispatch) => {
    await dispatch(getApproveUserRequest());
    const { result, error } = await putRequest(`user/${type === 1 ? 'tax-accountant' : 'mortgage-broker'}/approve/${id}`);

    if (!error) {
      return await dispatch(getApproveUserSuccess(result));
    }
    return await dispatch(getApproveUserFailure(result));
  };
};
