import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getPropertyListWithOutLoanSlice = createSlice({
    name: "GetPropertyList",
    initialState,
    reducers: {
        getPropertyListWithOutLoanRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getPropertyListWithOutLoanSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getPropertyListWithOutLoanFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const {
    getPropertyListWithOutLoanRequest,
    getPropertyListWithOutLoanSuccess,
    getPropertyListWithOutLoanFailure,
} = getPropertyListWithOutLoanSlice.actions;

export default getPropertyListWithOutLoanSlice.reducer;
