import { postRequest } from '../../../utils/apiUtils';
import { addRentalSummaryRequest, addRentalSummarySuccess, addRentalSummaryFailure } from '../../reducers/slices/rental_summary/addRentalSummary.slice';

export const AddRentalSummary = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/rent-summary`;
  } else {
    tempURl = `user/rent-summary?userId=${id}`;
  }
  return async (dispatch) => {
    await dispatch(addRentalSummaryRequest());
    const { result, error } = await postRequest(tempURl, data);

    if (!error) {
      return await dispatch(addRentalSummarySuccess(result));
    }
    return await dispatch(addRentalSummaryFailure(result));
  };
};
