import { Avatar, Button, Card, Col, Modal, notification, Row, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { GoBackDefault } from '../../common/GoBack';
import { DepreciationMethod, PropertyType, UserTypes } from '../../common/userTypes';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { GetPropertyById } from '../../../redux/actions/property/getPropertyListById.action';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getPropertyTypeLabel } from '../../../utils/ConstLabel';
import { DeleteProperty } from '../../../redux/actions/property/deleteProperty.action';
import PropertyNotificationModal from './notificationModal';
import Transactions from '../transactions';
import PropertyBanks from './bank';
import CustomIcon from '../../sidebar/custIcon';
import editIcon from '../../../assets/images/edit.svg';
import deleteIcon from '../../../assets/images/delete.svg';
import propertyDashboard from '../../../assets/images/property.svg';
import { ReactComponent as Priminum } from '../../../assets/images/priminum.svg';
import TransactionsByUserProperty from '../details/Details';
import BankConnection from '../details/clientTab/BankConnection';
import { formatCurrency } from '../../../utils';
import { getAustralianFinancialYear } from '../../../utils';
import PendingTasks from './PendingTasks';
import Reports from '../reports';
import Documentation from '../details/clientTab/Documentation';

const { Paragraph } = Typography;

export default function PropertyDetails({ isTaxAccountant = false }) {
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);
  const location = useLocation();
  const [propertyData, setPropertyData] = useState({});
  const [loading, setLoading] = useState(false);
  const currentClient = localStorage.getItem('currentClient');
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentNotification, setCurrentNotification] = useState([]);

  const activeKey = propertyData?.action_item?.length > 0 ? 'pendingTasks' : 'transaction';

  const [activeTabKey, setActiveTabKey] = useState(activeKey);
  const [modal, contextHolder] = Modal.useModal();

  const propertyDataById = useSelector((state) => state.getPropertyListByIdReducer);

  // Function to update URL query parameter
  const updateQuery = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    const updatedActiveKey = propertyData?.action_item?.length > 0 ? 'pendingTasks' : 'transaction';
    const activeTab = new URLSearchParams(location.search).get('tab');
    setActiveTabKey(activeTab || updatedActiveKey);
  }, [propertyData, location.search]);

  // On component mount, check the URL for the active tab key
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromQuery = searchParams.get('tab');
    if (tabFromQuery) {
      setActiveTabKey(tabFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    if (id) {
      dispatch(GetPropertyById(id, currentClient));
    }
  }, [currentClient, dispatch, id]);

  useEffect(() => {
    setLoading(propertyDataById.isLoading);
    if (id && propertyDataById && propertyDataById.data?.result && propertyDataById.error === false) {
      setPropertyData(propertyDataById?.data?.result || {});
    }
  }, [propertyDataById, id]);

  const items = [
    {
      key: 'transaction',
      label: 'Transaction',
    },
    {
      key: 'document',
      label: 'Document',
    },
    {
      key: 'loan',
      label: 'Loan',
    },
    {
      key: 'report',
      label: 'Report',
    },
  ];

  if (propertyData?.action_item?.length > 0) {
    items.unshift({ key: 'pendingTasks', label: 'Pending Tasks' });
  }

  const DeleteHandleProperty = (id) => {
    setLoading(true);
    dispatch(DeleteProperty(id, currentClient)).then((data) => {
      if (data.payload.success === true) {
        notification.success({
          message: 'Success',
          description: data.payload.message || 'Property Deleted Successfully',
        });
        admin1.user_type === UserTypes.USER ? navigate('/property') : navigate('/clients/client/property-details');
      }
    });
  };

  const clearNotifications = (isReload = false, id = null) => {
    setCurrentNotification([]);
    if (isReload) {
      dispatch(GetPropertyById(id, currentClient));
    }
  };

  const getDepreciationMethod = (key) => {
    return key === DepreciationMethod.PRIME_COST_DEPRECIATION ? 'Prime Cost' : 'Diminishing Value';
  };

  const onDeleteHandle = (record) => {
    modal.confirm({
      title: 'Are you sure you want to delete your property?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      content: (
        <div className="desc">
          {record.property_type !== PropertyType.OWNER_OCCUPIED_PROPERTY && record?.stripe_subscription_id ? (
            <Paragraph className="pt-2">Deleting this property will cancel your subscription to this property and permanently delete all property related data and documents.</Paragraph>
          ) : (
            <Paragraph className="pt-2">Deleting this property will delete all property related data and documents.</Paragraph>
          )}
          <Paragraph>We cannot recover or reinstate any deleted information if you change your mind later.</Paragraph>
          <Paragraph>Are you sure you want to proceed with the deletion?</Paragraph>
        </div>
      ),
      onOk: () => {
        DeleteHandleProperty(record.id);
      },
      width: 800,
    });
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
    updateQuery('tab', key);
  };

  const fromSameSite = location?.state && location?.state?.fromSameSite;

  return (
    <main className="page-content">
      {/* <Spin loading={'false'} size="large"> */}
      <div className="container-fluid bg-dash-back">
        <div className={` ${isTaxAccountant ? '' : 'layout-specing'} `}>
          <div className="add_form-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <GoBackDefault fromSameSite={fromSameSite} fallbackPath={admin1.user_type === UserTypes.USER ? '/property' : '/clients/client/property-details'} />

                <h5 className="m-0"> Property Details </h5>
              </div>
              <div>
                {!isTaxAccountant && (
                  <button onClick={() => navigate('/record-expenses', { state: { fromSameSite: true } })} className="p-0 property_btn1 btn btn-primary px-3 py-2 property_btn1_responsive mr-0">
                    + Add Income / Expense
                  </button>
                )}
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col">
                <Card className="shadow-sm property_list_card" loading={loading}>
                  <div className="row">
                    <div className="col d-flex justify-content-between">
                      <div className="card-title mb-0">
                        <div className="d-flex gap-4">
                          <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa !important', verticalAlign: 'middle' }} size="large">
                            <img style={{ filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)' }} width={20} src={propertyDashboard} alt="" />
                          </Avatar>
                          <div>
                            <div className="d-flex mb-2 align-items-center justify-content-left">
                              <strong className="text-decoration-underline property_title">{`${propertyData.street_number}${propertyData.street_name ? ' ' + propertyData.street_name : ''}`}</strong>
                              {propertyData.stripe_subscription_id && <Priminum width={22} className="ml-1" title="Subscribed" />}
                            </div>
                            <strong>{`${propertyData.suburb || ''}${propertyData.state ? ', ' + propertyData.state : ''}${propertyData.postcode ? ' ' + propertyData.postcode : ''}`}</strong>
                            <h6 className="card-subtitle text-secondary mt-2">{getPropertyTypeLabel(propertyData.property_type)}</h6>
                            <div className="mt-2">
                              <label>Depreciation:</label> <strong> {propertyData.depreciation_method ? getDepreciationMethod(propertyData.depreciation_method) : 'N/A'} </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        {/* <Badge
                          count={propertyData.action_item && propertyData?.action_item?.filter((item) => item.complete !== true).length}
                          style={{ boxShadow: 'none' }}
                          className="noti-badge mr-2 mt-1"
                        > 
                          <NotificationOutlined className="icon" onClick={() => setCurrentNotification(propertyData)} />
                        </Badge> */}
                        <Button
                          title="Edit"
                          type="primary"
                          icon={<CustomIcon icon={editIcon} />}
                          onClick={() => {
                            isTaxAccountant
                              ? navigate('/clients/client/property/edit/' + propertyData.id, { state: { fromSameSite: true } })
                              : navigate('/property/edit/' + propertyData.id, { state: { fromSameSite: true } });
                          }}
                          size="small"
                          className="card_button"
                        />

                        <Button title="Delete" type="primary" className="Delete_btn card_button" icon={<CustomIcon icon={deleteIcon} />} size="small" onClick={() => onDeleteHandle(propertyData)} />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mt-4">
                <Row gutter={[16, 16]}>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Net Income</h6>
                        <Tooltip title={`Net Income (FY ${getAustralianFinancialYear(new Date())})`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData?.netincome || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Rent+</h6>
                        <Tooltip title={`Rent + Other Income (FY ${getAustralianFinancialYear(new Date())})`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData.cy_income || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Expense</h6>
                        <Tooltip title={`Expense (FY ${getAustralianFinancialYear(new Date())})`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData.cy_expense || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <h6 className="text-muted mb-3">Market Value</h6>
                      <h5 className="m-0">
                        {propertyData.market_value === 'SOLD' ? (
                          <Tag color="red">SOLD</Tag>
                        ) : parseFloat(propertyData.market_value) > propertyData.admin_market_value ? (
                          formatCurrency(propertyData.market_value || '0')
                        ) : (
                          formatCurrency(propertyData.admin_market_value || '0')
                        )}{' '}
                      </h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <h6 className="text-muted mb-3">Loan</h6>
                      <h5 className="m-0 text-danger">{propertyData.loan === 'Not Available' ? 'N/A' : formatCurrency(propertyData.loan || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Net Equity</h6>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData.net_equity || '0')}</h5>
                    </Card>
                  </Col>
                  {/* <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">LVR</h6>
                        <Tooltip title={`Loan to Value Ratio`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{`${propertyData.lvr || propertyData.lvr === '' ? propertyData.lvr + '%' : 'N/A'}`}</h5>
                    </Card>
                  </Col> */}
                </Row>
              </div>
              <div className="mt-4 overflow-hidden">
                <Card
                  style={{
                    width: '100%',
                  }}
                  className={`${activeTabKey === 'report' ? 'reportPropertyCard' : 'shadow-sm'}`}
                  loading={loading}
                  tabList={items}
                  activeTabKey={activeTabKey}
                  onTabChange={onTabChange}
                  tabProps={{
                    size: 'large',
                  }}
                >
                  {activeTabKey === 'loan' && propertyData.loans && (
                    <>{admin1.user_type === UserTypes.USER ? <PropertyBanks propertyId={propertyData.id} /> : <BankConnection propertyId={propertyData.id} />}</>
                  )}

                  {activeTabKey === 'transaction' && (
                    <>
                      {admin1.user_type === UserTypes.USER ? (
                        <Transactions propertyId={propertyData.id} />
                      ) : (
                        <TransactionsByUserProperty propertyId={propertyData.id} isTaxAccountant={true} spanIncrease={true} />
                      )}
                    </>
                  )}

                  {activeTabKey === 'document' && <Documentation propertyId={propertyData.id} individual={true} isTaxAccountant={isTaxAccountant} />}

                  {activeTabKey === 'pendingTasks' && propertyData?.action_item?.length > 0 && (
                    <PendingTasks isTaxAccountant={isTaxAccountant} list={propertyData?.action_item} clearNotifications={clearNotifications} loading={loading} />
                  )}

                  {activeTabKey === 'report' && <Reports propertyId={[id]} />}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Spin> */}
      <PropertyNotificationModal list={currentNotification?.action_item} clearNotifications={clearNotifications} loading={loading} />
      {contextHolder}
    </main>
  );
}
