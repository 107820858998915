import { ClockCircleOutlined, CreditCardOutlined, DeleteOutlined, DollarOutlined, DownloadOutlined, HistoryOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { UserActivityType, UserTypes } from '../../common/userTypes';

export const userActivityTypesOptions = [
  {
    value: UserActivityType.ACTIVITY_PROPERTY_INSERT,
    label: 'Property Insert',
  },
  {
    value: UserActivityType.ACTIVITY_PROPERTY_EDIT,
    label: 'Property Edit',
  },
  {
    value: UserActivityType.ACTIVITY_PROPERTY_DELETE,
    label: 'Property Delete',
  },
  {
    value: UserActivityType.ACTIVITY_DEPRECIATIONS_INSERT,
    label: 'Depreciation Insert',
  },
  {
    value: UserActivityType.ACTIVITY_DEPRECIATIONS_EDIT,
    label: 'Depreciation Edit',
  },
  {
    value: UserActivityType.ACTIVITY_DEPRECIATIONS_DELETE,
    label: 'Depreciation Delete',
  },
  {
    value: UserActivityType.ACTIVITY_RENT_INSERT,
    label: 'Rent Insert',
  },
  {
    value: UserActivityType.ACTIVITY_RENT_EDIT,
    label: 'Rent Edit',
  },
  {
    value: UserActivityType.ACTIVITY_RENT_DELETE,
    label: 'Rent Delete',
  },
  {
    value: UserActivityType.ACTIVITY_EXPENSE_INSERT,
    label: 'Expense Insert',
  },
  {
    value: UserActivityType.ACTIVITY_EXPENSE_EDIT,
    label: 'Expense Edit',
  },
  {
    value: UserActivityType.ACTIVITY_EXPENSE_DELETE,
    label: 'Expense Delete',
  },
  {
    value: UserActivityType.ACTIVITY_SETTLEMENT_INSERT,
    label: 'Settlement Insert',
  },
  {
    value: UserActivityType.ACTIVITY_SETTLEMENT_EDIT,
    label: 'Settlement Edit',
  },
  {
    value: UserActivityType.ACTIVITY_SETTLEMENT_DELETE,
    label: 'Settlement Delete',
  },
  {
    value: UserActivityType.ACTIVITY_BANK_CONNECT,
    label: 'Bank Connect',
  },
  {
    value: UserActivityType.ACTIVITY_REPORT_GENERATED,
    label: 'Report Generated',
  },
  {
    value: UserActivityType.ACTIVITY_SUBSCRIPTION_PURCHASE,
    label: 'Subscription Purchase',
  },
  {
    value: UserActivityType.ACTIVITY_SUBSCRIPTION_CANCELED,
    label: 'Subscription Canceled',
  },
];

export const userSourcesOptions = [
  {
    value: UserTypes.USER,
    label: 'Self',
  },
  {
    value: UserTypes.TAXACCOUTANT,
    label: 'Tax Account',
  },
  {
    value: UserTypes.ADMIN,
    label: 'Automated Entry',
  },
];

export const activityIcon = (value) => {
  switch (value) {
    case UserActivityType.ACTIVITY_PROPERTY_INSERT:
    case UserActivityType.ACTIVITY_DEPRECIATIONS_INSERT:
    case UserActivityType.ACTIVITY_SETTLEMENT_INSERT:
    case UserActivityType.ACTIVITY_RENT_INSERT:
    case UserActivityType.ACTIVITY_EXPENSE_INSERT:
      return <PlusCircleOutlined className="timeline-clock-icon" style={{ fontSize: 20, color: '#56C36E' }} />;

    case UserActivityType.ACTIVITY_DEPRECIATIONS_DELETE:
    case UserActivityType.ACTIVITY_SETTLEMENT_DELETE:
    case UserActivityType.ACTIVITY_RENT_DELETE:
    case UserActivityType.ACTIVITY_EXPENSE_DELETE:
    case UserActivityType.ACTIVITY_PROPERTY_DELETE:
      return <DeleteOutlined className="timeline-clock-icon" style={{ fontSize: 20, color: '#F35C5C' }} />;

    case UserActivityType.ACTIVITY_PROPERTY_EDIT:
    case UserActivityType.ACTIVITY_DEPRECIATIONS_EDIT:
    case UserActivityType.ACTIVITY_SETTLEMENT_EDIT:
    case UserActivityType.ACTIVITY_RENT_EDIT:
    case UserActivityType.ACTIVITY_EXPENSE_EDIT:
      return <HistoryOutlined className="timeline-clock-icon" style={{ fontSize: 20, color: '#43B6FE' }} />;

    case UserActivityType.ACTIVITY_BANK_CONNECT:
      return <DollarOutlined className="timeline-clock-icon" style={{ fontSize: 20, color: '#6B49FB' }} />;

    case UserActivityType.ACTIVITY_REPORT_GENERATED:
      return <DownloadOutlined className="timeline-clock-icon" style={{ fontSize: 20 }} />;

    case UserActivityType.ACTIVITY_SUBSCRIPTION_PURCHASE:
    case UserActivityType.ACTIVITY_SUBSCRIPTION_CANCELED:
      return <CreditCardOutlined className="timeline-clock-icon" style={{ fontSize: 20 }} />;

    default:
      return <ClockCircleOutlined className="timeline-clock-icon" style={{ fontSize: 20 }} />;
  }
};
