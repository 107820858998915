import React, { useEffect, useState } from 'react';
import { MailOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Descriptions, Divider, Form, Input, notification, Popconfirm, Popover, Spin } from 'antd';
import CustomeInput from '../components/common/index';

import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTaxAccountantAsync,
  deleteTaxAccountantAsync,
  fetchTaxAccountantAsync,
  selectTaxAccountantData,
  selectTaxAccountantFetching,
} from '../redux/reducers/slices/UserTaxAccountant/UserTaxAccountantSlice';

const AddTaxAccountantDiv = ({ setIsOpen1 = () => {}, userId = null, onReloadHandler = () => {}, apiCall = false }) => {
  const [spin1, setSpin1] = useState(false);

  const [form] = Form.useForm();

  const loading = useSelector(selectTaxAccountantFetching);
  const assignTax = useSelector(selectTaxAccountantData);

  const dispatch = useDispatch();

  const taxAccoutantDetails = [
    {
      key: '1',
      label: 'Name',
      children: assignTax?.company_name || assignTax?.name,
    },
    {
      key: '2',
      label: 'Email',
      children: assignTax?.email,
    },
    {
      key: '3',
      label: 'Mobile',
      children: assignTax?.phone_number,
    },
  ];

  const taxAccoutanUsertDetails = [
    {
      key: '1',
      label: 'Name',
      children: assignTax?.tax_accountant?.name,
    },
    {
      key: '2',
      label: 'Email',
      children: assignTax?.tax_accountant?.email,
    },
    {
      key: '3',
      label: 'Mobile',
      children: assignTax?.tax_accountant?.phone_number,
    },
  ];

  useEffect(() => {
    dispatch(fetchTaxAccountantAsync());
  }, [dispatch]);

  const handleAccountent = async (values) => {
    setSpin1(true);
    delete values.agreement;

    var newValues = {
      ...values,
      mobile: values.mobile ? `+${values.mobile}` : '',
    };

    try {
      setSpin1(true);

      const res = await dispatch(addTaxAccountantAsync(newValues));

      if (res.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        if (!userId) {
          onReloadHandler();
        }
        await dispatch(fetchTaxAccountantAsync());

        setIsOpen1(false);
        setSpin1(false);
        form.resetFields();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
    setSpin1(false);
  };

  const deleteTaxuser = async () => {
    const res = await dispatch(deleteTaxAccountantAsync());

    if (res?.payload?.data?.success === true) {
      notification.success({
        message: 'Success',
        description: res?.payload?.data?.message,
      });
      setIsOpen1(false);
    } else {
      notification.error({
        message: 'Failure',
        description: res?.payload?.message || 'Something went wrong!',
      });
    }
  };

  return (
    <Card bordered={false} className={`shadow-none profile-content-card profile-card-parent`} title={apiCall && 'Tax Accountant'}>
      {!apiCall && <p className="profile-item-heading">Tax Accountant</p>}
      <Spin spinning={loading || spin1}>
        {spin1 === false && assignTax?.id === undefined && (
          <Form form={form} className="w-100" layout="vertical" id="forgotPassword" onFinish={handleAccountent}>
            <Form.Item
              label="Accountant Name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter Accountant Name!',
                },
              ]}
            >
              <CustomeInput prefix={<UserOutlined className="site-form-item-icon" />} inputtype="alphabetspace" />
            </Form.Item>
            <Form.Item label="Company Name (Optional)" name="companyName">
              <CustomeInput prefix={<SmileOutlined className="site-form-item-icon" />} inputtype="alphabetspace" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter correct email!',
                  type: 'email',
                },
              ]}
            >
              <Input prefix={<MailOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[
                {
                  required: false,
                  message: 'Please input your Mobile Number!',
                },
                {
                  pattern: new RegExp(/^([0-9]+.?[0-9]*|.[0-9]+)$/),
                  message: 'Please input only digits!',
                },
              ]}
            >
              <PhoneInput country="au" placeholder="Enter Phone Number" enableSearch />
            </Form.Item>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              className="m-0"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept consent.'))),
                },
              ]}
            >
              <Checkbox>
                I{' '}
                <Popover
                  content={
                    <>
                      <p>By accepting the Terms & Conditions, you authorize Tax Accountant to access and manage specific property-related data necessary for tax purposes.</p>
                      <p>This includes:</p>
                      <ul>
                        <li>Property Details and Reports</li>
                        <li>Income, Expenses, and Costs associated with the property (including supporting documents)</li>
                        <li>Reports such as Income and Expenses and Cost Base Reports</li>
                        <li>Bank Loan Summary</li>
                      </ul>
                      <p>
                        With this consent, Tax Accountant is permitted to make additions or modifications to property details and expenses.
                        <br /> They may also share this information with their team members for tax preparation and analytical purposes.
                        <br /> Rest assured, your data will be handled securely and with utmost confidentiality.
                      </p>
                    </>
                  }
                  title="Consent to Share Your Property Data"
                  trigger="click"
                >
                  <button className="link-button" type="button">
                    consent
                  </button>
                </Popover>{' '}
                to share my data.
              </Checkbox>
            </Form.Item>
            <div className="normal_button text-center">
              <button size="large" type="submit">
                Add
              </button>
            </div>
          </Form>
        )}

        {spin1 === false && assignTax?.id && (
          <div>
            {assignTax?.tax_accountant && <Divider className="p-0 mt-0" />}
            <div className={!apiCall ? '' : 'd-flex'}>
              <Descriptions title={assignTax?.tax_accountant && 'Tax Accountant Company Details'} className="userPreviewDetails" column={1} items={taxAccoutantDetails} />
              {assignTax?.tax_accountant && <Descriptions title="Tax Accountant Details" className="userPreviewDetails" column={1} items={taxAccoutanUsertDetails} />}
            </div>
            <Popconfirm placement="right" title="Are you sure to delete?" onConfirm={() => deleteTaxuser()} okText="Yes" cancelText="No">
              <Button className="btn-danger mt-4 ">Remove Tax Accountant</Button>
            </Popconfirm>
          </div>
        )}
      </Spin>
    </Card>
  );
};

export default AddTaxAccountantDiv;
