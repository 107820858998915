import React from 'react';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import '../src/assets/css/accountant.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/admin.css';
import './assets/css/custom.css';
// import './assets/css/antd.css'
// import './assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsconfig.REGION,
      userPoolId: awsconfig.USER_POOL_ID,
      userPoolClientId: awsconfig.USER_POOL_APP_CLIENT_ID,
      signUpVerificationMethod: 'code',
      // loginWith: {
      //   oauth: {
      //     domain: 'auth-tpa.itechnotion.dev',
      //     scopes: ['phone', 'email', 'profile', 'openid'],
      //     redirectSignIn: ['http://localhost:3000/login/user/'],
      //     redirectSignOut: ['http://locallhost:3000/login/user/'],
      //     responseType: 'code',
      //     providers: ['Google', 'Apple'],
      //   },
      // },
    },
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [process.env.REACT_APP_SENTRY_WEB_URL, process.env.REACT_APP_API_URL],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Authenticator.Provider>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Nunito',
              colorPrimary: '#56aaaa',
            },
          }}
        >
          <App />
        </ConfigProvider>
      </Provider>
    </Authenticator.Provider>
  </BrowserRouter>,
);

reportWebVitals();
