import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import imgBrowse from '../../../assets/browse.svg';
import '../../../assets/less/index.less';
import { Button, DatePicker, Form, notification, Row, Select, Spin, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { GoBackDefault } from '../../common/GoBack';
import { GetCategoryList } from '../../../redux/actions/category/getCategoryList.action';
import { GetPropertyListWithOutLoan } from '../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { AddRecordExpense } from '../../../redux/actions/record_expense/addRecordExpense.action';
import { STSToken } from '../../../redux/actions/record_expense/uploadFile.action';
import Input from '../../common/index';
import { PropertyType, UserTypes } from '../../common/userTypes';
import CustomIcon from '../../sidebar/custIcon';
import deleteIcon from '../../../assets/images/delete.svg';
import { getPropertyTypeLabel } from '../../../utils/ConstLabel';
import { getLockYearsList } from '../../../redux/actions/AuditTrail/getAuditTrailList.action';
import isBetween from 'dayjs/plugin/isBetween';
import { getErrorMessageForLockedYear, getFinancialYearRanges } from '../../../utils';

dayjs.extend(isBetween);

const { Option } = Select;

const regex = /https?:\/\/[^\s]*\.pdf/g;
const isPdfUrl = (url) => {
  return url?.match(regex) || url.includes('application/pdf');
};
const RecordExpenses = ({ onUpdateTransaction = {}, isTaxAccountant, tstate = null }) => {
  const admin = localStorage.getItem('admin');
  const admin1 = JSON.parse(admin);
  const currentClient = localStorage.getItem('currentClient');

  const navigate = useNavigate();
  const location = useLocation();

  const isFromSameSite = location?.state && location?.state?.fromSameSite;

  const { id } = useParams();
  const [productForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [costCategorydata, setCostCategorydata] = useState([]);
  const [fileList, setFileList] = useState();
  const [previewUrl, setPreviewUrl] = useState('');
  const dispatch = useDispatch();
  const [checkPropertyType, setCheckPropertyType] = useState(0);
  const [financialYears, setFinancialYears] = useState([]);
  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);
  const categoryDta = useSelector((state) => state.getCategoryListReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(GetPropertyListWithOutLoan(currentClient));
    dispatch(GetCategoryList());
    getLockYears();
    //eslint-disable-next-line
  }, [dispatch, currentClient]);

  useEffect(() => {
    if (propertyDta.isLoading && categoryDta.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta?.data?.result.map((elm) => {
        return {
          value: elm.id,
          label: elm.street_number ? elm.street_number + (elm.street_name ? ' ' + elm.street_name : '') : '',
          flag: elm.property_type === PropertyType.COMMERCIAL_PROPERTY ? 1 : 0,
          property_type: elm.property_type,
        };
      });
      setPropertyData(dt);
    }
    if (categoryDta && categoryDta.data.result && categoryDta.error === false) {
      let dt = categoryDta.data.result
        .filter((item) => ![45, 46].includes(item.id))
        .map((elm) => {
          return {
            value: elm.id,
            label: elm.category_name,
            income: !!(elm.expense_type === 'Revenue' && elm.transaction_type === 'In'),
            expense: !!(elm.expense_type === 'Revenue' && elm.transaction_type === 'Out'),
            cost: elm.expense_type === 'Capital',
          };
        });
      let Incom = dt.filter((item) => ![false].includes(item.income));
      setIncomeCategorydata(Incom);
      let Expense = dt.filter((item) => ![false].includes(item.expense));
      setExpenseCategorydata(Expense);
      let Cost = dt.filter((item) => ![false].includes(item.cost));
      setCostCategorydata(Cost);
    }
  }, [propertyDta, categoryDta]);

  useEffect(() => {
    productForm.validateFields(['gstAmount']);
  }, [checkPropertyType, productForm]);

  const getLockYears = () => {
    dispatch(getLockYearsList(currentClient)).then((data) => {
      if (data.payload.success) {
        setFinancialYears(data.payload.result || []);
      }
    });
  };

  // const getErrorMessage = (range, role, user_id) => {
  //   const fiscalYear = `${range.start.format('YY')}-${range.end.format('YY')}`;
  //   if (admin1.id === user_id) {
  //     return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by You`;
  //   } else if (role === UserTypes.TAXACCOUTANT || role === UserTypes.SUB_TAXACCOUTANT) {
  //     return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by your Tax Accountant`;
  //   } else if (role === UserTypes.USER) {
  //     return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by the Property Investor`;
  //   }
  // };

  const validateDate = (date) => {
    const ranges = getFinancialYearRanges(financialYears);
    for (let range of ranges) {
      if (range.locked && date && date.isBetween(range.start, range.end, 'day', '[]')) {
        return getErrorMessageForLockedYear(date, range.locked_user_type, range.locked_by, admin1?.id);
      }
    }
    return null;
  };

  const onRemove = () => {
    try {
      setFileList([]);
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteFile = () => {
    onRemove();
    setPreviewUrl('');
  };

  const changeProperty = (e, data) => {
    setCheckPropertyType(data?.flag);
    productForm.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.property_type),
    });
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0 && !['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(newFileList[0]?.type)) {
      notification.error({
        message: 'Failure',
        description: 'You can upload file formats .jpg, .jpeg, .png, .pdf only!',
      });
      onRemove();
      return false;
    }
    setFileList(newFileList);
  };

  const handleSave = () => {
    if (admin1?.user_type === UserTypes.USER) {
      if (location.state && location.state.fromSameSite) {
        navigate(-1);
      } else {
        navigate('/transactions');
      }
    } else {
      if (location.state && location.state.fromSameSite) {
        navigate(-1);
      } else {
        navigate('/clients/client/transaction-details');
      }
    }
  };

  const saveExp = (dd) => {
    setLoading(true);
    let id1 = id || currentClient;
    dispatch(AddRecordExpense(dd, id1)).then((data) => {
      if (data.payload.success === true) {
        setLoading(false);
        notification.success({
          message: 'Success',
          description: data.payload.message,
        });

        if (!tstate) {
          handleSave();
          // admin1?.user_type === UserTypes.USER ? navigate(-1) : navigate(-1, { state: { key: 1 } });
        } else {
          onUpdateTransaction();
        }
        productForm.resetFields();
      } else {
        notification.error({
          message: 'Failure',
          description: data.payload.message || 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };

  const handleFinish = (values) => {
    setLoading(true);
    values = {
      transactionDate: dayjs(values.transaction_date).format('YYYY-MM-DD'),
      propertyId: values.property_id,
      description: values?.description ? values.description : '',
      categoryId: values.category,
      amount: values.amount,
      receiptPath: values.receiptPath,
      gstAmount: values.gstAmount ? Number(values.gstAmount).toFixed(2) : 0,
    };
    let sendData = values;

    if (fileList && fileList.length > 0) {
      const timestamp = new Date().valueOf();
      const fileName = fileList[0]?.name.replace(/[^a-zA-Z0-9.]/g, '');
      const key = `${admin1?.id}/transactionsReceipt/${timestamp}${fileName}`;

      dispatch(STSToken()).then(async (res) => {
        if (res.payload.success) {
          const awsConfig = {
            region: res?.payload?.result?.token.Region,
            credentials: {
              accessKeyId: res?.payload?.result?.token.AccessKeyId,
              secretAccessKey: res?.payload?.result?.token.SecretAccessKey,
              sessionToken: res?.payload?.result?.token.SessionToken,
            },
          };
          const client = new S3Client(awsConfig);
          const input = {
            Body: fileList[0]?.originFileObj,
            Bucket: res?.payload?.result?.token?.BucketName,
            Key: key,
            ContentType: fileList[0]?.type,
          };
          const command = new PutObjectCommand(input);
          const response = await client.send(command);
          if (response?.$metadata?.httpStatusCode === 200) {
            sendData.receiptPath = `https://${res?.payload?.result?.token?.BucketName}.s3.amazonaws.com/${key}`;
            setLoading(false);
            saveExp(sendData);
          } else {
            setLoading(false);
            notification.error({
              message: 'Failure',
              description: 'Something went wrong in file!',
            });
          }
        }
      });
    } else {
      values.receiptPath = '';
      setLoading(false);
      saveExp(sendData);
    }
  };

  const beforeUpload = (file, fileList) => {
    if (!['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(file?.type)) {
      return false;
    }
    // Access file content here and do something with it
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);

    // Prevent upload
    return false;
  };

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className={`${isTaxAccountant ? '' : 'layout-specing'}`}>
          {!tstate && (
            <Row className="align-items-center">
              <GoBackDefault fromSameSite={isFromSameSite} fallbackPath={admin1.user_type === UserTypes.USER ? '/transactions' : '/clients/client/transaction-details'} />
              <h5 className="m-0">Record Expense</h5>
            </Row>
          )}

          <div className="record_wrapper">
            <Form
              name="addProductForm"
              form={productForm}
              layout="vertical"
              autoComplete="off"
              onFinish={handleFinish}
              initialValues={{
                name: '',
                transaction_date: dayjs(),
                code: '',
                description: '',
                price: 0,
                discount: 0,
                offer_discount: 0,
                property_type: '',
                tax: 0,
                stock: '',
                is_active: true,
              }}
              wrapperCol={{ span: 24 }}
            >
              <Spin spinning={loading} size="large">
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="record_upload" style={{ width: '100%' }}>
                      <div className="card d-flex justify-content-center align-items-center p-3" style={{ width: '100%' }}>
                        <div className="record_file" style={{ width: '100%' }}>
                          {previewUrl ? <Button type="primary" className="del_btn" icon={<CustomIcon icon={deleteIcon} />} size="small" onClick={DeleteFile} /> : ''}
                          {previewUrl ? (
                            <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                              <div className="responsive-wrapper responsive-wrapper-wxh-572x612 text-center">
                                {isPdfUrl(previewUrl) ? <iframe title="nothing" src={previewUrl}></iframe> : <img src={previewUrl} className="Image_set h-100 img-fluid" alt="" />}
                              </div>
                            </div>
                          ) : (
                            <div className="file-input" style={{ width: '100%' }}>
                              <Form.Item name="receiptPath" style={{ width: '100%', height: '100%' }}>
                                <Upload.Dragger
                                  onRemove={onRemove}
                                  onChange={handleChange}
                                  fileList={fileList}
                                  maxCount={1}
                                  style={{ width: '100%', height: '100%' }}
                                  beforeUpload={beforeUpload}
                                  className="file-upload-custome"
                                  accept=".jpg, .jpeg, .png, image/jpeg, image/png, .pdf"
                                >
                                  <p className="ant-upload-drag-icon">
                                    <img src={imgBrowse} alt="" />
                                  </p>
                                  <p className="ant-upload-text">Click or drag file to this area to upload (.jpg, .jpeg, .png, .pdf )</p>
                                </Upload.Dragger>
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="row">
                            <div className="col-xl-6 col-md-6 col-12 date_picker">
                              <div className="mb-3 mb-lg-0">
                                <Form.Item
                                  label="Expense Date"
                                  name="transaction_date"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input Expense Date!',
                                    },
                                    () => ({
                                      validator(_, value) {
                                        const errorMessage = validateDate(value);
                                        if (!value || !errorMessage) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(errorMessage));
                                      },
                                    }),
                                  ]}
                                >
                                  <DatePicker className="rmInput form-control p-2" format="DD-MM-YYYY" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12">
                              <Form.Item
                                label="Select Property"
                                name="property_id"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select Property!',
                                  },
                                ]}
                              >
                                <Select
                                  style={{ width: '100%', textAlign: 'left' }}
                                  placeholder="Select Property"
                                  showSearch
                                  options={propertyData}
                                  onSelect={(e, data) => changeProperty(e, data)}
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                >
                                  <Option value="" key={crypto.randomUUID()}>
                                    - - - Select Property - - -
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12 mt-4">
                              <Form.Item
                                label="Select Category"
                                name="category"
                                rules={[
                                  {
                                    required: true,
                                    type: 'number',
                                    message: 'Please select Category!',
                                  },
                                ]}
                              >
                                <Select
                                  style={{ width: '100%', textAlign: 'left' }}
                                  placeholder="Select Category"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                  options={[
                                    {
                                      label: 'Income',
                                      options: incomeCategorydata,
                                    },
                                    {
                                      label: 'Expense',
                                      options: expenseCategorydata,
                                    },
                                    {
                                      label: 'Cost',
                                      options: costCategorydata,
                                    },
                                  ]}
                                >
                                  <Option value="" key={crypto.randomUUID()}>
                                    - - - Select Category - - -
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12 mt-4">
                              <Form.Item
                                label="Amount inclusive GST"
                                name="amount"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input Amount!',
                                  },
                                  {
                                    validator: (_, value) => (value > 0 ? Promise.resolve() : Promise.reject(new Error('Amount must be greater than 0'))),
                                  },
                                ]}
                              >
                                <Input className="rmInput form-control p-2" inputtype="number" allowdecimal="true" placeholder="Amount" />
                              </Form.Item>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12 mt-4">
                              <Form.Item label="Property Type" name="property_type">
                                <Input className="form-control p-2" style={{ color: 'black' }} disabled={true} placeholder="Property Type" type="text" />
                              </Form.Item>
                            </div>
                            {checkPropertyType > 0 && (
                              <div className="col-xl-6 col-md-6 col-12 mt-4">
                                <Form.Item
                                  label="GST"
                                  name="gstAmount"
                                  rules={[
                                    {
                                      // required: checkPropertyType === 0 ? false : true,
                                      message: 'Please enter GST amount!',
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(rule, value) {
                                        if (!value || Number(getFieldValue('amount')) >= value) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject('The GST amount cannot be greater than the total amount.');
                                      },
                                    }),
                                  ]}
                                >
                                  <Input className="form-control p-2" placeholder="GST Amount" inputtype="number" allowdecimal="true" maxLength={10} />
                                </Form.Item>
                              </div>
                            )}
                            <div className="col-12 mt-4">
                              <div className="mb-3">
                                <Form.Item label="Description(Optional)" name="description">
                                  <TextArea className="rmInput form-control p-2" placeholder="Description" rows={3} />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 mt-4">
                              <div className="text-center login_button text-md-end">
                                <button type="submit">Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Spin>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

RecordExpenses.propTypes = {
  onUpdateTransaction: PropTypes.func,
  tstate: PropTypes.any,
};

export default RecordExpenses;
