import { deleteRequest } from '../../../utils/apiUtils';
import { deletePropertyFailure, deletePropertyRequest, deletePropertySuccess } from '../../reducers/slices/property/deleteProperty.slice';

export const DeleteProperty = (id, user_id = null) => {
  return async (dispatch) => {
    await dispatch(deletePropertyRequest());

    const user = user_id ? `?userId=${user_id}` : '';
    const { result, error } = await deleteRequest(`user/property/${id}${user}`);

    if (!error) {
      return await dispatch(deletePropertySuccess(result));
    }
    return await dispatch(deletePropertyFailure(result));
  };
};
