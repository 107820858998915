import React from 'react';

import VerifyOtp from './VerifyOtp';
import { Modal, Typography } from 'antd';

const ModalVerifyOtp = ({
  open = false,
  enterOtp = false,
  setEnterOtp = () => {},
  status = null,
  setLoading = () => {},
  loading = false,
  setOpen = () => {},
  lockValue = {},
  getLockYears = () => {},
}) => {
  return (
    <Modal
      onCancel={() => {
        setOpen(false);
      }}
      footer={null}
      centered
      destroyOnClose
      style={{ zIndex: '1005', borderRadius: '1em' }}
      className="forgotPasswordModal"
      title={<Typography.Text style={{ fontSize: '1.5rem' }}>OTP Verification</Typography.Text>}
      open={open}
      maskClosable={false}
    >
      <VerifyOtp
        open={open}
        enterOtp={enterOtp}
        setEnterOtp={setEnterOtp}
        status={status}
        setLoading={setLoading}
        loading={loading}
        setOpen={setOpen}
        lockValue={lockValue}
        getLockYears={getLockYears}
      />
    </Modal>
  );
};

export default ModalVerifyOtp;
