import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const deleteOnbordingUserSlice = createSlice({
    name: "deleteProperty",
    initialState,
    reducers: {
        deleteOnbordingUserRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        deleteOnbordingUserSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        deleteOnbordingUserFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { deleteOnbordingUserRequest, deleteOnbordingUserSuccess, deleteOnbordingUserFailure } =
    deleteOnbordingUserSlice.actions;

export default deleteOnbordingUserSlice.reducer;
