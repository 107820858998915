import { Spin } from 'antd';
import React from 'react';

export const AppBlankPage = () => {
  return (
    <div className="login_wrapper">
      <div className="">
        <div className="card-body p-0">
          <div className="modal-body p-4">
            <div className="text-center">
              <Spin spinning={true} size="large"></Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
