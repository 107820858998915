import { putRequestImport } from '../../../utils/apiUtils';
import { UserTypes } from '../../../components/common/userTypes';
import { OtpCheckVerificationFailure, OtpCheckVerificationRequest, OtpCheckVerificationSuccess } from '../../reducers/slices/auth/otpVerification.slice';

export const userCompanyInfo = (data) => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(OtpCheckVerificationRequest());
    const { result, error } = await putRequestImport(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'channel-partner' : 'tax-accountant'}/company-info`, data);

    if (!error) {
      return await dispatch(OtpCheckVerificationSuccess(result));
    }
    return await dispatch(OtpCheckVerificationFailure(error));
  };
};
