import React, { useState } from 'react';
import { Card, Col, Menu, Row } from 'antd';
import UserProfileNavigation from './UserProfileNavigation';

const UserProfile = () => {
  const [activeTabKey1, setActiveTabKey1] = useState('1');

  const MenuItems = UserProfileNavigation();

  const handleMenuChange = ({ key }) => {
    setActiveTabKey1(key);
  };

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  return (
    <main className="page-content custom-scroll ">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <Row className="align-items-center mt-1">
            <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={6} span={4}>
              <div className="text-center text-sm-start mb-sm-0  mb-sm-0">
                <h5 className="m-0">My Account</h5>
              </div>
            </Col>
          </Row>
          <div className="row d-flex flex-row mt-3">
            <Card bordered={false} className="d-flex p-3 w-100  userProfileParentCard box_shadow" activeTabKey={activeTabKey1} onTabChange={onTab1Change}>
              <div className="d-flex gap-4 w-100 h-100 profile-card-parent">
                <div
                  className="profile-menu"
                  style={{
                    width: 296,
                  }}
                >
                  <Menu
                    defaultSelectedKeys={['1']}
                    mode={'inline'}
                    onClick={handleMenuChange}
                    items={MenuItems}
                    style={{
                      width: 299,
                    }}
                  />
                </div>
                <div className="w-100">{MenuItems.find((item) => item.key === activeTabKey1)?.toRender}</div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserProfile;
