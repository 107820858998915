import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoutes = ({ children }) => {
  const token = localStorage.getItem('token');

  // Check if the user is authenticated and redirect if true
  if (token) {
    return <Navigate to="/" />;
  }

  // Render child components if the user is not authenticated
  return children;
};

export default PublicRoutes;
