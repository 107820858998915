// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-search-parent {
  display: flex;
  justify-content: end;
  align-items: center;
}
.custom-search-parent .ant-input-group-addon .ant-input-search-button {
  min-height: 52px !important;
  border-left: none;
  border-radius: 6px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-color: #d9d9d9 !important;
  color: #8c8c8c !important;
}
.custom-search-parent .custom-search {
  max-width: 300px;
}
.custom-search-parent .custom-search .ant-input-clear-icon {
  scale: 1.5;
}
.custom-search-parent .ant-input-outlined {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
}
.client_table table tr th {
  padding: 16px 16px !important;
}
.client_table table tr td {
  padding: 16px 16px !important;
}
.client_table .ant-table-measure-row td {
  padding: 0px !important;
}
.dashboard_list .cursor-pointer {
  cursor: pointer;
  text-decoration: underline !important;
}
.dashboard_list .cursor-pointer span {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Clients/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oBAAA;EACA,mBAAA;AACF;AAJA;EAMI,2BAAA;EACA,iBAAA;EACA,6BAAA;EACA,oCAAA;EACA,uCAAA;EACA,gCAAA;EACA,yBAAA;AACJ;AAbA;EAgBI,gBAAA;AAAJ;AAhBA;EAmBI,UAAA;AAAJ;AAnBA;EAuBI,qCAAA;EACA,wCAAA;EACA,6BAAA;AADJ;AAKA;EAEI,6BAAA;AAJJ;AAEA;EAKI,6BAAA;AAJJ;AADA;EAQI,uBAAA;AAJJ;AAQA;EAEI,eAAA;EACA,qCAAA;AAPJ;AAIA;EAKM,0BAAA;AANN","sourcesContent":[".custom-search-parent {\n  display: flex;\n  justify-content: end;\n  align-items: center;\n\n  .ant-input-group-addon .ant-input-search-button {\n    min-height: 52px !important;\n    border-left: none;\n    border-radius: 6px !important;\n    border-top-left-radius: 0 !important;\n    border-bottom-left-radius: 0 !important;\n    border-color: #d9d9d9 !important;\n    color: #8c8c8c !important;\n  }\n\n  .custom-search {\n    max-width: 300px;\n  }\n  .custom-search .ant-input-clear-icon {\n    scale: 1.5;\n  }\n\n  .ant-input-outlined {\n    border-top-right-radius: 0 !important;\n    border-bottom-right-radius: 0 !important;\n    border-right: none !important;\n  }\n}\n\n.client_table {\n  table tr th {\n    padding: 16px 16px !important;\n  }\n  table tr td {\n    padding: 16px 16px !important;\n  }\n  .ant-table-measure-row td {\n    padding: 0px !important;\n  }\n}\n\n.dashboard_list {\n  .cursor-pointer {\n    cursor: pointer;\n    text-decoration: underline !important;\n    span {\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
