import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResendEmailChannelPartner, ResendEmailTaxAccountant } from '../../../../actions/tex-dashboard/UserOnbording/resendEmailApi';

const initialState = {
  error: false,
  isLoading: true,
};

export const resendEmailTaxAccountantAsync = createAsyncThunk('ResendEmail/tax-accountant/sendLink', async (ids) => {
  const response = await ResendEmailTaxAccountant(ids);
  return response;
});

export const resendEmailChannelPartnerAsync = createAsyncThunk('ResendEmail/channel-partner/sendLink', async (id) => {
  const response = await ResendEmailChannelPartner(id);
  return response;
});

export const ResendEmailSlice = createSlice({
  name: 'ResendEmail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resendEmailTaxAccountantAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resendEmailTaxAccountantAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(resendEmailTaxAccountantAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })

      .addCase(resendEmailChannelPartnerAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resendEmailChannelPartnerAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(resendEmailChannelPartnerAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const resendEmailLoading = (state) => state.resendEmail.isLoading;

export default ResendEmailSlice.reducer;
