import { getRequest } from '../../../utils/apiUtils';
import { sendOtpCheckVerificationDelFailure, sendOtpCheckVerificationDelRequest, sendOtpCheckVerificationDelSuccess } from '../../reducers/slices/Header/sendOtp.slice';

export const sendOtpCheckVerificationDelete = () => {
  return async (dispatch) => {
    await dispatch(sendOtpCheckVerificationDelRequest());
    const { result, error } = await getRequest(`user/otp/send/deleteuser`);

    if (!error) {
      return await dispatch(sendOtpCheckVerificationDelSuccess(result));
    }
    return await dispatch(sendOtpCheckVerificationDelFailure(error));
  };
};

export const sendOtpCheckVerificationCode = (type) => {
  return async (dispatch) => {
    await dispatch(sendOtpCheckVerificationDelRequest());
    const { result, error } = await getRequest(`user/otp/send/${type}`);

    if (!error) {
      return await dispatch(sendOtpCheckVerificationDelSuccess(result));
    }
    return await dispatch(sendOtpCheckVerificationDelFailure(error));
  };
};
