import { Table } from 'antd';
import React from 'react';

const DT = (props) => {
  return (
    <Table
      className={props.paddingclassName}
      columns={props.columns}
      dataSource={props.data}
      scroll={{ x: props.data.length === 0 ? null : '100%' }}
      pagination={{ defaultPageSize: props.rowCount }}
      style={{ display: props.display }}
      sticky={props.sticky}
      components={props.components}
      {...props}
    />
  );
};

export default DT;
