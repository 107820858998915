// import { Button, Input, Modal, Result, Select } from 'antd';
import { Modal, notification } from 'antd';
import React, { useState } from 'react';
// import { Form } from 'antd';
import '../../../assets/css/accountant.css';
import { useDispatch } from 'react-redux';
import VerifyOtp from '../../../utils/VerifyOtp';
import { fetchUserMFAPreferenceAsync, mfaUpdateForSystemAsync, setMFAPreferenceAsync } from '../../../redux/reducers/slices/UserMFA/UserMFASlice';
import { MFAType } from '../../common/userTypes';

// const { Option } = Select;

const TwoFactorSMSAuthenticationModal = ({ open = false, setOpen = false }) => {
  // const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  // const [viewPhoneInput, setViewPhoneInput] = useState(false);

  // const [authenticatePhone, setAuthenticatePhone] = useState(false);
  const [enterOtp, setEnterOtp] = useState(true);
  // const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  const handleCancel = () => {
    setOpen(false);
    setEnterOtp(true);
    // setSuccess(false);
  };

  const onEnterOtp = async () => {
    try {
      await dispatch(setMFAPreferenceAsync('sms'));

      await dispatch(mfaUpdateForSystemAsync({ mfa_status: true, mfa_type: MFAType.SMS_MFA }));

      await dispatch(fetchUserMFAPreferenceAsync());

      notification.success({
        message: 'Success',
        description: 'Your account is now protected with 2FA. Enjoy peace of mind knowing your data is safe.',
      });

      setEnterOtp(true);
      // setSuccess(true);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong',
      });
    }
  };

  return (
    <Modal title={<h5>Two Factor authentication</h5>} onCancel={handleCancel} footer={null} style={{ zIndex: '1005', borderRadius: '1em' }} destroyOnClose open={open}>
      {/* {viewConfirmPassword && (
          <div className="mt-4 d-flex flex-column">
            <h2 className="text-center">Enter your password to setup 2FA</h2>
            <Form.Item
              label={<small>Confirm Password</small>}
              labelAlign="center"
              name="password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="d-flex justify-content-center">
              <Button type="primary" htmlType="submit">
                Confirm Password
              </Button>
            </Form.Item>
          </div>
        )}

        {viewPhoneInput && (
          <div>
            <Form.Item
              label={<small>We'll text a verification code to this mobile number whenever you sign in to your account</small>}
              className="w-100 customInputPhone"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                },
                {
                  pattern: /^\d{8,10}$/,
                  message: 'Please enter a valid mobile number!',
                },
              ]}
            >
              <Input
                onKeyDown={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
                    e.preventDefault();
                  }
                }}
                addonBefore={
                  <Form.Item name="phone_code" className="w-100" noStyle initialValue="+61">
                    <Select style={{ width: 100 }}>
                      <Option value="+61">+61</Option>
                      <Option value="+64">+64</Option>
                      <Option value="+65">+65</Option>
                      <Option value="+91">+91</Option>
                    </Select>
                  </Form.Item>
                }
                placeholder="412345678"
                maxLength={10}
              />
            </Form.Item>
            <div className="d-flex justify-content-end align-items-center gap-3">
              <button type="button" className="twoFAButton border-0 bg-transparent shadow-none" onClick={() => handleCancel()}>
                Cancel
              </button>
              <Button type="primary" htmlType="submit">
                Verify
              </Button>
            </div>
          </div>
        )} */}

      {/* {authenticatePhone && (
        <div>
          <p>Please confirm your account by entering the authorization code we will send to {maskPhoneNumber('+6112345678')} </p>
          <div className="d-flex justify-content-center">
            <Button className="d-flex justify-content-end" type="primary" onClick={onAuthenticate}>
              Send OTP
            </Button>
          </div>
        </div>
      )} */}

      {enterOtp && <VerifyOtp onEnterOtp={onEnterOtp} enterOtp={enterOtp} status={'verifyphone'} setEnterOtp={setEnterOtp} setOpen={setOpen} titleChange={true} />}

      {/* {success && (
        <Result
          status="success"
          icon={
            <CheckCircleOutlined
              style={{
                color: '#008027',
              }}
            />
          }
          title={
            <h3
              style={{
                color: '#008027',
              }}
            >
              Sucessfully enabled!
            </h3>
          }
          subTitle={
            <div>
              <p>Your phone number is set to +91 1234657980</p>
              <p>Authentication codes will be texted to this number for logging in.</p>
            </div>
          }
        />
      )} */}
    </Modal>
  );
};

export default TwoFactorSMSAuthenticationModal;
