import { getRequest } from "../../../utils/apiUtils";
import { PropertyVideoFailure, PropertyVideoRequest, PropertyVideoSuccess } from "../../reducers/slices/property-video/Video.slice";


export const PropertyVideo = () => {
    return async (dispatch) => {
        await dispatch(PropertyVideoRequest());
        const { result, error } = await getRequest(`/user/explainer-video`);

        if (!error) {
            return await dispatch(PropertyVideoSuccess(result));
        }
        return await dispatch(PropertyVideoFailure(result));
    };
};
