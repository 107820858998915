import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const addTaxAccountantSlice = createSlice({
    name: "AddTaxAccountant",
    initialState,
    reducers: {
        addTaxAccountantRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        addTaxAccountantSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        addTaxAccountantFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { addTaxAccountantSuccess, addTaxAccountantFailure, addTaxAccountantRequest } =
    addTaxAccountantSlice.actions;

export default addTaxAccountantSlice.reducer;
