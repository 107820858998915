import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, DatePicker, Drawer, Form, notification, Row, Select, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { GetTransactionByUserId } from '../../../redux/actions/transactions/getTransactions.action';
import dayjs from 'dayjs';
import { EyeFilled } from '@ant-design/icons';
import { GetPropertyListWithOutLoan } from '../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { GetCategoryList } from '../../../redux/actions/category/getCategoryList.action';
import { TrensectionType } from '../../common/userTypes';
import EditRecordExpenses from '../record_expenses/edit';
import CustomIcon from '../../sidebar/custIcon';
import editIcon from '../../../assets/images/edit.svg';
import { getTransactionTypeLabel } from '../../../utils/ConstLabel';
import '../../../assets/less/index.less';

const { Option } = Select;

export default function Details({ isTaxAccountant = false, spanIncrease = false, propertyId = null }) {
  const currentClient = localStorage.getItem('currentClient');
  const csvLinkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [propertySearch, setPropertySearch] = useState();
  const [categarySearch, setCategarySearch] = useState();
  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [costCategorydata, setCostCategorydata] = useState([]);
  const [openTransactionEdit, setOpenTransactionEdit] = useState(false);
  const [transactionEditValue, setTransactionEditValue] = useState(null);
  const [transactionList, setTransactionList] = useState([]);

  const [propertyData, setPropertyData] = useState([]);
  const [filter, setFilter] = useState({
    fromDate: '',
    toDate: '',
    propertyId: propertyId || '',
    categoryId: '',
  });
  const [transData, setTransData] = useState([]);
  const [transDataTotal, setTransDataTotal] = useState(0);
  const transDta = useSelector((state) => state.getTransactionReducer);
  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);
  const categoryDta = useSelector((state) => state.getCategoryListReducer);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
  });

  useEffect(() => {
    if (!propertyId) {
      dispatch(GetPropertyListWithOutLoan(currentClient));
    }
    dispatch(GetCategoryList());

    return () => {
      setFilter({ fromDate: '', toDate: '', propertyId: propertyId || '', categoryId: '' });
    };
  }, [dispatch, currentClient]);

  useEffect(() => {
    if (transactionList && transactionList.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [transactionList]);

  //* API Call
  useEffect(() => {
    getTransactionList();
    form.setFieldsValue({
      Property_type: propertySearch || propertyId || '',
      fromDate: filter?.fromDate,
      toDate: filter?.toDate,
      category_type: categarySearch || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pagination]);

  const getTransactionList = () => {
    dispatch(GetTransactionByUserId({ ...filter, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize }, currentClient));
  };

  useEffect(() => {
    setLoading(transDta.isLoading);

    if (transDta && transDta.data?.result?.data && transDta.error === false) {
      let dt = transDta.data?.result?.data.map((elm) => {
        return { ...elm, key: crypto.randomUUID() };
      });
      setTransData(dt);
      setTransDataTotal(transDta.data?.result?.total);
    }
    if (categoryDta && categoryDta.data.result && categoryDta.error === false) {
      let dt = categoryDta.data.result.map((e) => {
        return {
          value: e.id,
          label: e.category_name,
          income: !!(e.expense_type === 'Revenue' && e.transaction_type === 'In'),
          expense: !!(e.expense_type === 'Revenue' && e.transaction_type === 'Out'),
          cost: e.expense_type === 'Capital',
        };
      });
      let Incom = dt.filter((item) => ![false].includes(item.income));
      setIncomeCategorydata(Incom);
      let Expense = dt.filter((item) => ![false].includes(item.expense));
      setExpenseCategorydata(Expense);
      let Cost = dt.filter((item) => ![false].includes(item.cost));
      setCostCategorydata(Cost);
    }
    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((e) => {
        return {
          value: e.id,
          label: e.street_number ? e.street_number + (e.suburb ? ' - ' + e.street_name : '') : '',
        };
      });
      setPropertyData(dt);
    }
  }, [transDta, propertyDta, categoryDta]);

  const onCloseTransactionEdit = () => {
    setOpenTransactionEdit(false);
  };

  const onUpdateTransaction = () => {
    onCloseTransactionEdit();
    getTransactionList();
  };

  const propertyChange = (value) => {
    setPropertySearch(value || '');
    setPagination({ ...pagination, current: 1 });
    if (value === 'all') {
      return setFilter({ ...filter, propertyId: '', categoryId: categarySearch === undefined || categarySearch === 'all' ? '' : categarySearch });
    } else {
      return setFilter({ ...filter, propertyId: value || '' });
    }
  };

  const categoryChange = (value) => {
    setCategarySearch(value || '');
    setPagination({ ...pagination, current: 1 });
    if (value === 'all') {
      return setFilter({ ...filter, categoryId: '', propertyId: propertyId === null && (propertySearch === undefined || propertySearch === 'all') ? '' : propertySearch || propertyId || '' });
    } else {
      return setFilter({ ...filter, categoryId: value || '' });
    }
  };
  const startDateChange = (value) => {
    if (value && value.isValid && dayjs(filter.toDate) < value) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      form.setFieldsValue({ startDate: '' });
      return;
    }
    setFilter({ ...filter, fromDate: value && value !== null ? value.format('YYYY-MM-DD') : '' });
    setPagination({ ...pagination, current: 1 });
  };

  const endDateChange = (value) => {
    if (value && value.isValid && dayjs(filter.fromDate) > value) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      form.setFieldsValue({ endDate: '' });
      return;
    }
    setFilter({ ...filter, toDate: value && value !== null ? value.format('YYYY-MM-DD') : '' });
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: 'Property Name',
      dataIndex: 'street',
      key: 'street',
      width: '20%',
      filterSearch: true,
      // editable: true,
      onFilter: (value, record) => record?.suburb?.startsWith(value),
      render: (text, record) => {
        return `${record?.street_number} ${record.street_name}`;
      },
    },
    {
      title: 'Transaction Source',
      dataIndex: 'transaction_type',
      key: 'expense_type',
      width: '13%',
      render: (_text, record) => {
        return getTransactionTypeLabel(Number(record?.transaction_type));
      },
    },
    {
      title: 'Expense Category',
      dataIndex: 'category_name',
      key: 'category_name',
      width: '15%',
      editable: true,
    },
    {
      title: 'Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      width: '10%',
      editable: true,
      render: (text) => {
        return dayjs(text).format('DD-MM-YYYY');
      },
    },
    {
      title: 'Price (Inclusive GST)',
      dataIndex: 'amount',
      key: 'amount',
      width: '12%',
      editable: true,
      render: (text, record) => {
        let prices = parseFloat(record.amount || 0) + parseFloat(record.gst_amount || 0);
        prices = Number(prices)
          ?.toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className="text-decoration-underline cursor-pointer"
            onClick={(e) => {
              if (
                [
                  TrensectionType.RENT_TYPE_TRANSACTION,
                  TrensectionType.MANUAL_TYPE_TRANSACTION,
                  TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
                  TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
                  TrensectionType.BASIQ_TYPE_TRANSACTION,
                ].includes(Number(record.transaction_type))
              ) {
                e.preventDefault();
                setOpenTransactionEdit(true);

                setTransactionEditValue({ id: record.id, state: { TransectionType: Number(record.transaction_type), PropertyId: record?.property_id, LoanId: record?.loan_id } });
              }
            }}
          >
            {text ? `$${prices}` : '-'}
          </span>
        );
      },
    },
    {
      title: 'GST Amount',
      dataIndex: 'gst_amount',
      key: 'gst_amount',
      width: '10%',
      editable: true,
      render: (text, record) => {
        const prices = text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return text ? `$${prices}` : '-';
      },
    },
    {
      title: 'Edit/View',
      dataIndex: 'action',
      width: '8%',
      render: (_, record) => {
        if (
          ![
            TrensectionType.RENT_TYPE_TRANSACTION,
            TrensectionType.MANUAL_TYPE_TRANSACTION,
            TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
            TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
            TrensectionType.BASIQ_TYPE_TRANSACTION,
          ].includes(Number(record.transaction_type))
        ) {
          return '-';
        }
        return (
          <Button
            className="mr-2"
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              setOpenTransactionEdit(true);

              setTransactionEditValue({ id: record.id, state: { TransectionType: Number(record.transaction_type), PropertyId: record?.property_id, LoanId: record?.loan_id } });
            }}
            icon={record.locked ? <EyeFilled /> : <CustomIcon icon={editIcon} />}
            size="small"
          />
        );
      },
    },
  ];

  const headers = [
    { label: 'Property Name', key: 'street1' },
    { label: 'Expense Type', key: 'expense_type' },
    { label: 'Expense Category', key: 'category_name' },
    { label: 'Expense Date', key: 'transaction_date' },
    { label: 'Price', key: 'amount' },
    { label: 'GST Amount', key: 'gst_amount' },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const fetchCsvData = () => {
    dispatch(GetTransactionByUserId({ ...filter, skip: null, limit: null }, currentClient)).then((res) => {
      if (res?.payload?.success === true) {
        if (res?.payload?.result?.data?.length > 0) {
          const newData1 = res?.payload?.result?.data?.map((elm) => {
            return {
              ...elm,
              street1: `${elm.street_number} ${elm.street_name}`,
              // receipt_path: elm.receipt_path ? `${window.location.origin}/document/${elm.id}/${currentClient}/${elm.transaction_type}/${elm.receipt_path.split('/').pop()}` : '',
            };
          });
          setTransactionList(newData1);
        }
      }
    });
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <div className="mt-0">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12"></div>
          <Form form={form}>
            <div className="row align-items-end mt-0 ">
              <Row gutter={(10, 10)} className="p-2 text-end justify-content-end text-sm-start align-items-center ">
                <Col span={spanIncrease ? 12 : 4}>
                  <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                    <h5 className="m-0">Transactions</h5>
                  </div>
                </Col>
                {!propertyId && (
                  <Col span={4}>
                    <Select
                      style={{ width: '100%', textAlign: 'left', background: 'transparent' }}
                      placeholder="Select Property"
                      className="transSelect"
                      onChange={propertyChange}
                      showSearch
                      allowClear
                      options={[
                        {
                          label: 'All',
                          options: [
                            {
                              label: ' - - All Property - - ',
                              value: 'all',
                            },
                          ],
                        },
                        {
                          label: 'Properties',
                          options: propertyData,
                        },
                      ]}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                    >
                      <Option value="">
                        <b>Select Property</b>
                      </Option>
                    </Select>
                  </Col>
                )}
                <Col span={4}>
                  <Select
                    style={{ width: '100%', textAlign: 'left', background: 'transparent' }}
                    placeholder="Select Category"
                    className="transSelect"
                    onChange={categoryChange}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    options={[
                      {
                        label: 'All',
                        options: [
                          {
                            label: ' - - All Categories - - ',
                            value: 'all',
                          },
                        ],
                      },
                      {
                        label: 'Income',
                        options: incomeCategorydata,
                      },
                      {
                        label: 'Expense',
                        options: expenseCategorydata,
                      },
                      {
                        label: 'Cost',
                        options: costCategorydata,
                      },
                    ]}
                  ></Select>
                </Col>
                <Col xl={4} span={4}>
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={startDateChange}
                      style={{ width: '100%', background: 'transparent' }}
                      placeholder="Start Date"
                      disabledDate={(date) => {
                        return date && filter.toDate && date > dayjs(filter.toDate);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={endDateChange}
                      style={{ width: '100%', background: 'transparent' }}
                      placeholder="End Date"
                      disabledDate={(date) => {
                        return date && filter.fromDate && date < dayjs(filter.fromDate);
                      }}
                    />
                  </Form.Item>
                </Col>
                {!isTaxAccountant && (
                  <Col span={4}>
                    <button
                      onClick={() => navigate('/record-expenses', { state: { fromSameSite: true } })}
                      style={{ width: '100%' }}
                      className="property_btn1 btn btn-primary px-3 py-2 property_btn1_responsive"
                    >
                      + Add Expense
                    </button>
                  </Col>
                )}
                {!propertyId && (
                  <>
                    <Col span={3} xl={4} onClick={() => fetchCsvData()} className="property_btn1 btn btn-primary px-3 py-2 property_btn1_responsive">
                      Export to CSV
                    </Col>
                  </>
                )}
                <CSVLink data={transactionList} headers={headers} filename={'client-detail.csv'} className="hidden" ref={csvLinkRef} target="_blank" />
              </Row>
            </div>
          </Form>
          <Form form={editForm} component={false}>
            <Table paddingclassName="mt-4 property_list extra" pagination={{ ...pagination, total: transDataTotal || 0 }} columns={columns} dataSource={transData} onChange={handleTableChange} />
          </Form>
        </div>
      </Spin>
      <Drawer title="Edit Transaction" width={1120} destroyOnClose onClose={onCloseTransactionEdit} open={openTransactionEdit}>
        <EditRecordExpenses tid={transactionEditValue?.id} tstate={transactionEditValue?.state} onUpdateTransaction={onUpdateTransaction} />
      </Drawer>
    </>
  );
}
