import { postRequest } from '../../../utils/apiUtils';
import {
  getUserActivityRequest,
  getUserActivitySuccess,
  getUserActivityFailure,
  getMoreUserActivitySuccess,
  getMoreUserActivityRequest,
} from '../../reducers/slices/user-activity/getTransactions.slice';

export const GetUserActivity = (data) => {
  return async (dispatch) => {
    await dispatch(getUserActivityRequest());
    const { result, error } = await postRequest(`user/activity`, data);

    if (!error) {
      return await dispatch(getUserActivitySuccess(result));
    }
    return await dispatch(getUserActivityFailure(result));
  };
};

export const GetMoreUserActivity = (data) => {
  return async (dispatch) => {
    await dispatch(getMoreUserActivityRequest());
    const { result, error } = await postRequest(`user/activity`, data);

    if (!error) {
      return await dispatch(getMoreUserActivitySuccess(result));
    }
    return await dispatch(getUserActivityFailure(result));
  };
};
