import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const SendOtpCheckVerificationDeleteSlice = createSlice({
    name: "SendDeleteAccountVerify",
    initialState,
    reducers: {
        sendOtpCheckVerificationDelRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        sendOtpCheckVerificationDelSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        sendOtpCheckVerificationDelFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { sendOtpCheckVerificationDelRequest, sendOtpCheckVerificationDelSuccess, sendOtpCheckVerificationDelFailure } = SendOtpCheckVerificationDeleteSlice.actions;

export default SendOtpCheckVerificationDeleteSlice.reducer;
