// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-menu-light > .ant-menu .ant-menu-item.menuReferralCode {
  background: #f2f8f8 !important;
  color: black !important;
}
.ant-menu-light > .ant-menu .ant-menu-item {
  background: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/homeCss.less"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,uBAAA;AACF;AAEA;EACE,4BAAA;AAAF","sourcesContent":[".ant-menu-light > .ant-menu .ant-menu-item.menuReferralCode {\n  background: #f2f8f8 !important;\n  color: black !important;\n}\n\n.ant-menu-light > .ant-menu .ant-menu-item {\n  background: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
