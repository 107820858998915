import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateUserProfileName, updateUserProfilePicture } from '../../../actions/UserProfile/UserProfileAction';

const initialState = {
  error: false,
  isLoading: false,
  data: {},
};

export const updateProfilePictureAsync = createAsyncThunk('userProfile/updateProfilePicture', async (pictureInfo) => {
  const response = await updateUserProfilePicture(pictureInfo);
  return response;
});

export const updateProfileNameAsync = createAsyncThunk('userProfile/updateProfileName', async (pictureInfo) => {
  const response = await updateUserProfileName(pictureInfo);
  return response;
});

export const UserProfileSlice = createSlice({
  name: 'User Profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProfilePictureAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfilePictureAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateProfilePictureAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(updateProfileNameAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfileNameAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateProfileNameAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUserProfileLoading = (state) => state.userProfile.isLoading;

export default UserProfileSlice.reducer;
