import React, { useEffect, useState } from 'react';
import { Modal, Button, notification, Alert, Space, Flex } from 'antd';
import { FrownOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getReauthorizeConsent } from '../../../redux/actions/dashboard/consent.action';
import './index.less';
import { BankAccountTypes } from '../../common/userTypes';

export default function Consent({ data = {}, bdata = {}, setConnectLoading = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpenConsentPopup, setIsOpenConsentPopup] = useState(false);
  const [isOpenConsentExpiredPopup, setIsOpenConsentExpiredPopup] = useState(false);
  const [isOpenBankConsentPopup, setIsOpenBankConsentPopup] = useState(false);
  const [isOpenBankConsentExpiredPopup, setIsOpenBankConsentExpiredPopup] = useState(false);
  const [isOpenRemainJobPopup, setIsOpenRemainJobPopup] = useState(false);

  const [bankPopupRemaningTime, setBankPopupRemaningTime] = useState(null);

  const [loading, setLoading] = useState(false);

  const expire_before_title = bankPopupRemaningTime ? `Attention! Bank Consent Expiring in ${bankPopupRemaningTime}` : `Attention! Bank Consent Expiring Soon!`;

  const expire_before_description = bankPopupRemaningTime ? (
    <p>
      Your consent for sharing bank data expires in {bankPopupRemaningTime}. Reconsent now to keep your loan transaction details, including interest costs and rates, updated seamlessly. Renew your
      consent promptly for uninterrupted access to real-time transaction updates. Don't miss out on staying up to date with your property records.
    </p>
  ) : (
    <p>
      To ensure uninterrupted access to your loan transaction details, including interest costs and rates, please visit your bank page to:
      <ul>
        <li>Check the consent expiry date</li>
        <li>Review other important information</li>
        <li>And Reconsent Now!</li>
      </ul>
      Staying up-to-date with your property records relies on this information. Don't delay!
    </p>
  );

  useEffect(() => {
    if (data?.consent_popup) {
      setBankPopupRemaningTime(data?.consent_popup_remain_day);
      setIsOpenConsentPopup(data?.consent_popup);
    } else if (data?.consent_expired_popup) {
      const later = localStorage.getItem('later');
      if (!later) {
        setIsOpenConsentExpiredPopup(data?.consent_expired_popup);
      }
    }
  }, [data]);

  useEffect(() => {
    bdata?.consent?.forEach((consent) => {
      if (consent.consent_popup) {
        setIsOpenBankConsentPopup(consent.consent_popup);
      }
      if (consent.consent_expired_popup) {
        setIsOpenBankConsentExpiredPopup(consent.consent_expired_popup);
      }
    });
    setIsOpenRemainJobPopup(bdata.remain_job || false);
  }, [bdata]);

  const handleClick = () => {
    setLoading(true);
    if (bankPopupRemaningTime) {
      reauthorizeConsent(data?.bu_id);
    } else {
      navigate('/bank');
    }
  };

  const reauthorizeConsent = (id) => {
    setConnectLoading(true);
    setLoading(true);
    dispatch(getReauthorizeConsent(id)).then((res) => {
      if (res.success === true && res.result.connect !== null && res.result.connect !== '' && typeof res.result.connect === 'string') {
        window.open(res.result.connect, '_self');
        setLoading(false);
        setConnectLoading(false);
      } else {
        notification.open({
          message: 'Failure',
          description: res.message || 'Something went wrong!',
          icon: <FrownOutlined style={{ color: 'red' }} />,
        });
        setConnectLoading(false);
        setLoading(false);
      }
    });
  };

  const getAccountTypeName = (typeNumber) => {
    for (const [key, value] of Object.entries(BankAccountTypes)) {
      if (value === typeNumber) {
        return key.split(' ')[0]; // Get only the first word (e.g., 'Individual', 'Trust')
      }
    }
    return '';
  };

  const generateExpiredMessage = (consents) => {
    return consents?.map((consent, index) => {
      if (consent?.consent_expired_popup) {
        let accountDetails = '';
        consent?.account?.forEach((account, index) => {
          const accountTypeName = getAccountTypeName(account['ba_type']);
          accountDetails += `${account.bank_name} (${accountTypeName})`;
          if (index < consent.account.length - 1) {
            accountDetails += ', ';
          }
        });

        const multiple_user_message = `Bank Consent Expired for ${accountDetails}! Reconnect Now for smooth experience & latest transactions.`;

        const message = consent?.account && consent?.account.length > 0 ? multiple_user_message : ` Bank Consent Expired! Reconnect Now for a smooth experience & latest transactions.`;

        return <Alert key={index} message={<div dangerouslySetInnerHTML={{ __html: message }} />} type="warning" className="mt-4" />;
      }
    });
  };

  const generateAlertMessages = (consents) => {
    return consents?.map((consent, index) => {
      if (consent?.consent_popup) {
        const accountDetails = consent?.account
          ?.map((account) => {
            const accountTypeName = getAccountTypeName(account['ba_type']);
            return `${account.bank_name} (${accountTypeName})`;
          })
          .join(', ');

        const multiple_user_message = `Bank consent for ${accountDetails} expires in ${consent.consent_popup}! Reconsent Now for continued access. `;

        const message = consent?.account && consent?.account?.length > 0 ? multiple_user_message : `Bank consent expires in ${consent.consent_popup }! Reconsent Now for continued access.`;

        return (
          <Alert
            key={index}
            message={<div dangerouslySetInnerHTML={{ __html: message }} />}
            type="warning"
            className="mt-2 consentAlert"
            action={
              <Space>
                <Button type="primary" size="middle" onClick={() => reauthorizeConsent(consent.bu_id)}>
                  Reconsent Now
                </Button>
              </Space>
            }
          />
        );
      }
    });
  };

  const setLater = () => {
    localStorage.setItem('later', JSON.stringify(new Date().getTime() + 86400000 * 365));
    setIsOpenConsentExpiredPopup(false);
  };

  return (
    <div className="mt-2">
      {isOpenBankConsentPopup && <>{generateAlertMessages(bdata?.consent)}</>}
      {isOpenBankConsentExpiredPopup && <>{generateExpiredMessage(bdata?.consent)}</>}

      {isOpenRemainJobPopup && (
        <Alert message="We're currently working on establishing a secure connection to your bank. Please check back in a short while to see your connection status." type="warning" className="mt-4" />
      )}

      {/* Consent before opening MODAL */}
      <Modal
        onCancel={() => setIsOpenConsentPopup(false)}
        footer={null}
        centered
        width={550}
        style={{ zIndex: '1005', borderRadius: '1em' }}
        className="txtAccountentModal"
        open={isOpenConsentPopup}
        title={
          <Flex justify="flex-start" align="center">
            <ExclamationCircleFilled style={{ backgroundColor: 'white', color: '#faad14', fontSize: '22px' }} size={34} />
            <span className="ant-modal-confirm-title-consent m-2">{expire_before_title}</span>
          </Flex>
        }
      >
        {expire_before_description}

        <div className="p-2 text-end">
          <Button type="primary" loading={loading} className="btn_primary" onClick={() => handleClick()}>
            {bankPopupRemaningTime ? 'Reconsent' : 'Check Now'}
          </Button>
        </div>
      </Modal>

      {/* Consent after date opening  */}
      <Modal
        onCancel={() => setIsOpenConsentExpiredPopup(false)}
        footer={null}
        centered
        width={550}
        style={{ zIndex: '1005', borderRadius: '1em' }}
        className="txtAccountentModal"
        open={isOpenConsentExpiredPopup}
        title={
          <Flex justify="flex-start" align="center">
            <ExclamationCircleFilled style={{ backgroundColor: 'white', color: '#faad14', fontSize: '22px' }} size={34} />
            <span className="ant-modal-confirm-title-consent  m-2">Expired Bank Consent: Take Action!</span>
          </Flex>
        }
      >
        <div>
          We noticed your bank consent has expired. To keep your financial data flowing and access the latest transactions, please reconnect your bank account. This quick update will ensure your
          financial insights stay up-to-date. <br />
        </div>

        <div className="p-2 text-end">
          <Button className="btn_secondary mr-2" onClick={() => setLater()}>
            Later
          </Button>
          <Button type="primary" className="btn_primary" onClick={() => navigate('/bank')}>
            Reconnect Now
          </Button>
        </div>
      </Modal>
    </div>
  );
}
