import { deleteRequest } from '../../../../utils/apiUtils';
import { UserTypes } from '../../../../components/common/userTypes';
import { deleteOnbordingUserFailure, deleteOnbordingUserRequest, deleteOnbordingUserSuccess } from '../../../reducers/slices/texDashboard/UserOnbording/deleteuseronboarding.slice';

export const DeleteOnboardingUser = (id) => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(deleteOnbordingUserRequest());
    const { result, error } = await deleteRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'channel-partner/onboard/user' : 'tax-accountant/onboard-user'}/${id}`);

    if (!error) {
      return await dispatch(deleteOnbordingUserSuccess(result));
    }
    return await dispatch(deleteOnbordingUserFailure(result));
  };
};
