import React, { Component } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

class CustomInput extends Component {
  onChange = (e) => {
    const { onChange, inputtype, maxamount, makecaps, allowdecimal, negitiveNumber = false } = this.props;
    let { value } = e.target;

    if (!negitiveNumber) {
      value = value.replace('-', '');
    }

    if (inputtype === 'number' && value) {
      if (negitiveNumber && value === '-') {
      } else if (allowdecimal) {
        value = value.match(/-?\d+\.?(\d{1,2})?/);
        value = value && value[0];
      } else {
        value = value.match(/-?\d+/g);
        value = value && value[0];
      }
    } else if (inputtype === 'alphabet' && value) {
      const valCheck = value.match('^[A-Za-z]+$');
      value = Array.isArray(valCheck) === true ? value : value.replace(/[^A-Za-z]/g, '');
    } else if (inputtype === 'alphabetspace' && value) {
      const valCheck = value.match('^[A-Za-z? ]+$');
      value = Array.isArray(valCheck) === true ? value : value.replace(/[^A-Za-z? ]/g, '');
    } else if (inputtype === 'alphanumeric' && value) {
      const valCheck = value.match('^[A-Za-z0-9]+$');
      value = Array.isArray(valCheck) === true ? value : value.replace(/[^A-Za-z0-9]/g, '');
    } else if (inputtype === 'alphanumericspace' && value) {
      const valCheck = value.match('^[A-Za-z0-9? ,_-]+$');
      value = Array.isArray(valCheck) === true ? value : value.replace(/[^\w\s]/gi, '');
    }

    if (parseInt(value) > maxamount) {
      value = value && value.slice(0, value.length - 1);
    }

    if (makecaps === 'true') {
      value = value.toUpperCase();
    }
    onChange(value);
  };

  render() {
    const propsCopy = { ...this.props };
    delete propsCopy.negitiveNumber;
    delete propsCopy.inputtype;
    return <Input className="custom-input" {...propsCopy} addonBefore={this.addonBefore} onChange={this.onChange} />;
  }
}
CustomInput.propTypes = {
  inputtype: PropTypes.string,
  negitiveNumber: PropTypes.bool,
  onChange: PropTypes.func,
};
CustomInput.defaultProps = {
  inputtype: 'string',
  negitiveNumber: false,
  onChange: () => {},
};
export default CustomInput;
CustomInput.TextArea = Input.TextArea;
CustomInput.Password = Input.Password;
CustomInput.Search = Input.Search;
