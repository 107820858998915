import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const deleteRecordExpenseSlice = createSlice({
  name: 'DeleteRentalSummary',
  initialState,
  reducers: {
    DeleteRecordExpenseRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    DeleteRecordExpenseSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    DeleteRecordExpenseFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { DeleteRecordExpenseRequest, DeleteRecordExpenseSuccess, DeleteRecordExpenseFailure } = deleteRecordExpenseSlice.actions;

export default deleteRecordExpenseSlice.reducer;
