import { getRequest, postRequest } from '../../../utils/apiUtils';
import { getLoginFailure, getLoginSuccess } from '../../reducers/slices/auth/getUserLogin.slice';

export const GetUserLogin = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`login`, data);

    if (!error) {
      return dispatch(getLoginSuccess(result));
    }
    return dispatch(getLoginFailure(result));
  };
};

export const getUserUsingToken = (token) => {
  return async () => {
    const { result, error } = await getRequest(`user/token-data/${token}`);

    if (!error) {
      return result;
    }
    return error;
  };
};

export const unsubscribeEmail = (token, data) => {
  return async () => {
    const { result, error } = await postRequest(`user/unsubscribe/${token}`, data);

    if (!error) {
      return result;
    }
    return result;
  };
};
