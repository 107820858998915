import { Spin, Image, Form, Radio, Space, Input, Button, notification, Card } from 'antd';
import logosvg from '../../../assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import { unsubscribeEmail } from '../../../redux/actions/login/userLogin.action';
import { useLocation } from 'react-router';
import { useState } from 'react';

const UnsubscribeEmail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleFinish = (values) => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    if (tokenParam === null || tokenParam === '') {
      window.open(process.env.REACT_APP_LANDING_PAGE_URL, '_self');
    }
    dispatch(unsubscribeEmail(tokenParam, values)).then((data) => {
      if (data && data.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        setTimeout(() => {
          window.open(process.env.REACT_APP_LANDING_PAGE_URL, '_self');
        }, 3000);
      } else {
        setLoading(false);
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
        });
      }
    });
  };

  return (
    <div className="login_wrapper">
      <div className="login_form">
        <div className="">
          <div className="card-body p-0">
            <div className="modal-body p-4">
              <div className="text-center">
                <Image alt="The Property Account logo" src={logosvg} />
                <h4 className="mt-4"></h4>
              </div>
              <Card title="Unsubscribe Emails">
                <div className="text-right">
                  <h6>To help us improve our services, we would be grateful if you could tell us why you chose to unsubscribe.</h6>
                  <Spin spinning={loading} size="large">
                    <Form layout="vertical" id="forgotPassword" style={{ marginTop: '2em' }} onFinish={handleFinish} className="text-left">
                      <Form.Item
                        name="reason"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please select your reason!',
                          },
                        ]}
                      >
                        <Radio.Group onChange={handleRadioChange}>
                          <Space direction="vertical">
                            <Radio value={'Your emails are not relevant to me'}>Your emails are not relevant to me</Radio>
                            <Radio value={'Your emails are too frequent'}>Your emails are too frequent</Radio>
                            <Radio value={"I don't remember signing up for this"}>I don't remember signing up for this</Radio>
                            <Radio value={'I no longer want to receive these emails'}>I no longer want to receive these emails</Radio>
                            <Radio value={'The emails are spam and should be reported'}>The emails are spam and should be reported</Radio>
                            <Radio value={'Others'}>Others</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      {selectedValue === 'Others' && (
                        <Form.Item
                          name="comments"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please enter your reason!',
                            },
                          ]}
                        >
                          <Input.TextArea rows={4} placeholder="Enter your reason here ..." />
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Button type="primary" size="large" danger htmlType="submit">
                          Unsubscribe
                        </Button>
                      </Form.Item>
                    </Form>
                  </Spin>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeEmail;
