import { getRequest } from "../../../utils/apiUtils";
import {
    getCategoryListRequest,
    getCategoryListSuccess,
    getCategoryListFailure,
} from "../../reducers/slices/category/getCategoryList.slice";

export const GetCategoryList = () => {
    return async (dispatch) => {
        await dispatch(getCategoryListRequest());
        const { result, error } = await getRequest(`/user/category`);

        if (!error) {
            return await dispatch(getCategoryListSuccess(result));
        }
        return await dispatch(getCategoryListFailure(result));
    };
};
