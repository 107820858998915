import { ShopOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Alert, Button, Form, Modal, notification, Select, Upload } from 'antd';
import logosvg from '../../../assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../common';
import { userCompanyInfo } from '../../../redux/actions/login/userCompanyInfo.action';
import { ChannelPartnerType, UserTypes } from '../../common/userTypes';
import '../../../assets/css/antd.css';
import SignUpHOC from './SignUpHOC';
import { getNotificationData } from '../../../redux/actions/Header/getNotificationData.action';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ChannelPartnerTypeOption = [
  {
    value: ChannelPartnerType.MORTGAGE_BROKER,
    label: 'Mortgage Broker',
  },
  {
    value: ChannelPartnerType.CONVEYANCER,
    label: 'Conveyancer',
  },
  {
    value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
    label: 'Rental Property Manager',
  },
  {
    value: ChannelPartnerType.BUYERS_AGENT,
    label: 'Buyers Agent',
  },
  {
    value: ChannelPartnerType.OTHERS,
    label: 'Others',
  },
];
const CompanyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const terms = false;
  const [channelPartnerType, setChannelPartnerType] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const type = JSON.parse(localStorage.getItem('admin'));

  // UPLOAD IMAGE
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0 && !['image/png', 'image/jpg', 'image/jpeg'].includes(newFileList[0]?.type)) {
      notification.error({
        message: 'Failure',
        description: 'You can upload image formats .jpg, .jpeg, .png only!',
      });
      setFileList([]);
      return false;
    }
    setFileList(newFileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  // END UPLOAD IMAGE

  const handleFinish = (value) => {
    setLoading(true);
    value.checked = terms;

    if (value?.company_name !== undefined) {
      const formData = new FormData();
      formData.append('file', value?.company_logo?.fileList[0]?.originFileObj ? value?.company_logo?.fileList[0]?.originFileObj : '');
      formData.append('companyName', value?.company_name);

      if (type.user_type === UserTypes.CHANNEL_PARTNER && value?.type) {
        formData.append('ChannelPartnerType', value?.type);
      }

      if (type.user_type === UserTypes.CHANNEL_PARTNER && (value?.type === ChannelPartnerType.MORTGAGE_BROKER || type.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER)) {
        formData.append('mortgage_broker_uni_number', value.mortgage_broker_uni_number || '');
      }

      dispatch(userCompanyInfo(formData)).then(async (response) => {
        if (response?.payload?.success === true) {
          const { result } = response.payload;

          await dispatch(getNotificationData());

          if (result.user_type === UserTypes.CHANNEL_PARTNER && result.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) {
            navigate('/dashboard', { replace: true });
          } else {
            navigate('/clients', { replace: true });
          }
        } else {
          notification.error({
            message: 'Failure',
            description: response?.payload?.message || 'Something went wrong!',
          });
        }
        setLoading(false);
      });
    }
  };

  return (
    <SignUpHOC>
      <div className="login_wrapper tandc_wrapper">
        <div className="card shadow border-0">
          <div className="card-body p-0">
            <div className="modal-body p-4 ">
              <div className="text-center tandc_logo mb-4">
                <img src={logosvg} className="img-fluid w-50" alt="" />
              </div>

              <div className="row">
                <h5>Complete Profile</h5>
                <p>Please enter your details to complete your profile</p>
              </div>

              {errorMessage && (
                <div className="pb-2">
                  <Alert message={errorMessage} type="error" bordered closable onClose={() => setErrorMessage('')} />
                </div>
              )}

              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFinish}
              >
                <div>
                  <div className="row">
                    <div className="col-md-12"></div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-icon">
                          <label>
                            Company Name <span className="text-danger">*</span>
                          </label>
                          <Form.Item
                            name="company_name"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please input your Company Name!',
                              },
                            ]}
                          >
                            <CustomInput prefix={<ShopOutlined className="site-form-item-icon" />} placeholder="Company Name" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    {type.user_type === UserTypes.CHANNEL_PARTNER && type.channel_partner_type === null && (
                      <div className="col-md-12">
                        <div className="mb-3">
                          <div className="form-icon">
                            <label>
                              Channel Partner Type <span className="text-danger">*</span>
                            </label>
                            <Form.Item
                              name="type"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select one!',
                                },
                              ]}
                            >
                              <Select placeholder="Select Type" options={ChannelPartnerTypeOption} onChange={(value) => setChannelPartnerType(value)} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    )}

                    {type.user_type === UserTypes.CHANNEL_PARTNER &&
                      (channelPartnerType === ChannelPartnerType.MORTGAGE_BROKER || type.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) && (
                        <div className="col-md-12">
                          <div className="mb-3">
                            <div className="form-icon">
                              <label>CRN Number</label>
                              <Form.Item name="mortgage_broker_uni_number">
                                <CustomInput placeholder="CRN Number" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="col-md-6 ">
                      <div className="mb-3">
                        <div className="form-icon">
                          <label>
                            Company Logo <span className="text-danger"></span>
                          </label>
                          <Form.Item name="company_logo">
                            <Upload
                              action=""
                              listType="picture-card"
                              fileList={fileList}
                              accept=".jpg, .jpeg, .png, image/jpeg, image/png"
                              onPreview={handlePreview}
                              onChange={handleChange}
                              className="login_upload"
                            >
                              {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                          </Form.Item>
                          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                            <img
                              alt="example"
                              style={{
                                width: '100%',
                              }}
                              src={previewImage}
                            />
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center ">
                  <Button type="primary" size="large" htmlType="submit" className="ml-2" loading={loading}>
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </SignUpHOC>
  );
};

export default CompanyProfile;
