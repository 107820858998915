import React from 'react';
import { UserTypes } from '../../common/userTypes';
import Profile from './Profile';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import ChangePassword from '../../../utils/ChangePassword';
import AddTaxAccountantDiv from '../../../utils/AddTaxAccountantDiv';
import AddMortgageBrokerDiv from '../../../utils/AddMortgageBrokerDiv';
import ApplyAsFPDiv from '../../../utils/ApplyAsFPDiv';
import DeleteAccount from '../delete-account';
// import Preferences from './Preferences';

const UserProfileNavigation = () => {
  const { user_type } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};

  const menuItems = [
    {
      label: 'Profile',
      key: '1',
      tab: 'Profile',
      toRender: <Profile />,
    },
    {
      label: 'Two-factor authentication (2FA)',
      tab: 'Two-factor authentication (2FA)',
      key: '2',
      toRender: <TwoFactorAuthentication />,
    },
    // {
    //   label: 'Preferences',
    //   tab: 'Preferences',
    //   key: '3',
    //    toRender: <Preferences />,
    // },
    {
      label: 'Change Password',
      tab: 'Password',
      key: '4',
      toRender: <ChangePassword modal={false} />,
    },
  ];

  if (user_type === UserTypes.USER) {
    menuItems.push(
      {
        label: 'Tax Accountant',
        tab: 'Tax Accountant',
        key: '5',
        toRender: <AddTaxAccountantDiv apiCall={true} />,
      },
      {
        label: 'Mortgage Broker',
        tab: 'Mortgage Broker',
        key: '6',
        toRender: <AddMortgageBrokerDiv apiCall={true} />,
      },
      {
        label: 'Delete Account',
        tab: 'Delete Account',
        key: '8',
        toRender: <DeleteAccount heading={false} />,
      },
    );
  }

  if (user_type === UserTypes.TAXACCOUTANT) {
    menuItems.push(
      {
        label: 'Financial Planner',
        tab: 'Financial Planner',
        key: '7',
        toRender: <ApplyAsFPDiv apiCall={true} />,
      },
      {
        label: 'Delete Account',
        tab: 'Delete Account',
        key: '9',
        toRender: <DeleteAccount heading={false} />,
      },
    );
  }

  if (user_type === UserTypes.CHANNEL_PARTNER) {
    menuItems.push({
      label: 'Delete Account',
      tab: 'Delete Account',
      key: '10',
      toRender: <DeleteAccount heading={false} />,
    });
  }

  return menuItems;
};

export default UserProfileNavigation;
