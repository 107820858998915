import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getBankDetails, getCardData, getListData } from '../../../actions/BankConnection/BankConnectionApi';

const initialState = {
  error: false,
  isLoading: true,
  viewDetailsLoading: false,
  cardData: [],
  listData: [],
  bankDetails: {},
};

export const getCardDataAsync = createAsyncThunk('BankConnection/getCardData', async (propertyId = null) => {
  const response = await getCardData(propertyId);
  return response;
});

export const getBankDetailsAsync = createAsyncThunk('BankConnection/getBankDetails', async (id) => {
  const response = await getBankDetails(id);
  return response;
});

export const getListDataAsync = createAsyncThunk('BankConnection/getListData', async () => {
  const response = await getListData();
  return response;
});

// export const getFinancialYearRentSummaryPendingListAsync = createAsyncThunk('FYRentSummary/getFinancialYearRentSummaryPendingList', async (filters) => {
//   const response = await getFinancialYearRentSummaryPendingList(filters);
//   return response;
// });

// export const getFinancialYearEntryDataAsync = createAsyncThunk('FYRentSummary/getFinancialYearEntryData', async (id) => {
//   const response = await getFinancialYearEntryData(id);
//   return response;
// });

// export const getFinancialYearRentSummaryCompletedListAsync = createAsyncThunk('FYRentSummary/getFinancialYearRentSummaryCompletedList', async (filters) => {
//   const response = await getFinancialYearRentSummaryCompletedList(filters);
//   return response;
// });

// export const deleteFinancialYearRentSummaryAsync = createAsyncThunk('FYRentSummary/deleteFinancialYearRentSummary', async (id) => {
//   const response = await deleteFinancialYearRentSummary(id);
//   return response;
// });

export const BankConnectionSlice = createSlice({
  name: 'BankConnection',
  initialState,
  reducers: {
    ResetBankDetails: (state) => {
      state.bankDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardDataAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCardDataAsync.fulfilled, (state, action) => {
        state.cardData = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getCardDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getListDataAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListDataAsync.fulfilled, (state, action) => {
        state.listData = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getListDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getBankDetailsAsync.pending, (state, action) => {
        state.viewDetailsLoading = true;
      })
      .addCase(getBankDetailsAsync.fulfilled, (state, action) => {
        state.bankDetails = action.payload.data;
        state.error = action.payload.error;
        state.viewDetailsLoading = false;
      })
      .addCase(getBankDetailsAsync.rejected, (state, action) => {
        state.viewDetailsLoading = false;
        state.error = true;
      });
    // .addCase(getFinancialYearEntryDataAsync.pending, (state, action) => {
    //   state.isLoading = true;
    // })
    // .addCase(getFinancialYearEntryDataAsync.fulfilled, (state, action) => {
    //   state.data = action.payload.data;
    //   state.error = false;
    //   state.isLoading = false;
    // })
    // .addCase(getFinancialYearEntryDataAsync.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = true;
    // })
    // .addCase(getFinancialYearRentSummaryPendingListAsync.pending, (state, action) => {
    //   state.isLoading = true;
    // })
    // .addCase(getFinancialYearRentSummaryPendingListAsync.fulfilled, (state, action) => {
    //   state.pendingList = action.payload.data;
    //   state.error = false;
    //   state.isLoading = false;
    // })
    // .addCase(getFinancialYearRentSummaryPendingListAsync.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = true;
    // })
    // .addCase(getFinancialYearRentSummaryCompletedListAsync.pending, (state, action) => {
    //   state.isLoading = true;
    // })
    // .addCase(getFinancialYearRentSummaryCompletedListAsync.fulfilled, (state, action) => {
    //   state.completedList = action.payload.data;
    //   state.error = false;
    //   state.isLoading = false;
    // })
    // .addCase(getFinancialYearRentSummaryCompletedListAsync.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = true;
    // })
    // .addCase(deleteFinancialYearRentSummaryAsync.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(deleteFinancialYearRentSummaryAsync.fulfilled, (state, action) => {
    //   state.error = action.payload.error;
    //   state.isLoading = false;
    // })
    // .addCase(deleteFinancialYearRentSummaryAsync.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = true;
    // });
  },
});

export const { ResetBankDetails } = BankConnectionSlice.actions;

export const selectBankDataLoading = (state) => state.bankConnection.isLoading;
export const selectViewDetailsLoading = (state) => state.bankConnection.viewDetailsLoading;
export const selectCardData = (state) => state.bankConnection.cardData;
export const selectListData = (state) => state.bankConnection.listData;
export const selectBankDetails = (state) => state.bankConnection.bankDetails;

export default BankConnectionSlice.reducer;
