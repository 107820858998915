import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const RemindSettlementSlice = createSlice({
    name: "RemindSettlement",
    initialState,
    reducers: {
        RemindSettlementRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        RemindSettlementSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        RemindSettlementFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { RemindSettlementSuccess, RemindSettlementFailure, RemindSettlementRequest } =
    RemindSettlementSlice.actions;

export default RemindSettlementSlice.reducer;
