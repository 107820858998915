export const UserTypes = {
  USER: 1,
  TAXACCOUTANT: 11,
  SUB_TAXACCOUTANT: 12,
  ADMIN_SUB_TAXACCOUNTANT: 13,
  ADMIN: 21,
  CHANNEL_PARTNER: 22,
  SUB_CHANNEL_PARTNER: 23,
};

// Enum representing different types of channel partners
export const ChannelPartnerType = {
  MORTGAGE_BROKER: 81,
  CONVEYANCER: 82,
  RENTAL_PROPERTY_MANAGER: 83,
  BUYERS_AGENT: 85,
  OTHERS: 84,
};

export const BankAccountTypes = {
  'Individual Bank Account': 41,
  'Trust Bank Account': 42,
  'Joint Bank Account': 43,
  'Spouse Bank Account': 44,
};

// depreciation method
export const DepreciationMethod = {
  WDV_DEPRECIATION: 61,
  PRIME_COST_DEPRECIATION: 62,
};

// property type
export const PropertyType = {
  INVESTMENT_PROPERTY: 51,
  OWNER_OCCUPIED_PROPERTY: 52,
  COMMERCIAL_PROPERTY: 53,
};
export const TrensectionType = {
  RENT_TYPE_TRANSACTION: 31,
  SETTLEMENT_TYPE_TRANSACTION: 32,
  MANUAL_TYPE_TRANSACTION: 33,
  BASIQ_TYPE_TRANSACTION: 34,
  DEPRECIATION_SCHEDULE_TYPE_TRANSACTION: 35,
  SUBSCRIPTION_TYPE_TRANSACTION: 36,
};
export const UserStatus = {
  ACTIVE: 1,
  DELETED: 0,
  COMPLETED: 4,
  PENDING: 3,
  CREATED: 5,
  CANCELED: 6,
  CANCELED_ACTIVE: 7,
};

// Onboard User status
export const OnboardUserStatus = {
  APPROVED: 45,
  PENDING: 47,
  CENCELED: 46,
  FAILED: 48,
  ALREADY_EXISTS: 49,
};
// settlements type
export const SettlementsType = {
  PURCHASE_SETTLEMENT: 40,
  SELL_SETTLEMENT: 42,
};
export const DocumentationReciptType = {
  DEPRECIATION: 1,
  SETTLEMENT: 2,
  PROPERTY_DOCUMENTS: 4,
};

export const ProductTourType = {
  PRODUCT_TOUR_PENDING: 75,
  PRODUCT_TOUR_COMPLETED: 76,
};

export const UserActivityType = {
  ACTIVITY_PROPERTY_INSERT: 91,
  ACTIVITY_DEPRECIATIONS_INSERT: 92,
  ACTIVITY_SETTLEMENT_INSERT: 93,
  ACTIVITY_BANK_CONNECT: 94,
  ACTIVITY_RENT_INSERT: 95,
  ACTIVITY_REPORT_GENERATED: 96,
  ACTIVITY_EXPENSE_INSERT: 97,
  ACTIVITY_PROPERTY_DELETE: 98,
  ACTIVITY_DEPRECIATIONS_DELETE: 99,
  ACTIVITY_SETTLEMENT_DELETE: 100,
  ACTIVITY_RENT_DELETE: 101,
  ACTIVITY_EXPENSE_DELETE: 102,
  ACTIVITY_PROPERTY_EDIT: 103,
  ACTIVITY_DEPRECIATIONS_EDIT: 104,
  ACTIVITY_SETTLEMENT_EDIT: 105,
  ACTIVITY_RENT_EDIT: 106,
  ACTIVITY_EXPENSE_EDIT: 107,
  ACTIVITY_SUBSCRIPTION_PURCHASE: 109,
  ACTIVITY_SUBSCRIPTION_CANCELED: 110,
};

// GST type
export const GstType = {
  GST_IN: 45,
  GST_OUT: 46,
};

// OCR Status
export const OCRStatus = {
  OCR_COMPLETE: 1,
  OCR_REVIEW: 2,
  OCR_PENDING: 3,
  OCR_FAILED: 0,
};

// Bank connection job status
export const BankConnectionJobStatus = {
  JOB_COMPLETED: 71,
  JOB_FAILED: 72,
  JOB_PENDING: 73,
};

// Tax accountant tabs
export const clientKeys = {
  REPORT: 1,
  SUBSCRIPTION: 7,
};

// category type
export const CategoryType = {
  RENT_CATEGORY: 1,
  INTEREST_CAPITALIZED_CATEGORY_ID: 27,
  INTEREST_ON_LOAN_CATEGORY_ID: 16,

  COUNCIL_RATES: 2, // Council rates: 2,
  WATER_CHARGE: 3, // Water Charge: 3,
  REPAIR_AND_MAINTENANCE: 12, // Repair & Maintenance : 12,
  LAND_TAX: 13, // Land Tax: 13,
  INSURANCE: 35, // Insurance: 35
};

export const MFAType = {
  SMS_MFA: 78,
  TOTP_MFA: 79,
  EMAIL_MFA: 80,
};

export const DocumentType = {
  PLANS_AND_PERMITS: 4,
  ENGINEERING_AND_CONSTRUCTION: 5,
  CONTRACTS: 6,
  CERTIFICATES_AND_TITLES: 7,
  COMPLIANCE_AND_INSURANCE: 8,
  WARRANTY: 9,
  OTHERS: 10,
  DEPRECIATION_DOC: 55,
  EOFY_DOC: 56,
};
