import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true, total: 0 };

export const getUserActivitySlice = createSlice({
  name: 'GetUserActivitys',
  initialState,
  reducers: {
    getUserActivityRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getMoreUserActivityRequest: (state, action) => {
      state.isLoading = true;
    },
    getUserActivitySuccess: (state, action) => {
      state.data = action.payload;
      state.total = action.payload?.result?.total;
      state.error = false;
      state.isLoading = false;
    },
    getMoreUserActivitySuccess: (state, action) => {
      const existingData = state.data?.result?.data || [];
      const newData = action.payload?.result?.data || [];
      state.data = { ...action.payload, result: { data: [...existingData, ...newData] } };
      state.total = action.payload?.result?.total;
      state.error = false;
      state.isLoading = false;
    },

    getUserActivityFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getUserActivityRequest, getUserActivitySuccess, getUserActivityFailure, getMoreUserActivitySuccess, getMoreUserActivityRequest } = getUserActivitySlice.actions;

export default getUserActivitySlice.reducer;
