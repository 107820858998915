import { postRequest } from "../../../utils/apiUtils";
import {
    addRecordExpenseRequest,
    addRecordExpenseSuccess,
    addRecordExpenseFailure,
} from "../../reducers/slices/record_expense/addRecord.slice";

export const AddRecordExpense = (data, id) => {
    let tempURl = ``;
    if (!id) {
        tempURl = `user/transaction`
    } else {
        tempURl = `user/transaction?userId=${id}`
    }
    return async (dispatch) => {
        await dispatch(addRecordExpenseRequest());
        const { result, error } = await postRequest(tempURl, data);

        if (!error) {
            return await dispatch(addRecordExpenseSuccess(result));
        }
        return await dispatch(addRecordExpenseFailure(result));
    };
};
