import { Drawer, Typography } from 'antd';

import DownloadStatement from './DownloadStatement';
import { InfoCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export default function DownloadStatementModal({ openDownloadStaement, onCloseDownloadStaement }) {
  return (
    <Drawer
      title="Download Bank Statement"
      size="large"
      destroyOnClose
      onClose={onCloseDownloadStaement}
      open={openDownloadStaement}
      footer={
        <Text type="secondary">
          <InfoCircleFilled style={{ color: '#56AAAA' }} /> Bank Statements are automatically deleted after 14 days, Be sure to export it before then!
        </Text>
      }
    >
      <DownloadStatement />
    </Drawer>
  );
}
