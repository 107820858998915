import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const deleteRentalSummarySlice = createSlice({
    name: "DeleteRentalSummary",
    initialState,
    reducers: {
        deleteRentalSummaryRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        deleteRentalSummarySuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        deleteRentalSummaryFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { deleteRentalSummaryRequest, deleteRentalSummarySuccess, deleteRentalSummaryFailure } =
    deleteRentalSummarySlice.actions;

export default deleteRentalSummarySlice.reducer;
