import { Modal } from 'antd';
import React from 'react';
import ChangePassword from './ChangePassword';

const ModalChangePassword = ({ setIsOpen = () => {}, isOpen = false }) => {
  return (
    <Modal onCancel={() => setIsOpen(false)} footer={null} centered style={{ zIndex: '1005', borderRadius: '1em' }} destroyOnClose className="forgotPasswordModal" open={isOpen}>
      <ChangePassword modal={true} setIsOpen={setIsOpen} />
    </Modal>
  );
};

export default ModalChangePassword;
