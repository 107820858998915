import { getRequest, postRequest } from '../../../../utils/apiUtils';

export const ResendEmailTaxAccountant = (ids) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`tax-accountant/onboard-user/resend-notification`, { users: ids });
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const ResendEmailChannelPartner = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`channel-partner/onboard/user-email-resend/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
