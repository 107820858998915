import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getSubUserListSlice = createSlice({
    name: "GetsubUserList",
    initialState,
    reducers: {
        getSubUserListRequest: (state) => {
            state.data = [];
            state.isLoading = true;
        },
        getSubUserListSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getSubUserListFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getSubUserListRequest, getSubUserListSuccess, getSubUserListFailure } = getSubUserListSlice.actions;

export default getSubUserListSlice.reducer;
