import { putRequest } from '../../../utils/apiUtils';
import { editRecordExpenseRequest, editRecordExpenseSuccess, editRecordExpenseFailure } from '../../reducers/slices/record_expense/editRecord.slice';

export const EditRecordExpense = (data, id, type, userId) => {
  let tempURl = ``;
  if (!userId) {
    tempURl = `user/transaction/update/${id}?type=${type}`;
  } else {
    tempURl = `user/transaction/update/${id}?type=${type}&userId=${userId}`;
  }
  return async (dispatch) => {
    await dispatch(editRecordExpenseRequest());
    const { result, error } = await putRequest(tempURl, data);

    if (!error) {
      return await dispatch(editRecordExpenseSuccess(result));
    }
    return await dispatch(editRecordExpenseFailure(result));
  };
};
