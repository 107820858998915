import { ProductTourType } from '../../../components/common/userTypes';
import { putRequest } from '../../../utils/apiUtils';

export const changeProductTour = () => {
  return async () => {
    return await putRequest(`user/product/tour`, {
      tour_status: ProductTourType.PRODUCT_TOUR_COMPLETED,
    });
  };
};
