import React from 'react';
// import Property from '../property';
import Reports from '../reports';
// import { useDispatch } from 'react-redux';
// import Details from './Details';
// import Documentation from './clientTab/Documentation';
// import BankConnection from './clientTab/BankConnection';
// import { GetclientDetailById } from '../../../redux/actions/client-detail/getclientById.action';
import { UserTypes } from '../../common/userTypes';
// import Subscription from './../subscription/index';
// import AuditTrail from './clientTab/AuditTrail';
import { Outlet } from 'react-router-dom';

const SubClient = () => {
  const { user_type } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};

  // const currentClient = localStorage.getItem('currentClient');
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (currentClient) {
  //     dispatch(GetclientDetailById(currentClient, user_type === UserTypes.CHANNEL_PARTNER || user_type === UserTypes.SUB_CHANNEL_PARTNER ? 2 : 1));
  //   }
  // }, [currentClient, dispatch, user_type]);

  // const tabs = [
  //   {
  //     key: 1,
  //     label: 'Reports',

  //     children: <Reports />,
  //   },
  //   {
  //     key: 8,
  //     label: 'Audit Trail',
  //     children: <AuditTrail />,
  //   },
  //   {
  //     key: 3,
  //     label: 'Transaction Details',
  //     children: <Details />,
  //   },
  //   {
  //     key: 2,
  //     label: 'Property Details',
  //     children: <Property />,
  //   },
  //   {
  //     key: 4,
  //     label: 'Bank Connection',
  //     children: <BankConnection />,
  //   },
  //   {
  //     key: 6,
  //     label: 'Documentation',
  //     children: <Documentation />,
  //   },
  // ];

  // if (UserTypes.USER === user_type || UserTypes.TAXACCOUTANT === user_type) {
  //   tabs.push({
  //     key: 7,
  //     label: 'Subscription',
  //     children: <Subscription />,
  //   });
  // }

  return (
    <main className="page-content client-detail-container">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <div className="row align-items-center mt-4">{user_type === UserTypes.CHANNEL_PARTNER || user_type === UserTypes.SUB_CHANNEL_PARTNER ? <Reports /> : <Outlet />}</div>
        </div>
      </div>
    </main>
  );
};

export default SubClient;
