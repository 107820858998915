import { putRequest } from '../../../utils/apiUtils';
import { getRejectUserFailure, getRejectUserRequest, getRejectUserSuccess } from '../../reducers/slices/dashboard/getReject.slice';

export const getRejectUser = (id, type) => {
  return async (dispatch) => {
    await dispatch(getRejectUserRequest());
    const { result, error } = await putRequest(`user/${type === 1 ? 'tax-accountant' : 'mortgage-broker'}/reject/${id}`);

    if (!error) {
      return await dispatch(getRejectUserSuccess(result));
    }
    return await dispatch(getRejectUserFailure(result));
  };
};
