import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getAuditTrailChartDataSlice = createSlice({
  name: 'getAuditTrailChartData',
  initialState,
  reducers: {
    getAuditTrailChartDataRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    getAuditTrailChartDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getAuditTrailChartDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getAuditTrailChartDataRequest, getAuditTrailChartDataSuccess, getAuditTrailChartDataFailure } = getAuditTrailChartDataSlice.actions;

export default getAuditTrailChartDataSlice.reducer;
