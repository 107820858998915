// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amplify-tabs__list {
  display: none;
}
[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  border-radius: 6px;
}
[data-amplify-authenticator] [data-amplify-form] {
  padding: 16px 32px;
}
.amplify-button--primary {
  margin: 10px 0 0;
  background-color: #56aaaa;
  border: 1px solid #56aaaa;
  border-radius: 6px;
}
.amplify-button--disabled {
  color: #304050;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/login/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACA;EACE,YAAA;EACA,kBAAA;AACF;AACA;EACE,kBAAA;AACF;AAEA;EACE,gBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;AAAF;AAGA;EACE,cAAA;AADF","sourcesContent":[".amplify-tabs__list {\n  display: none;\n}\n[data-amplify-authenticator] [data-amplify-router] {\n  border: none;\n  border-radius: 6px;\n}\n[data-amplify-authenticator] [data-amplify-form] {\n  padding: 16px 32px;\n}\n\n.amplify-button--primary {\n  margin: 10px 0 0;\n  background-color: #56aaaa;\n  border: 1px solid #56aaaa;\n  border-radius: 6px;\n}\n\n.amplify-button--disabled {\n  color: #304050;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
