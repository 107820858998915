import { UserTypes } from '../../../../components/common/userTypes';
import { getRequest } from '../../../../utils/apiUtils';
import { getSubUserListFailure, getSubUserListRequest, getSubUserListSuccess } from '../../../reducers/slices/texDashboard/subusers/getSubusers.slice';

export const GetSubUserList = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(getSubUserListRequest());
    const { result, error } = await getRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'sub-channel-partner' : 'sub-tax-accountant'}/list`);

    if (!error) {
      return await dispatch(getSubUserListSuccess(result));
    }
    return await dispatch(getSubUserListFailure(result));
  };
};
