// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-segmented .ant-segmented-item-selected {
  background-color: #56aaaa;
  color: #fff;
}
.popover {
  position: relative;
  width: 436px !important;
}
.noticeButton {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.icon {
  padding: 4px;
  vertical-align: middle;
  font-size: 20px;
  cursor: pointer;
}
.small-icon {
  padding: 4px;
  vertical-align: middle;
  font-size: 18px;
  cursor: pointer;
}
.noti-badge {
  font-size: 16px;
}
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}
.header-notification .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #262626 !important;
  font-weight: 400;
  font-size: 16px;
  text-shadow: none;
  letter-spacing: 0.2px;
}
.header-notification button {
  border-radius: 5px;
}
.header-notification .tabs .ant-tabs-nav-list {
  margin-left: 16px;
}
.header-notification .tabs .ant-tabs-ink-bar {
  background: #262626 !important;
  opacity: 0.6;
}
.header-notification .tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.header-notification .tabs .ant-tabs-nav {
  margin-bottom: 0;
}
.notification-list .ant-dropdown-menu-item {
  padding: 0px !important;
  border: 0;
}
.notification-list .card {
  border: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/index.less"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,WAAA;AAAF;AAEA;EACE,kBAAA;EACA,uBAAA;AAAF;AAGA;EACE,qBAAA;EACA,eAAA;EACA,oBAAA;AADF;AAGA;EACE,YAAA;EACA,sBAAA;EACA,eAAA;EACA,eAAA;AADF;AAGA;EACE,YAAA;EACA,sBAAA;EACA,eAAA;EACA,eAAA;AADF;AAIA;EACE,eAAA;AAFF;AAIA;EACE,kCAAA;AAFF;AAKA;EAEI,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAJJ;AAFA;EASI,kBAAA;AAJJ;AALA;EAaM,iBAAA;AALN;AARA;EAiBM,8BAAA;EACA,YAAA;AANN;AAZA;EAsBM,kBAAA;AAPN;AAfA;EAyBM,gBAAA;AAPN;AAYA;EAEI,uBAAA;EACA,SAAA;AAXJ;AAQA;EAOI,oBAAA;AAZJ","sourcesContent":["// @import (reference) '~antd/es/style/themes/index';\n.ant-segmented .ant-segmented-item-selected {\n  background-color: #56aaaa;\n  color: #fff;\n}\n.popover {\n  position: relative;\n  width: 436px !important;\n}\n\n.noticeButton {\n  display: inline-block;\n  cursor: pointer;\n  transition: all 0.3s;\n}\n.icon {\n  padding: 4px;\n  vertical-align: middle;\n  font-size: 20px;\n  cursor: pointer;\n}\n.small-icon {\n  padding: 4px;\n  vertical-align: middle;\n  font-size: 18px;\n  cursor: pointer;\n}\n\n.noti-badge {\n  font-size: 16px;\n}\n.ant-menu-light.ant-menu-root.ant-menu-vertical {\n  border-inline-end: none !important;\n}\n\n.header-notification {\n  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: #262626 !important;\n    font-weight: 400;\n    font-size: 16px;\n    text-shadow: none;\n    letter-spacing: 0.2px;\n  }\n  button {\n    border-radius: 5px;\n  }\n  .tabs {\n    .ant-tabs-nav-list {\n      margin-left: 16px;\n    }\n\n    .ant-tabs-ink-bar {\n      background: #262626 !important;\n      opacity: 0.6;\n    }\n\n    .ant-tabs-nav-scroll {\n      text-align: center;\n    }\n    .ant-tabs-nav {\n      margin-bottom: 0;\n    }\n  }\n}\n\n.notification-list {\n  .ant-dropdown-menu-item {\n    padding: 0px !important;\n    border: 0;\n  }\n\n  .card {\n    border: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
