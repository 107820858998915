import { UserTypes } from '../../../../components/common/userTypes';
import { deleteRequest } from '../../../../utils/apiUtils';
import { deleteSubuserFailure, deleteSubuserRequest, deleteSubuserSuccess } from '../../../reducers/slices/texDashboard/subusers/deleteSubuser.slice';

export const DeleteSubuser = (id) => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(deleteSubuserRequest());
    const { result, error } = await deleteRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'sub-channel-partner' : 'sub-tax-accountant'}/${id}`);

    if (!error) {
      return await dispatch(deleteSubuserSuccess(result));
    }
    return await dispatch(deleteSubuserFailure(result));
  };
};
