import { getRequest } from '../../../utils/apiUtils';
import { getPropertyListByIdRequest, getPropertyListByIdSuccess, getPropertyListByIdFailure } from '../../reducers/slices/property/getPropertyListById.slice';

export const GetPropertyListById = (id, id1) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/property/${id}`;
  } else {
    tempURl = `user/property/${id}/?userId=${id1}`;
  }
  return async (dispatch) => {
    await dispatch(getPropertyListByIdRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPropertyListByIdSuccess(result));
    }
    return await dispatch(getPropertyListByIdFailure(result));
  };
};

export const GetPropertyById = (id, id1) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/properties/${id}`;
  } else {
    tempURl = `user/properties/${id}/?userId=${id1}`;
  }
  return async (dispatch) => {
    await dispatch(getPropertyListByIdRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPropertyListByIdSuccess(result));
    }
    return await dispatch(getPropertyListByIdFailure(result));
  };
};
