import { Modal } from 'antd';
import React from 'react';
import ApplyAsFPDiv from './ApplyAsFPDiv';

const ModalApplyAsFP = ({ setIsOpen = () => {}, isOpen = false }) => {
  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal onCancel={handleCancel} footer={null} centered style={{ zIndex: '1005', borderRadius: '1em' }} destroyOnClose open={isOpen}>
        <ApplyAsFPDiv apiCall={false} setIsOpen={setIsOpen} />
      </Modal>
    </>
  );
};

export default ModalApplyAsFP;
