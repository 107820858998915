import { postRequest } from '../../../utils/apiUtils';
import { verifyUserFailure, verifyUserRequest, verifyUserSuccess } from '../../reducers/slices/auth/verifyUser.slice';

export const verifyUser = (data) => {
  return async (dispatch) => {
    await dispatch(verifyUserRequest());
    const { result, error } = await postRequest(`/auth/verify-token`, data);
    if (!error) {
      return await dispatch(verifyUserSuccess(result));
    }
    return await dispatch(verifyUserFailure(error));
  };
};
