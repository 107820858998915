import { createSlice } from '@reduxjs/toolkit';

const initialState = { BankData: [], error: false, isLoading: true };

export const getReciptdocumentationDataSlice = createSlice({
  name: 'GetdocumentationData',
  initialState,
  reducers: {
    GetReciptdocumentationDataRequest: (state, action) => {
      state.BankData = [];
      state.isLoading = true;
    },
    GetReciptdocumentationDataSuccess: (state, action) => {
      state.BankData = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    GetReciptdocumentationDataFailure: (state, action) => {
      state.BankData = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { GetReciptdocumentationDataRequest, GetReciptdocumentationDataSuccess, GetReciptdocumentationDataFailure } = getReciptdocumentationDataSlice.actions;

export default getReciptdocumentationDataSlice.reducer;
