import React from 'react';
import { TrensectionType, UserTypes } from '../../common/userTypes';
import RentalSummary from '../rental_summary/index';
import Settelement from '../rental_summary/Settelement';
import ManualTransaction from './ManualTransaction';
import { useLocation } from 'react-router-dom';

export default function EditRecordExpenses({ tid = null, tstate = {}, onUpdateTransaction }) {
  let state = useLocation()?.state;
  state = Object.keys(tstate).length > 0 ? tstate : state;

  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);

  const isTaxAccountant = admin1.user_type === UserTypes.TAXACCOUTANT;

  const location = useLocation();

  const fromSameSite = location?.state && location?.state?.fromSameSite;

  return (
    <>
      {state.TransectionType === TrensectionType.MANUAL_TYPE_TRANSACTION ? (
        <ManualTransaction tid={tid} isTaxAccountant={isTaxAccountant} fromSameSite={fromSameSite} onUpdateTransaction={onUpdateTransaction} TransectionType={state.TransectionType} />
      ) : state.TransectionType === TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION ? (
        <ManualTransaction fromSameSite={fromSameSite} tid={tid} isTaxAccountant={isTaxAccountant} onUpdateTransaction={onUpdateTransaction} TransectionType={state.TransectionType} />
      ) : state.TransectionType === TrensectionType.BASIQ_TYPE_TRANSACTION ? (
        <ManualTransaction
          fromSameSite={fromSameSite}
          isTaxAccountant={isTaxAccountant}
          tid={tid}
          onUpdateTransaction={onUpdateTransaction}
          TransectionType={state.TransectionType}
          PropertyId={state.PropertyId}
          LoanId={state.LoanId}
        />
      ) : state.TransectionType === TrensectionType.RENT_TYPE_TRANSACTION ? (
        <RentalSummary tid={tid} isTaxAccountant={isTaxAccountant} onUpdateTransaction={onUpdateTransaction} fromSameSite={fromSameSite} TransectionType={state.TransectionType} />
      ) : state.TransectionType === TrensectionType.SETTLEMENT_TYPE_TRANSACTION ? (
        <Settelement tid={tid} isTaxAccountant={isTaxAccountant} onUpdateTransaction={onUpdateTransaction} fromSameSite={fromSameSite} TransectionType={state.TransectionType} />
      ) : (
        ''
      )}
    </>
  );
}
