import { getRequest } from '../../../utils/apiUtils';
import { getBankFailure, getBankRequest, getBankSuccess } from '../../reducers/slices/dashboard/getBankConnect.slice';

export const GetBankConnect = (id, type) => {
  return async (dispatch) => {
    await dispatch(getBankRequest());
    const { result, error } = await getRequest(`/user/bank-connect/${id}/?ba_type=${type}`);

    if (!error) {
      return await dispatch(getBankSuccess(result));
    }
    return await dispatch(getBankFailure(result));
  };
};
