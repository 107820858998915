import { putRequest, putRequestImport } from '../../../utils/apiUtils';

export const updateUserProfilePicture = (pictureInfo) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequestImport(`user/profile/picture`, pictureInfo);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const updateUserProfileName = (name) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`user/profile`, name);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
