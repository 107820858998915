import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const OtpCheckVerificationDeleteSlice = createSlice({
    name: "DeleteAccountVerify",
    initialState,
    reducers: {
        OtpCheckVerificationDelRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        OtpCheckVerificationDelSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        OtpCheckVerificationDelFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { OtpCheckVerificationDelRequest, OtpCheckVerificationDelSuccess, OtpCheckVerificationDelFailure } = OtpCheckVerificationDeleteSlice.actions;

export default OtpCheckVerificationDeleteSlice.reducer;
