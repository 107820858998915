import iconRent from '../../assets/dashboard-cosmetics/icon-rent.svg';
import iconSettlement from '../../assets/dashboard-cosmetics/handshake-icon.svg';
import iconDepreciation from '../../assets/dashboard-cosmetics/chat-arrow-down-icon.svg';
import onboardingIcon from '../../assets/dashboard-cosmetics/icon-onboarding.svg';
import propertyIcon from '../../assets/dashboard-cosmetics/icon-property.svg';
import taxUserIcon from '../../assets/dashboard-cosmetics/icon-tax-user.svg';
import bankIcon from '../../assets/dashboard-cosmetics/icon-bank.svg';

export const getTitle = (val) => {
  if (val === 'rent_summary') {
    return 'Rent Statement';
  } else if (val === 'check_investment_rent_mail') {
    return 'Rent Summary';
  } else if (val === 'check_property_loan') {
    return 'Bank Details';
  } else if (val === 'check_settlement_mail') {
    return 'Property Settlement';
  } else if (val === 'check_deprecation_mail') {
    return 'Depreciation Schedule';
  } else if (val === 'add_property') {
    return 'Add Property';
  } else if (val === 'check_tax_accountant') {
    return 'Tax Accountant';
  } else if (val === 'tax_accountant_request') {
    return 'Onboarding Request';
  } else if (val === 'mortgage_broker_request') {
    return 'Mortgage Broker Request';
  }
};

export const getIcon = (val) => {
  if (val === 'rent_summary') {
    return (
      <p className="m-0 pera_icon3">
        <img src={iconRent} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'check_investment_rent_mail') {
    return (
      <p className="m-0 pera_icon3">
        <img src={iconRent} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'check_property_loan') {
    return (
      <p className="m-0 pera_icon1">
        <img src={bankIcon} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'check_settlement_mail') {
    return (
      <p className="m-0 pera_icon">
        <img src={iconSettlement} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'check_deprecation_mail') {
    return (
      <p className="m-0 pera_icon2">
        <img src={iconDepreciation} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'add_property') {
    return (
      <p className="m-0 pera_icon_property">
        <img src={propertyIcon} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'tax_accountant_request') {
    return (
      <p className="m-0 pera_icon_onboard">
        <img src={onboardingIcon} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'mortgage_broker_request') {
    return (
      <p className="m-0 pera_icon_onboard">
        <img src={onboardingIcon} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'check_tax_accountant') {
    return (
      <p className="m-0 pera_icon_tax">
        <img src={taxUserIcon} className="img-fluid" alt="" />
      </p>
    );
  } else if (val === 'add_property_new') {
    return <img src={propertyIcon} className="img-fluid pera_icon_property" alt="" />;
  }
};
