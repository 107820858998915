import { UserTypes } from '../components/common/userTypes';
import { Navigate } from 'react-router';
const BothRoutes = ({ children }) => {
  const token = localStorage.getItem('token');
  const admin = localStorage.getItem('admin');
  const { user_type = null, ...user_info } = admin ? JSON.parse(admin) : {};

  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;

  // Redirect to landing page if token or user_type is missing
  if (!token || !user_type) {
    window.open(landing_url, '_self');
    return null; // Prevent further rendering until redirect
  }

  // Redirect to mobile verification if phone is not verified
  if (user_info?.phone_verified === false) {
    return window.location.pathname !== '/mobile-verification' ? <Navigate to="/mobile-verification" /> : children;
  }

  if (['/referral', '/2fa-setup'].includes(window.location.pathname)) {
    return children;
  }

  // Redirect to complete profile if user type is not USER and company_name is null
  if (UserTypes.USER !== user_type && ![UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type) && user_info?.company_name === null) {
    return window.location.pathname !== '/complete-profile' ? <Navigate to="/complete-profile" /> : children;
  }

  // Allow access to children if user_type matches
  if (
    user_type === UserTypes.USER ||
    [UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER, UserTypes.ADMIN_SUB_TAXACCOUNTANT].includes(user_type)
  ) {
    return children;
  }

  // Redirect to landing page as a fallback
  window.open(landing_url, '_self');
  return null; // Prevent further rendering until redirect
};

export default BothRoutes;
