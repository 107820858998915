import { Button, Card, Form, Input, notification, Popconfirm, Spin, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteFinancialPlannerDataAsync,
  getFinancialPlannerDataAsync,
  postFinancialPlannerDataAsync,
  selectFinancialPlannerData,
  selectFinancialPlannerLoading,
  updateFinancialPlannerDataAsync,
} from '../redux/reducers/slices/FinancialPlanner/FinancialPlannerSlice';
import editIcon from '../assets/images/edit.svg';
import CustomIcon from '../components/sidebar/custIcon';
import { UserStatus } from '../components/common/userTypes';
import deleteIcon from '../assets/images/delete.svg';

const ApplyAsFPDiv = ({ setIsOpen = () => {}, apiCall = false }) => {
  const dispatch = useDispatch();
  const [spin1, setSpin1] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const financialPlannerData = useSelector(selectFinancialPlannerData);

  const financialPlannerLoading = useSelector(selectFinancialPlannerLoading);

  useEffect(() => {
    dispatch(getFinancialPlannerDataAsync());
  }, [dispatch]);

  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setSpin1(true);
      let res;
      if (editOpen) {
        res = await dispatch(updateFinancialPlannerDataAsync({ ...values, id: financialPlannerData.id }));
      } else if (financialPlannerData?.approved_status === UserStatus.CANCELED) {
        res = await dispatch(updateFinancialPlannerDataAsync({ ...values, id: financialPlannerData.id }));
      } else {
        res = await dispatch(postFinancialPlannerDataAsync(values));
      }
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        form.resetFields();
        setSpin1(false);
        setEditOpen(false);
        await dispatch(getFinancialPlannerDataAsync());
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message,
        });
        setSpin1(false);
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const deleteFinancialPlanner = async () => {
    try {
      setSpin1(true);
      const res = await dispatch(deleteFinancialPlannerDataAsync(financialPlannerData.id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        form.resetFields();
        await dispatch(getFinancialPlannerDataAsync());
        setIsOpen(false);
        setSpin1(false);
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
        setSpin1(false);
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
      form.resetFields();

      setSpin1(true);
      setIsOpen(false);
    }
  };

  return (
    <Card
      bordered={false}
      title={apiCall && 'Financial Planner'}
      className={`shadow-none profile-content-card profile-card-parent`}
      extra={
        !financialPlannerLoading &&
        apiCall && (
          <div className="d-flex align-items-center mb-3">
            {!editOpen && financialPlannerData?.approved_status === UserStatus.PENDING && (
              <div key={crypto.randomUUID()} className="my-3 my-sm-0 d-flex align-items-center gap-2">
                <Tag color="red">Pending</Tag>
                <Button
                  icon={<CustomIcon icon={editIcon} />}
                  className="btn-primary"
                  onClick={() => {
                    form.setFieldsValue({
                      license_entity: financialPlannerData?.license_entity,
                      authorize_representative_number: financialPlannerData?.authorize_representative_number,
                      authorize_representative: financialPlannerData?.authorize_representative,
                    });
                    setEditOpen(true);
                  }}
                />

                <Popconfirm placement="left" title="Are you sure to delete?" onConfirm={() => deleteFinancialPlanner()} okText="Yes" cancelText="No">
                  <Button type="primary" className="Delete_btn" icon={<CustomIcon icon={deleteIcon} />} />
                </Popconfirm>
              </div>
            )}
            {!editOpen && financialPlannerData?.approved_status === UserStatus.ACTIVE && (
              <div className="d-flex align-items-center gap-2">
                <Tag color="green">Approved</Tag>
              </div>
            )}
          </div>
        )
      }
    >
      <>
        {!apiCall && (
          <div
            className="d-flex justify-content-between align-items-center mt-2 "
            style={{
              width: '90%',
            }}
          >
            <Typography.Text style={{ fontSize: '1.5rem' }}>Financial Planner</Typography.Text>
            {!editOpen && financialPlannerData?.approved_status === UserStatus.PENDING && (
              <div key={crypto.randomUUID()} className="my-3 my-sm-0 d-flex align-items-center gap-2">
                <Tag color="red">Pending</Tag>
                <Button
                  icon={<CustomIcon icon={editIcon} />}
                  className="btn-primary"
                  onClick={() => {
                    form.setFieldsValue({
                      license_entity: financialPlannerData?.license_entity,
                      authorize_representative_number: financialPlannerData?.authorize_representative_number,
                      authorize_representative: financialPlannerData?.authorize_representative,
                    });
                    setEditOpen(true);
                  }}
                />

                <Popconfirm placement="left" title="Are you sure to delete?" onConfirm={() => deleteFinancialPlanner()} okText="Yes" cancelText="No">
                  <Button type="primary" className="Delete_btn" icon={<CustomIcon icon={deleteIcon} />} />
                </Popconfirm>
              </div>
            )}
            {!editOpen && financialPlannerData?.approved_status === UserStatus.ACTIVE && (
              <div className="d-flex align-items-center gap-2">
                <Tag color="green">Approved</Tag>
              </div>
            )}
          </div>
        )}

        {((!financialPlannerLoading && Object.keys(financialPlannerData)?.length < 1) || editOpen || financialPlannerData?.approved_status === UserStatus.CANCELED) && (
          <Spin spinning={spin1} size="large">
            <Form layout="vertical" form={form} className={`profilerForm ${!apiCall && 'mt-2'} `} onFinish={handleFinish}>
              <Form.Item
                label="Licensed Entity"
                name="license_entity"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter Licensed Entity!',
                  },
                ]}
              >
                <Input placeholder="Licensed Entity" />
              </Form.Item>
              <Form.Item
                label="Authorise Representative Number"
                className="w-100"
                name="authorize_representative_number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Authorise Representative Number!',
                  },
                  {
                    pattern: /^\+?[1-9]\d{1,14}$/,
                    message: 'Please enter a valid Authorise Representative Number!',
                  },
                ]}
              >
                <Input placeholder="Authorise Representative Number" />
              </Form.Item>
              <Form.Item
                label="Authorised Representative Name"
                name="authorize_representative"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter Authorised Representative Name!',
                  },
                ]}
              >
                <Input placeholder="Authorised Representative Name" />
              </Form.Item>
              <div className={`normal_button text-center d-flex gap-2 ${apiCall ? 'justify-content-start' : 'justify-content-center'}  `}>
                {editOpen && (
                  <button size="large" onClick={() => setEditOpen(false)}>
                    Cancel
                  </button>
                )}
                <button size="large" type="submit">
                  {editOpen ? 'Update' : 'Submit'}
                </button>
              </div>
            </Form>
          </Spin>
        )}

        {financialPlannerLoading && !editOpen && financialPlannerData?.approved_status !== UserStatus.CANCELED && (
          <div className="d-flex justify-content-center align-items-center p-5">
            <Spin spinning={true} size="large" />
          </div>
        )}

        {!financialPlannerLoading && Object.keys(financialPlannerData)?.length > 0 && financialPlannerData?.approved_status !== UserStatus.CANCELED && !editOpen && (
          <>
            <li className="list-group-item" key={`assignTax-${financialPlannerData?.id}`}>
              <div className="d-sm-flex w-100  justify-content-between align-items-center mt-4 mb-3">
                <div className="w-100">
                  <div className="d-flex gap-2">
                    <span>License Entity :</span>
                    <h6 key={`assignTax-name-${financialPlannerData?.id}`}>{financialPlannerData.license_entity}</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <span>Authorise Representative Number :</span>
                    <p className="mb-1 text-muted" key={`assignTax-email-${financialPlannerData?.id}`}>
                      {financialPlannerData.authorize_representative_number}
                    </p>
                  </div>
                  <div className="d-flex gap-2">
                    <span>Authorised Representative Name :</span>
                    <p className="mb-1 text-muted" key={`assignTax-email-${financialPlannerData?.id}`}>
                      {financialPlannerData.authorize_representative}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </>
        )}
      </>
    </Card>
  );
};

export default ApplyAsFPDiv;
