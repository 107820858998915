import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const addPropertySlice = createSlice({
    name: "AddProperty",
    initialState,
    reducers: {
        addPropertyRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        addPropertySuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        addPropertyFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { addPropertyRequest, addPropertySuccess, addPropertyFailure } = addPropertySlice.actions;

export default addPropertySlice.reducer;
