import { AuditOutlined, DownOutlined, FileSyncOutlined, InfoCircleOutlined, LeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Flex, Row, Spin, Button, Form, Col, Dropdown, Space, notification, Empty, Segmented, Table, Divider, Popconfirm, Popover } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserTypes } from '../../../../common/userTypes';
import dayjs from 'dayjs';
import CustomInput from '../../../../common';
import { getAuditTrailList, postAuditTrailData, resetAuditReport } from '../../../../../redux/actions/AuditTrail/getAuditTrailList.action';
import '../index.less';
import AuditOutput from './AuditOutput';
import { getYearList } from '../../../../../utils';

const { Column } = Table;

const AuditTrail = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [auditYear, setAuditYear] = useState(dayjs().month() >= 6 ? dayjs().year() - 1 : dayjs().year() - 2);
  const auditTrailDta = useSelector((state) => state.getAuditTrailListReducer);
  const auditTrailChartDta = useSelector((state) => state.getAuditTrailChartDataReducer);
  const [formState, setFormState] = useState({ rent_annual_income: [], bank_data: [] });
  const [auditData, setAuditData] = useState({});
  const [auditDataChart, setAuditDataChart] = useState({});
  const [auditSubmit, setAuditSubmit] = useState(false);
  const [auditForm] = Form.useForm();

  const tooltipText = (
    <p className="ms-2 fs-6 ">
      <span className="d-block">Streamline your reconciliation process.</span>
      <span className="d-block">Easily compare rent statements, verify loan interest, and ensure all expenses are captured.</span>
      <span className="d-block"> Highlighted variances help you quickly spot discrepancies.</span>
    </p>
  );

  const currentClient = localStorage.getItem('currentClient') || undefined;
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};

  // reset form when form state changes
  useEffect(() => {
    auditForm.resetFields();
  }, [formState, auditForm]);

  // get audit list
  const getAuditList = useCallback(() => {
    dispatch(getAuditTrailList(currentClient, auditYear));
  }, [dispatch, currentClient, auditYear]);

  useEffect(() => {
    getAuditList();
  }, [auditYear, getAuditList]);
  // on set form value for edit
  useEffect(() => {
    setLoading(auditTrailDta.isLoading || false);
    if (auditTrailDta && auditTrailDta.data.result && auditTrailDta.error === false) {
      const { submit = false, property_rent_data = [], property_loan_data = [], property_extra_loan_data = [] } = auditTrailDta.data.result;
      setAuditData(auditTrailDta.data.result);
      setAuditSubmit(submit);
      // if (submit) {
      //   dispatch(getAuditTrailChartData(currentClient, auditYear));
      // }
      // set form value
      const propertyDT = property_rent_data.map((elm) => {
        return {
          key: elm.property_id,
          name: elm.property_id,
          label: elm.street_number ? elm.street_number + (elm.street_name ? '  ' + elm.street_name : '') : '',
          amounts: elm.user_input_amount.map((item, index) => {
            return { key: `key-amount-${index}`, id: item.id, amount: item.amount };
          }),
        };
      });

      const loanDT = property_loan_data
        ?.filter((elm) => elm.bank_id !== null)
        .map((elm) => {
          return {
            key: `key${elm.loan_id}`,
            id: elm.id,
            name: elm.loan_id,
            amount: elm.user_input_amount,
            label: `${elm.bank_bsb_number}-${elm.bank_account_number} (${elm.bank_name})`,
          };
        });

      setFormState({
        bank_data: loanDT,
        rent_annual_income: propertyDT,
        extra_bank_data: property_extra_loan_data,
      });
    }
  }, [auditTrailDta, auditYear, currentClient, dispatch]);

  useEffect(() => {
    if (auditTrailChartDta && auditTrailChartDta.data.result && auditTrailChartDta.error === false) {
      setAuditDataChart(auditTrailChartDta.data.result);
    }
  }, [auditTrailChartDta]);

  // submit audit
  const onSubmitAudit = (values) => {
    setLoading(true);
    const auditInputData = {
      year: auditYear,
      property_rent_data: values.rent_annual_income.flatMap((item) => {
        return item.amounts.map((sub_item) => {
          return {
            id: sub_item.id,
            property_id: item.name,
            amount: Number(sub_item.amount) || 0,
          };
        });
      }),
      property_loan_data: values.bank_data.map((item) => {
        return { id: item.id, loan_id: item.name, amount: Number(item.amount) || 0 };
      }),
      property_extra_loan_data: values?.extra_bank_data?.map((item) => {
        return { id: item.id, bank_name: item.bank_name, bank_bsb_number: item.bank_bsb_number, bank_account_number: item.bank_account_number, amount: Number(item.amount) || 0 };
      }),
    };

    dispatch(postAuditTrailData(auditInputData, currentClient)).then((data) => {
      if (data && data?.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        getAuditList();
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const onResetData = () => {
    setLoading(true);
    dispatch(resetAuditReport(auditYear, currentClient)).then((data) => {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        getAuditList();
      } else {
        notification.error({
          message: 'Failure',
          description: data.message,
        });
      }
      setLoading(false);
    });
  };

  return (
    <main className={user_type === UserTypes.USER ? 'page-content' : ''}>
      <div className={user_type === UserTypes.USER ? 'container-fluid bg-dash-back' : ''}>
        <div className={user_type === UserTypes.USER ? 'layout-specing' : ''}>
          <div className="audit_form">
            <Flex justify="space-between">
              <Row className="align-items-center mt-1">
                {auditSubmit === false && auditData?.submit === true && (
                  <Button
                    className="btn_goBack"
                    onClick={() => {
                      setAuditSubmit(true);
                    }}
                    icon={<LeftOutlined />}
                  />
                )}
                <div className="d-flex flex-row gap-2 align-items-center">
                  <h5 className="m-0"> {user_type === UserTypes.USER ? 'Checks & Controls' : 'Audit Check'} </h5>
                  <Popover overlayStyle={{ maxWidth: '600px' }} placement="right" title={tooltipText}> <InfoCircleOutlined className="cursor-pointer" />
                  </Popover>
                </div>
              </Row>

              <Row>
                <Popconfirm title={`All data will be reset. Are you sure?`} okText="Yes" cancelText="No" onConfirm={() => onResetData()}>
                  <Button size="large" className="mr-2">
                    Reset Input
                  </Button>
                </Popconfirm>
                <Dropdown
                  menu={{
                    items: getYearList(),
                    selectable: true,
                    defaultSelectedKeys: auditYear,
                    onSelect: (e) => {
                      setAuditYear(Number(e.key));
                    },
                  }}
                  trigger={['click']}
                  className="mr-2"
                >
                  <Button size="large">
                    <Space>
                      {getYearList()?.find((elm) => elm.key === auditYear)?.label}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                <Segmented
                  options={[
                    {
                      value: 'input',
                      label: 'Input',
                      icon: <AuditOutlined />,
                    },
                    {
                      value: 'output',
                      label: 'Output',
                      icon: <FileSyncOutlined />,
                      disabled: auditData?.submit !== true,
                    },
                  ]}
                  size="large"
                  style={{ backgroundColor: 'rgb(229, 229, 229)' }}
                  value={auditSubmit ? 'output' : 'input'}
                  onChange={(value) => {
                    if (value === 'input') {
                      setAuditSubmit(false);
                      auditForm.setFieldsValue(formState);
                    } else {
                      setAuditSubmit(true);
                    }
                  }}
                />
              </Row>
            </Flex>
            <Spin spinning={loading} size="large">
              {auditSubmit === false && (
                <Form layout="horizontal" form={auditForm} initialValues={formState} onFinish={onSubmitAudit} className="audit_form">
                  <Row className="mt-3 mb-3 card_wrapper" gutter={20}>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <Card
                        className="card"
                        title={
                          <div className="d-flex flex-row gap-2 align-items-center">
                            <h6 className="m-0 underline-text">Annual Rent Income Reconciliation</h6>
                            <Popover
                              placement="bottom"
                              overlayStyle={{ maxWidth: '600px' }}
                              title={
                                <div className="fs-6">
                                  <p className="tooltipChildCss">Easily check for differences between your End of Year Statement and the Monthly Rent statements from your property manager.</p>
                                  <ul className="tooltipChildCss">
                                    <li>If rent summaries are emailed to your unique ID, simply review the variance.</li>
                                    <li>Otherwise, forward the End of Year Rent summary to your unique email or manually add the annual rent amount.</li>
                                  </ul>
                                </div>
                              }
                            >
                              <InfoCircleOutlined className="cursor-pointer" />
                            </Popover>
                          </div>
                        }
                        type="inner"
                      >
                        <>
                          {!loading && auditData?.property_rent_data?.length === 0 && <Empty />}

                          <Form.List name="rent_annual_income">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Flex key={key} justify="space-between">
                                    <label className="pt-2">{formState?.['rent_annual_income']?.[name]?.label}</label>

                                    <Form.Item>
                                      <Form.List name={[name, 'amounts']}>
                                        {(subFields, subOpt) => (
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              rowGap: 16,
                                            }}
                                          >
                                            {subFields.map((subField, subindex) => (
                                              <Space key={subField.key}>
                                                <Form.Item noStyle name={[subField.name, 'amount']}>
                                                  <CustomInput className="form-control" placeholder="00.00" inputtype="number" allowdecimal="true" negitiveNumber={true} maxLength={10} />
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                  onClick={() => {
                                                    subOpt.remove(subField.name);
                                                  }}
                                                  style={{ visibility: subFields.length > 1 ? 'visible' : 'hidden' }}
                                                />
                                                {subindex === subFields.length - 1 ? (
                                                  <PlusOutlined
                                                    onClick={() => {
                                                      subOpt.add();
                                                    }}
                                                  />
                                                ) : null}
                                              </Space>
                                            ))}
                                          </div>
                                        )}
                                      </Form.List>
                                    </Form.Item>
                                  </Flex>
                                ))}
                              </>
                            )}
                          </Form.List>
                        </>
                      </Card>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <Card
                        className="card"
                        title={
                          <div className="d-flex flex-row gap-2 align-items-center">
                            <h6 className="m-0 underline-text">Bank - Interest on loans</h6>
                            <Popover
                              overlayStyle={{ maxWidth: '600px' }}
                              placement="bottom"
                              title={
                                <div className="fs-6">
                                  <p className="tooltipChildCss">Match your direct transaction feed's interest with the year-end interest summary from your banks.</p>
                                  <ul className="tooltipChildCss">
                                    <li>Input the interest amounts for each loan account and review any differences.</li>
                                    <li>This ensures all interest costs for your property investments are accurately captured.</li>
                                  </ul>
                                </div>
                              }
                            >
                              <InfoCircleOutlined className="cursor-pointer" />
                            </Popover>
                          </div>
                        }
                        type="inner"
                      >
                        <>
                          {!loading && auditData?.property_loan_data?.length === 0 && <Empty />}

                          <Form.List name="bank_data">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Flex key={key} justify="space-between">
                                    <label className="pt-2">{formState?.['bank_data']?.[name]?.label}</label>
                                    <Form.Item {...restField} name={[name, 'amount']}>
                                      <CustomInput className="form-control" placeholder="00.00" inputtype="number" allowdecimal="true" negitiveNumber={true} maxLength={10} />
                                    </Form.Item>
                                  </Flex>
                                ))}
                              </>
                            )}
                          </Form.List>

                          <Divider>Additional Loan</Divider>

                          <Form.List name="extra_bank_data">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.length > 0 && (
                                  <Table dataSource={fields} pagination={false} bordered={false} size="small">
                                    <Column
                                      title="BSB Number"
                                      dataIndex="bank_bsb_number"
                                      key="bank_bsb_number"
                                      render={(text, { name, ...restField } = Row) => (
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'bank_bsb_number']}
                                          className="m-0"
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: 'Please enter BSB Number!',
                                            },
                                          ]}
                                        >
                                          <CustomInput inputtype="number" maxLength={10} />
                                        </Form.Item>
                                      )}
                                    />
                                    <Column
                                      title="Account No."
                                      dataIndex="bank_account_number"
                                      key="bank_account_number"
                                      render={(text, { name, ...restField } = Row) => (
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'bank_account_number']}
                                          className="m-0"
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: 'Please enter Account Number!',
                                            },
                                          ]}
                                        >
                                          <CustomInput inputtype="number" maxLength={10} />
                                        </Form.Item>
                                      )}
                                    />
                                    <Column
                                      title="Bank Name"
                                      dataIndex="bank_name"
                                      key="bank_name"
                                      render={(text, { name, ...restField } = Row) => (
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'bank_name']}
                                          className="m-0"
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: 'Please enter Bank Name!',
                                            },
                                          ]}
                                        >
                                          <CustomInput />
                                        </Form.Item>
                                      )}
                                    />
                                    <Column
                                      title="Amount"
                                      dataIndex="cancel"
                                      key="cancel"
                                      render={(text, { name, ...restField } = Row) => (
                                        <Form.Item {...restField} name={[name, 'amount']} className="m-0">
                                          <CustomInput placeholder="00.00" inputtype="number" allowdecimal="true" negitiveNumber={true} maxLength={10} />
                                        </Form.Item>
                                      )}
                                    />
                                    <Column title="" dataIndex="address" key="address" render={(text, { name, ...restField } = Row) => <MinusCircleOutlined onClick={() => remove(name)} />} />
                                  </Table>
                                )}
                                <Form.Item>
                                  <Button type="dashed" className="mt-2" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Loan
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </>
                      </Card>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}></Col>
                  </Row>
                  <Flex justify="center" align="center">
                    <Row>
                      <div className="text-center login_button my-4">
                        <button type="submit">Submit</button>
                      </div>
                    </Row>
                  </Flex>
                </Form>
              )}
              {auditSubmit === true && (
                <div className="mt-3 mb-3">
                  <AuditOutput formState={formState} auditYear={auditYear} getAuditList={getAuditList} auditData={auditData} auditDataChart={auditDataChart} />
                </div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuditTrail;
