// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-bank-list .add-bank-card {
  border: 2px dashed #d9d9d9;
  border-radius: 8px;
  text-align: center;
  background-color: #fafafa;
  cursor: pointer;
}
.add-bank-list .add-bank-card .custom-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-bank-list td {
  border: none !important;
}
.add-bank-list td .ant-descriptions-item-label::after {
  content: ' ';
}
.add-bank-list .ant-card-body {
  padding-bottom: 2px;
}
#addPropertyForm .ant-form-item-label,
.addBankLoanModal .ant-form-item-label {
  font-weight: 700;
}
.property_list_card .card_button {
  width: 2.2em !important;
  height: 2.2em !important;
}
.property_list_card .ant-card-body label {
  color: #6c757d;
  font-size: 14px !important;
  font-weight: 500;
}
.property_list_card .ant-card-body strong {
  font-size: 15px !important;
  font-weight: 600;
}
.property_list_card .ant-card-body .property_title {
  font-size: 17px !important;
  font-weight: 600;
}
.property_list_card .text-primary {
  color: #56aaaa !important;
  font-weight: 600;
}
.property_list_card .text-secondary {
  color: #6c757d !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/property/index.less"],"names":[],"mappings":"AAAA;EAEI,0BAAA;EACA,kBAAA;EACA,kBAAA;EAEA,yBAAA;EACA,eAAA;AADJ;AANA;EAUM,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AADN;AAZA;EAkBI,uBAAA;AAHJ;AAfA;EAqBM,YAAA;AAHN;AAlBA;EAyBI,mBAAA;AAJJ;AAQA;;EAGI,gBAAA;AAPJ;AAWA;EAEI,uBAAA;EACA,wBAAA;AAVJ;AAOA;EASM,cAAA;EACA,0BAAA;EACA,gBAAA;AAbN;AAEA;EAcM,0BAAA;EACA,gBAAA;AAbN;AAFA;EAmBM,0BAAA;EACA,gBAAA;AAdN;AANA;EAyBI,yBAAA;EACA,gBAAA;AAhBJ;AAVA;EA8BI,yBAAA;AAjBJ","sourcesContent":[".add-bank-list {\n  .add-bank-card {\n    border: 2px dashed #d9d9d9;\n    border-radius: 8px;\n    text-align: center;\n    //   padding: 20px;\n    background-color: #fafafa;\n    cursor: pointer;\n\n    .custom-card-content {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n\n  td {\n    border: none !important;\n\n    .ant-descriptions-item-label::after {\n      content: ' ';\n    }\n  }\n  .ant-card-body {\n    padding-bottom: 2px;\n  }\n}\n\n#addPropertyForm,\n.addBankLoanModal {\n  .ant-form-item-label {\n    font-weight: 700;\n  }\n}\n\n.property_list_card {\n  .card_button {\n    width: 2.2em !important;\n    height: 2.2em !important;\n  }\n  .ant-card-body {\n    // padding-bottom: 0;\n\n    label {\n      color: #6c757d;\n      font-size: 14px !important;\n      font-weight: 500;\n    }\n    strong {\n      font-size: 15px !important;\n      font-weight: 600;\n    }\n\n    .property_title {\n      font-size: 17px !important;\n      font-weight: 600;\n    }\n  }\n\n  .text-primary {\n    color: #56aaaa !important;\n    font-weight: 600;\n  }\n\n  .text-secondary {\n    color: #6c757d !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
