import { postRequest } from '../../../../utils/apiUtils';
import { UserTypes } from '../../../../components/common/userTypes';
import { postTaxUserFailure, postTaxUserRequest, postTaxUserSuccess } from '../../../reducers/slices/texDashboard/UserOnbording/postTaxUser.slice';

export const postTaxUserData = (data) => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(postTaxUserRequest());
    const { result, error } = await postRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'channel-partner/form/create-user' : 'tax-accountant/onboard-user'}`, data);

    if (!error) {
      return await dispatch(postTaxUserSuccess(result));
    }
    return await dispatch(postTaxUserFailure(result));
  };
};
