import { createSlice } from '@reduxjs/toolkit';

const initialState = { BankData: [], error: false, isLoading: true };

export const getClientBankDetailsSlice = createSlice({
  name: 'GetDashboardBankData',
  initialState,
  reducers: {
    getClientBankDetailsRequest: (state, action) => {
      state.BankData = [];
      state.isLoading = true;
    },
    getClientBankDetailsSuccess: (state, action) => {
      state.BankData = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getClientBankDetailsFailure: (state, action) => {
      state.BankData = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getClientBankDetailsRequest, getClientBankDetailsSuccess, getClientBankDetailsFailure } = getClientBankDetailsSlice.actions;

export default getClientBankDetailsSlice.reducer;
