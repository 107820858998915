import { getRequest } from '../../../utils/apiUtils';
import { getConnectedBankListRequest, getConnectedBankListSuccess, getConnectedBankListFailure } from '../../reducers/slices/property/getConnectedBankList.slice';

export const GetConnectedBankList = (userId) => {
  const queryString = userId ? `?userId=${userId}` : '';
  return async (dispatch) => {
    await dispatch(getConnectedBankListRequest());
    const { result, error } = await getRequest(`user/bank-accounts${queryString}`);

    if (!error) {
      return await dispatch(getConnectedBankListSuccess(result));
    }
    return await dispatch(getConnectedBankListFailure(result));
  };
};
