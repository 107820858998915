import { deleteRequest, getRequest, postRequest } from '../../../utils/apiUtils';

export const fetchTaxAccountant = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`user/tax-accountant`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const addTaxAccountant = (info) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`user/assign/tax-accountant`, info);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteTaxAccountant = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`user/tax-accountant`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
