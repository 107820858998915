import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const LoanRejectSlice = createSlice({
  name: 'LoanReject',
  initialState,
  reducers: {
    LoanRejectRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    LoanRejectSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    LoanRejectFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { LoanRejectSuccess, LoanRejectFailure, LoanRejectRequest } = LoanRejectSlice.actions;

export default LoanRejectSlice.reducer;
