import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ChannelPartnerType, UserTypes } from '../components/common/userTypes';
/**
 * Component to handle authentication for protected routes.
 * @param children - JSX.Element - The routes to render if the user is authenticated.
 * @returns JSX.Element - The routes to render if the user is authenticated, otherwise navigates to the landing page.
 */
const ProtectedRoutes = ({ role, children }) => {
  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;
  const token = localStorage.getItem('token');
  const admin = localStorage.getItem('admin');
  const { user_type = null, ...user_info } = admin ? JSON.parse(admin) : {};

  if (!token || !user_type) {
    window.open(landing_url, '_self');
    return null; // Prevent further rendering until redirect
  } else if (user_info?.phone_verified === false) {
    return <Navigate to="/mobile-verification" />;
  } else if (UserTypes.USER !== user_type && ![UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type) && user_info?.company_name === null) {
    return <Navigate to="/complete-profile" />;
  } else if (user_type === UserTypes.USER) {
    return children;
  } else if (role?.includes(user_type)) {
    return children;
  } else if (
    [UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.TAXACCOUTANT].includes(user_type) ||
    (user_type === UserTypes.CHANNEL_PARTNER && user_info?.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER)
  ) {
    return <Navigate to="/dashboard" />;
  } else if ([UserTypes.SUB_CHANNEL_PARTNER, UserTypes.CHANNEL_PARTNER].includes(user_type)) {
    return <Navigate to="/clients" />;
  }
};

ProtectedRoutes.propTypes = {
  role: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  children: PropTypes.node.isRequired,
};

export default ProtectedRoutes;
