import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addTaxAccountant, deleteTaxAccountant, fetchTaxAccountant } from '../../../actions/UserTaxAccountant/UserTaxAccountantAction';

const initialState = {
  error: false,
  isLoading: false,
  data: {},
};

export const fetchTaxAccountantAsync = createAsyncThunk('user/fetchTaxAccountant', async () => {
  const response = await fetchTaxAccountant();
  return response;
});

export const addTaxAccountantAsync = createAsyncThunk('user/addTaxAccountant', async (info) => {
  const response = await addTaxAccountant(info);
  return response;
});

export const deleteTaxAccountantAsync = createAsyncThunk('user/deleteTaxAccountant', async () => {
  const response = await deleteTaxAccountant();
  return response;
});

export const UserTaxAccountant = createSlice({
  name: 'User Tax Accountant',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxAccountantAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTaxAccountantAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchTaxAccountantAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(addTaxAccountantAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addTaxAccountantAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(addTaxAccountantAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteTaxAccountantAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTaxAccountantAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(deleteTaxAccountantAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectTaxAccountantFetching = (state) => state.userTaxAccountant.isLoading;
export const selectTaxAccountantData = (state) => state.userTaxAccountant.data;

export default UserTaxAccountant.reducer;
