import { getRequest } from '../../../utils/apiUtils';
import { UserTypes } from '../../../components/common/userTypes';
import { getTexDashboardDataSuccess, getTexDashboardDataFailure, getTexDashboardDataRequest } from '../../reducers/slices/texDashboard/getTexDashboardData.slice';

export const GetTexDashboardData = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(getTexDashboardDataRequest());
    const { result, error } = await getRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER || admin.user_type === UserTypes.SUB_CHANNEL_PARTNER ? 'channel-partner' : 'tax-accountant'}/clients`);
    if (!error) {
      return await dispatch(getTexDashboardDataSuccess(result));
    }
    return await dispatch(getTexDashboardDataFailure(result));
  };
};

export const GetTexInviteLaterDashboardData = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(getTexDashboardDataRequest());
    const { result, error } = await getRequest(
      `${admin.user_type === UserTypes.CHANNEL_PARTNER || admin.user_type === UserTypes.SUB_CHANNEL_PARTNER ? 'channel-partner/clients' : 'tax-accountant/onboard-managed-user'}`,
    );
    if (!error) {
      return await dispatch(getTexDashboardDataSuccess(result));
    }
    return await dispatch(getTexDashboardDataFailure(result));
  };
};
