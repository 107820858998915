import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { deleteFinancialPlannerData, getFinancialPlannerData, postFinancialPlannerData, updateFinancialPlannerData } from '../../../actions/FinancialPlanner/FinancialPlannerApi';

const initialState = {
  error: false,
  isLoading: true,
  data: {},
};

export const getFinancialPlannerDataAsync = createAsyncThunk('FinancialPlanner/getData', async () => {
  const response = await getFinancialPlannerData();
  return response;
});

export const postFinancialPlannerDataAsync = createAsyncThunk('FinancialPlanner/postData', async (info) => {
  const response = await postFinancialPlannerData(info);
  return response;
});

export const updateFinancialPlannerDataAsync = createAsyncThunk('FinancialPlanner/updateData', async (info) => {
  const response = await updateFinancialPlannerData(info);
  return response;
});

export const deleteFinancialPlannerDataAsync = createAsyncThunk('FinancialPlanner/deleteData', async (id) => {
  const response = await deleteFinancialPlannerData(id);
  return response;
});

export const FinancialPlannerSlice = createSlice({
  name: 'FinancialPlanner',
  initialState,
  reducers: {
    resetFinancialPlannerData: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialPlannerDataAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFinancialPlannerDataAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getFinancialPlannerDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(postFinancialPlannerDataAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postFinancialPlannerDataAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(postFinancialPlannerDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(updateFinancialPlannerDataAsync.pending, (state, action) => {
        state.viewDetailsLoading = true;
      })
      .addCase(updateFinancialPlannerDataAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.viewDetailsLoading = false;
      })
      .addCase(updateFinancialPlannerDataAsync.rejected, (state, action) => {
        state.viewDetailsLoading = false;
        state.error = true;
      })
      .addCase(deleteFinancialPlannerDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFinancialPlannerDataAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteFinancialPlannerDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const { resetFinancialPlannerData } = FinancialPlannerSlice.actions;

export const selectFinancialPlannerLoading = (state) => state.financialPlanner.isLoading;
export const selectFinancialPlannerData = (state) => state.financialPlanner.data;

export default FinancialPlannerSlice.reducer;
