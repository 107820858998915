import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { disableUserMFAPreference, fetchUserMFAPreference, mfaUpdateForSystem, setMFAPreference } from '../../../actions/UserMFA/UserMFAAction';

const initialState = {
  error: false,
  isLoading: false,
  preference: {},
};

export const fetchUserMFAPreferenceAsync = createAsyncThunk('userMFA/fetchMFA', async () => {
  const response = await fetchUserMFAPreference();
  return response;
});

export const setMFAPreferenceAsync = createAsyncThunk('userMFA/setMFA', async (type) => {
  const response = await setMFAPreference(type);
  return response;
});

export const mfaUpdateForSystemAsync = createAsyncThunk('userMFA/systemPreferenceUpdate', async (mfaInfo) => {
  const response = await mfaUpdateForSystem(mfaInfo);
  return response;
});

export const disableUserMFAPreferenceAsync = createAsyncThunk('userMFA/disableMFA', async (type) => {
  const response = await disableUserMFAPreference(type);
  return response;
});

export const UserMFASlice = createSlice({
  name: 'User MFA',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMFAPreferenceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserMFAPreferenceAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.preference = action.payload;
      })
      .addCase(fetchUserMFAPreferenceAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(mfaUpdateForSystemAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(mfaUpdateForSystemAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(mfaUpdateForSystemAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(setMFAPreferenceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setMFAPreferenceAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(setMFAPreferenceAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(disableUserMFAPreferenceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(disableUserMFAPreferenceAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(disableUserMFAPreferenceAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUserPreferenceLoading = (state) => state.userMFA.isLoading;
export const selectUserMFAPreference = (state) => state.userMFA.preference;

export default UserMFASlice.reducer;
