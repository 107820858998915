import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMortgageBrokerDashboardData, getMortgageBrokerDashboardListData } from '../../../actions/tax-accountant-dashboard/TaxAccountantApi';

const initialState = {
  dashboardData: [],
  listData: [],
  error: false,
  isLoading: true,
};

export const getTaxAccountantDashboardDataAsync = createAsyncThunk('GetTaxAccountantBrokerDashboardData/getDashboardData', async () => {
  const response = await getMortgageBrokerDashboardData();
  return response;
});

export const getTaxAccountantListDataAsync = createAsyncThunk('GetTaxAccountantBrokerDashboardData/getListData', async () => {
  const response = await getMortgageBrokerDashboardListData();
  return response;
});

export const GetTaxAccountantBrokerDashboardData = createSlice({
  name: 'TaxAccountantDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTaxAccountantDashboardDataAsync.pending, (state, action) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(getTaxAccountantDashboardDataAsync.fulfilled, (state, action) => {
        state.dashboardData = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getTaxAccountantDashboardDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getTaxAccountantListDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTaxAccountantListDataAsync.fulfilled, (state, action) => {
        state.listData = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getTaxAccountantListDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectTaxAccountantDashboardData = (state) => state.taxAccountant.dashboardData;
export const selectTaxAccountantListData = (state) => state.taxAccountant.listData;
export const selectTaxAccountantDashboardLoading = (state) => state.taxAccountant.isLoading;

export default GetTaxAccountantBrokerDashboardData.reducer;
