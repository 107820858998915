import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Card, Popover, Spin, Table, Flex } from 'antd';
import { Spin } from 'antd';
// import { QuestionCircleOutlined } from '@ant-design/icons';
// import { Pie } from '@ant-design/plots';
import {
  getTaxAccountantDashboardDataAsync,
  selectTaxAccountantDashboardLoading,
  // getTaxAccountantListDataAsync,
  selectTaxAccountantDashboardData,
  // selectTaxAccountantListData,
} from '../../../../redux/reducers/slices/tax-accountant-dashboard/TaxAccountantSlice';
import Clients from '../../../Clients';
import './index.less';
import { UserTypes } from '../../../common/userTypes';
// const config = {
//   angleField: 'value',
//   colorField: 'type',
//   tooltip: {
//     title: 'type',
//   },
//   label: {
//     text: 'value',
//     style: {
//       fontWeight: 'bold',
//     },
//   },
//   legend: {
//     color: {
//       title: false,
//       position: 'right',
//       rowPadding: 5,
//     },
//   },
//   scale: {
//     color: { palette: 'set2' },
//   },
// };

// function convertGraphData(data) {
//   return Object.entries(data).map(([key, value]) => ({
//     type: key,
//     value: Number(value) || undefined,
//   }));
// }

// const clientAccessColumns = [
//   {
//     title: 'Name',
//     dataIndex: 'email',
//     key: 'email',
//     render(text, record) {
//       return (
//         <>
//           <span className="fw5-bold">{record?.name}</span>
//           <br />
//           <span className="fw5-normal">{text}</span>
//         </>
//       );
//     },
//   },
//   {
//     title: 'Assigned Teammate',
//     dataIndex: 'assignedTeammate',
//     key: 'assignedTeammate',
//     render(text, record) {
//       return <span className="fw5-bold">{record?.taxAccountant?.name || 'No Teammate assigned'}</span>;
//     },
//   },
// ];

// const clientAccessPendingColumns = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     key: 'name',
//     render: (text) => <span className="fw5-bold">{text}</span>,
//   },
//   {
//     title: 'Info',
//     dataIndex: 'email',
//     key: 'email',
//     render(text, record) {
//       return (
//         <Flex vertical>
//           <span className="fw5-bold">{text}</span>
//           <span className="fw5-normal">{record?.phone_number}</span>
//         </Flex>
//       );
//     },
//   },
// ];

const TaxDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_type } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};
  const dataLoading = useSelector(selectTaxAccountantDashboardLoading);
  const dashboardData = useSelector(selectTaxAccountantDashboardData);
  // const listData = useSelector(selectTaxAccountantListData);

  useEffect(() => {
    dispatch(getTaxAccountantDashboardDataAsync());
    // dispatch(getTaxAccountantListDataAsync());
  }, [dispatch]);

  const handleClick = () => {
    navigate('/dashboard', { state: { invite_later: false } });
    document.getElementById('clientTable').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <main className="page-content mt-2">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <div>
            <div className="row align-items-center">
              <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                <h5 className="m-0">Dashboard</h5>
              </div>
            </div>
            <Spin spinning={dataLoading}>
              <div className="row align-items-center">
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                  <div className="card" onClick={() => navigate('/onboarding')} role="button">
                    <div className="card-body">
                      <div className="card_ico justify-content-between align-items-center d-flex">
                        <h6 className="card-text text-muted fw-normal m-0">Invitation Pending </h6>
                        <h5 className="card-title fw-bold mb-0">{user_type === UserTypes.TAXACCOUTANT ? dashboardData?.totalOnboardUsers || 0 : dashboardData?.invitationPendingUsers || 0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                  <div className="card" onClick={() => navigate('/dashboard', { state: { invite_later: true } })} role="button">
                    <div className="card-body">
                      <div className="card_ico justify-content-between align-items-center d-flex">
                        <h6 className="card-text text-muted fw-normal m-0">Manage Clients</h6>
                        <h5 className="card-title fw-bold mb-0">{user_type === UserTypes.TAXACCOUTANT ? dashboardData?.managed_client_count || 0 : dashboardData?.managedUsers || 0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                  <div className="card" onClick={handleClick} role="button">
                    <div className="card-body">
                      <div className="card_ico d-flex justify-content-between align-items-center">
                        <h6 className="card-text text-muted fw-normal m-0">Client Access Received</h6>
                        <h5 className="card-title fw-bold mb-0">{user_type === UserTypes.TAXACCOUTANT ? dashboardData?.totalClients || 0 : dashboardData?.accessReceivedUsers || 0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                {user_type === UserTypes.SUB_TAXACCOUTANT ? (
                  <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                    <div className="card" onClick={() => navigate('/onboarding')} role="button">
                      <div className="card-body">
                        <div className="card_ico d-flex justify-content-between align-items-center">
                          <h6 className="card-text text-muted fw-normal m-0">Total Onboard Users</h6>
                          <h5 className="card-title fw-bold mb-0">{dashboardData?.totalOnboardUsers || 0}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                    <div className="card" onClick={() => navigate('/sub-users')} role="button">
                      <div className="card-body">
                        <div className="card_ico d-flex justify-content-between align-items-center">
                          <h6 className="card-text text-muted fw-normal m-0">Team Members</h6>
                          <h5 className="card-title fw-bold mb-0">{user_type === UserTypes.TAXACCOUTANT ? dashboardData?.totalTeamMember || 0 : dashboardData?.totalTeamMember || 0}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="row dashboard_wrapper">
                <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mt-3">
                  <Card
                    title={
                      <>
                        Onboarded Users{' '}
                        <Popover title="Onboarded Users" content={'Users who accepted your invitation versus those still pending.'}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                  >
                    {dashboardData?.graphOnboardClientData && <Pie {...config} data={convertGraphData(dashboardData?.graphOnboardClientData || {})} height={180} />}
                  </Card>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mt-3">
                  <Card
                    title={
                      <>
                        Subscription{' '}
                        <Popover title="Subscription" content={'Subscription'}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                    extra={
                      <span className="Route_showmore" onClick={() => navigate('/clients')}>
                        View More
                      </span>
                    }
                  >
                    {dashboardData?.graphOnboardClientData && <Pie {...config} data={convertGraphData(dashboardData?.graphSubscriptionData || {})} height={180} />}
                  </Card>
                </div>
              </div> */}
            </Spin>
            {/* <Spin spinning={dataLoading}>
              <div className="row dashboard_wrapper">
                <div className="row dashboard_wrapper">
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                    <Card
                      title={
                        <>
                          Client Access{' '}
                          <Popover title="Client Access" content={'Clients who have provided access to their loan data.'}>
                            <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                          </Popover>
                        </>
                      }
                      extra={
                        <span className="Route_showmore" onClick={() => navigate('/clients')}>
                          View More
                        </span>
                      }
                    >
                      <Table dataSource={listData?.clientData || []} columns={clientAccessColumns} size="small" pagination={false} className="p-0 m-0" />
                    </Card>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                    <Card
                      title={
                        <>
                          Client Access Pending{' '}
                          <Popover title="Client Access Pending" content={'Clients who have registered but have not yet granted access to you.'}>
                            <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                          </Popover>
                        </>
                      }
                      extra={
                        <span className="Route_showmore" onClick={() => navigate('/onboarding')}>
                          View More
                        </span>
                      }
                    >
                      <Table dataSource={listData?.referralUserData || []} columns={clientAccessPendingColumns} size="small" pagination={false} className="p-0 m-0" />
                    </Card>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                    <Card
                      title={
                        <>
                          Invited User{' '}
                          <Popover title="Invited User" content={`Invited users who haven't signed up on The Property Accountant.`}>
                            <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                          </Popover>
                        </>
                      }
                      extra={
                        <span className="Route_showmore" onClick={() => navigate('/onboarding')}>
                          View More
                        </span>
                      }
                    >
                      <Table dataSource={listData?.remainingOnboardUserData || []} columns={clientAccessPendingColumns} size="small" pagination={false} className="p-0 m-0" />
                    </Card>
                  </div>
                </div>
              </div>
            </Spin> */}
            {/* <Spin spinning={dataLoading}> */}
            <div className="row mt-5">
              <div className="col" id="clientTable">
                <Clients />
              </div>
            </div>
            {/* </Spin> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default TaxDashboard;
