import { getRequest, postRequest } from '../../../utils/apiUtils';
import { bankStatementListRequest, bankStatementListSuccess, bankStatementListFailure } from '../../reducers/slices/property/bankStatementList.slice';

export const GetBankStatementList = (userId) => {
  const queryString = userId ? `?userId=${userId}` : '';
  return async (dispatch) => {
    await dispatch(bankStatementListRequest());
    const { result, error } = await getRequest(`user/bank-statements${queryString}`);

    if (!error) {
      return await dispatch(bankStatementListSuccess(result));
    }
    return await dispatch(bankStatementListFailure(result));
  };
};

export const CreateBankStatementList = (params, userId) => {
  const queryString = userId ? `?userId=${userId}` : '';
  return async () => {
    const { result, error } = await postRequest(`user/bank-statement${queryString}`, params);

    if (!error) {
      return result;
    }
    return error;
  };
};

export const GetBankStatementPDF = (id, userId) => {
  const queryString = userId ? `?userId=${userId}` : '';
  return async () => {
    const { result, error } = await getRequest(`user//bank-statement/${id}${queryString}`);

    if (!error) {
      return result;
    }
    return error;
  };
};
