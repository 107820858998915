import { getRequest } from '../../../utils/apiUtils';
import { getPropertyListWithOutLoanRequest, getPropertyListWithOutLoanSuccess, getPropertyListWithOutLoanFailure } from '../../reducers/slices/property/getPropertyListWithOutLoan.slice';

export const GetPropertyListWithOutLoan = (id) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `user/d-properties`;
    } else {
      tempURl = `user/d-properties?userId=${id}`;
    }
    await dispatch(getPropertyListWithOutLoanRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPropertyListWithOutLoanSuccess(result));
    }
    return await dispatch(getPropertyListWithOutLoanFailure(result));
  };
};
