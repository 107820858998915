import React, { useEffect, useState } from 'react';
import { GetClinetBankDetails } from '../../../../redux/actions/client-detail/clients-tabs/bankConnection';
import { Button, Card, Empty, Modal, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import card2 from '../../../../assets/card5.png';
import bankPoint from '../../../../assets/images/bankpoint.svg';
import { CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import DownloadStatementModal from '../../BankConnection/DownloadStatementModal';

const BankConnection = ({ propertyId = null }) => {
  const dispatch = useDispatch();
  const inviteLater = localStorage.getItem('inviteLater') ? JSON.parse(localStorage.getItem('inviteLater')) : null;

  const [BankData, setBankData] = useState([]);

  const [checkLoad, setCheckLoad] = useState(false);
  const [openDownloadStaement, setOpenDownloadStaement] = useState(false);

  const referspage = true;
  const [accountData, setAccountData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const currentClient = localStorage.getItem('currentClient');
  useEffect(() => {
    setCheckLoad(true);
    dispatch(GetClinetBankDetails(currentClient, propertyId)).then((res) => {
      if (res.payload.success === true) {
        const AccountData = res.payload.result.bank_data.map((item) => {
          return { ...item, bank_name: item.bank_name, bank_id: item.bank_id, account: item.account };
        });
        setBankData(AccountData);
        setCheckLoad(false);
      }
    });
  }, [referspage, dispatch, currentClient, propertyId]);

  const ShowMore = (bankData) => {
    setIsOpen(true);
    if (bankData) {
      const data = bankData.account?.map((res) => {
        return { ...res, account_number: res.account_number, BankName: bankData.bank_name };
      });
      setAccountData(data);
    }
  };

  const onCloseDownloadStaement = () => {
    setOpenDownloadStaement(false);
  };

  return (
    <>
      <Spin spinning={checkLoad} size="large">
        <div className="row align-items-center mt-2">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
              <h5 className="m-0">Bank List</h5>
            </div>
          </div>
          {!inviteLater && (
            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
              <div className="text-center text-sm-end mb-sm-0 mb-3 mb-sm-0">
                <Button className="property_btn1_reload" icon={<DownloadOutlined />} onClick={() => setOpenDownloadStaement(true)}>
                  Download Statements
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="card_wrapper Bank_card">
          <div className="row">
            <div className="cardList bankCard">
              {checkLoad === false && BankData?.length <= 0 && (
                <Card className="box_shadow mt-3">
                  <Empty />
                </Card>
              )}
            </div>
            {BankData &&
              BankData?.map((ele, i) => {
                return (
                  <div key={i} className="col-xl-4 col-md-6 col-sm-6 col-12 mt-4">
                    <div className="card">
                      <div className="card-body">
                        <img className="circle" src={card2} alt="" />
                        <h5 className="card-title Card_BankName mb-0 fw-bold">
                          <img src={ele.bank_logo} style={{ height: 24, width: 24, borderRadius: '4px' }} className="img-fluid mr-2" alt="" />
                          {ele.bank_name}
                        </h5>
                        <div className="card_ico Card_bank d-flex justify-content-between align-items-center">
                          <div>
                            {ele?.account?.map((elem, i) =>
                              i < 3 ? (
                                <li key={elem.account_number} className="d-flex align-items-center account_text text-muted">
                                  <span>
                                    <img src={bankPoint} alt="" className="img-fluid mb-1 me-2 bank_point" />
                                    {elem.account_number}
                                  </span>
                                  <div key={crypto.randomUUID()} className=" mr-1 text-end">
                                    {elem.connect ? <CheckCircleOutlined className="mb-1 m-1 correct_connect" /> : ''}
                                  </div>
                                </li>
                              ) : (
                                ''
                              ),
                            )}
                            {/* <img src={correctSign} className="img-fluid mx-2 Correct_connect" /> */}
                          </div>
                        </div>
                        <>
                          <div className="row d-flex">
                            <div className="col-5 col-md-5 col-xl-5 col-sm-5 col-lg-5">
                              {ele?.account?.length > 3 ? (
                                // eslint-disable-next-line
                                <a className="card-text fw-normal mt-1" style={{ color: '#009797' }} onClick={() => ShowMore(ele)}>
                                  Show more
                                </a>
                              ) : (
                                <div className="card-text fw-normal mt-3 pt-1" style={{ color: '#009797' }}></div>
                              )}
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Spin>

      <Modal
        title={
          <>
            <h4 className="mb-0">{accountData[0]?.BankName} - Accounts</h4>
          </>
        }
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
      >
        {accountData?.map((elem, i) => (
          <li key={elem.account_number} className="d-flex align-items-center text-muted p-2">
            <div key={crypto.randomUUID()} className="mb-1 mr-1 text-end"></div>
            <img src={bankPoint} alt="" className="img-fluid mb-1 me-2 bank_point" />
            {elem.account_number}
            {elem.connect === false ? '' : <CheckCircleOutlined className="mb-1 m-1 correct_connect" />}
          </li>
        ))}
      </Modal>

      <DownloadStatementModal openDownloadStaement={openDownloadStaement} onCloseDownloadStaement={onCloseDownloadStaement} />
    </>
  );
};

export default BankConnection;
