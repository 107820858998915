import { SearchOutlined } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';

import { PropertyVideo } from '../../../redux/actions/property-video/property-video.action';
import videoBanner1 from '../../../assets/images/videoImage/video_banner.png';
import videoBanner2 from '../../../assets/images/videoImage/video_banner1.png';
import videoBanner3 from '../../../assets/images/videoImage/video_banner_2.png';
import videoBanner4 from '../../../assets/images/videoImage/video_banner_3.png';
import videoBanner5 from '../../../assets/images/videoImage/video_banner_4.png';

const Video = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [video, setVideo] = useState([]);
  const [onClickVideoData, setOnClickVideoData] = useState({});
  const dispatch = useDispatch();

  const [filteredList, setFilteredList] = useState(video);
  // const [propertyVideoContent, setPropertyVideoContent] = useState([])
  const propertyVideoContent = useSelector((state) => state.propertyvideoReducer);

  useEffect(() => {
    dispatch(PropertyVideo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredList(video);
  }, [video]);

  useEffect(() => {
    if (propertyVideoContent && propertyVideoContent?.data.result) {
      let dt = propertyVideoContent?.data?.result.map((elm, index) => {
        return { value: elm.video_link, title: elm.title, id: index };
      });
      setVideo(dt);
    }
  }, [propertyVideoContent]);

  const showModal = (data) => {
    setIsModalOpen(true);
    setOnClickVideoData(data);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedList = [...video];
    updatedList = updatedList.filter((item) => {
      return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredList(updatedList);
  };

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <div className="row align-items-center m-3 justify-content-between">
            <div className="col-xl-9 col-md-6 col-sm-6 col-12 ">
              <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                <h5 className="m-0">Video Items</h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-sm-6 col-6 ">
              <div className="search-header text-end search_input">
                <Input id="search-box" placeholder="Search" className="form-control p-2" onChange={filterBySearch} />
                <SearchOutlined className="search_ico" />
              </div>
            </div>
          </div>
          <div className="row align-items-center m-3 justify-content-between">
            <div className="col-xl-12 col-sm-12">
              <div className="card box_shadow">
                <ul className="list-group list-group-flush">
                  {filteredList?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <li className="list-group-item">
                          <div className="d-sm-flex align-items-center p-3" id="item-list">
                            <div key={crypto.randomUUID()} className="my-3 my-sm-0 parant_play cursor-pointer">
                              {/* <PlayCircleOutlined className="play_icon" onClick={() => showModal(item)} /> */}
                              <img
                                src={index === 0 ? videoBanner1 : index === 1 ? videoBanner2 : index === 2 ? videoBanner3 : index === 3 ? videoBanner4 : videoBanner5}
                                onClick={() => showModal(item)}
                                width="130"
                                height="100"
                                className="img-fluid Image"
                                alt="video"
                              />
                            </div>
                            <div className="ms-5">
                              <h6 className="">{item.title}</h6>
                            </div>
                          </div>
                        </li>
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal width={1000} open={isModalOpen} footer={null} onCancel={() => handleCancel()}>
        <ReactPlayer className="video_content" width={930} height={500} id="videoPlayer" playing url={onClickVideoData.value} />
        {/* <video className="video_content" autoPlay="true" width="1150" id="videoPlayer" height="auto" controls={ControlFilled} >
                    <source src={`https://www.youtube.com/embed/${onClickVideoData.value}`} type="video/mp4" />
                </video> */}
      </Modal>
    </main>
  );
};

export default Video;
