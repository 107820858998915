import { deleteRequest } from '../../../utils/apiUtils';
import { DeleteRecordExpenseFailure, DeleteRecordExpenseRequest, DeleteRecordExpenseSuccess } from '../../reducers/slices/record_expense/deleteRecordExpense.slice';

export const DeleteRecordExpense = (id, type, id1) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/transaction/delete/${id}?type=${type}`;
  } else {
    tempURl = `user/transaction/delete/${id}?type=${type}&userId=${id1}`;
  }
  return async (dispatch) => {
    await dispatch(DeleteRecordExpenseRequest());
    const { result, error } = await deleteRequest(tempURl);

    if (!error) {
      return await dispatch(DeleteRecordExpenseSuccess(result));
    }
    return await dispatch(DeleteRecordExpenseFailure(result));
  };
};
