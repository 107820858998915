import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const deletePropertySlice = createSlice({
    name: "deleteProperty",
    initialState,
    reducers: {
        deletePropertyRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        deletePropertySuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        deletePropertyFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { deletePropertyRequest, deletePropertySuccess, deletePropertyFailure } =
    deletePropertySlice.actions;

export default deletePropertySlice.reducer;
