// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-content td {
  border: none;
  border-left: 1px solid #0000001a;
}
.report-content .ant-table-wrapper .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 1px solid #0000001a;
}
.report-content .ant-table-cell {
  color: rgba(0, 0, 0, 0.88);
}
.report-content .ant-table .ant-table-container .ant-table-header table thead tr th:last-of-type {
  border-right: 0 !important;
}
.report-content .ant-table .ant-table-container .ant-table-header table thead tr th:last-of-type::before {
  background-color: transparent !important;
}
.report-content .ant-table .ant-table-container .ant-table-header .ant-table-thead .ant-table-cell-scrollbar {
  background: #f2f8f8;
  border: 0;
}
.report-content .ant-table .ant-table-footer {
  border: 1px solid #0000001a;
}
.report-content .cursor-pointer {
  cursor: pointer;
  text-decoration: underline;
}
.report-content .cur-pointer {
  cursor: pointer;
}
.transmeta td {
  border: none !important;
}
#reportFilterForm .ant-form-item .ant-form-item-label > label {
  font-weight: 600;
}
.loan-report th:has(span) {
  text-align: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/reports/index.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,gCAAA;AAAJ;AAHA;EAMI,kCAAA;AAAJ;AANA;EAUI,0BAAA;AADJ;AAcgB;EACE,0BAAA;AAZlB;AAakB;EACE,wCAAA;AAXpB;AAfA;EAmCY,mBAAA;EACA,SAAA;AAjBZ;AAnBA;EAgDM,2BAAA;AA1BN;AAtBA;EAqDI,eAAA;EACA,0BAAA;AA5BJ;AA1BA;EA0DI,eAAA;AA7BJ;AAiCA;EAEI,uBAAA;AAhCJ;AAmCA;EAEI,gBAAA;AAlCJ;AAsCA;EAEI,6BAAA;AArCJ","sourcesContent":[".report-content {\n  td {\n    border: none;\n    border-left: 1px solid #0000001a;\n  }\n  .ant-table-wrapper .ant-table-thead > tr:not(:last-child) > th[colspan] {\n    border-bottom: 1px solid #0000001a;\n  }\n\n  .ant-table-cell {\n    color: rgba(0, 0, 0, 0.88);\n  }\n\n  .ant-table table {\n    // width: auto !important;\n  }\n  .ant-table {\n    .ant-table-container {\n      .ant-table-header {\n        table {\n          thead {\n            tr {\n              th {\n                &:last-of-type {\n                  border-right: 0 !important;\n                  &::before {\n                    background-color: transparent !important;\n                  }\n                }\n              }\n            }\n          }\n        }\n        .ant-table-thead {\n          .ant-table-cell-scrollbar {\n            background: #f2f8f8;\n            border: 0;\n          }\n        }\n      }\n    }\n    .ant-table-body {\n      // tbody {\n      // display: inline-block;\n      // overflow: scroll;\n      // }\n    }\n    .ant-table-footer {\n      border: 1px solid #0000001a;\n    }\n  }\n\n  .cursor-pointer {\n    cursor: pointer;\n    text-decoration: underline;\n  }\n\n  .cur-pointer {\n    cursor: pointer;\n  }\n}\n\n.transmeta {\n  td {\n    border: none !important;\n  }\n}\n#reportFilterForm {\n  .ant-form-item .ant-form-item-label > label {\n    font-weight: 600;\n  }\n}\n\n.loan-report {\n  th:has(span) {\n    text-align: center !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
