import React, { useCallback, useEffect, useState } from 'react';
import '../../../assets/less/index.less';
import { Alert, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Select, Spin } from 'antd';
import { GetPropertyListWithOutLoan } from '../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { GetCategoryList } from '../../../redux/actions/category/getCategoryList.action';
import { GetRentalSummaryById } from '../../../redux/actions/rental_summary/getRentalSummaryById.action';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import CustomInput from '../../common/index';
import { DeleteSettlementList } from '../../../redux/actions/rental_summary/deleteRentalSummary.action';
import dayjs from 'dayjs';
import { EditRecordExpense } from '../../../redux/actions/record_expense/editRecordExpense.action';
import { PropertyType, UserTypes } from '../../common/userTypes';
import { DeleteRecordExpense } from '../../../redux/actions/record_expense/deleteRecordExpense.action';
import { GoBackWithClick } from '../../common/GoBack';
import { getPropertyTypeLabel } from '../../../utils/ConstLabel';
import { getLockYearsList } from '../../../redux/actions/AuditTrail/getAuditTrailList.action';
import { getErrorMessageForLockedYear, getErrorNoteForLockedYear, getFinancialYearRanges } from '../../../utils';
const { Option } = Select;
const text = 'Are you sure you want to discard?';

export default function RentalSummary({ tid = null, fromSameSite, TransectionType, isTaxAccountant, onUpdateTransaction }) {
  const [modal, contextHolder] = Modal.useModal();
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);
  let { id } = useParams();
  id = tid || id || null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rentalSummaryForm] = Form.useForm();
  const currentClient = localStorage.getItem('currentClient');

  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [propertyData, setPropertyData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [rSummaryData, setRSummaryData] = useState({});
  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [costCategorydata, setCostCategorydata] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [lockErrorMessage, setLockErrorMessage] = useState('');
  const [lockDisabled, setLockDisabled] = useState(false);

  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);
  const categoryDta = useSelector((state) => state.getCategoryListReducer);
  const rSummaryDta = useSelector((state) => state.getRentalSummaryByIdReducer);

  const navigateFunction = () => {
    if (localStorage.getItem('transactionFilters')) {
      localStorage.removeItem('transactionFilters');
    }
    admin1?.user_type === UserTypes.TAXACCOUTANT ? navigate('/clients/client/subscription/checkout') : navigate('/subscription/checkout');
  };

  const refreshPage = useCallback(() => {
    dispatch(GetRentalSummaryById(id, TransectionType, currentClient));
    dispatch(GetPropertyListWithOutLoan(currentClient));
    dispatch(GetCategoryList());
    getLockYears();
  }, [dispatch, currentClient, id, TransectionType]);

  useEffect(() => {
    setLoading(true);
    refreshPage();
  }, [refreshPage]);

  useEffect(() => {
    if (propertyDta.isLoading && categoryDta.isLoading && rSummaryDta.isLoading) {
      setLoading(true);
      setDataLoading(true);
    }
    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((elm) => {
        return {
          value: elm.id,
          label: elm.street_number ? elm.street_number + (elm.street_name ? ' ' + elm.street_name : '') : '',
          propertyType: elm.property_type,
        };
      });

      setPropertyData(dt);
    }
    if (categoryDta && categoryDta.data.result && categoryDta.error === false) {
      if (categoryDta.data.result) {
        let dt = categoryDta.data.result.map((elm) => {
          return {
            value: elm.id,
            label: elm.category_name,
            income: elm.expense_type === 'Revenue' && elm.transaction_type === 'In' ? true : false,
            expense: elm.expense_type === 'Revenue' && elm.transaction_type === 'Out' ? true : false,
            cost: elm.expense_type === 'Capital' ? true : false,
          };
        });
        setCategoryData(dt);
        let Incom = dt.filter((item) => ![false].includes(item.income));
        setIncomeCategorydata(Incom);
        let Expense = dt.filter((item) => ![false].includes(item.expense));
        setExpenseCategorydata(Expense);
        let Cost = dt.filter((item) => ![false].includes(item.cost));
        setCostCategorydata(Cost);
        let dtCat = categoryDta.data.result.filter((elm) => [1, 8].includes(elm.id));
        let dtFinal = dtCat.map((elm) => {
          return { category_id: elm.id, amount: '' };
        });
        rentalSummaryForm.setFieldsValue({ categories: dtFinal || [] });
      }
    }
    if (rSummaryDta && rSummaryDta.data.result && rSummaryDta.error === false && !rSummaryDta.isLoading) {
      if (rSummaryDta.data.result) {
        setRSummaryData(rSummaryDta.data.result);
        if (rSummaryDta.data.result.property_id && rSummaryDta.data.result.property_id !== null && rSummaryDta.data.result.property_id !== undefined) {
          rentalSummaryForm.setFieldsValue({ property_id: Number(rSummaryDta.data.result.property_id) || '' });
        }
        if (rSummaryDta.data.result.categories && rSummaryDta.data.result.categories.length !== 0) {
          let dtFinal = rSummaryDta.data.result.categories.map((elm) => {
            return { category_id: elm.category_id, amount: elm.amount, id: elm.id };
          });
          rentalSummaryForm.setFieldsValue({ categories: dtFinal || [] });
        }
        const PROPERTYTYPE = propertyDta?.data?.result?.find((x) => x.id === Number(rSummaryDta?.data?.result?.property_id));
        rentalSummaryForm.setFieldsValue({ rent_start_date: rSummaryDta?.data?.result?.rent_start_date ? dayjs(rSummaryDta?.data?.result?.rent_start_date) : '' });
        rentalSummaryForm.setFieldsValue({ rent_end_date: rSummaryDta?.data?.result?.rent_end_date ? dayjs(rSummaryDta?.data?.result?.rent_end_date) : '' });
        rentalSummaryForm.setFieldsValue({
          property_type: getPropertyTypeLabel(PROPERTYTYPE?.property_type),
        });
        rentalSummaryForm.setFieldsValue({ transaction_date: rSummaryDta?.data?.result?.transaction_date ? dayjs(rSummaryDta?.data?.result?.transaction_date) : '' });
        setLoading(false);
        setDataLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDta, categoryDta, rSummaryDta]);

  const handleFinish = (values) => {
    const sendCategory = values?.categories.map((elm) => {
      return { categoryId: elm.category_id, amount: elm.amount, id: elm.id };
    });
    if (sendCategory.length !== 0) {
      try {
        setLoading(true);
        values = {
          propertyId: values.property_id,
          categories: sendCategory,
          rentEndDate: values.rent_end_date.format('YYYY-MM-DD'),
          rentStartDate: values.rent_start_date.format('YYYY-MM-DD'),
          transactionDate: values.transaction_date.format('YYYY-MM-DD'),
        };

        dispatch(EditRecordExpense(values, id, TransectionType, currentClient)).then((data) => {
          if (data.payload.success === true) {
            notification.success({
              message: 'Success',
              description: data.payload.message,
            });
            rentalSummaryForm.resetFields();
            setLoading(false);
            if (!tid) {
              admin1?.user_type === UserTypes.USER ? navigate(-1) : navigate(-1);
            } else {
              onUpdateTransaction();
            }
          } else if (data?.payload?.success === false && data?.payload?.result?.subscription === false) {
            if (admin1?.user_type === UserTypes.USER || admin1?.user_type === UserTypes.TAXACCOUTANT) {
              modal.confirm({
                title: data?.payload?.message,
                icon: <ExclamationCircleOutlined />,
                okText: 'Subscribe',
                cancelText: 'Cancel',
                onOk: () => {
                  navigateFunction();
                },
                onCancel: () => {
                  setLoading(false);
                },
              });
            } else {
              modal.confirm({
                title: 'Kindly ask your client to subscribe for this property.',
                icon: <ExclamationCircleOutlined />,
                cancelText: 'Cancel',
                onOk: () => {
                  setLoading(false);
                },
                onCancel: () => {
                  setLoading(false);
                },
              });
            }
          } else {
            notification.error({
              message: 'Failure',
              description: data.payload.message || 'Something went wrong!',
            });
            setLoading(false);
          }
        });
      } catch (error) {}
    } else {
      notification.error({
        message: 'Failure',
        description: 'Please Add Category !',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (financialYears.length > 0 && rSummaryData && rSummaryData.id) {
      const ranges = getFinancialYearRanges(financialYears);
      const date = dayjs(rSummaryData.transaction_date);
      let lock = false;
      for (let range of ranges) {
        if (range.locked && date && date.isBetween(range.start, range.end, 'day', '[]')) {
          lock = true;
          const errorMessage = getErrorNoteForLockedYear(date, range.locked_user_type, range.locked_by, admin1?.id);
          setLockErrorMessage(errorMessage);
          break;
        }
      }
      if (lock) {
        setLockDisabled(true);
      } else {
        setLockDisabled(false);
      }
    }
  }, [rSummaryData, financialYears]);

  const getLockYears = () => {
    dispatch(getLockYearsList(currentClient)).then((data) => {
      if (data.payload.success) {
        setFinancialYears(data.payload.result || []);
      }
    });
  };

  const discardRecord = (id) => {
    setLoading(true);
    dispatch(DeleteSettlementList(id, currentClient)).then((response) => {
      if (response?.payload?.success) {
        notification.success({
          message: 'Success',
          description: response.payload.message || 'Rental summary deleted!',
        });
        navigate('/');
        setLoading(false);
      } else if (response?.payload?.success === false && response?.payload?.result?.subscription === false) {
        if (admin1?.user_type === UserTypes.USER || admin1?.user_type === UserTypes.TAXACCOUTANT) {
          modal.confirm({
            title: response?.payload?.message,
            icon: <ExclamationCircleOutlined />,
            okText: 'Subscribe',
            cancelText: 'Cancel',
            onOk: () => {
              navigateFunction();
            },
          });
        } else {
          modal.confirm({
            title: 'Kindly ask your client to subscribe for this property.',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Cancel',
          });
        }
      } else {
        notification.error({
          message: 'Failure',
          description: response?.payload?.message || 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };

  const deleteRecord = (id) => {
    dispatch(DeleteRecordExpense(id, TransectionType, currentClient)).then((response) => {
      if (response?.payload?.success) {
        notification.success({
          message: 'Success',
          description: response.payload.message || 'Record Rent Summary deleted!',
        });
        if (!tid) {
          admin1?.user_type === UserTypes.USER ? navigate(-1) : navigate(-1);
        } else {
          onUpdateTransaction();
        }
        setLoading(false);
      } else if (response?.payload?.success === false && response?.payload?.result?.subscription === false) {
        if (admin1?.user_type === UserTypes.USER || admin1?.user_type === UserTypes.TAXACCOUTANT) {
          modal.confirm({
            title: response?.payload?.message,
            icon: <ExclamationCircleOutlined />,
            okText: 'Subscribe',
            cancelText: 'Cancel',
            onOk: () => {
              navigateFunction();
            },
          });
        } else {
          modal.confirm({
            title: 'Kindly ask your client to subscribe for this property.',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Cancel',
          });
        }
      } else {
        notification.error({
          message: 'Failure',
          description: response?.payload?.message || 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };

  const validateDate = (date) => {
    const ranges = getFinancialYearRanges(financialYears);
    for (let range of ranges) {
      if (range.locked && date && date.isBetween(range.start, range.end, 'day', '[]')) {
        return getErrorMessageForLockedYear(date, range.locked_user_type, range.locked_by, admin1?.id);
      }
    }
    return null;
  };

  const changeProperty = (e, data) => {
    rentalSummaryForm.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.propertyType),
    });
  };

  return (
    <Spin size="large" spinning={loading}>
      <main className="page-content">
        <div className="container-fluid">
          <div className="layout-specing">
            {!tid && (
              <Row className="align-items-center">
                <GoBackWithClick fallbackPath={isTaxAccountant ? '/clients/client/transaction-details' : '/transactions'} fromSameSite={fromSameSite} />
                <h5 className="m-0">Rental Summary</h5>
              </Row>
            )}

            <div className="faq_wrapper1">
              <Form disabled={lockDisabled} name="addProductForm" form={rentalSummaryForm} layout="vertical" onFinish={handleFinish} autoComplete="off" wrapperCol={{ span: 24 }}>
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="card">
                      <div className="card-body">
                        <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                          <div className="responsive-wrapper responsive-wrapper-wxh-572x612">
                            {!rSummaryDta.isLoading && !dataLoading && <iframe title="nothing" src={rSummaryData?.receipt_path}></iframe>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Select Property"
                                  name="property_id"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Property!',
                                    },
                                  ]}
                                >
                                  <Select
                                    style={{ width: '100%', textAlign: 'left' }}
                                    placeholder="Select Property"
                                    showSearch
                                    options={propertyData}
                                    onChange={changeProperty}
                                    disabled={propertyData?.propertyType === PropertyType.OWNER_OCCUPIED_PROPERTY || lockDisabled}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                  >
                                    <Option value="" key={crypto.randomUUID()}>
                                      - - - Select Property - - -
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Statement Date"
                                  name="transaction_date"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Statement Date!',
                                    },
                                    () => ({
                                      validator(_, value) {
                                        const errorMessage = validateDate(value);
                                        if (!value || !errorMessage) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(errorMessage));
                                      },
                                    }),
                                  ]}
                                >
                                  <DatePicker format="DD-MM-YYYY" style={{ background: 'transparent', width: '100%' }} className="form-control" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Start Date"
                                  name="rent_start_date"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Start Date!',
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ background: 'transparent', width: '100%' }}
                                    className="form-control"
                                    disabledDate={(date) => {
                                      return date && date > dayjs(rentalSummaryForm.getFieldValue('rent_end_date'));
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="End Date"
                                  name="rent_end_date"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select End Date!',
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ background: 'transparent', width: '100%' }}
                                    className="form-control"
                                    disabledDate={(date) => {
                                      return date && date < dayjs(rentalSummaryForm.getFieldValue('rent_start_date'));
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item label="Property Type" name="property_type">
                                  <Input className="form-control p-2" style={{ color: 'black' }} disabled={true} placeholder="property Type" type="text" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className="row justify-content-center mt-1 rental_wrapper">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-header d-flex justify-content-between p-4">
                                <h6 className="m-0">Details</h6>
                                <h6 className="m-0">Amount</h6>
                              </div>
                              <div className="card-body p-4" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                                <Form.List name="categories">
                                  {(fields, { add, remove }) => (
                                    <div className="col-md-12 p-0">
                                      {fields.map(({ key, name, ...restField }) => (
                                        <Row gutter={24} key={key}>
                                          <Col span={12}>
                                            <Form.Item
                                              className="p-0 m-0"
                                              {...restField}
                                              name={[name, 'category_id']}
                                              style={{ width: '100%' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please select Category',
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder="Select Category"
                                                style={{
                                                  width: '100%',
                                                }}
                                                defaultActiveFirstOption={true}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                                options={[
                                                  {
                                                    label: 'Income',
                                                    options: incomeCategorydata,
                                                  },
                                                  {
                                                    label: 'Expense',
                                                    options: expenseCategorydata,
                                                  },
                                                  {
                                                    label: 'Cost',
                                                    options: costCategorydata,
                                                  },
                                                ]}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={2}>
                                            <span></span>
                                          </Col>
                                          <Col span={8}>
                                            <Form.Item
                                              className="p-0 m-0"
                                              {...restField}
                                              style={{ width: '100%' }}
                                              name={[name, 'amount']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please enter Amount',
                                                },
                                              ]}
                                            >
                                              <CustomInput
                                                style={{
                                                  width: '100%',
                                                }}
                                                inputtype="number"
                                                className="form-control"
                                                placeholder="Amount"
                                                allowdecimal="true"
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={2}>
                                            {fields.length > 1 ? (
                                              <Form.Item className="delete_ico_hide">
                                                <Popconfirm
                                                  title="Are you sure! You want to delete?"
                                                  placement="topLeft"
                                                  onConfirm={() => {
                                                    remove(name);
                                                  }}
                                                >
                                                  <DeleteOutlined
                                                    style={{
                                                      fontSize: '1.3rem',
                                                      color: 'red',
                                                    }}
                                                  />
                                                </Popconfirm>
                                              </Form.Item>
                                            ) : null}
                                          </Col>
                                        </Row>
                                      ))}
                                      {categoryData.length === rentalSummaryForm.getFieldValue('categories')?.length || lockDisabled ? (
                                        ''
                                      ) : (
                                        <div className="normal_button text-center" style={{ padding: '1.4em' }}>
                                          <button size="large" type="button" className="btn_submit" onClick={() => add()}>
                                            + Add
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Form.List>
                              </div>
                            </div>
                          </div>
                          {!lockDisabled && (
                            <div className="col-12 mt-4 d-flex justify-content-end">
                              {rSummaryData?.property_id === null ? (
                                <div className="justify-content-start text-start me-3">
                                  <Popconfirm placement="left" className="btn_submit" title={text} onConfirm={() => discardRecord(id)} okText="Yes" cancelText="No">
                                    <button type="button" className="btn_submit">
                                      Discard
                                    </button>
                                  </Popconfirm>
                                </div>
                              ) : (
                                <div className="justify-content-start text-start me-3">
                                  <Popconfirm placement="left" className="btn_submit" title={text} onConfirm={() => deleteRecord(id)} okText="Yes" cancelText="No">
                                    <button type="button" className="btn_submit">
                                      Delete
                                    </button>
                                  </Popconfirm>
                                </div>
                              )}
                              <div className="justify-content-end text-end">
                                <button disabled={lockDisabled} type="submit" className="btn_submit btn-primary px-5">
                                  Save
                                </button>
                              </div>
                            </div>
                          )}
                          {lockDisabled && (
                            <div className="col-12 mt-4">
                              <Alert
                                message={
                                  <>
                                    <b>Note: </b>
                                    {lockErrorMessage}{' '}
                                  </>
                                }
                                type="warning"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>

            {/* Error message of subscription */}
            {contextHolder}
          </div>
        </div>
      </main>
    </Spin>
  );
}
