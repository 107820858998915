import React from 'react';
import '../../../assets/less/index.less';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const Faqs = () => {
  return (
    <>
      <div className="container-fluid bg-dash-back">
        <div className="page-content">
          <div className="layout-specing">
            <div className="row align-items-center mt-1">
              <div className="col-12">
                <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                  <h5 className="m-0">FAQ’s</h5>
                </div>
              </div>
            </div>

            <div className="faq_wrapper">
              <div className="row">
                <div className="col-xl-6 col-md-6 col-12 mt-4">
                  <div className="card box_shadow">
                    <div className="card-body">
                      <h5 className="card-title mb-4">Account Setup</h5>
                      <div className="accordion" id="buyingquestions2">
                        <div className="accordion-item rounded mt-4">
                          <Collapse bordered={false} defaultActiveKey={1} className="collapse_ant collapsed" accordion>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">How do I Setup my account?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="1"
                            >
                              <div className="accordion-body text-muted">
                                <p className="answer my-2">We've prepared a detailed guide to assist you in setting up your account</p>
                                <p className="answer my-2">
                                  1. You can either (
                                    <a href="https://tpa-prod-assets-bucket.s3.ap-southeast-2.amazonaws.com/thepropertyaccountantSOP.pdf" target="_blank" rel="noopener noreferrer">
                                    view
                                  </a>
                                  ) the PDF for a comprehensive written walkthrough of the New Account Setup process. or{' '}
                                </p>
                                <p className="answer my-2">
                                  2. You can simply (
                                    <a href="https://youtu.be/9WcVCONKmlc" target="_blank" rel="noopener noreferrer">
                                    click here
                                  </a>
                                  ) to watch a video tutorial on how to do it.
                                </p>
                                <p className="answer my-2">Choose the option that suits you best!</p>
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <div className="card-body">
                      <h5 className="card-title mb-4">Data and security</h5>
                      <div className="accordion" id="buyingquestions">
                        <div className="accordion-item rounded">
                          <Collapse bordered={false} className="collapse_ant collapsed" accordion>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Will I receive marketing emails from Thepropertyaccountant?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="1"
                            >
                              <div className="accordion-body text-muted">
                                With your permission we will send you marketing and training emails time-to-time. These will primarily be informing you about upcoming events, new products and special
                                offers that could benefit you.
                              </div>
                              <div className="accordion-body text-muted">
                                You can unsubscribe at any time by following the instructions in your latest marketing email. When it comes to marketing communications, you can ask us not to send you
                                these at any time – just follow the unsubscribe instructions contained in the marketing communication.
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Is Thepropertyaccountant GDPR compliant?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="2"
                            >
                              <div className="accordion-body text-muted">Yes, Thepropertyaccountant is GDPR complaint</div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Where is my data held?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="3"
                            >
                              <div className="accordion-body text-muted">Your data will be held on AWS data centre located in Sydney, Australia</div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">What happens to my data if I cancel my subscription?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="4"
                            >
                              <div className="accordion-body text-muted">
                                Your personal data will be removed from system within 3 month but your data linked to any payment transaction with thepropertyaccount will be with us.
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Can I download a copy of my data?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="5"
                            >
                              <div className="accordion-body text-muted">Yes, you can ask admin in order to download copy of your data</div>
                            </Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <div className="card-body">
                      <h5 className="card-title mb-4">Account settings</h5>
                      <div className="accordion" id="buyingquestions2">
                        <div className="accordion-item rounded mt-4">
                          <Collapse bordered={false} className="collapse_ant collapsed" accordion>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">How do I reset my password or recover my user ID?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="1"
                            >
                              <div className="accordion-body text-muted">
                                To reset password, You have forgot password option in login screen and to recover your id you can contact admin (contact@thepropertyaccountant.io) and can submit the
                                valid proofs in order to recover your User ID
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Can I upgrade from trial to subscription in the app?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="2"
                            >
                              <div className="accordion-body text-muted">No , you will need to upgrade on our web portal only</div>
                            </Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-md-6 col-12 mt-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title mb-4">System Requirements</h5>
                      <div className="accordion" id="buyingquestion">
                        <div className="accordion-item ">
                          <Collapse bordered={false} className="collapse_ant collapsed" accordion>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <button className="accordion-button border-0 bg-light">What happens at the end of my free trial?</button>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="1"
                            >
                              <div className="accordion-body text-muted">
                                If you choose to continue using thePropertyAccountant after your free trial, you will be asked to add your billing information, and will be billed monthly from that
                                point. If you choose not to continue using thePropertyAccountant, you may simply don’t add the billing information.
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Does my free trial include access to all features?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="2"
                            >
                              <div className="accordion-body text-muted">Yes, it will include all features of thepropertyaccountant apps and web portal</div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Does thepropertyaccountant provide onsite setup assistance?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="3"
                            >
                              <div className="accordion-body text-muted">
                                The set-up is very simple and should not take more than 10-15 minutes for a single property. You can set up your property profile on app or via our web portal. We will
                                recommend using web portal as this will be easier and quicker.
                              </div>
                              <div className="accordion-body text-muted">
                                If you still find it hard, then please contact us on <a href="mailto: contact@thepropertyaccountant.io">contact@thepropertyaccountant.io</a> and will help you set up
                                everything.
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Are there any hidden costs?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="4"
                            >
                              <div className="accordion-body text-muted">No. We’re up front about pricing, and have one plan with all features</div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">How do I subscribe if I decide that Thepropertyaccountant is right for me after free trial period?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="5"
                            >
                              <div className="accordion-body text-muted">
                                It’s easy to upgrade from a trial to a subscription with just a few clicks on our web portal. Simply add your card ( credit or debit) details for the payment. No. We’re
                                up front about pricing, and have one plan with all features
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">How do I cancel Thepropertyaccountant if I decide it isn't right for me?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="6"
                            >
                              <div className="accordion-body text-muted">You can cancel your subscription anytime and we will not charge you from next month.</div>
                              <div className="accordion-body text-muted">
                                After you've cancelled your trial subscription you won't be able to access so we recommend you <b>export your reports and data out of “thepropertyaccountant” first.</b>
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Is there a minimum subscription period?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="7"
                            >
                              <div className="accordion-body text-muted">No, you can cancel your subscription at any time.</div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Can I pay the subscription annually?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="8"
                            >
                              <div className="accordion-body text-muted">No, your ‘thepropertyAccountant’ subscription will be charged in advance on a monthly basis.</div>
                              <div className="accordion-body text-muted">
                                Thepropertyaccountant will add your invoice to your expenses relating to your property. Therefore, your costs of subscription will be tax deductible
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Adding multiple properties to my ‘thePropertyAccountant’ account?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="9"
                            >
                              <div className="accordion-body text-muted">
                                You can add as many properties as you like in your ‘thePropertyAccountant’ account. Our fees will be charges per property per month.
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-md-6 col-12 mt-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title mb-4">Bank Integration </h5>
                      <div className="accordion" id="buyingquestions1">
                        <div className="accordion-item rounded">
                          <Collapse bordered={false} className="collapse_ant " accordion>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4"></div>
                                  <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button border-0 bg-light">What bank integration do you need?</button>
                                  </h2>
                                </>
                              }
                              className="accordion-header"
                              key="1"
                            >
                              <div className="accordion-body text-muted">
                                We will only link your loan accounts for investment property to our property profile. The key information we pull from your loan accounts is as below
                                <ul>
                                  <li>Loan Account balance</li>
                                  <li>Interest costs </li>
                                  <li>Monthly or weekly loan repayments </li>
                                  <li>Bank Charges</li>
                                </ul>
                                We do not need any integration with your transaction accounts or credit card account. We do not need to collect very day expenses data
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">How will I integrate bank data to my “thePropertyAccountant” profile?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="2"
                            >
                              <div className="accordion-body text-muted">
                                You will provide the loan account details i.e. BSB and Account Number”. We will then link you to the Open Banking platform we have integrated with.
                              </div>
                              <div className="accordion-body text-muted">
                                On the prompts, you will select the bank where you have the loan accounts. Bank will send you one-time password on your mobile which you will enter to give us access to
                                your account data. We will only get 12 months access every time.
                              </div>
                              <div className="accordion-body text-muted text-decoration-underline">We will never ask you for your bank password.</div>
                              <div className="accordion-body text-muted">
                                You will clearly see what information we will receive and store, you will select the account details you need to give access
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">Do you use the Web Connector or Web Scrapping for data integration?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="3"
                            >
                              <div className="accordion-body text-muted">No, we do not use Web Connector or Web Scrapping</div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light">What’s the difference between Web connectors and Open Banking connectors?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="4"
                            >
                              <div className="accordion-body text-muted">
                                Web connectors- are the standard way to obtain data from institutions. Globally and even locally for connecting to non-ADIs that are not yet part of CDR, this is the
                                most popular method used to retrieve the data.
                                <span className="text-decoration-underline"> Web connectors</span> typically rely on the consumer to disclose their login credentials i.e. user id and password for the
                                bank account in order to set up a connection to an institution.
                              </div>
                              <div className="accordion-body text-muted">
                                <span className="text-decoration-underline">Open Banking</span> on the other hand does not require your login id an password, and instead, the login credentials are
                                supplied directly to the bank. Open Banking promises to be more reliable, safer and faster.
                              </div>
                              <div className="accordion-body text-muted">
                                We value security of your banking id and password very seriously. That is why we have integrated our platform to open banking connector rather than web connectors
                              </div>
                            </Panel>
                            <Panel
                              header={
                                <>
                                  <div className="accordion-item rounded mt-4">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button border-0 bg-light arrow_ant">How do I cancel access to my data?</button>
                                    </h2>
                                  </div>
                                </>
                              }
                              className="accordion-header"
                              key="5"
                            >
                              <div className="accordion-body text-muted">
                                There are two ways to stop giving access to your data: <br />
                                1. Go to the regulated app or website, and withdraw your consent directly with them <br />
                                2. Contact your bank or building society to let them know you no longer want the regulated <br />
                                app or website to have access to your information
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 mt-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h5 className="card-title mb-4">Can't find what you are looking for?</h5>
                                            <p className="pera_1 mx-auto">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                                1500s.
                                            </p>
                                            <div className="row justify-content-center">
                                                <div className="col-xl-4 col-md-6 col-10">
                                                    <div className="input_email d-flex">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Email address"
                                                        />
                                                        <a href="/" className="send_icon">
                                                            <SendIcon />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
