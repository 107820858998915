import { getRequest } from "../../../utils/apiUtils";
import {
    stateListRequest,
    stateListSuccess,
    stateListFailure,
} from "../../reducers/slices/property/getStateList.slice";

export const GetStateList = () => {
    return async (dispatch) => {
        await dispatch(stateListRequest());
        const { result, error } = await getRequest(`/user/states`);

        if (!error) {
            return await dispatch(stateListSuccess(result));
        }
        return await dispatch(stateListFailure(result));
    };
};
