import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const signUpSlice = createSlice({
    name: "UserSignUp",
    initialState,
    reducers: {
        signUpSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        signUpFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { signUpSuccess, signUpFailure } = signUpSlice.actions;

export default signUpSlice.reducer;
