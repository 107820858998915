import { fetchAuthSession } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { verifyUser } from '../../../redux/actions/login/verify.action';
import { ChannelPartnerType, UserTypes } from '../../common/userTypes';
import { notification, Spin } from 'antd';
import { useNavigate } from 'react-router';
import watsappIcon from '../../../assets/images/watsappIcon.svg';

const SignUpHOC = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user_data = useSelector((state) => state.verifyUserReducer);

  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;
  const user_info = JSON.parse(localStorage.getItem('admin'));

  const verifyUserUsingJwtToken = async () => {
    try {
      setIsLoading(true);
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const userType = user_info?.user_type === 'tax-accountant' ? 2 : user_info?.user_type === 'channel-partner' ? 3 : 1;
      const values = { token: accessToken.toString(), userType };
      const res = await dispatch(verifyUser(values));

      setIsLoading(false);

      if (res?.payload?.success === true) {
        const userTypes = [UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.USER, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER, UserTypes.ADMIN_SUB_TAXACCOUNTANT];
        if (userTypes.includes(res.payload.result.user_type)) {
          const { result } = res.payload;
          localStorage.setItem('admin', JSON.stringify(result));
          localStorage.setItem('token', result.accessToken);
          if (result.phone_verified === false) {
            navigate('/mobile-verification', { replace: true });
          } else if (
            UserTypes.USER !== result.user_type &&
            ![UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(result.user_type) &&
            result.company_name === null
          ) {
            navigate('/complete-profile', { replace: true });
          } else if (
            (result.user_type === UserTypes.CHANNEL_PARTNER && result.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) ||
            result.user_type === UserTypes.TAXACCOUTANT ||
            result.user_type === UserTypes.ADMIN_SUB_TAXACCOUNTANT ||
            result.user_type === UserTypes.SUB_TAXACCOUTANT
          ) {
            navigate('/dashboard', { replace: true });
          } else {
            navigate('/', { replace: true });
          }
        } else {
          localStorage.clear();
          window.open(landing_url, '_self');
        }
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.message || 'Something went wrong!',
        });
        localStorage.clear();
        window.open(landing_url, '_self');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (user_data.isLoading === false && Array.isArray(user_data.data) && user_data.data.length === 0) {
      if (user_info) {
        verifyUserUsingJwtToken();
      }
    } else if (user_data.isLoading === false && user_data.data?.result) {
      // write logic of redirect based on user type
    }
  }, [user_data]);

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <a href="https://wa.me/+61493767832?text=I%20am%20interested%20in%20Property%20Accountant" className="whats_icon" target="_blank" rel="noreferrer">
          <img src={watsappIcon} className="img-fluid" alt="whatsapp icon" />
        </a>
      </div>
      <Spin spinning={isLoading}>{children}</Spin>
    </main>
  );
};

export default SignUpHOC;
