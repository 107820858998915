import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const ManageConsentSlice = createSlice({
    name: "SendDeleteAccountVerify",
    initialState,
    reducers: {
        manageConsentRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        manageConsentSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        manageConsentFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { manageConsentRequest, manageConsentSuccess, manageConsentFailure } = ManageConsentSlice.actions;

export default ManageConsentSlice.reducer;
