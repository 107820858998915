import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const deleteSubuserSlice = createSlice({
    name: "deleteSubuser",
    initialState,
    reducers: {
        deleteSubuserRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        deleteSubuserSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        deleteSubuserFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { deleteSubuserRequest, deleteSubuserSuccess, deleteSubuserFailure } =
    deleteSubuserSlice.actions;

export default deleteSubuserSlice.reducer;
