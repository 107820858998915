import { Alert, Badge, Card, Descriptions, List, Modal, Spin, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBankDetailsAsync,
  getCardDataAsync,
  ResetBankDetails,
  selectBankDataLoading,
  selectBankDetails,
  selectCardData,
  selectViewDetailsLoading,
} from '../../../redux/reducers/slices/BankConnection/BankConnectionSlice';

import dayjs from 'dayjs';
import './index.less';
import { BankAccountTypes } from '../../common/userTypes';

const PropertyBanks = ({ propertyId }) => {
  const dispatch = useDispatch();

  const cardDataSelector = useSelector(selectCardData);
  const cardData = cardDataSelector?.bank_data || [];

  const accountDetails = useSelector(selectBankDetails);
  const accountDetailsLoading = useSelector(selectBankDataLoading);
  const viewDetailsLoading = useSelector(selectViewDetailsLoading);
  const [isBankInfoModalOpen, setIsBankInfoModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getCardDataAsync(propertyId));
  }, [dispatch]);

  const viewBankDetails = (ele, item) => {
    const { id } = item;
    dispatch(getBankDetailsAsync(id));
    setIsBankInfoModalOpen(true);
  };

  const handleDetailsModalClose = () => {
    setIsBankInfoModalOpen(false);
    dispatch(ResetBankDetails());
  };

  return (
    <>
      <div className="">
        <div className="card_wrapper Bank_card">
          <Spin spinning={accountDetailsLoading} size="large">
            <div className="row">
              <div className="cardList bankCard mb-4">
                {cardData?.map((ele, i) => {
                  return (
                    <Card
                      className={'projectList box_shadow'}
                      style={{ marginBottom: 24 }}
                      styles={{
                        body: {
                          padding: '0 8px 0 8px',
                        },
                      }}
                      key={i}
                      title={
                        <>
                          <img src={ele.bank_logo} style={{ height: 24, width: 24, borderRadius: '4px' }} className="img-fluid" alt="" />
                          &nbsp; {ele.bank_name} &nbsp;
                          {ele.display_type && ele.ba_type && (
                            <span>
                              (
                              {
                                Object.entries(BankAccountTypes)
                                  .find(([key, val]) => val === Number(ele.ba_type))?.[0]
                                  .split(' ')[0]
                              }
                              )
                            </span>
                          )}
                        </>
                      }
                      bordered={false}
                    >
                      <List
                        rowKey="id"
                        grid={{
                          gutter: 16,
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 3,
                          xl: 3,
                          xxl: 4,
                        }}
                        dataSource={ele.account}
                        renderItem={(item) => {
                          if (item && item) {
                            return (
                              <Card.Grid className={'projectGrid'} key={item.id}>
                                <Card
                                  styles={{
                                    body: {
                                      padding: 0,
                                    },
                                  }}
                                  bordered={false}
                                  actions={[
                                    <>
                                      {item.connect ? (
                                        <>
                                          <Badge size={'small'} status="success" styles={{ fontSize: '40px' }} /> <span className="bank-status">Connected</span>
                                        </>
                                      ) : (
                                        <>
                                          <Badge status="error" /> <span className="bank-status"> Pending</span>
                                        </>
                                      )}
                                    </>,
                                    <span
                                      style={{ color: '#56aaaa' }}
                                      onClick={() => {
                                        viewBankDetails(ele, item);
                                      }}
                                    >
                                      <u>View Details</u>
                                    </span>,
                                  ]}
                                >
                                  <Card.Meta
                                    description={
                                      <Descriptions
                                        column={1}
                                        items={[
                                          {
                                            key: 'acc-no',
                                            label: 'Account Number',
                                            children: item.mask_account_number,
                                          },
                                          {
                                            key: 'loan-bal',
                                            label: 'Loan Balance',
                                            children: item.connect ? `$${item.loan_balance || 0}` : 'N/A',
                                          },
                                          {
                                            key: 'interest-rate',
                                            label: 'Interest Rate',
                                            children: item.connect ? `${item.interest_rate || 0}%` : 'N/A',
                                          },
                                        ]}
                                      />
                                    }
                                  />
                                </Card>
                              </Card.Grid>
                            );
                          }
                        }}
                      />
                    </Card>
                  );
                })}
                {accountDetailsLoading === false && cardData.length < 1 && (
                  <Card className="box_shadow mt-3">
                    <Empty />
                  </Card>
                )}
              </div>
            </div>
          </Spin>
        </div>
      </div>

      <Modal
        title={
          <>
            {!viewDetailsLoading && (
              <>
                <img src={accountDetails?.bank_logo} style={{ height: 24, width: 24, borderRadius: '4px' }} className="img-fluid" alt="" />
                &nbsp; {accountDetails?.bank_name} ({accountDetails?.mask_account_number})
              </>
            )}
          </>
        }
        open={isBankInfoModalOpen}
        onCancel={() => handleDetailsModalClose()}
        className="bank-info-modal"
        destroyOnClose
        footer={null}
      >
        <Spin spinning={viewDetailsLoading}>
          {!viewDetailsLoading && accountDetails?.connect !== '1' && (
            <>
              <Descriptions title="Loan Details" />
              <Alert description="Please connect your bank account to access all the loan details." type="warning" showIcon style={{ marginBottom: '20px' }} />
            </>
          )}

          {accountDetails.connect === '1' && (
            <Descriptions
              title="Loan Details"
              layout="vertical"
              column={2}
              items={[
                {
                  key: 'Interest-Rate',
                  label: 'Interest Rate',
                  children: <span>{accountDetails?.interest_rate || 0}%</span>,
                },
                {
                  key: 'Interest-Type',
                  label: 'Interest Type',
                  children: accountDetails.interest_type ? <span style={{ textTransform: 'capitalize' }}>{accountDetails.interest_type?.toLowerCase()}</span> : '-',
                },
                {
                  key: 'install-amount',
                  label: 'Repayment',
                  children: accountDetails?.min_instalment_amount && accountDetails?.min_instalment_amount !== '0' ? `$${accountDetails?.min_instalment_amount}` : '-',
                },
                {
                  key: 'Repayment-Type',
                  label: 'Repayment Type',
                  children: accountDetails?.loan_replacement_type ? (
                    <span style={{ textTransform: 'capitalize' }}>{accountDetails?.loan_replacement_type?.replace(/_/g, ' ').toLowerCase()}</span>
                  ) : (
                    '-'
                  ),
                },
                {
                  key: 'Available Redraw',
                  label: 'Available-Redraw',
                  children: `$${accountDetails?.available_funds || 0}`,
                },
                {
                  key: 'Repayment-Frequency',
                  label: 'Repayment Frequency',
                  children: accountDetails?.loan_application_frequency || '-',
                },
                {
                  key: 'Start-Date',
                  label: 'Start Date',
                  children: accountDetails?.loan_start_date ? dayjs(accountDetails?.loan_start_date).format('DD/MM/YYYY') : '-',
                },
                {
                  key: 'End-Date',
                  label: 'End Date',
                  children: accountDetails?.loan_end_date ? dayjs(accountDetails?.loan_end_date).format('DD/MM/YYYY') : '-',
                },
                {
                  key: 'product_name',
                  label: 'Product Name',
                  children: accountDetails?.product_name || '-',
                },
              ]}
            />
          )}

          {accountDetails?.loan_fees && (
            <Descriptions
              title="Fees & Charges"
              layout="vertical"
              column={2}
              items={accountDetails?.loan_fees?.map((item, i) => {
                return {
                  key: i,
                  label: item.name,
                  children: `$${item.amount || 0}`,
                };
              })}
            />
          )}

          {accountDetails?.property && (
            <Descriptions
              title="Properties"
              column={1}
              items={accountDetails?.property?.map((item, i) => {
                return {
                  key: i,
                  label: `${item.street_number} ${item.street_name}`,
                  children: `${Number(item.interest_allocation || 0)}%`,
                };
              })}
            />
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default PropertyBanks;
