import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../../assets/less/index.less';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Divider, Form, Modal, Popover, Radio, Row, Select, Spin, notification } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetPropertyListById } from '../../../redux/actions/property/getPropertyListById.action';
import { AddProperty as addPropery } from '../../../redux/actions/property/addProperty.action';
import { EditProperty } from '../../../redux/actions/property/editProperty.action';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import CustomInput from '../../common';
import { GetBankList } from '../../../redux/actions/property/getBankList.action';
import { GetStateList } from '../../../redux/actions/property/stateList.action';
import Autocomplete from 'react-google-autocomplete';
import { UserTypes } from '../../common/userTypes';
import { GoBackDefault } from '../../common/GoBack';
import { depreciationMethodOptions, properyTypeOptions } from '../../../utils/ConstLabel';
import './index.less';

const current_year = dayjs().year();

const allocationTooltip = (
  <>
    If the full loan amount is used (100%) for buying this property, then the 'Loan Allocation' would be 100%. <br />
    <br /> If only a part of the loan amount is used for purchasing this property, just calculate the percentage used.
    <br /> For example, if the loan amount is $100,000 and only $65,000 is used for this property, the Loan Allocation would be 65% (i.e., $65,000/$100,000).
  </>
);

const allocationPercentageTooltip = (
  <>
    <p>We will explain this section with below example:</p>
    <p>
      Mr. X took an extra loan of $400,000 on January 1, {current_year}, using his current property as collateral (it could be for investment or personal use). <br /> Mr X spent $160,000 from this
      loan to buy Property A on January 1, {current_year}, and another $240,000 for the purchase of Property B on June 30, {current_year}.
    </p>
    <p>
      From January 1, {current_year}, to June 30, {current_year}, the loan amount was $160,000, which was entirely used for Property A during that period. <br /> Starting from July 1, {current_year},
      the total loan value was $400,000, and Mr. X used $160,000 (40% of $400,000) for Property A.{' '}
    </p>
    <p>
      Input for this example would be as below:
      <table border="1">
        <tr>
          <th>Loan Allocation %</th>
          <th>From Date</th>
          <th>To Date</th>
        </tr>
        <tr>
          <td>100%</td>
          <td>Jan 1, {current_year}</td>
          <td>Jun 30, {current_year}</td>
        </tr>
        <tr>
          <td>40%</td>
          <td>Jul 1, {current_year}</td>
          <td></td>
        </tr>
      </table>
    </p>
    <p>Please leave ‘To date’ blank to ensure this is percentage allocation is ongoing in future. </p>
  </>
);

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
const ModalForm = ({ open, onCancel, bankData, initialValues }) => {
  const [form] = Form.useForm();
  const [allocationChecked, setAllocationChecked] = useState(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  useEffect(() => {
    if (open && initialValues) {
      setAllocationChecked(initialValues?.allocations && initialValues?.allocations.length > 0);
      form.setFieldsValue(initialValues);
    } else {
      setAllocationChecked(false);
    }
  }, [open, initialValues]);

  const onOk = () => {
    form.submit();
  };

  const onChangeAllocations = (e) => {
    setAllocationChecked(e.target.checked);
  };

  return (
    <Modal onCancel={onCancel} footer={null} centered width={850} className="addBankLoanModal" open={open} title={<h5>{initialValues?.id ? 'Edit Loan' : 'Add Loan'}</h5>} destroyOnClose>
      <Form id="addBankLoanForm" form={form} name="loanForm" layout="vertical" wrapperCol={{ span: 24 }}>
        <div className="p-0 pt-3 row ">
          <div className="col-md-6">
            <div className="mb-3">
              <Form.Item
                label="Bank Name"
                name="bankId"
                rules={[
                  {
                    required: true,
                    message: 'Please select Bank Name!',
                  },
                ]}
              >
                <Select
                  disabled={initialValues?.id}
                  placeholder="Select Bank"
                  className="bank_name"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                  options={bankData}
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Form.Item
                label="BSB Number"
                name="bankBSBNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter BSB Number!',
                  },
                ]}
              >
                <CustomInput disabled={initialValues?.id} className="form-control p-2" placeholder="BSB Number" inputtype="number" style={{ color: 'black' }} maxLength={6} />
              </Form.Item>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Form.Item
                label="Loan Account Number"
                name="bankAccountNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Loan Account Number!',
                  },
                ]}
              >
                <CustomInput disabled={initialValues?.id} className="form-control p-2" placeholder="Loan Account Number" inputtype="number" style={{ color: 'black' }} maxLength={25} />
              </Form.Item>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <Form.Item
                name="interestAllocation"
                label={
                  <>
                    Loan Allocation %
                    <Popover title="Loan Allocation" content={allocationTooltip}>
                      <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                    </Popover>
                  </>
                }
              >
                <CustomInput placeholder="Allocation %" className="form-control p-2" id="property-intrest-allocation" inputtype="number" allowdecimal="true" maxamount={100} />
              </Form.Item>
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="mb-3">
              <Form.Item name="category" label="Loan Category" initialValue={'1'}>
                <Radio.Group>
                  <Radio value="1">Revenue</Radio>
                  <Radio value="2">Capital</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="mb-3">
              <Checkbox className="mb-1" onChange={(e) => onChangeAllocations(e)} checked={allocationChecked}>
                Allocate loan usage by percentage and date range{' '}
                <Popover title="Loan Allocation %" content={allocationPercentageTooltip}>
                  <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                </Popover>
              </Checkbox>
            </div>
          </div>

          {allocationChecked && (
            <div className="d-flex align-items-center">
              <div className="col-md-10">
                <Form.List name="allocations">
                  {(fields, { add, remove }) => (
                    <div className="col-md-12 align-items-start bank_loan">
                      {fields.map((field, index) => {
                        const isLastField = index === fields.length - 1;
                        const { key, ...restField } = field;

                        return (
                          <Row gutter={24} className="align-items-start p-0" style={{ padding: '.5rem' }} key={`interest-row-loan-${field.key}`}>
                            <Col span={8}>
                              <Form.Item
                                className="p-0 m-0"
                                {...restField}
                                name={[field.name, 'interest']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input Interest!',
                                  },
                                ]}
                                label={
                                  <>
                                    Allocation %
                                    <Popover title="Loan Allocation %" content={allocationPercentageTooltip}>
                                      <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                                    </Popover>
                                  </>
                                }
                              >
                                <CustomInput placeholder="Allocation %" className="form-control p-2" id="property-intrest-allocation" inputtype="number" allowdecimal="true" maxamount={100} />
                              </Form.Item>
                            </Col>
                            <Col span={7}>
                              <Form.Item
                                className="p-0 m-0"
                                {...restField}
                                name={[field.name, 'fromDate']}
                                label="From Date"
                                dependencies={[
                                  ['allocations', index - 1, 'toDate'],
                                  ['allocations', index, 'toDate'],
                                ]}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select From Date!',
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      const prevToDate = index > 0 ? getFieldValue(['allocations', index - 1, 'toDate']) : null;
                                      const currentToDate = getFieldValue(['allocations', index, 'toDate']);

                                      if (prevToDate && value && !dayjs(value).isAfter(dayjs(prevToDate))) {
                                        return Promise.reject(new Error('From Date must be after the previous To Date.'));
                                      }

                                      if (currentToDate && value && !dayjs(value).isBefore(dayjs(currentToDate))) {
                                        return Promise.reject(new Error('From Date must be before the current To Date.'));
                                      }

                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" placeholder="From Date" />
                              </Form.Item>
                            </Col>
                            <Col span={7}>
                              <Form.Item
                                className="p-0 m-0"
                                {...restField}
                                name={[field.name, 'toDate']}
                                label={isLastField ? 'To Date *' : 'To Date'}
                                dependencies={[['allocations', index, 'fromDate']]}
                                rules={
                                  isLastField
                                    ? []
                                    : [
                                        {
                                          required: true,
                                          message: 'Please select To Date!',
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            const currentFromDate = getFieldValue(['allocations', index, 'fromDate']);
                                            if (value && currentFromDate && !dayjs(value).isAfter(dayjs(currentFromDate))) {
                                              return Promise.reject(new Error('To Date must be after the From Date.'));
                                            }
                                            return Promise.resolve();
                                          },
                                        }),
                                      ]
                                }
                              >
                                <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" placeholder="To Date" />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item label="To" className="delete_ico_hide">
                                <DeleteOutlined style={{ fontSize: '1.2rem', color: 'red' }} onClick={() => remove(field.name)} />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}

                      <div className="normal_button text-center">
                        {fields.length > 0 && (
                          <div
                            className="col-md-12 mt-0 mb-2"
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            * If loan is on-going then keep To Date blank
                          </div>
                        )}
                        <button type="button" onClick={() => add(1)}>
                          + Add Interest
                        </button>
                      </div>
                    </div>
                  )}
                </Form.List>
              </div>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <div className="normal_button text-center">
              <Button htmlType="submit" type="primary" size="large" onClick={onOk}>
                {initialValues?.id ? 'Update' : 'Submit'}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default function ViewProperty(props) {
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);
  const userId = admin1.id;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isFromSameSite = location?.state && location?.state?.fromSameSite;

  const dispatch = useDispatch();
  const addPropRef = React.createRef();
  const [productForm] = Form.useForm();
  const [data, setData] = useState({});
  const [purchaseType, setPurchaseType] = useState(false);
  const [depreciationSchedule, setDepreciationSchedule] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [formState, setFormState] = useState({});

  const [open, setOpen] = useState(false);
  const [editingLoan, setEditingLoan] = useState(null);
  const [addressList, setAddressList] = useState({});

  const currentClient = localStorage.getItem('currentClient');

  const propertyDataById = useSelector((state) => state.getPropertyListByIdReducer);
  const bankList = useSelector((state) => state.getBankListReducer);
  const stateList = useSelector((state) => state.getStateListReducer);

  useEffect(() => {
    if (id) {
      dispatch(GetPropertyListById(id, currentClient));
    }
    dispatch(GetBankList());
    dispatch(GetStateList());
  }, [currentClient, dispatch, id]);

  useEffect(() => {
    setLoading(propertyDataById.isLoading && bankList.isLoading, stateList.isLoading);
    if (id && propertyDataById && propertyDataById.data.result && propertyDataById.error === false) {
      let dt = propertyDataById?.data?.result;
      setData(dt);
    }
    if (bankList && bankList.data.result && bankList.error === false) {
      const drpdown = bankList.data.result.map((e, i) => {
        return { value: e.id, label: e.shortName, key: crypto.randomUUID() };
      });
      setBankData(drpdown);
    }
    if (stateList && stateList.data.result && stateList.error === false) {
      const drpdown = stateList.data.result.map((e, i) => {
        return { value: e.state_name, label: e.state_name, key: crypto.randomUUID() };
      });
      setStateData(drpdown);
    }
  }, [propertyDataById, bankList, stateList, data, id]);

  useEffect(() => {
    if (id && Object.keys(data).length !== 0) {
      setFormState({
        ...formState,
        streetNumber: data.street_number || '',
        subUrb: data.suburb || '',
        state: data.state || '',
        postCode: data.postcode || '',
        streetName: data.street_name,
        titleOwner: data.title_owner || '',
        propertyType: data.property_type,
        estimatedMarketValue: data.market_value || '',
        admin_market_value: data.admin_market_value || 0,
        propertyTypeChange: Boolean(data.property_type_history),
        depreciationSchedule: Boolean(data.depreciation_schedule),
        depreciationMethod: data.depreciation_method,

        loans:
          data && data.loans && data.loans.length > 0
            ? data.loans.map((item, index) => {
                return {
                  bankId: item.bank_id,
                  interestAllocation: item.interest_allocation,
                  bankBSBNumber: item.bank_bsb_number,
                  bankAccountNumber: item.bank_account_number,
                  id: item.id,
                  key: index,
                  allocations: item.interests?.map((subItem) => {
                    return {
                      id: subItem.id,
                      fromDate: subItem.from_date !== null && subItem.from_date !== undefined && subItem.from_date !== '0000-00-00' ? dayjs(subItem.from_date) : '',
                      toDate: subItem.to_date !== null && subItem.to_date !== undefined && subItem.to_date !== '0000-00-00' ? dayjs(subItem.to_date) : '',
                      interest: subItem.interest_rate,
                    };
                  }),
                };
              })
            : [],
      });

      setPurchaseType(Boolean(data.property_type_history));
      setDepreciationSchedule(Boolean(data.depreciation_schedule));
    }
    //eslint-disable-next-line
  }, [data, id]);

  useEffect(() => {
    if (id) {
      productForm.resetFields();
    }
  }, [formState, id, productForm]);

  const onmapchange = (place) => {
    let subpre = '';

    let addresslist = {};

    place.address_components.forEach((ele, i) => {
      if (ele.types[0] === 'subpremise' || ele.types[0] === 'street_number') {
        if (ele.types[0] === 'subpremise') {
          subpre += `${ele.long_name}`;
          if (place.address_components[1].types[0] === 'street_number') {
            subpre += `/ ${place.address_components[1].long_name}`;
          }
        }
        productForm.setFieldsValue({
          streetNumber: subpre ? subpre : place.address_components[0].long_name,
        });

        addresslist.streetNumber = subpre ? subpre : place.address_components[0].long_name;
      } else if (ele.types[0] === 'sublocality_level_1' || ele.types[0] === 'route') {
        const streetName = ele.long_name;
        productForm.setFieldsValue({
          streetName: streetName ? streetName : '',
        });
        addresslist.streetName = streetName ? streetName : '';
      } else if (ele.types[0] === 'locality') {
        const subUrb = ele.long_name;
        productForm.setFieldsValue({
          subUrb: subUrb,
        });
        addresslist.subUrb = subUrb;
      } else if (ele.types[0] === 'administrative_area_level_1') {
        const state = ele.long_name;
        productForm.setFieldsValue({
          state: state ? state : '',
        });
        addresslist.state = state ? state : '';
      } else if (ele.types[0] === 'postal_code') {
        const postal_code = ele.long_name;
        productForm.setFieldsValue({
          postCode: postal_code ? postal_code : '',
        });
        addresslist.postCode = postal_code ? postal_code : '';
      }
    });

    setAddressList(addresslist);
  };
  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const customValidator = (rule, value) => {
    return new Promise((resolve, reject) => {
      if (rule.field === 'streetNumber' && value === undefined) {
        setChecked(true);
        reject('Please input Street No!');
      } else if (rule.field === 'streetName' && value === undefined) {
        reject('Please input Street Name!');
      } else if (rule.field === 'subUrb' && value === undefined) {
        reject('Please input Suburb!');
      } else if (rule.field === 'postCode' && value === undefined) {
        reject('Please input Post Code!');
      } else {
        resolve();
      }
    });
  };

  useEffect(() => {
    if (id) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [id]);

  const hideLoanModal = () => {
    setOpen(false);
  };
  const onFinish = (values) => {
    try {
      setLoadingEdit(true);
      setLoading(true);

      const addedLoans = values?.loans?.map((ele) => {
        const bankName = bankList?.data?.result?.find((item) => item.id === ele.bankId)?.shortName;
        ele.interestAllocation = Number(ele.interestAllocation);
        delete ele.key;
        const updatedAllocations = ele.allocations?.map((elem) => {
          return {
            ...elem,
            interest: Number(elem.interest),
            fromDate: dayjs(elem.fromDate).format('YYYY-MM-DD'),
            toDate: elem.toDate !== undefined && elem.toDate !== null && dayjs(elem.toDate).isValid() ? dayjs(elem.toDate).format('YYYY-MM-DD') : '',
          };
        });

        return {
          ...ele,
          bankName: bankName,
          allocations: updatedAllocations,
        };
      });

      if (addedLoans !== undefined && addedLoans.length > 0) {
        values.loan = [...addedLoans];
      }

      !id && delete values.map;

      if (id && formState.user_id) {
        values.user_id = formState.user_id;
      }

      if (!id && currentClient) {
        values.userId = currentClient ? parseFloat(currentClient) : userId;
      }

      values = {
        ...values,
        propertyTypeChange: purchaseType,
        depreciationSchedule: depreciationSchedule,
      };

      delete values.loans;
      id && delete values.admin_market_value;
      id && delete values.newLoan;
      id && delete values.map;

      id
        ? dispatch(EditProperty({ ...values, ...addressList }, id, currentClient)).then((data) => {
            if (data.payload.success === true) {
              notification.success({
                message: 'Success',
                description: data.payload.message,
              });
              if (admin1.user_type === UserTypes.USER) {
                navigate(isFromSameSite ? -1 : '/property');
              } else {
                navigate(isFromSameSite ? -1 : '/clients/client/property-details');
              }
              setLoadingEdit(false);
              setLoading(false);
            } else {
              notification.error({
                message: 'Failure',
                description: data.payload.message || 'Something went wrong!',
              });

              setLoadingEdit(false);
              setLoading(false);
            }
          })
        : dispatch(addPropery({ ...values, ...addressList })).then((data) => {
            if (data?.payload?.success === true) {
              notification.success({
                message: 'Success',
                description: data?.payload.message,
                duration: 5,
              });
              if (admin1.user_type === UserTypes.USER) {
                navigate(isFromSameSite ? -1 : '/property');
              } else {
                navigate(isFromSameSite ? -1 : '/clients/client/property-details');
              }
              setLoadingEdit(false);
              setLoading(false);
            } else {
              notification.error({
                message: 'Failure',
                description: data.payload.message || 'Something went wrong!',
              });
              setLoadingEdit(false);
              setLoading(false);
            }
          });
    } catch (error) {}
  };

  const handleSaveLoan = (values) => {
    const loans = productForm.getFieldValue('loans') || [];
    if (editingLoan) {
      const updatedLoans = loans.map((loan) => (loan.key === editingLoan.key ? { ...loan, ...values, allocations: values?.allocations || [] } : loan));
      productForm.setFieldsValue({
        loans: updatedLoans,
      });
    } else {
      productForm.setFieldsValue({
        loans: [...loans, { ...values, key: loans.length }],
      });
    }
    setOpen(false);
    setEditingLoan(null);
  };

  const handleEditLoan = (loan) => {
    if (loan) {
      setEditingLoan(loan);
    } else {
      setEditingLoan(null);
    }
    setOpen(true);
  };

  const handleDeleteLoan = (loan) => {
    const loans = productForm.getFieldValue('loans') || [];
    const updatedLoans = loans.filter((u) => u.key !== loan.key);
    productForm.setFieldsValue({
      loans: updatedLoans,
    });
  };

  // Memoize the function to get the bank name
  const getBankName = useCallback(
    (bankId) => {
      return bankList?.data?.result?.find((item) => item.id === bankId)?.shortName;
    },
    [bankList],
  );

  // Memoize the function to get the bank name
  const getBankLogo = useCallback(
    (bankId) => {
      return bankList?.data?.result?.find((item) => item.id === bankId)?.bank_logo;
    },
    [bankList],
  );

  return (
    <main className="page-content">
      <Spin spinning={loading || loadingEdit === true} size="large">
        <div className="container-fluid bg-dash-back">
          <div className={`${props.isTaxAccountant ? '' : 'layout-specing'}`}>
            <div className="add_form-1">
              <Row className="align-items-center">
                <GoBackDefault fallbackPath={admin1.user_type === UserTypes.USER ? '/property' : '/clients/client/property-details'} fromSameSite={location?.state?.fromSameSite} />
                <h5 className="m-0"> {id ? 'Edit Property' : 'Add Property'} </h5>
              </Row>

              <div className="row justify-content-center">
                <Form.Provider
                  onFormFinish={(name, { values }) => {
                    if (name === 'loanForm') {
                      handleSaveLoan(values);
                    } else {
                      onFinish(values);
                    }
                  }}
                >
                  <Form id="addPropertyForm" name="propertyForm" form={productForm} layout="vertical" initialValues={formState} wrapperCol={{ span: 24 }} ref={addPropRef}>
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-12 mt-3">
                        <Card title={'Property Details'} headStyle={{ backgroundColor: '#f2f8f8' }}>
                          <div className="row">
                            <div className="col-md-6 col-xl-6">
                              <div className="mb-3">
                                <Form.Item
                                  label="Address"
                                  name="map"
                                  rules={[
                                    {
                                      required: !checked,
                                      message: 'Please search location!',
                                    },
                                  ]}
                                >
                                  <Autocomplete
                                    className="form-control p-2"
                                    placeholder="Search"
                                    apiKey={process.env.REACT_APP_MAP_API_KEY}
                                    options={{
                                      types: [],
                                      componentRestrictions: { country: ['au'] },
                                    }}
                                    onPlaceSelected={(place) => {
                                      productForm.setFieldsValue({
                                        map: place.formatted_address,
                                      });
                                      onmapchange(place);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3 mt-2">
                                <Form.Item label=" ">
                                  <Checkbox className="mb-1" onChange={(e) => onChange(e)} checked={checked}>
                                    Enter Property Address Manually
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            </div>
                            {checked && (
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <Form.Item label="Street No" name="streetNumber" hidden={!checked} rules={[{ validator: customValidator, required: true }]}>
                                      <CustomInput className="form-control p-2" placeholder="Enter street no" inputtype="text" />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <Form.Item label="Street Name" name="streetName" hidden={!checked} rules={[{ validator: customValidator, required: true }]}>
                                      <CustomInput className="form-control p-2" placeholder="Enter Street Name" style={{ textTransform: 'capitalize' }} />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <Form.Item label="Suburb" name="subUrb" hidden={!checked} rules={[{ validator: customValidator, required: true }]}>
                                      <CustomInput className="form-control p-2" placeholder="Enter Suburb" inputtype="alphanumericspace" />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <Form.Item
                                      label="State"
                                      name="state"
                                      hidden={!checked}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input State!',
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Select State"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                        options={stateData}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <Form.Item label="Post Code" name="postCode" hidden={!checked} rules={[{ validator: customValidator, required: true }]}>
                                      <CustomInput className="form-control p-2" placeholder="Enter Post Code" inputtype="number" maxLength={10} />
                                    </Form.Item>
                                  </div>
                                </div>
                                <Divider className="mt-0">Other Information</Divider>
                              </div>
                            )}
                            <div className="col-md-4">
                              <div className="mb-3">
                                <Form.Item
                                  label="Property Type"
                                  name="propertyType"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Property Type!',
                                    },
                                  ]}
                                >
                                  <Select placeholder="Property Type" id="property_type" defaultActiveFirstOption={true} options={properyTypeOptions} />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <Form.Item
                                  label="Estimated Market Value"
                                  name="estimatedMarketValue"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Estimated Market Value!',
                                    },
                                  ]}
                                >
                                  <CustomInput className="form-control p-2" placeholder="Estimated Market Value" inputtype="number" maxLength={10} />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <Form.Item label="Owner" name="titleOwner">
                                  <CustomInput className="form-control p-2 rmInput" placeholder="Title owner(Optional)" inputtype="alphanumericspace" />
                                </Form.Item>
                              </div>
                            </div>
                            {id && (
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Form.Item
                                    label="Admin Estimated Market Value"
                                    name="admin_market_value"
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Please enter Estimated Market Value!',
                                      },
                                    ]}
                                  >
                                    <CustomInput disabled className="form-control p-2" placeholder="Estimated Market Value" inputtype="number" maxLength={10} />
                                  </Form.Item>
                                </div>
                              </div>
                            )}
                            <div className="col-md-4">
                              <Form.Item className="p-2" label="Depreciation Schedule Available?" name="depreciationSchedule">
                                <Radio.Group
                                  className="p-1"
                                  initialValue={id ? formState.depreciationSchedule : false}
                                  onChange={(e) => {
                                    setDepreciationSchedule(e.target.value);
                                    if (e.target.value === false) {
                                      productForm.setFieldsValue({
                                        depreciation_method: null,
                                      });
                                    }
                                  }}
                                >
                                  <Radio name="depreciationSchedule" value={true}>
                                    Yes
                                  </Radio>
                                  <Radio name="depreciationSchedule" value={false}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>

                            {depreciationSchedule === false ? (
                              ''
                            ) : (
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <Form.Item
                                    label="Depreciation Method"
                                    name="depreciationMethod"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select Depreciation Method!',
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="form-control-label"
                                      id="depreciation_method"
                                      placeholder="Depreciation Method"
                                      defaultActiveFirstOption={true}
                                      options={depreciationMethodOptions}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            )}
                          </div>
                        </Card>
                      </div>
                      <div className="col-xl-12 col-md-12 col-12 mt-3 ">
                        <Card title="Loan Details" headStyle={{ backgroundColor: '#f2f8f8' }}>
                          <Form.Item name="loans" noStyle />

                          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.loans !== curValues.loans}>
                            {({ getFieldValue }) => {
                              const loans = getFieldValue('loans') || [];
                              return (
                                loans && (
                                  <div className="row add-bank-list row-cols-1 row-cols-md-3 g-3">
                                    {loans.length > 0 &&
                                      loans.map((loan, index) => (
                                        <div className="col" key={index}>
                                          <Card
                                            bordered={false}
                                            className="card h-100 border-0 shadow"
                                            actions={[
                                              <Button type="link" danger icon={<DeleteOutlined />} key="delete" onClick={() => handleDeleteLoan(loan)}>
                                                Delete
                                              </Button>,
                                              <Button type="link" icon={<EditOutlined />} key="edit" onClick={() => handleEditLoan(loan)}>
                                                View/Edit
                                              </Button>,
                                            ]}
                                          >
                                            <Card.Meta
                                              className="p-0"
                                              description={
                                                <Descriptions
                                                  className="border-0"
                                                  column={1}
                                                  items={[
                                                    {
                                                      key: 'image',
                                                      label: getBankLogo(loan.bankId) ? (
                                                        <img src={getBankLogo(loan.bankId) || ''} style={{ height: 24, width: 24, borderRadius: '4px' }} className="img-fluid" alt="" />
                                                      ) : (
                                                        ''
                                                      ),
                                                      children: <b>{getBankName(loan.bankId)}</b>,
                                                    },
                                                    {
                                                      key: 'acc-no',
                                                      label: 'Account Number :',
                                                      children: `${loan.bankBSBNumber}-${loan.bankAccountNumber}`,
                                                    },
                                                  ]}
                                                />
                                              }
                                            />
                                          </Card>
                                        </div>
                                      ))}
                                    <div className="col">
                                      <div className="card add-bank-card justify-content-center align-items-center shadow h-100" onClick={() => handleEditLoan(null)}>
                                        <div className="custom-card-content m-4">
                                          <PlusOutlined style={{ fontSize: '24px', marginBottom: '8px' }} />
                                          <span>Add Loan</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              );
                            }}
                          </Form.Item>
                        </Card>
                      </div>
                      <div className="col-12 text-center my-2" style={{ width: '100%' }}>
                        <div className="text-center login_button my-4">
                          <button type="submit"> {id ? 'Update' : 'Save'} </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <ModalForm open={open} onCancel={hideLoanModal} bankData={bankData} initialValues={editingLoan ? editingLoan : { interestAllocation: 100 }} />
                </Form.Provider>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </main>
  );
}
