import { getRequest } from '../../../utils/apiUtils';

export const getReauthorizeConsent = (id) => {
  return async () => {
    const { result } = await getRequest(`/user/bank-consent/reauthorize/${id}`);
    return result;
  };
};

export const updateConsent = (id) => {
  return async () => {
    const { result } = await getRequest(`/user/bank-consent/reauthorize/${id}`);
    return result;
  };
};
