import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const RemindDepreciationSlice = createSlice({
    name: "RemindDepreciation",
    initialState,
    reducers: {
        RemindDepreciationRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        RemindDepreciationSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        RemindDepreciationFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { RemindDepreciationSuccess, RemindDepreciationFailure, RemindDepreciationRequest } =
    RemindDepreciationSlice.actions;

export default RemindDepreciationSlice.reducer;
