import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const stateListSlice = createSlice({
  name: "GetBankList",
  initialState,
  reducers: {
    stateListRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    stateListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    stateListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { stateListRequest, stateListSuccess, stateListFailure } = stateListSlice.actions;

export default stateListSlice.reducer;
