import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getLockYearsDataSlice = createSlice({
  name: 'getLockYearsData',
  initialState,
  reducers: {
    getLockYearsDataRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    getLockYearsDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getLockYearsDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getLockYearsDataRequest, getLockYearsDataSuccess, getLockYearsDataFailure } = getLockYearsDataSlice.actions;

export default getLockYearsDataSlice.reducer;
