import React from 'react';
import { Modal } from 'antd';

import AddTaxAccountantDiv from './AddTaxAccountantDiv';

const ModalAddTaxAccountant = ({ setIsOpen1 = () => {}, isOpen1 = false, userId = null, onReloadHandler = () => {} }) => {
  return (
    <Modal destroyOnClose onCancel={() => setIsOpen1(false)} footer={null} centered style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpen1}>
      <AddTaxAccountantDiv setIsOpen1={setIsOpen1} userId={userId} onReloadHandler={onReloadHandler} />
    </Modal>
  );
};

export default ModalAddTaxAccountant;
