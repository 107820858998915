import { putRequest } from "../../../utils/apiUtils";
import { signUpSuccess, signUpFailure } from "../../reducers/slices/auth/userSignUp.slice";

export const UserReferral = (data) => {
    return async (dispatch) => {
        const { result, error } = await putRequest(`user/referral`, data);

        if (!error) {
            return dispatch(signUpSuccess(result));
        }
        return dispatch(signUpFailure(result));
    };
};
