import { Button, Modal, notification, Spin } from 'antd';
import '../../../assets/css/accountant.css';
import { useDispatch } from 'react-redux';
import { mfaUpdateForSystemAsync, setMFAPreferenceAsync } from '../../../redux/reducers/slices/UserMFA/UserMFASlice';
import { useState } from 'react';
import { MFAType } from '../../common/userTypes';

const TwoFactorSMSModal = ({ open = false, setOpen = false, onSuccess = null, user_info = {} }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const maskPhoneNumber = (phoneNumber) => {
    let start = phoneNumber.substring(0, phoneNumber.length - 8);
    let end = phoneNumber.substring(phoneNumber.length - 4);

    let middle = 'x'.repeat(4);

    return `${start}${middle}${end}`;
  };

  const onAuthenticate = async () => {
    try {
      setLoading(true);
      await dispatch(setMFAPreferenceAsync('sms'));

      await dispatch(mfaUpdateForSystemAsync({ mfa_status: true, mfa_type: MFAType.SMS_MFA }));
      notification.success({
        message: 'Success',
        description: 'Your account is now protected with 2FA. Enjoy peace of mind knowing your data is safe.',
      });

      setLoading(false);
      onSuccess();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong',
      });
      setLoading(false);
    }
  };

  return (
    <Modal title={<h5>Confirm 2FA Setup via SMS</h5>} onCancel={handleCancel} footer={null} style={{ zIndex: '1005', borderRadius: '1em' }} destroyOnClose open={open} maskClosable={false}>
      <Spin spinning={loading}>
        <div>
          <p>
            Please confirm to enable Two-Factor Authentication (2FA) using SMS. A verification code will be sent to your registered mobile number, {maskPhoneNumber(user_info?.phone_number)}, each time
            you log in to your account.
          </p>
          <div className="d-flex justify-content-center">
            <Button className="d-flex justify-content-end" type="primary" onClick={onAuthenticate}>
              Set Up
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default TwoFactorSMSModal;
