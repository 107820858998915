import { Button, DatePicker, Form, notification, Popover, Select, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateBankStatementList, GetBankStatementList, GetBankStatementPDF } from '../../../redux/actions/property/bankStatementDownload.action';
import { GetConnectedBankList } from '../../../redux/actions/property/getConnectedBankList.action';
import { DownloadOutlined } from '@ant-design/icons';
import { UserStatus } from '../../common/userTypes';

const { Option } = Select;

const openBase64PDFInNewTab = (base64String) => {
  // Convert the Base64 string to a Blob object
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  // Create a URL for the Blob and open it in a new tab
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, '_blank');

  // Download the PDF file
  // const link = document.createElement('a');
  // link.href = blobUrl;
  // link.download = 'statement.pdf';
  // link.click();

  // Clean up the URL object after use
  // URL.revokeObjectURL(blobUrl);
};

const disableFutureDates = (current) => {
  const twoYearsAgo = dayjs().subtract(2, 'year');
  return (current && current > dayjs().subtract(1, 'day').endOf('day')) || current < twoYearsAgo;
};

export default function DownloadStatement() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);

  const getConnectedBankList = useSelector((state) => state.getConnectedBankListReducer);
  const bankStatementList = useSelector((state) => state.bankStatementListReducer);

  const currentClient = localStorage.getItem('currentClient');

  useEffect(() => {
    dispatch(GetBankStatementList(currentClient));
    dispatch(GetConnectedBankList(currentClient));
  }, []);

  useEffect(() => {
    if (getConnectedBankList && getConnectedBankList.data.result && getConnectedBankList.error === false) {
      const dt = getConnectedBankList.data.result.map((elm) => {
        return {
          value: elm.id || '-',
          label: elm.masked_account_number || '-',
        };
      });
      setBankOptions(dt);
    }
  }, [getConnectedBankList]);

  const handleOnFinish = (values) => {
    setLoading(true);
    const params = {
      from_date: values.from_date.format('YYYY-MM-DD'),
      to_date: values.to_date.format('YYYY-MM-DD'),
      account_id: values.account_id,
    };
    dispatch(CreateBankStatementList(params, currentClient)).then((data) => {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        dispatch(GetBankStatementList(currentClient));
      } else {
        notification.error({
          message: 'Failure',
          description: data.message,
        });
      }
      setLoading(false);
    });
  };

  const handledownloadStatement = async (record) => {
    setDownloadLoading(true);
    dispatch(GetBankStatementPDF(record.id, currentClient)).then((data) => {
      if (data.success === true) {
        openBase64PDFInNewTab(data.result);

        setDownloadLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
        });
        setDownloadLoading(false);
      }
    });
  };

  const columns = [
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      key: 'bank_name',
    },
    {
      title: 'Account Number',
      dataIndex: 'masked_account_number',
      key: 'masked_account_number',
    },
    {
      title: 'Statement Date',
      dataIndex: 'from_date',
      key: 'from_date',
      render: (text, record) => (
        <>
          {dayjs(text).format('DD-MM-YYYY')} - {dayjs(record.to_date).format('DD-MM-YYYY')}
        </>
      ),
    },
    {
      title: 'Download',
      dataIndex: 'address',
      key: 'address',
      render: (_text, record) => {
        return (
          <>
            {record?.status !== UserStatus.COMPLETED ? (
              <Popover content="Statement would be available for download within few mins">
                <Button icon={<DownloadOutlined />} type="dashed"></Button>
              </Popover>
            ) : (
              <Button title="Download" type="primary" icon={<DownloadOutlined />} disabled={record?.status !== UserStatus.COMPLETED} onClick={() => handledownloadStatement(record)} />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Form name="horizontal_download_statment" onFinish={handleOnFinish} layout="inline" className="mb-4" size="middle" initialValues={{ from_date: dayjs().subtract(1, 'day'), to_date: dayjs() }}>
        <Form.Item
          name="account_id"
          rules={[
            {
              required: true,
              message: 'Please select loan!',
            },
          ]}
        >
          <Select
            style={{ width: '190px', textAlign: 'left' }}
            placeholder="Select Loan"
            showSearch
            options={bankOptions || []}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
          >
            <Option value="" key={crypto.randomUUID()}>
              - - - Select Loan - - -
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="from_date"
          rules={[
            {
              required: true,
              message: 'Please select from date!',
            },
          ]}
        >
          <DatePicker placeholder="From Date" format="DD-MM-YYYY" disabledDate={disableFutureDates} />
        </Form.Item>
        <Form.Item name="to_date">
          <DatePicker disabled format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {bankOptions.length < 1 ? (
            <Popover content="Please connect your bank to download statements">
              <Button size="large" loading={loading} disabled>
                Generate
              </Button>
            </Popover>
          ) : (
            <Button type="primary" size="large" htmlType="submit" loading={loading} disabled={getConnectedBankList.isLoading}>
              Generate
            </Button>
          )}
        </Form.Item>
      </Form>
      <Table rowKey={'id'} dataSource={bankStatementList?.data?.result || []} loading={bankStatementList.isLoading || downloadLoading} columns={columns} />
    </>
  );
}
