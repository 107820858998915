import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getLoginSlice = createSlice({
    name: "Getlogins",
    initialState,
    reducers: {
        getLoginSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getLoginFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getLoginSuccess, getLoginFailure } = getLoginSlice.actions;

export default getLoginSlice.reducer;
