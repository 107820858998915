import { getRequest } from '../../../utils/apiUtils';
import { getNotificationDataSuccess, getNotificationDataFailure, getNotificationDataRequest } from '../../reducers/slices/Header/getNotificationData.slice';

export const getNotificationData = () => {
  return async (dispatch) => {
    await dispatch(getNotificationDataRequest());
    const { result, error } = await getRequest(`user/header`);

    if (!error) {
      if (result.success === true) {
        localStorage.setItem('admin', JSON.stringify(result?.result?.userData));
      }
      return await dispatch(getNotificationDataSuccess(result));
    }
    return await dispatch(getNotificationDataFailure(result));
  };
};
