import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getuserdropListSlice = createSlice({
    name: "GetUserList",
    initialState,
    reducers: {
        getuserDropListRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getuserDropListSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getuserDropListFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const {
    getuserDropListRequest,
    getuserDropListSuccess,
    getuserDropListFailure,
} = getuserdropListSlice.actions;

export default getuserdropListSlice.reducer;
