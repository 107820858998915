import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getPropertyNotificationDataSlice = createSlice({
  name: 'GetPropertyNotificationData',
  initialState,
  reducers: {
    getPropertyNotificationDataRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getPropertyNotificationDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getPropertyNotificationDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getPropertyNotificationDataSuccess, getPropertyNotificationDataFailure, getPropertyNotificationDataRequest } = getPropertyNotificationDataSlice.actions;

export default getPropertyNotificationDataSlice.reducer;
