import { postRequestImport } from '../../../../utils/apiUtils';
import { UserTypes } from '../../../../components/common/userTypes';
import { postImportTaxUserFailure, postImportTaxUserRequest, postImportTaxUserSuccess } from '../../../reducers/slices/texDashboard/UserOnbording/postImportCsv.slice';

export const postImportTaxUserData = (data) => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(postImportTaxUserRequest());
    const { result, error } = await postRequestImport(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'channel-partner/file/create-user' : 'tax-accountant/onboard-users-file'}`, data);

    if (!error) {
      return await dispatch(postImportTaxUserSuccess(result));
    }
    return await dispatch(postImportTaxUserFailure(result));
  };
};
