import { getRequest } from '../../../utils/apiUtils';
import {
  getMortgageBrokerClientListDataSuccess,
  getMortgageBrokerClientListDataFailure,
  getMortgageBrokerClientListDataRequest,
} from '../../reducers/slices/mortgage_broker/getMortgageBrokerClientListData.slice';

export const GetMortgageBrokerClientList = () => {
  return async (dispatch) => {
    await dispatch(getMortgageBrokerClientListDataRequest());
    const { result, error } = await getRequest(`channel-partner/mortgage-broker/clients`);
    if (!error) {
      return await dispatch(getMortgageBrokerClientListDataSuccess(result));
    }
    return await dispatch(getMortgageBrokerClientListDataFailure(result));
  };
};
