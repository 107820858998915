import { EyeFilled } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, Col, DatePicker, Form, notification, Row, Select, Table } from 'antd';
import dayjs from 'dayjs';
import '../../../assets/less/index.less';

import { useDispatch, useSelector } from 'react-redux';
import { GetTransactionByUserId } from '../../../redux/actions/transactions/getTransactions.action';
import { GetPropertyListWithOutLoan } from '../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { GetCategoryList } from '../../../redux/actions/category/getCategoryList.action';
import { useNavigate } from 'react-router-dom';
import CustomIcon from '../../sidebar/custIcon';
import editIcon from '../../../assets/images/edit.svg';
import { TrensectionType } from '../../common/userTypes';
import { getPropertyTypeLabel, getTransactionTypeLabel } from '../../../utils/ConstLabel';

export default function Transactions({ propertyId = null }) {
  const [filterForm] = Form.useForm();
  const [propertySearch, setPropertySearch] = useState('all');
  const [categarySearch, setCategarySearch] = useState('all');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transData, setTransData] = useState([]);
  const [transDataTotal, setTransDataTotal] = useState(0);
  const [propertyData, setPropertyData] = useState([]);
  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [costCategorydata, setCostCategorydata] = useState([]);
  const [paginationCurrent, setPaginationCurrent] = useState(null);

  const [pagination, setPagination] = useState({
    current: paginationCurrent || 1,
    pageSize: 20,
    defaultPageSize: 20,
  });

  const [loading, setLoading] = useState(false);
  const transDta = useSelector((state) => state.getTransactionReducer);
  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);
  const categoryDta = useSelector((state) => state.getCategoryListReducer);
  const [initialRender, setInitialRender] = useState(true);

  // Filter Context
  const [filters, setFilters] = useState({ fromDate: '', toDate: '', propertyId: '', categoryId: '' });

  useEffect(() => {
    if (!propertyId) {
      dispatch(GetPropertyListWithOutLoan());
    }
    dispatch(GetCategoryList());
  }, [dispatch, propertyId]);

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem('transactionFilters'));
    if (storedFilters && initialRender) {
      filterForm.setFieldsValue({
        Property_type: Number(storedFilters?.Property_type) || propertySearch || propertyId || '',
        fromDate: storedFilters?.fromDate?.length > 0 ? dayjs(storedFilters?.fromDate) : null,
        toDate: storedFilters?.toDate?.length > 0 ? dayjs(storedFilters?.toDate) : null,
        category_type: Number(storedFilters?.category_type) || categarySearch || '',
      });
      setPaginationCurrent(storedFilters?.current);
      dispatch(
        GetTransactionByUserId({
          propertyId: (storedFilters?.Property_type === 'all' ? '' : storedFilters?.Property_type) || propertyId || '',
          categoryId: storedFilters?.category_type === 'all' ? '' : storedFilters?.category_type || '',
          fromDate: storedFilters?.fromDate || '',
          toDate: storedFilters?.toDate || '',
          skip: storedFilters?.skip || (Number(storedFilters?.current) - 1) * storedFilters?.pageSize || (Number(storedFilters.current) - 1) * storedFilters.pageSize,
          limit: storedFilters?.pageSize || pagination.pageSize,
        }),
      );
    } else {
      const formValues = filterForm.getFieldsValue();

      filterForm.setFieldsValue({
        Property_type: formValues.Property_type || propertySearch || propertyId || '',
        fromDate: formValues.fromDate || null,
        toDate: formValues.toDate || null,
        category_type: formValues.category_type || categarySearch || '',
      });

      dispatch(
        GetTransactionByUserId({
          propertyId: formValues?.Property_type === 'all' ? '' : formValues.Property_type || propertyId || '',
          categoryId: formValues?.category_type === 'all' ? '' : formValues.category_type || '',
          fromDate: formValues?.fromDate || '',
          toDate: formValues?.toDate || '',
          skip: (Number(pagination.current) - 1) * pagination.pageSize,
          limit: pagination.pageSize,
        }),
      );
    }
  }, [filters, pagination, dispatch, propertySearch, categarySearch, propertyId, initialRender, filterForm, setFilters]);

  let columns = [];

  !propertyId &&
    columns.push({
      title: 'Property Name',
      dataIndex: 'street_name',
      key: 'street',
      width: '30%',
      render: (text, record) => {
        return `${record?.street_number} ${record?.street_name}, ${record?.suburb}`;
      },
    });

  columns.push(
    ...[
      {
        title: 'Category',
        dataIndex: 'category_name',
        key: 'category_name',
        width: '20%',
        // ...getColumnSearchProps("category_name"),
      },
      {
        title: 'Transaction Source',
        dataIndex: 'transaction_type',
        key: 'expense_type',
        width: '16%',
        render: (_text, record) => {
          return getTransactionTypeLabel(Number(record?.transaction_type));
        },
      },
      {
        title: 'Property Type',
        dataIndex: 'property_type',
        key: 'property_type',
        width: '20%',
        render: (_text, record) => {
          return getPropertyTypeLabel(Number(record?.property_type));
        },
      },
      {
        title: 'Date',
        dataIndex: 'transaction_date',
        key: 'expense_date',
        width: '12%',
        render: (text) => {
          const formattedDate = dayjs(text).format('DD-MM-YYYY');
          return <span>{formattedDate}</span>;
        },
        // ...getColumnSearchProps("expense_date"),
      },
      {
        title: 'Price',
        dataIndex: 'amount',
        key: 'amount',
        width: '15%',
        render: (text, record) => {
          const prices = Number(Math.abs(Number(text || 0)))
            ?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return (
            <span
              className="text-decoration-underline cursor-pointer"
              onClick={(e) => {
                if (
                  [
                    TrensectionType.RENT_TYPE_TRANSACTION,
                    TrensectionType.MANUAL_TYPE_TRANSACTION,
                    TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
                    TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
                    TrensectionType.BASIQ_TYPE_TRANSACTION,
                  ].includes(Number(record.transaction_type))
                ) {
                  e.preventDefault();
                  storeFilters();
                  navigate(`/transaction/${record.id}`, { state: { TransectionType: Number(record.transaction_type), PropertyId: record.property_id, LoanId: record?.loan_id, fromSameSite: true } });
                }
              }}
            >
              {Number(text || 0) >= 0 ? `$${prices}` : `-$${prices}`}
            </span>
          );
        },
        // ...getColumnSearchProps("amount"),
      },
      {
        title: 'Edit/View',
        dataIndex: 'action',
        key: 'action',
        width: '15%',
        render: (_text, record) => {
          if (
            ![
              TrensectionType.RENT_TYPE_TRANSACTION,
              TrensectionType.MANUAL_TYPE_TRANSACTION,
              TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
              TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
              TrensectionType.BASIQ_TYPE_TRANSACTION,
            ].includes(Number(record.transaction_type))
          ) {
            return '-';
          }
          return (
            <Row>
              <Col>
                <Button
                  className="mr-2"
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    storeFilters();
                    navigate(`/transaction/${record.id}`, { state: { TransectionType: Number(record.transaction_type), PropertyId: record.property_id, LoanId: record?.loan_id, fromSameSite: true } });
                  }}
                  icon={record.locked ? <EyeFilled /> : <CustomIcon icon={editIcon} />}
                  size="small"
                />
              </Col>
            </Row>
          );
        },
      },
    ],
  );

  const modifyLocalStorage = (paramater) => {
    filterForm.setFieldsValue({
      [`${paramater}`]: '',
    });
    const storedFilters = JSON.parse(localStorage.getItem('transactionFilters'));

    const newFilters = {
      ...storedFilters,
      [paramater]: '',
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      current: pagination.current,
    };

    localStorage.setItem('transactionFilters', JSON.stringify(newFilters));
  };

  const startDateChange = (value) => {
    if (value === null) {
      modifyLocalStorage('fromDate');
    }
    setInitialRender(false);
    setFilters({ ...filters, fromDate: value && value !== null ? value.format('YYYY-MM-DD') : '' });
    setPagination({ ...pagination, current: 1 });
    setPaginationCurrent(1);
  };

  const endDateChange = (value) => {
    if (value === null) {
      modifyLocalStorage('toDate');
    }
    setInitialRender(false);
    setFilters({ ...filters, toDate: value && value !== null ? value.format('YYYY-MM-DD') : '' });
    setPagination({ ...pagination, current: 1 });
    setPaginationCurrent(1);
  };

  const propertyChange = (value) => {
    setInitialRender(false);
    setPaginationCurrent(1);
    setPropertySearch(value || '');
    setPagination({ ...pagination, current: 1 });
    if (value === 'all') {
      return setFilters({ ...filters, propertyId: '', categoryId: categarySearch === undefined || categarySearch === 'all' ? '' : categarySearch });
    } else {
      return setFilters({ ...filters, propertyId: value || '' });
    }
  };

  const categoryChange = (value) => {
    setPaginationCurrent(1);
    setInitialRender(false);
    setCategarySearch(value || '');
    setPagination({ ...pagination, current: 1 });
    if (value === 'all') {
      return setFilters({ ...filters, categoryId: '', propertyId: propertySearch === undefined || propertySearch === 'all' ? '' : propertySearch });
    } else {
      return setFilters({ ...filters, categoryId: value || '' });
    }
  };

  useEffect(() => {
    setLoading(transDta.isLoading);

    if (transDta && transDta.data?.result?.data && transDta.error === false) {
      let dt = transDta.data?.result?.data.map((elm) => {
        return { ...elm, expense_date: dayjs(elm.expense_date).format('DD-MM-YYYY'), key: crypto.randomUUID() };
      });
      setTransData(dt);
      setTransDataTotal(transDta.data?.result?.total);
    }
    if (categoryDta && categoryDta.data.result && categoryDta.error === false) {
      let dt = categoryDta.data.result.map((e) => {
        return {
          value: e.id,
          label: e.category_name,
          income: !!(e.expense_type === 'Revenue' && e.transaction_type === 'In'),
          expense: !!(e.expense_type === 'Revenue' && e.transaction_type === 'Out'),
          cost: e.expense_type === 'Capital',
        };
      });
      let Incom = dt.filter((item) => ![false].includes(item.income));
      setIncomeCategorydata(Incom);
      let Expense = dt.filter((item) => ![false].includes(item.expense));
      setExpenseCategorydata(Expense);
      let Cost = dt.filter((item) => ![false].includes(item.cost));
      setCostCategorydata(Cost);
    }
    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((e) => {
        return {
          value: e.id,
          label: e.street_number ? e.street_number + (e.suburb ? ' ' + e.street_name : '') : '',
        };
      });
      setPropertyData(dt);
    }
  }, [transDta, propertyDta, categoryDta]);

  const handleAddExpense = () => {
    if (propertyId === null && propertyData.length === 0) {
      notification.error({
        message: 'Failure',
        description: 'Add property first!',
        duration: 10,
      });
    } else {
      storeFilters();
      navigate('/record-expenses', { state: { fromSameSite: true } });
    }
  };

  const storeFilters = () => {
    const formValues = filterForm.getFieldsValue();
    const newFilters = {
      fromDate: formValues.fromDate !== '' ? dayjs(formValues.fromDate) : '',
      toDate: formValues.toDate !== '' ? dayjs(formValues.toDate) : '',
      Property_type: formValues.Property_type,
      category_type: formValues.category_type === 'all' ? '' : formValues.category_type,
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      current: pagination.current,
    };
    localStorage.setItem('transactionFilters', JSON.stringify(newFilters));
  };

  const handleTableChange = (pagination) => {
    setInitialRender(false);
    setPaginationCurrent(pagination.current);
    setPagination(pagination);
  };

  return (
    <main className={propertyId ? '' : 'page-content'}>
      <div className={propertyId ? '' : 'container-fluid bg-dash-back'}>
        <div className={propertyId ? '' : 'layout-specing'}>
          <Form form={filterForm}>
            <Row className="align-items-center">
              <Col xs={24} sm={24} md={9} lg={6} xl={4} xxl={6} span={4}>
                <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                  <h5 className="m-0">Transaction List</h5>
                </div>
              </Col>
              <Col xs={24} sm={24} md={15} lg={18} xl={20} xxl={18} span={20}>
                <Row gutter={16} className="p-2 text-center text-sm-end" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={4} span={4} style={{ paddingRight: '5px' }} className="mt-1">
                    <Form.Item name="fromDate" className="p-0 m-0">
                      <DatePicker
                        format="DD-MM-YYYY"
                        onChange={startDateChange}
                        style={{ width: '100%', background: 'transparent' }}
                        placeholder="Start Date"
                        disabledDate={(date) => {
                          const toDate = filterForm.getFieldValue(['toDate']);
                          return date && toDate && date > dayjs(toDate);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={4} span={4} style={{ paddingRight: '5px' }} className="mt-1">
                    <Form.Item name="toDate" className="p-0 m-0">
                      <DatePicker
                        format="DD-MM-YYYY"
                        onChange={endDateChange}
                        style={{ width: '100%', background: 'transparent' }}
                        placeholder="End Date"
                        disabledDate={(date) => {
                          const fromDate = filterForm.getFieldValue(['fromDate']);
                          return date && fromDate && date < dayjs(fromDate);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!propertyId && (
                    <Col xs={24} sm={12} md={12} lg={12} xl={5} span={4} style={{ paddingRight: '5px' }} className="mt-1">
                      <Form.Item name="Property_type" className="p-0 m-0">
                        <Select
                          style={{ width: '100%', textAlign: 'left', background: 'transparent' }}
                          allowClear
                          placeholder="- - All Property - -"
                          className="transSelect"
                          onClear={() => modifyLocalStorage('Property_type')}
                          id="trans_property_filter"
                          onChange={propertyChange}
                          showSearch
                          options={[
                            {
                              label: 'All',
                              options: [
                                {
                                  label: ' - - All Property - - ',
                                  value: 'all',
                                },
                              ],
                            },
                            {
                              label: 'Properties',
                              options: propertyData,
                            },
                          ]}
                          optionFilterProp="children"
                          dropdownStyle={{
                            color: 'rgba(0, 0, 0, 0.45)',
                          }}
                          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase()) || (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                        ></Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} sm={12} md={12} lg={12} xl={5} span={5} style={{ paddingRight: '5px' }} className="mt-1">
                    <Form.Item name="category_type" className="p-0 m-0">
                      <Select
                        allowClear
                        onClear={() => modifyLocalStorage('category_type')}
                        style={{ width: '100%', textAlign: 'left', background: 'transparent' }}
                        placeholder="- - All Categories - - "
                        className="transSelect"
                        id="trans_category_filter"
                        onChange={categoryChange}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        options={[
                          {
                            label: 'All',
                            options: [
                              {
                                label: ' - - All Categories - - ',
                                value: 'all',
                              },
                            ],
                          },
                          {
                            label: 'Income',
                            options: incomeCategorydata,
                          },
                          {
                            label: 'Expense',
                            options: expenseCategorydata,
                          },
                          {
                            label: 'Cost',
                            options: costCategorydata,
                          },
                        ]}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={6} span={6} className="mt-1 no-padding">
                    <button
                      onClick={() => handleAddExpense()}
                      disabled={!propertyId && propertyDta.isLoading}
                      className="p-0 property_btn1 btn btn-primary px-3 py-2 property_btn1_responsive mr-0"
                      style={{ width: '100%' }}
                    >
                      + Add Income / Expense
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Table
            paddingclassName="mt-4 property_list table_shadow"
            pagination={{ ...pagination, current: paginationCurrent || pagination.current, total: transDataTotal || 0 }}
            columns={columns}
            dataSource={transData}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </main>
  );
}
