import React, { useState } from 'react';
import { Alert, Button, Form, notification } from 'antd';
import logosvg from '../../../assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/antd.css';
import SignUpHOC from './SignUpHOC';
import CustomInput from '../../common';
import { CopyOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { UserReferral } from '../../../redux/actions/login/userReferral.action';

const Referral = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFinish = (value) => {
    setLoading(true);

    if (value.referral_code || value.referral_code === undefined || value.referral_code === '') {
      dispatch(UserReferral({ referralCode: value?.referral_code ? value?.referral_code : '' })).then((response) => {
        if (response?.payload?.success === true) {
          setLoading(false);
          navigate('/', { replace: true });
        } else {
          notification.error({
            message: 'Failure',
            description: response?.payload?.message || 'Something went wrong!',
          });
          setErrorMessage(response?.payload?.message || 'Something went wrong!');
        }
        setLoading(false);
      });
    }
  };

  return (
    <SignUpHOC>
      <div className="login_wrapper tandc_wrapper">
        <div className="card shadow border-0">
          <div className="card-body p-0">
            <div className="modal-body p-4 ">
              <div className="text-center tandc_logo mb-4">
                <img src={logosvg} className="img-fluid w-50" alt="" />
              </div>

              <div className="row">
                <h5>Have a Referral Code?</h5>
                <p>Enter your accountant's or friend's referral code to unlock exclusive benefits and rewards.</p>
              </div>

              {errorMessage && (
                <div className="pb-2">
                  <Alert message={errorMessage} type="error" bordered closable onClose={() => setErrorMessage('')} />
                </div>
              )}

              <div className="verify-mobile-number mt-2">
                <Form layout="vertical" onFinish={handleFinish}>
                  <div className="row d-flex align-items-center gap-4">
                    <div className="col">
                      <Form.Item
                        name="referral_code"
                        rules={[
                          {
                            min: 8,
                            message: 'Referral Code required minimum 8 digits!',
                          },
                        ]}
                      >
                        <CustomInput prefix={<CopyOutlined className="site-form-item-icon" />} firstzero="false" placeholder="Referral code" />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <div className="text-center">
                        <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
                          Submit
                        </Button>
                        <Button className="ml-2 text-muted" onClick={() => navigate('/')} icon={<DoubleRightOutlined />} iconPosition="right">
                          Skip
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SignUpHOC>
  );
};

export default Referral;
