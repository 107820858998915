import React, { Fragment, forwardRef, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BellOutlined, CopyOutlined, LeftCircleOutlined, QuestionCircleOutlined, RightCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Modal, notification, Typography, Spin, Button, Dropdown, Badge, Tabs, Popover, Menu, Avatar } from 'antd';
import avtar from '../../assets/images/avtar2.png';
import downArrowSVG from '../../assets/images/down-arrow.svg';
import { useSelector, useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ManageRevert } from '../../redux/actions/Header/ManageRevert.action';
import { getNotificationData } from '../../redux/actions/Header/getNotificationData.action';
import { ProductTourType, UserTypes } from '../common/userTypes';
import './index.less';
import NoticeList from './NoticeList';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getIcon, getTitle } from '../common/NotificationUtil';
import { getApproveUser } from '../../redux/actions/dashboard/getApprove.action';
import { getRejectUser } from '../../redux/actions/dashboard/getReject.action';
import ModalAddTaxAccountant from '../../utils/ModalAddTaxAccountant';
import ModalAddMortgageBroker from '../../utils/ModalAddMortgageBroker';
import ModalChangePassword from '../../utils/ModalChangePassword';
import CommonAvatar from '../../utils/CommonAvatar';
import ModalApplyAsFP from '../../utils/ModalApplyAsFP';

const Header = forwardRef(function Header(props, tour_ref, collapsed, setCollapsed = () => {}) {
  const [modal, contextHolder] = Modal.useModal();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [profilerOpen, setProfilerOpen] = useState(false);

  const [isOpenTour, setIsOpenTour] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [spin, setSpin] = useState(false);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const [notificationData, setNotificationData] = useState([]);
  const [notiLoading, setNotiLoading] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);

  // Mortgage broker state
  const [isOpenMortgageBroker, setIsOpenMortgageBroker] = useState(false);

  const [searchParams] = useSearchParams();
  const states = searchParams.get('state');
  const isInitialMount = useRef(true);

  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;

  const { name, email, user_type, phone_number, profile_image_path, company_logo } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);
  const userId = admin1?.id || 0;

  const isTaxAccountant = user_type === UserTypes.TAXACCOUTANT || user_type === UserTypes.SUB_TAXACCOUTANT || user_type === UserTypes.ADMIN_SUB_TAXACCOUNTANT;

  const hideUserDropdown = pathname.split('/').includes('clients') && isTaxAccountant && pathname.split('/').includes('client');

  const notificationDta = useSelector((state) => state.getNotificationDataReducer);

  const [collapsedPosition, setCollapsedPosition] = useState(null);

  useEffect(() => {
    if (props.view) {
      setCollapsedPosition('295px');
    }
    if (props.collapsed && props.view) {
      setCollapsedPosition('161px');
    }
    if (props.collapsed && !props.view) {
      setCollapsedPosition('81px');
    }
    if (!props.collapsed && !props.view) {
      setCollapsedPosition('231px');
    }
  }, [pathname, props.collapsed, props.view]);

  useEffect(() => {
    if (isInitialMount.current) {
      // Handle initial mount
      if (admin1?.user_type) {
        dispatch(getNotificationData());
      }
      isInitialMount.current = false;
    } else {
      // Handle pathname changes
      if (pathname === '/' && admin1?.user_type) {
        dispatch(getNotificationData());
      }
    }
  }, [dispatch, pathname, admin1?.user_type]);

  useEffect(() => {
    setNotiLoading(notificationDta.isLoading || false);
    if (notificationDta && notificationDta?.data.result && notificationDta.error === false) {
      setNotificationData(notificationDta?.data.result.ActionItemData || []);

      // open notification modal if user is tax accountant
      const tax_accountant_request = notificationDta?.data.result.ActionItemData.find((item) => item.type === 'tax_accountant_request');

      if (!tax_accountant_request && admin1?.user_type === UserTypes.USER && notificationDta?.data?.result?.userData?.product_tour === ProductTourType.PRODUCT_TOUR_PENDING) {
        setIsOpenTour(true);
      }

      if (tax_accountant_request) {
        modal.confirm({
          title: getTitle(tax_accountant_request?.type),
          icon: <p className="mr-2"> {getIcon(tax_accountant_request?.type)} </p>,
          content: (
            <div>
              <p>
                I{' '}
                <Popover
                  content={
                    <>
                      <p>By accepting the Terms & Conditions, you authorize Tax Accountant to access and manage specific property-related data necessary for tax purposes.</p>
                      <p>This includes:</p>
                      <ul>
                        <li>Property Details and Reports</li>
                        <li>Income, Expenses, and Costs associated with the property (including supporting documents)</li>
                        <li>Reports such as Income and Expenses and Cost Base Reports</li>
                        <li>Bank Loan Summary</li>
                      </ul>
                      <p>
                        With this consent, Tax Accountant is permitted to make additions or modifications to property details and expenses.
                        <br /> They may also share this information with their team members for tax preparation and analytical purposes.
                        <br /> Rest assured, your data will be handled securely and with utmost confidentiality.
                      </p>
                    </>
                  }
                  title="Consent to Share Your Property Data"
                  trigger="click"
                >
                  <button className="link-button" type="button">
                    consent
                  </button>
                </Popover>{' '}
                to share my property accounting and financial data with my Tax Accountant {tax_accountant_request?.name}.
              </p>
            </div>
          ),
          okText: 'Approve',
          cancelText: 'Deny',
          onOk() {
            return new Promise((resolve, reject) => {
              handleApprove(tax_accountant_request?.id, 1, resolve, reject);
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {
            return new Promise((resolve, reject) => {
              handleReject(tax_accountant_request?.id, 1, resolve, reject);
            }).catch(() => console.log('Oops errors!'));
          },
        });
      }
    }
  }, [notificationDta, admin1?.user_type]);

  // mortgage broker api call

  useEffect(() => {
    if (states === 'manage') {
      dispatch(ManageRevert()).then((res) => {
        if (res.payload.success === true) {
          notification.success({
            message: 'Success',
            description: res?.payload?.message,
          });
        } else {
          notification.error({
            message: 'Failure',
            description: res?.payload?.message || 'Something went wrong!',
          });
        }
      });
    }
  }, [states, dispatch]);

  const handleApprove = (id, type, resolve, reject) => {
    dispatch(getApproveUser(id, type)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
        reloadNotification();
        resolve();
      } else {
        notification.error({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
        });
        reject();
      }
    });
  };

  const handleReject = (id, type, resolve, reject) => {
    notifiationLoading(true);
    setSpin(true);
    dispatch(getRejectUser(id, type)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
        reloadNotification();
        resolve();
      } else {
        notification.error({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
        });
        reject();
      }
    });
  };

  const copyText = () => {
    notification.success({
      message: 'Success',
      description: 'Copied.',
    });
  };

  const logout = () => {
    signOut();
    localStorage.clear();
    window.open(landing_url, '_self');
  };

  const reloadNotification = () => {
    dispatch(getNotificationData());
  };

  const notifiationLoading = (value) => {
    setNotiLoading(value);
  };
  const notifiationVisible = (value) => {
    setVisible(value);
  };

  const items = [
    {
      label: 'Videos',
      path: 'video',
      key: 'menu_video',
      icon: <VideoCameraOutlined style={{ fontSize: 15 }} />,
    },
    {
      label: 'FAQs',
      path: 'faqs',
      key: 'menu_faqs',
      icon: <QuestionCircleOutlined style={{ fontSize: 15 }} />,
    },
  ];

  const onHelpClick = (e) => {
    setOpen(false);
    if (e.key === 'menu_video') {
      navigate('/video');
    } else if (e.key === 'menu_faqs') {
      navigate('/faqs');
    }
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const tabItems = [
    {
      key: 'noti-tab-1',
      label: 'Notification',
      children: <NoticeList list={notificationData} onReloadHandler={reloadNotification} notifiationLoading={notifiationLoading} notifiationVisible={notifiationVisible} />,
    },
  ];
  return (
    <Fragment>
      <div className="top-header" style={{ zIndex: '1000', left: collapsedPosition }}>
        <div className={`header-bar d-flex justify-content-between border-bottom`}>
          <div className="d-flex gap-2 align-items-center">
            <div id="close-sidebar" className="btn btn-icon btn-soft-light toggle_button">
              <Button
                type="text"
                icon={props.collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                onClick={() => props.setCollapsed(!props.collapsed)}
                style={{
                  fontSize: '16px',
                }}
              />
            </div>

            {/* <div className="d-flex align-items-center">
            {admin1?.user_type === UserTypes.USER ? (
              <Link to="/" className="logo-icon me-3">
                <span className="big">
                  <AppIcon height="30" className="logo-light-mode" />
                  <AppIcon height="30" className="logo-dark-mode" />
                </span>
              </Link>
            ) : (
              <Link to="/" className="logo-icon me-3">
                <span className="big">
                  <AppIcon height="30" className="logo-light-mode" />
                </span>
              </Link>
            )}
            <div id="close-sidebar" className="btn btn-icon btn-soft-light toggle_button" onClick={() => toggleSideBar()}>
              <UnorderedListOutlined />
            </div>
            <div className="search-bar p-0 d-none d-md-block ms-2"></div>
            </div> */}

            {hideUserDropdown && clientDetail?.data?.result && (
              <div className="search-bar p-0  ms-2 mt-0">
                <div className="dropdown-item text-dark">
                  <div className="col-12 text-end virtual_mail_header startingSpan cursor-pointer">
                    <span className="m-0">
                      <strong>Unique ID : </strong> {clientDetail?.data?.result?.virtual_email}
                      <CopyToClipboard className="pb-0 pe-0" text={clientDetail?.data?.result?.virtual_email} onCopy={() => copyText()}>
                        <CopyOutlined />
                      </CopyToClipboard>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <ul className="list-unstyled mb-0 d-flex align-items-center me-2 ">
            <li className="list-inline-item mb-0 ms-1 d-none d-sm-block">
              <div className="search-bar p-0 d-none d-md-block ms-2">
                <ul className="list-unstyled mb-0"></ul>
              </div>
            </li>
            {admin1?.user_type === UserTypes.USER && (
              <>
                <li className="list-inline-item mb-0 ms-1 d-none d-sm-none d-md-block">
                  <div className="search-bar p-0  ms-2 mt-0">
                    <div className="dropdown-item text-dark">
                      <div className="col-12 text-end virtual_mail_header">
                        <span className="m-0">
                          <strong>Unique ID : </strong> {admin1.virtual_email}
                          <CopyToClipboard className="pb-0 pe-0" text={admin1.virtual_email} onCopy={() => copyText()}>
                            <CopyOutlined />
                          </CopyToClipboard>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-inline-item mb-0 ms-1 d-sm-block">
                  <div className="search-bar p-0  d-md-block ms-2 mt-1">
                    <Popover
                      placement="bottom"
                      content={<Menu items={items} selectedKeys={[`menu_${pathname.split('/').pop()}`]} onClick={onHelpClick} mode="vertical" />}
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <QuestionCircleOutlined className="icon" ref={(ref) => (tour_ref.current.header_help = ref)} />
                    </Popover>
                  </div>
                </li>
                <li className="list-inline-item mb-0 ms-1 d-sm-block    ">
                  <div className="search-bar p-0  d-md-block ms-2 mr-2 mt-1    ">
                    <Dropdown
                      placement="bottomRight"
                      menu={{
                        items: [
                          {
                            key: 'noti-tab-1',
                            label: (
                              <div className="card header-notification">
                                <Spin spinning={notiLoading} delay={300}>
                                  <Tabs className="tabs" items={tabItems} />
                                </Spin>
                              </div>
                            ),
                          },
                        ],
                      }}
                      overlayStyle={{
                        position: 'relative',
                        width: '410px',
                      }}
                      overlayClassName="notification-list"
                      trigger={['click']}
                      open={visible}
                      onOpenChange={(open) => {
                        if (open) {
                          setVisible(true);
                        } else {
                          setVisible(false);
                        }
                      }}
                    >
                      <span className="noticeButton" ref={(ref) => (tour_ref.current.header_notification = ref)}>
                        <Badge count={notificationData.length} style={{ boxShadow: 'none' }} className="noti-badge">
                          <BellOutlined className="icon" />
                        </Badge>
                      </span>
                    </Dropdown>
                  </div>
                </li>
              </>
            )}

            {!hideUserDropdown && (
              <li className="list-inline-item mb-0 ms-1 d-none d-sm-block">
                <div className="dropdown dropdown-primary">
                  <button
                    type="button"
                    className="btn dropdown-toggle p-0 dropdown__toggle "
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    ref={(ref) => (tour_ref.current.header_profile = ref)}
                  >
                    {/* <Avatar src={imageUrl} size={36} className="me-3" shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'}></Avatar> */}
                    <CommonAvatar size={36} marginEnd="me-3" />
                    {name}

                    <img src={downArrowSVG} className="img-fluid ms-3" alt="" />
                  </button>

                  <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3" style={{ minWidth: '200px' }}>
                    <button className="dropdown-item d-flex align-items-center text-dark pb-3">
                      {/* <Avatar src={profile_image_path || company_logo || avtar} size="large" shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'} /> */}
                      <CommonAvatar size={'large'} />
                      <div className="flex-1 ms-2">
                        <span className="d-block">{email}</span>
                        <small className="text-muted">{phone_number}</small>
                      </div>
                    </button>

                    {(admin1?.user_type === UserTypes.CHANNEL_PARTNER ||
                      admin1?.user_type === UserTypes.TAXACCOUTANT ||
                      admin1?.user_type === UserTypes.ADMIN_SUB_TAXACCOUNTANT ||
                      admin1?.user_type === UserTypes.SUB_TAXACCOUTANT) && (
                      <div className="code-box">
                        <div className="dropdown-item text-dark">
                          <div className="col-12 text-end virtual_mail_header">
                            <span className="m-0">
                              <strong>Referral Code : </strong> {admin1.referral_code}
                              <CopyToClipboard className="pb-0 pe-0" text={admin1.referral_code} onCopy={() => copyText()}>
                                <CopyOutlined />
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {admin1?.user_type === UserTypes.USER && (
                      <div>
                        <div className="dropdown-item text-dark">
                          <div className="col-12 text-end virtual_mail_header">
                            <span className="m-0">
                              {admin1.virtual_email}
                              <CopyToClipboard className="pb-0 pe-0" text={admin1.virtual_email} onCopy={() => copyText()}>
                                <CopyOutlined />
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="dropdown-divider border-top mt-4"></div>
                    <button className="dropdown-item text-dark " onClick={() => navigate(admin1?.user_type === UserTypes.USER ? '/user/account' : '/account')}>
                      <span className="mb-0 d-inline-block me-1">
                        <i className="ti ti-logout"></i>
                      </span>
                      My Account
                    </button>

                    {admin1?.user_type === UserTypes.TAXACCOUTANT && (
                      <>
                        <button className="dropdown-item profile_item text-dark" onClick={() => setProfilerOpen(true)}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Apply as Financial Planner
                        </button>

                        <ModalApplyAsFP isOpen={profilerOpen} setIsOpen={setProfilerOpen} />
                      </>
                    )}

                    {admin1?.user_type === UserTypes.USER ? (
                      <>
                        <button className="dropdown-item profile_item text-dark " onClick={() => setIsOpen1(true)}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Add TaxAccountant
                        </button>
                        <button className="dropdown-item profile_item text-dark " onClick={() => setIsOpenMortgageBroker(true)}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Add Mortgage Broker
                        </button>
                        <button className="dropdown-item profile_item text-dark" onClick={() => navigate('/subscription', { state: { fromSameSite: true } })}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Subscription
                        </button>
                      </>
                    ) : (
                      ''
                    )}
                    <button className="dropdown-item profile_item text-dark" onClick={() => setIsOpen(true)}>
                      <span className="mb-0 d-inline-block me-1">
                        <i className="ti ti-logout"></i>
                      </span>
                      Change Password
                    </button>
                    {[UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(admin1?.user_type) ? (
                      ''
                    ) : (
                      <button className="dropdown-item profile_item text-dark" onClick={() => navigate(`${admin1?.user_type === UserTypes.USER ? '/delete-account':'/account/delete-account'}`)}>
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>
                        Delete Account
                      </button>
                    )}

                    <button className="dropdown-item profile_item text-dark" onClick={() => logout()}>
                      <span className="mb-0 d-inline-block me-1">
                        <i className="ti ti-logout"></i>
                      </span>
                      Logout
                    </button>
                  </div>
                </div>
              </li>
            )}

            {hideUserDropdown && (
              <>
                <button onClick={() => navigate('/clients/client/record-expenses')} className="btn p-0 px-3 py-2 mr-1 property_btn header_extra_buttons">
                  + Add Expense
                </button>

                <button onClick={() => navigate('/clients/client/property/add')} className="property_btn1 btn btn-success p-0 px-3 py-2 me-2 header_extra_buttons">
                  + Add Property
                </button>
              </>
            )}

            <li className="list-inline-item mb-0 ms-1 d-sm-none">
              <div className="dropdown dropdown-primary">
                <button type="button" className="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <Avatar src={profile_image_path || company_logo || avtar} size={36} className="avatar avatar-ex-small" shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'} />
                </button>
                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3" style={{ minWidth: '200px' }}>
                  <button className="dropdown-item d-flex align-items-center text-dark pb-3">
                    <Avatar
                      src={profile_image_path || company_logo || avtar}
                      size="large"
                      className="avatar avatar-md-sm  border shadow"
                      shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'}
                    />
                    <div className="flex-1 ms-2">
                      <span className="d-block">{email}</span>
                      <small className="text-muted">{phone_number}</small>
                    </div>
                  </button>
                  {admin1?.user_type === UserTypes.USER ? (
                    <div>
                      <div className="dropdown-item text-dark">
                        <div className="col-12 text-start virtual_mail_header mt-1">
                          <span className="m-0" style={{ fontSize: '15px' }}>
                            {admin1.virtual_email}
                            <CopyToClipboard className="pb-0 pe-0" text={admin1.virtual_email} onCopy={() => copyText()}>
                              <CopyOutlined />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="dropdown-divider border-top mt-4"></div>

                  {admin1?.user_type === UserTypes.USER ? (
                    <>
                      <button className="dropdown-item text-dark " onClick={() => setIsOpen1(true)}>
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>
                        Add TaxAccountant
                      </button>
                      <button className="dropdown-item profile_item text-dark " onClick={() => setIsOpenMortgageBroker(true)}>
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>
                        Add Mortgage Broker
                      </button>
                      <button className="dropdown-item text-dark" onClick={() => navigate('/subscription', { state: { fromSameSite: true } })}>
                        Subscription
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                  <button className="dropdown-item text-dark" onClick={() => setIsOpen(true)}>
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-logout"></i>
                    </span>
                    Change Password
                  </button>
                  {[UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.TAXACCOUTANT, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(admin1?.user_type) ? (
                    ''
                  ) : (
                    <button className="dropdown-item text-dark" onClick={() => navigate('/delete-account')}>
                      <span className="mb-0 d-inline-block me-1">
                        <i className="ti ti-logout"></i>
                      </span>
                      Delete Account
                    </button>
                  )}

                  <button className="dropdown-item text-dark" onClick={() => logout()}>
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-logout"></i>
                    </span>
                    Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ModalChangePassword isOpen={isOpen} setIsOpen={setIsOpen} spin={spin} setSpin={setSpin} />
      </div>

      {/* MODAL add Tax-accountant */}
      <ModalAddTaxAccountant isOpen1={isOpen1} setIsOpen1={setIsOpen1} userId={userId} />

      {/* MODAL add Mortgage Broker */}

      <ModalAddMortgageBroker isOpen1={isOpenMortgageBroker} setIsOpen1={setIsOpenMortgageBroker} />

      {/* MODAL product tour */}
      <Modal
        onCancel={() => setIsOpenTour(false)}
        maskClosable={false}
        closeIcon={null}
        footer={null}
        centered
        style={{ zIndex: '1005', borderRadius: '1em' }}
        className="txtAccountentModal1"
        open={isOpenTour}
      >
        <center>
          <p className="mt-3">
            <Typography.Text style={{ fontSize: '1.5rem' }}>
              Hi {notificationDta?.data?.result?.userData?.name}, <br />
              Welcome to The Property Accountant
            </Typography.Text>
          </p>
          <p className="mt-3" style={{ fontSize: '1rem' }}>
            Unlock the full potential of The Property Accountant? View our product tour explore key features and functionalities.
          </p>
          <div className="justify-content-center d-flex mt-3 ">
            <button
              className="btn property_btn m-2 confirm_btn"
              onClick={() => {
                setIsOpenTour(false);
                props.productTour(true);
              }}
            >
              Get started
            </button>
          </div>
        </center>
      </Modal>

      {contextHolder}
    </Fragment>
  );
});

export default Header;
