import { deleteRequest } from '../../../utils/apiUtils';
import { DeleteConFailure, DeleteConRequest, DeleteConSuccess } from '../../reducers/slices/BankConnection/deleteConnection.slice';

export const DeleteConnections = (id) => {
  return async (dispatch) => {
    await dispatch(DeleteConRequest());
    const { result, error } = await deleteRequest(`/user/bank-connection/${id}`);

    if (!error) {
      return await dispatch(DeleteConSuccess(result));
    }
    return await dispatch(DeleteConFailure(error));
  };
};
