import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getAssignTaxtoUserSlice = createSlice({
    name: "GetPropertyList",
    initialState,
    reducers: {
        getAssignTaxtoUserRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getAssignTaxtoUserSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getAssignTaxtoUserFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getAssignTaxtoUserRequest, getAssignTaxtoUserSuccess, getAssignTaxtoUserFailure } = getAssignTaxtoUserSlice.actions;

export default getAssignTaxtoUserSlice.reducer;
