import { UserTypes } from '../../../../components/common/userTypes';
import { getRequest } from '../../../../utils/apiUtils';
import { getuserDropListFailure, getuserDropListRequest, getuserDropListSuccess } from '../../../reducers/slices/texDashboard/subusers/getuserdroplist.slice';

export const GetUserdropList = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  return async (dispatch) => {
    await dispatch(getuserDropListRequest());
    const { result, error } = await getRequest(`${admin.user_type === UserTypes.CHANNEL_PARTNER ? 'sub-channel-partner' : 'sub-tax-accountant'}/users`);

    if (!error) {
      return await dispatch(getuserDropListSuccess(result));
    }
    return await dispatch(getuserDropListFailure(result));
  };
};
