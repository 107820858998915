import { DepreciationMethod, PropertyType, TrensectionType } from '../components/common/userTypes';

export const getTransactionTypeLabel = (transactionType) => {
  switch (transactionType) {
    case TrensectionType.SETTLEMENT_TYPE_TRANSACTION:
      return 'Settlement Statement';
    case TrensectionType.RENT_TYPE_TRANSACTION:
      return 'Rental Summary';
    case TrensectionType.MANUAL_TYPE_TRANSACTION:
      return 'Direct Entry';
    case TrensectionType.BASIQ_TYPE_TRANSACTION:
      return 'Bank Feed';
    case TrensectionType.DEPRECIATION_SCHEDULE_TYPE_TRANSACTION:
      return 'Depreciation Schedule';
    case TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION:
      return 'Subscription';
    default:
      return '-';
  }
};

export const getPropertyTypeLabel = (propertyType) => {
  switch (propertyType) {
    case PropertyType.COMMERCIAL_PROPERTY:
      return 'Commercial - Investment';
    case PropertyType.INVESTMENT_PROPERTY:
      return 'Residential - Investment';
    case PropertyType.OWNER_OCCUPIED_PROPERTY:
      return 'Residential - Owner Occupied';
    default:
      return '-';
  }
};

export const properyTypeOptions = [
  { label: 'Residential - Investment', value: PropertyType.INVESTMENT_PROPERTY },
  { label: 'Residential - Owner Occupied', value: PropertyType.OWNER_OCCUPIED_PROPERTY },
  { label: 'Commercial - Investment', value: PropertyType.COMMERCIAL_PROPERTY },
];

export const depreciationMethodOptions = [
  { label: 'Diminishing Value', value: DepreciationMethod.WDV_DEPRECIATION },
  { label: 'Prime Cost', value: DepreciationMethod.PRIME_COST_DEPRECIATION },
];
