import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getMortgageBrokerDashboardListDataSlice = createSlice({
  name: 'GetMortgageBrokerDashboardListData',
  initialState,
  reducers: {
    getMortgageBrokerDashboardListDataRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getMortgageBrokerDashboardListDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getMortgageBrokerDashboardListDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getMortgageBrokerDashboardListDataSuccess, getMortgageBrokerDashboardListDataFailure, getMortgageBrokerDashboardListDataRequest } = getMortgageBrokerDashboardListDataSlice.actions;

export default getMortgageBrokerDashboardListDataSlice.reducer;
