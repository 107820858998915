import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Card, Popover, Spin, Table, Flex, Empty } from 'antd';
import { Pie } from '@ant-design/plots';
import { getMortgageBrokerDashboardData, getMortgageBrokerDashboardListData } from '../../../../redux/actions/mortgage-dashboard/getMortgageBrokerDashboardData.action';
import { UserStatus } from '../../../common/userTypes';
import '../index.less';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatCurrency } from '../../../../utils';

const config = {
  angleField: 'value',
  colorField: 'type',
  tooltip: {
    title: 'type',
  },
  label: {
    text: 'value',
    style: {
      fontWeight: 'bold',
    },
  },
  legend: {
    color: {
      title: false,
      position: 'right',
      rowPadding: 5,
    },
  },
  scale: {
    color: { palette: 'set2' },
  },
};

function convertGraphData(data) {
  return Object.entries(data).map(([key, value]) => ({
    type: key,
    value: Number(value) || undefined,
  }));
}

const MortgageBrokerDashboard = () => {
  const { mortgage_broker_status } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [statisticsData, setStatisticsData] = useState({});
  const [listData, setListData] = useState({});

  // Dashboard DATA
  const MortgageBrokerDashboardData = useSelector((state) => state.getMortgageBrokerDashboardReducer);
  const MortgageBrokerDashboardListData = useSelector((state) => state.getMortgageBrokerDashboardListReducer);

  useEffect(() => {
    if (mortgage_broker_status === UserStatus.ACTIVE) {
      dispatch(getMortgageBrokerDashboardData());
      dispatch(getMortgageBrokerDashboardListData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(MortgageBrokerDashboardData.isLoading);
    if (MortgageBrokerDashboardData && MortgageBrokerDashboardData.data.result && MortgageBrokerDashboardData.error === false) {
      setStatisticsData(MortgageBrokerDashboardData.data.result);
    }
  }, [MortgageBrokerDashboardData]);

  useEffect(() => {
    setLoadingList(MortgageBrokerDashboardListData.isLoading);
    if (MortgageBrokerDashboardListData && MortgageBrokerDashboardListData.data.result && MortgageBrokerDashboardListData.error === false) {
      setListData(MortgageBrokerDashboardListData.data.result);
    }
  }, [MortgageBrokerDashboardListData]);

  const client_columns = [
    {
      title: 'Info',
      dataIndex: 'email',
      key: 'email',
      render(text, record) {
        return (
          <>
            <span className="fw5-bold">{record?.name}</span>
            <br />
            <span className="fw5-normal">{text || '-'}</span>
          </>
        );
      },
    },
    {
      title: 'Loans',
      dataIndex: 'loans',
      key: 'loans',
      render(text, record) {
        return (
          <>
            <span className="fw5-bold">{text}</span>
            <br />
            <span className="fw5-normal">{record.loanAmount ? formatCurrency(record.loanAmount, 0, false, true) : ''}</span>
          </>
        );
      },
    },
  ];

  const referrals_columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span className="fw5-bold">{text}</span>,
    },
    {
      title: 'Info',
      dataIndex: 'email',
      key: 'email',
      render(text, record) {
        return (
          <Flex vertical>
            <span className="fw5-bold">{text}</span>
            <span className="fw5-normal">{record?.phone_number}</span>
          </Flex>
        );
      },
    },
  ];

  if (mortgage_broker_status === UserStatus.PENDING) {
    return (
      <main className="page-content">
        <div className="container-fluid bg-dash-back">
          <div className="layout-specing">
            <div className="row align-items-center mb-4">
              <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                <h5 className="m-0">Dashboard</h5>
              </div>
            </div>
            <Alert message="Mortgage Broker approval request pending." description="Mortgage Broker Request Pending. Please wait for it to be approved." type="warning" showIcon />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <div className="">
            <div className="row align-items-center">
              <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                <h5 className="m-0">Dashboard</h5>
              </div>
            </div>

            <Spin spinning={loading}>
              <div className="row align-items-center">
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                  <div className="card" onClick={() => navigate('/onboarding')} role="button">
                    <div className="card-body">
                      <div className="card_ico justify-content-between align-items-center d-flex">
                        <h6 className="card-text text-muted fw-normal m-0">Invitation Pending</h6>
                        <h5 className="card-title fw-bold mb-0">{statisticsData?.totalOnboardUsers || 0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                  <div className="card" onClick={() => navigate('/clients')} role="button">
                    <div className="card-body">
                      <div className="card_ico d-flex justify-content-between align-items-center">
                        <h6 className="card-text text-muted fw-normal m-0">Client Access Received</h6>
                        <h5 className="card-title fw-bold mb-0">{statisticsData?.totalClients || 0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                  <div className="card" onClick={() => navigate('/loan-overview')} role="button">
                    <div className="card-body">
                      <div className="card_ico d-flex justify-content-between align-items-center">
                        <h6 className="card-text text-muted fw-normal m-0">Total Loans</h6>
                        <h5 className="card-title fw-bold mb-0">{statisticsData?.totalLoans || 0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4">
                  <div className="card" onClick={() => navigate('/sub-users')} role="button">
                    <div className="card-body">
                      <div className="card_ico d-flex justify-content-between align-items-center">
                        <h6 className="card-text text-muted fw-normal m-0">Team Members</h6>
                        <h5 className="card-title fw-bold mb-0">{statisticsData?.totalTeamMember || 0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row dashboard_wrapper">
                <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                  <Card
                    style={{
                      height: '100%',
                    }}
                    title={
                      <>
                        Onboarded Clients{' '}
                        <Popover title="Onboarded Clients" content={'Users who accepted your invitation versus those still pending.'}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                  >
                    {statisticsData?.graphOnboardClientData && !Object.values(statisticsData?.graphOnboardClientData).every((value) => value === null) ? (
                      <Pie {...config} data={convertGraphData(statisticsData?.graphOnboardClientData || {})} height={180} />
                    ) : (
                      <div className="pt-4">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Card>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                  <Card
                    style={{
                      height: '100%',
                    }}
                    title={
                      <>
                        Banks{' '}
                        <Popover title="Banks" content={'Breakdown of your loan portfolio by Bank.'}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                  >
                    {statisticsData?.graphBankData && !Object.values(statisticsData?.graphBankData).every((value) => value === null) ? (
                      <Pie {...config} data={convertGraphData(statisticsData?.graphBankData || {})} height={180} />
                    ) : (
                      <div className="pt-4">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Card>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                  <Card
                    style={{
                      height: '100%',
                    }}
                    title={
                      <>
                        Interest Type{' '}
                        <Popover title="Interest Type" content={'Breakdown of Fixed and Variable loans in your portfolio.'}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                  >
                    {statisticsData?.graphInterestTypeData && !Object.values(statisticsData?.graphInterestTypeData).every((value) => value === null) ? (
                      <Pie {...config} data={convertGraphData(statisticsData?.graphInterestTypeData || {})} height={180} />
                    ) : (
                      <div className="pt-4">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            </Spin>
            <Spin spinning={loadingList}>
              <div className="row dashboard_wrapper">
                <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                  <Card
                    title={
                      <>
                        Access Granted{' '}
                        <Popover title="Access Granted" content={'Clients who have provided access to their loan data.'}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                    extra={
                      <span className="Route_showmore" onClick={() => navigate('/clients')}>
                        View More
                      </span>
                    }
                  >
                    <Table dataSource={listData?.clientData || []} columns={client_columns} size="small" pagination={false} className="p-0 m-0" />
                  </Card>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                  <Card
                    title={
                      <>
                        Access Pending{' '}
                        <Popover title="Access Pending" content={'Clients who have registered but have not yet granted access to you.'}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                    extra={
                      <span className="Route_showmore" onClick={() => navigate('/clients')}>
                        View More
                      </span>
                    }
                  >
                    <Table dataSource={listData?.referralUserData || []} columns={referrals_columns} size="small" pagination={false} className="p-0 m-0" />
                  </Card>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mt-3">
                  <Card
                    title={
                      <>
                        Awaiting Signup{' '}
                        <Popover title="Awaiting Signup" content={`Invited users who haven't signed up on The Property Accountant.`}>
                          <QuestionCircleOutlined className="help-tooltip-icon m-1" />
                        </Popover>
                      </>
                    }
                    extra={
                      <span className="Route_showmore" onClick={() => navigate('/onboarding')}>
                        View More
                      </span>
                    }
                  >
                    <Table dataSource={listData?.remainingOnboardUserData || []} columns={referrals_columns} size="small" pagination={false} className="p-0 m-0" />
                  </Card>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MortgageBrokerDashboard;
