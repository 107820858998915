import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const deleteAssignTaxtoUserSlice = createSlice({
    name: "deleteProperty",
    initialState,
    reducers: {
        deleteAssignTaxRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        deleteAssignTaxSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        deleteAssignTaxFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { deleteAssignTaxRequest, deleteAssignTaxSuccess, deleteAssignTaxFailure } =
    deleteAssignTaxtoUserSlice.actions;

export default deleteAssignTaxtoUserSlice.reducer;
