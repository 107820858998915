import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getUserDataListByIdSlice = createSlice({
    name: "GetUserdataListById",
    initialState,
    reducers: {
        getUserdataListByIdRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getUserdataListByIdSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getUserdataListByIdFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getUserdataListByIdSuccess, getUserdataListByIdFailure, getUserdataListByIdRequest } =
    getUserDataListByIdSlice.actions;

export default getUserDataListByIdSlice.reducer;
