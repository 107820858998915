import { getRequest } from '../../../utils/apiUtils';
import { uploadFileRequest, uploadFileSuccess, uploadFileFailure } from '../../reducers/slices/record_expense/uploadFile.slice';

export const STSToken = () => {
  return async (dispatch) => {
    await dispatch(uploadFileRequest());
    const { result, error } = await getRequest('auth/storage/token');

    if (!error) {
      return await dispatch(uploadFileSuccess(result));
    }
    return await dispatch(uploadFileFailure(result));
  };
};
