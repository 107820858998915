import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const bankStatementListSlice = createSlice({
  name: 'BankStatementList',
  initialState,
  reducers: {
    bankStatementListRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    bankStatementListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    bankStatementListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { bankStatementListRequest, bankStatementListSuccess, bankStatementListFailure } = bankStatementListSlice.actions;

export default bankStatementListSlice.reducer;
