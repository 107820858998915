import { deleteRequest, postRequest, putRequest } from '../../../utils/apiUtils';

export const uploadDocument = (document) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`user/documents`, document);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const updateDocument = (id, info) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`user/documents/${id}`, info);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteDocument = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`user/documents/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
