import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: false };

export const uploadFileSlice = createSlice({
  name: 'AddRecordExpense',
  initialState,
  reducers: {
    uploadFileRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    uploadFileSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    uploadFileFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { uploadFileRequest, uploadFileSuccess, uploadFileFailure } = uploadFileSlice.actions;

export default uploadFileSlice.reducer;
