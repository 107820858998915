import { getRequest } from '../../../utils/apiUtils';
import {
  getMortgageBrokerDashboardDataSuccess,
  getMortgageBrokerDashboardDataFailure,
  getMortgageBrokerDashboardDataRequest,
} from '../../reducers/slices/mortgage-dashboard/getMortgageBrokerDashboardData.slice';
import {
  getMortgageBrokerDashboardListDataSuccess,
  getMortgageBrokerDashboardListDataFailure,
  getMortgageBrokerDashboardListDataRequest,
} from '../../reducers/slices/mortgage-dashboard/getMortgageBrokerDashboardListData.slice';

export const getMortgageBrokerDashboardData = () => {
  return async (dispatch) => {
    await dispatch(getMortgageBrokerDashboardDataRequest());
    const { result, error } = await getRequest(`channel-partner/dashboard/statistics`);
    if (!error) {
      return await dispatch(getMortgageBrokerDashboardDataSuccess(result));
    }
    return await dispatch(getMortgageBrokerDashboardDataFailure(result));
  };
};

export const getMortgageBrokerDashboardListData = () => {
  return async (dispatch) => {
    await dispatch(getMortgageBrokerDashboardListDataRequest());
    const { result, error } = await getRequest(`channel-partner/dashboard/table`);
    if (!error) {
      return await dispatch(getMortgageBrokerDashboardListDataSuccess(result));
    }
    return await dispatch(getMortgageBrokerDashboardListDataFailure(result));
  };
};
