import { FrownOutlined } from '@ant-design/icons';
import { Card, Spin, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetReciptdocumentData } from '../../../../redux/actions/client-detail/clients-tabs/documentRecipt.action';

const Document = () => {
  const { transaction_id, user_id, transaction_type } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getDocument = useCallback(
    (id, currentClient, transaction_type) => {
      setLoading(true);
      dispatch(GetReciptdocumentData(id, currentClient, transaction_type)).then((res) => {
        if (res?.success) {
          window.open(res?.result?.receipt_path, '_self');
          setLoading(false);
        } else {
          notification.open({
            message: 'Failure',
            description: res?.payload.message || 'Something went wrong!',
            icon: <FrownOutlined style={{ color: 'red' }} />,
          });
          setLoading(false);
        }
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (transaction_id && user_id && transaction_type) {
      getDocument(transaction_id, user_id, transaction_type);
    }
  }, [transaction_id, user_id, transaction_type, getDocument]);

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <Spin spinning={loading} size="large">
            <Card></Card>
          </Spin>
        </div>
      </div>
    </main>
  );
};

export default Document;
