import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getMortgageBrokerClientListDataSlice = createSlice({
  name: 'GetMortgageBrokerClientListData',
  initialState,
  reducers: {
    getMortgageBrokerClientListDataRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getMortgageBrokerClientListDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getMortgageBrokerClientListDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getMortgageBrokerClientListDataSuccess, getMortgageBrokerClientListDataFailure, getMortgageBrokerClientListDataRequest } = getMortgageBrokerClientListDataSlice.actions;

export default getMortgageBrokerClientListDataSlice.reducer;
