import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const LogoutSlice = createSlice({
    name: "AddTaxAccountant",
    initialState,
    reducers: {
        getLogoutRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getLogoutSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getLogoutFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getLogoutRequest, getLogoutSuccess, getLogoutFailure } =
    LogoutSlice.actions;

export default LogoutSlice.reducer;
