import setAuthToken from './header';
import client from './Interceptor/axios';

const makeRequest = async (method, url, payload, headers = {}) => {
  try {
    const res = await client.request({
      method,
      url,
      data: payload,
      headers: { ...setAuthToken(), ...headers },
    });

    const { data, status } = res;

    if (status === 200) {
      return { result: data, error: false };
    }
    return { result: [], error: true };
  } catch (error) {
    return { result: [], error: true };
  }
};

export const getRequest = async (url) => {
  return makeRequest('get', url);
};

export const postRequest = async (url, payload = {}) => {
  return makeRequest('post', url, payload);
};

export const postRequestImport = async (url, payload = {}) => {
  return makeRequest('post', url, payload, { 'Content-Type': 'multipart/form-data' });
};

export const putRequest = async (url, payload) => {
  return makeRequest('put', url, payload);
};

export const putRequestImport = async (url, payload) => {
  return makeRequest('put', url, payload, { 'Content-Type': 'multipart/form-data' });
};

export const patchRequest = async (url, payload) => {
  return makeRequest('patch', url, payload);
};

export const deleteRequest = async (url, payload) => {
  return makeRequest('delete', url, payload);
};
