import { getRequest } from '../../../utils/apiUtils';
import { reviewRentalSummaryFailure, reviewRentalSummaryRequest, reviewRentalSummarySuccess } from '../../reducers/slices/rental_summary/reviewByIdSummay.slice';

export const ReviewRentalSummary = (id, id1) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/rent-summary/${id}`;
  } else {
    tempURl = `user/rent-summary/${id}?userId=${id1}`;
  }
  return async (dispatch) => {
    await dispatch(reviewRentalSummaryRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(reviewRentalSummarySuccess(result));
    }
    return await dispatch(reviewRentalSummaryFailure(result));
  };
};
