import React, { useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, notification, Spin } from 'antd';
import { updatePassword } from 'aws-amplify/auth';

const ChangePassword = ({ setIsOpen = () => {}, modal = false }) => {
  const [spin, setSpin] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setSpin(true);

      const newValue = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };

      await updatePassword(newValue);

      notification.success({
        message: 'Success',
        description: 'Password changed successfully!',
      });
      setIsOpen(false);
      setSpin(false);
      form.resetFields();
    } catch (error) {
      if (error.message === 'Incorrect username or password.') {
        notification.error({
          message: 'Error',
          description: 'Old password is incorrect!',
        });
      } else {
        notification.error({
          message: 'Error',
          description: error.message || 'Something went wrong!',
        });
      }
      setSpin(false);
    }
  };
  return (
    <Card bordered={false} className="shadow-none profile-content-card profile-card-parent" title={!modal && 'Change Password'}>
      {modal && <p className="profile-item-heading">Change Password</p>}

      <Spin spinning={spin}>
        <Form
          form={form}
          layout="vertical"
          requiredMark={'optional'}
          id="forgotPassword"
          onFinish={handleFinish}
          className="mt-2 profilerForm"
          onFieldsChange={() => {
            const fieldsError = form.getFieldsError();
            const hasError = fieldsError.some((field) => field.errors.length > 0);
            const values = form.getFieldsValue();
            const allFieldsFilled = Object.values(values).every((value) => value);

            setIsChanged(!hasError && allFieldsFilled);
          }}
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your Old Password!',
              },
            ]}
          >
            <Input.Password size="small" prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Old Password" />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your New Password!',
              },
              {
                min: 8,
                message: 'Password must be at least 8 characters long!',
              },
              {
                pattern: /^[\S]+.*[\S]+$/,
                message: 'Password must not start or end with whitespace and should not have consecutive whitespaces!',
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve(); // Required rule already handles empty input
                  }

                  const errors = [];
                  if (!/[a-z]/.test(value)) {
                    errors.push('at least one lowercase letter');
                  }
                  if (!/[A-Z]/.test(value)) {
                    errors.push('at least one uppercase letter');
                  }
                  if (!/[0-9]/.test(value)) {
                    errors.push('at least one number');
                  }
                  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                    errors.push('at least one special character');
                  }

                  if (errors.length > 0) {
                    return Promise.reject(`Password must contain ${errors.join(', ')}.`);
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password size="small" prefix={<LockOutlined className="site-form-item-icon" />} placeholder="New Password" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your Confirm Password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password size="small" prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirm Password" />
          </Form.Item>
          <div className={`${!modal ? 'justify-content-start' : 'justify-content-center'} w-100 d-flex `}>
            <Form.Item>
              <Button htmlType="submit" className={`${!isChanged ? 'text-muted' : ''}  `} type={`${!isChanged ? '' : 'primary'}`} disabled={!isChanged}>
                Save Changes
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Card>
  );
};

export default ChangePassword;
