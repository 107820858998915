import React from 'react';
import { UserTypes } from '../../common/userTypes';
import MortgageBrokerDashboard from './MortgageBrokerDashboard';
import TaxDashboard from './TaxAccountantDashboard';

const Dashboard = () => {
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};

  return <>{user_type === UserTypes.CHANNEL_PARTNER ? <MortgageBrokerDashboard /> : <TaxDashboard />}</>;
};

export default Dashboard;
