import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: false };

export const verifyUserSlice = createSlice({
  name: 'VerifyUser',
  initialState,
  reducers: {
    verifyUserRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    verifyUserSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    verifyUserFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { verifyUserRequest, verifyUserSuccess, verifyUserFailure } = verifyUserSlice.actions;

export default verifyUserSlice.reducer;
