import { Badge, Button, List, Modal, notification, Spin } from 'antd';
import React, { useRef, useState } from 'react';
import { getIcon, getTitle } from '../../common/NotificationUtil';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { LoanReject } from '../../../redux/actions/dashboard/loanReject.action';
import { UserTypes } from '../../common/userTypes';
import { useSelector } from 'react-redux';

const PendingTasks = ({ list = [], clearNotifications, isTaxAccountant = false, loading }) => {
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const dispatch = useDispatch();
  const admin = JSON.parse(localStorage.getItem('admin')) || {};

  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const ref = useRef();

  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);

  const clientVirtualEmail = clientDetail?.data?.result?.virtual_email;

  const propertyAddress = list?.find((item) => item.type === 'check_investment_rent_mail');

  const NOLoan = (id) => {
    setSpin(true);
    dispatch(LoanReject(id)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
      }
      setSpin(false);
      clearNotifications(true, id);
    });
  };

  const copyText = () => {
    navigator.clipboard.writeText(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    notification.success({
      message: 'Success',
      description: 'Text copied.',
    });
  };

  const emailText = () => {
    const encodedEmailContent = encodeURIComponent(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    // Create the mailto link
    const mailtoLink = `mailto:${admin?.virtual_email}?subject=Rent Summary&body=${encodedEmailContent}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  const renderActionItem = (item) => {
    if (item.type === 'check_property_loan' && admin.user_type === UserTypes.USER) {
      return !item.loan_id ? (
        <>
          <Button size="middle" className="mr-2" onClick={() => navigate(`/property/edit/${item.property_id}`, { state: { fromSameSite: true } })}>
            Update
          </Button>
          <Button size="middle" onClick={() => NOLoan(item.property_id)}>
            Loan Not Applicable
          </Button>
        </>
      ) : (
        <Button size="middle" onClick={() => navigate(`/bank`, { state: { fromSameSite: true } })}>
          Connect
        </Button>
      );
    } else if (item.type === 'check_investment_rent_mail') {
      return (
        <Button size="middle" onClick={() => setIsOpenEmail(true)}>
          Email Now
        </Button>
      );
    } else {
      return '';
    }
  };
  return (
    <div>
      <Spin spinning={spin || loading}>
        <List
          itemLayout="horizontal"
          dataSource={list || []}
          renderItem={(item, index) => (
            <List.Item extra={!item?.complete && renderActionItem(item)} key={index} style={item?.complete ? { background: '#f6f6f6' } : {}}>
              <List.Item.Meta
                className={'meta'}
                avatar={getIcon(item?.type)}
                title={
                  <>
                    {!item?.complete ? (
                      <div className={'title'}>
                        {getTitle(item?.type)}
                        {!item?.complete && <div className="extra"></div>}
                      </div>
                    ) : (
                      <Badge.Ribbon text="Completed" color="#56aaaa">
                        <div className={'title d-flex'}>
                          {getTitle(item?.type)}
                          {!item?.complete && <div className="extra">{renderActionItem(item)}</div>}
                        </div>
                      </Badge.Ribbon>
                    )}
                  </>
                }
                description={item?.message}
              />
            </List.Item>
          )}
        />
        <Modal onCancel={() => setIsOpenEmail(false)} footer={null} centered width={550} style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpenEmail}>
          <div ref={ref}>
            Dear Property Manager, <br />
            <br />
            How are you? I hope you are doing well.
            <br />
            <br />
            Going forward, please add my below email to send my monthly rental summary for all my properties "{isTaxAccountant ? clientVirtualEmail : admin?.virtual_email}"
            <br />
            <br />
            Property Details as below : <br />
            {propertyAddress?.property_address}
            <br />
            <br />
            Please confirm by email once this has been action. <br />
            <br />
            Have a nice day <br />
            Regards
          </div>

          <div className="p-2 text-end">
            <Button className="copy_btn3" onClick={() => copyText()}>
              Copy
            </Button>
            <Button className="copy_btn1" onClick={() => emailText()}>
              Send Mail
            </Button>
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default PendingTasks;
