import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const forgotPasswordUpdateSlice = createSlice({
    name: "ForgotPasswordUPdate",
    initialState,
    reducers: {
        ForgotPasswordUpdateRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        ForgotPasswordUpdateSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        ForgotPasswordUpdateFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { ForgotPasswordUpdateRequest, ForgotPasswordUpdateSuccess, ForgotPasswordUpdateFailure } = forgotPasswordUpdateSlice.actions;

export default forgotPasswordUpdateSlice.reducer;
