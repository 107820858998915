import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getRejectuserSlice = createSlice({
    name: "GetRejectUser",
    initialState,
    reducers: {
        getRejectUserRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        getRejectUserSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        getRejectUserFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { getRejectUserRequest, getRejectUserSuccess, getRejectUserFailure } =
    getRejectuserSlice.actions;

export default getRejectuserSlice.reducer;
