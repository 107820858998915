import { deleteRequest } from '../../../utils/apiUtils';
import { DeleteSettlementListFailure, DeleteSettlementListRequest, DeleteSettlementListSuccess } from '../../reducers/slices/rental_summary/deleteRentalSummarylist.Slice';

export const DeleteSettlementList = (id, id1) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/rent-summary/${id}`;
  } else {
    tempURl = `user/rent-summary/${id}?userId=${id1}`;
  }
  return async (dispatch) => {
    await dispatch(DeleteSettlementListRequest());
    const { result, error } = await deleteRequest(tempURl);

    if (!error) {
      return await dispatch(DeleteSettlementListSuccess(result));
    }
    return await dispatch(DeleteSettlementListFailure(result));
  };
};
