import { createSlice } from '@reduxjs/toolkit';

const initialState = { BankData: [], error: false, isLoading: true };

export const getClientActionItemsSlice = createSlice({
  name: 'GetActionItemData',
  initialState,
  reducers: {
    GetActionItemDashboardDataRequest: (state, action) => {
      state.BankData = [];
      state.isLoading = true;
    },
    GetActionItemDashboardDataSuccess: (state, action) => {
      state.BankData = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    GetActionItemDashboardDataFailure: (state, action) => {
      state.BankData = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { GetActionItemDashboardDataRequest, GetActionItemDashboardDataSuccess, GetActionItemDashboardDataFailure } = getClientActionItemsSlice.actions;

export default getClientActionItemsSlice.reducer;
