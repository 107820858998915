import { getRequest } from '../../../../utils/apiUtils';
import { getClientBankDetailsFailure, getClientBankDetailsRequest, getClientBankDetailsSuccess } from '../../../reducers/slices/client-detail/clients-tabs/Client-Bankconnetion.Slice';

export const GetClinetBankDetails = (id, propertyId) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `tax-accountant/client/bank-info?propertyId=${propertyId}`;
    } else {
      tempURl = `tax-accountant/client/bank-info?userId=${id}&propertyId=${propertyId}`;
    }
    await dispatch(getClientBankDetailsRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getClientBankDetailsSuccess(result));
    }
    return await dispatch(getClientBankDetailsFailure(result));
  };
};
