import { putRequest } from "../../../utils/apiUtils";
import {
    taxAccountantNoSuccess,
    taxAccountantNoFailure,
    taxAccountantNoRequest,
} from "../../reducers/slices/auth/taxAccountantNo.slice";

export const TaxAccountantNo = () => {
    return async (dispatch) => {
        await dispatch(taxAccountantNoRequest());
        const { result, error } = await putRequest(`user/taxaccountant/popup-close`);

        if (!error) {
            return await dispatch(taxAccountantNoSuccess(result));
        }
        return await dispatch(taxAccountantNoFailure(result));
    };
};
