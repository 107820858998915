import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const reviewRentalSummarySlice = createSlice({
    name: "GetTransactions",
    initialState,
    reducers: {
        reviewRentalSummaryRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        reviewRentalSummarySuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        reviewRentalSummaryFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { reviewRentalSummaryRequest, reviewRentalSummarySuccess, reviewRentalSummaryFailure } =
    reviewRentalSummarySlice.actions;

export default reviewRentalSummarySlice.reducer;
